const initState = {
  boolTrue: true,
  boolFalse: false,
  objNull: null,
  objEmpty: {},
  arrayEmpty: [],
  stringEmpty: '',
  numberZero: 0,
  lang: 'en',
  internet: true,
  isLoading: true,
  header: {},
  interval: 240,
  initCurrency: {
    value: 1,
    label: 'USD'
  }
}

export default initState
