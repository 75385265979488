import React, { useContext, useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import style from './style.module.scss'
import IconCustom from 'components/common/IconCustom'
import AboutToken from 'components/common/AboutToken'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import { LiquidityContext } from 'context/liquidity/liquidityContext'
import { formatNumberBro, getLength, lowerCase, nFormatter } from 'common/functions'
import BaseAPI from 'controller/api/BaseAPI'
import { useSelector } from 'react-redux'
import usePairs from 'common/poolEvm/hooks/usePairs'
import ReduxServices from 'common/redux'
import InfoTokenEarn from 'components/InfoTokenEarn'
import Tooltip from 'components/Tooltip'
import { getPriceLp } from 'controller/Library/evm'
import PuffLoading from 'components/common/PuffLoading'
import { Link } from 'react-router-dom'
import { useWallet } from '@coin98-com/wallet-adapter-react'
import { MAIN_COIN_AMM, W_MAIN_COIN } from 'common/poolEvm/constants'

const ManagePool = ({ onBack }) => {
  const { t } = useTranslation()
  const { address } = useWallet()

  const liquidityContext = useContext(LiquidityContext)
  const { fromCoin, toCoin, isRefreshLPAmount } = liquidityContext

  const [isLoadingPool, setIsLoadingPool] = useState(true)
  const [poolInfo, setPoolInfo] = useState({})

  const [arrTotalYourLp, setArrTotalYourLp] = useState([])
  const [priceLp, setPriceLp] = useState(0)
  const chainActive = useSelector((state) => state.chainActive)

  const mainCoin = MAIN_COIN_AMM[chainActive]
  const wrapMainCoin = W_MAIN_COIN[chainActive]
  const isFromMain = !fromCoin?.address && (lowerCase(fromCoin?.symbol) === lowerCase(mainCoin?.symbol))
  const isToMain = !toCoin?.address && (lowerCase(toCoin?.symbol) === lowerCase(mainCoin?.symbol))

  const newAddressFromCoin = { ...fromCoin, address: isFromMain ? wrapMainCoin?.address : fromCoin.address }
  const newAddressToCoin = { ...toCoin, address: isToMain ? wrapMainCoin?.address : toCoin.address }

  const { getPair, getInfoPoolAddress } = usePairs(chainActive, address)

  const isEmptyYourLp = getLength(arrTotalYourLp) === 0

  const checkIsPool = Object.keys(poolInfo)
  const getLenghtPool = getLength(checkIsPool)
  const yourLPFarming = isEmptyYourLp
    ? 0
    : arrTotalYourLp.map(item => item.balanceStakedUSD).reduce((accumulator, item) => parseFloat(accumulator) + parseFloat(item))

  const yourReward = isEmptyYourLp
    ? 0
    : arrTotalYourLp.map(item => item.totalRewardUSD).reduce((accumulator, item) => parseFloat(accumulator) + parseFloat(item))

  const itemFarm = arrTotalYourLp.find(item => item.balanceStaked)

  const linkFarm = `/farm?poolAddress=${get(poolInfo, 'poolAddress')}&completed=${itemFarm?.isCompleted}`

  const Div = ({ children, ...props }) => {
    return itemFarm ? <Link {...props}>{children}</Link> : <div {...props}>{children}</div>
  }

  useEffect(() => {
    getPoolInfo()
  }, [JSON.stringify(fromCoin), JSON.stringify(toCoin), address, isRefreshLPAmount])

  const getPoolInfo = async () => {
    setIsLoadingPool(true)

    const findPoolAddress = await getPair(newAddressFromCoin?.address, newAddressToCoin?.address)

    if (findPoolAddress) {
      const pool = await getInfoPoolAddress(findPoolAddress)

      const newPriceLp = await getPriceLp({
        token0: pool?.token0?.tokenInfo,
        token1: pool?.token1?.tokenInfo,
        chain: chainActive,
        totalLp: get(pool, 'totalSupplyLp', 0),
        balanceToken0Pool: get(pool, 'token0.totalBalancePool', 0),
        balanceToken1Pool: get(pool, 'token1.totalBalancePool', 0)
      })

      ReduxServices.loadYourLp({ poolAddress: findPoolAddress, addressWallet: address, priceLp: newPriceLp, chainActive, callBack: setArrTotalYourLp })

      setPriceLp(newPriceLp || 0)

      const response = await BaseAPI.postData('baryon/pool/info', {
        poolAddress: findPoolAddress,
        chain: chainActive
      })
      if (response) {
        setPoolInfo({ ...response, ...pool })
      }
    } else {
      setPoolInfo({})
    }
    setIsLoadingPool(false)
  }

  const arrYourLp = arrTotalYourLp.slice()
    .map(it => {
      return {
        symbol: 'Farm',
        type: 'farm',
        address: get(it, 'contractAddress'),
        lpAddress: get(poolInfo, 'poolAddress'),
        isCompleted: get(it, 'isCompleted')
      }
    })

  const listTokenInfo = useMemo(() => {
    if (!poolInfo) return []

    const defaultList = [
      {
        ...newAddressFromCoin,
        type: 'token'
      },
      {
        ...newAddressToCoin,
        type: 'token'
      },
      {
        symbol: 'LP POOL',
        type: 'pool',
        address: get(poolInfo, 'poolAddress')
      }
    ]

    return [...defaultList, ...arrYourLp]
  })

  const fromCoinShow = isFromMain ? mainCoin : fromCoin
  const toCoinShow = isToMain ? mainCoin : toCoin

  return (
    <div style={{ minHeight: '410px' }} className={cn(style['manage-pool'])}>
      <button onClick={onBack}>
        <IconCustom className={'me-3'} type={'default'} icon="web_arrow_left" />
        <h5 className="heading-5 lx-medium">{t('managePool')}</h5>
      </button>

      {isLoadingPool
        ? <div className='pull-loading'>
          <PuffLoading/>
        </div>
        : <div className="manage-content">
          <div className="content-token">
            <div className="token-item">
              <div>
                <AboutToken imgToken={get(fromCoinShow, 'image')} />
              </div>
              <span className="lx-medium text-uppercase">{get(fromCoinShow, 'symbol')}</span>
            </div>
            <span>/</span>
            <div className="token-item">
              <div>
                <AboutToken imgToken={get(toCoinShow, 'image')} />
              </div>
              <span className="lx-medium text-uppercase">{get(toCoinShow, 'symbol')}</span>
            </div>
            {getLenghtPool === 0
              ? ''
              : <Tooltip
                className="ml-2 cursor-pointer information-icon"
                contentClassName="info-liquidity-tooltip"
                content={<InfoTokenEarn listTokenInfo={listTokenInfo} />}
              >
                <IconCustom className={'icon-tooltip'} icon="web_info" type={'default'} />
              </Tooltip>}
          </div>
          <div className="content-total">
            <Row gutter={[8, 8]}>
              <Col span={8}>
                <div className="content-total__item">
                  <span>
                    {ReduxServices.formatPrice(get(poolInfo, 'totalSupplyLp', 0) * priceLp, true)}
                    {/* ${nFormatter(get(poolInfo, 'totalSupplyLp', 0) * priceLp)} */}
                  </span>
                  <span>{t('liquidity')}</span>
                </div>
              </Col>

              <Col span={8}>
                <div className="content-total__item">
                  <span>
                    {/* ${formatNumberBro({ number: get(poolInfo, 'volume24h', 0), mantissa: 2 })} */}

                    {ReduxServices.formatPrice(get(poolInfo, 'volume24h', 0))}
                  </span>
                  <span>{t('volume24h')}</span>
                </div>
              </Col>

              <Col span={8}>
                <div className="content-total__item">
                  <span>
                  ${formatNumberBro({ number: get(poolInfo, 'fee24h', 0), mantissa: 2 })}
                  </span>
                  <span>{t('fee24h')}</span>
                </div>
              </Col>
            </Row>
          </div>
          <div className="content-detail">
            <Row gutter={[16, 16]}>
              <Col span={12} >
                <div className="content-detail__item">
                  <span className='lx-medium text-uppercase'>
                    {formatNumberBro({ number: get(poolInfo, 'shareOfPool', 0), mantissa: 2 })}%
                  </span>
                  <span>{t('yourPoolShare')}</span>
                </div>
              </Col>
              <Col span={12} >
                <div className="content-detail__item">
                  <span className='lx-medium'>
                    {ReduxServices.formatPrice(get(poolInfo, 'balanceOfLp', 0) * priceLp)}
                  </span>
                  <span>{t('yourPoolToken')}</span>
                </div>
              </Col>
              <Col span={12} >
                <div className="content-detail__item">
                  <span className='lx-medium text-uppercase'>
                    {/* {formatNumberBro({ number: get(poolInfo, 'token0.balanceTokenUser', 0), mantissa: 2 })} */}
                    { get(poolInfo, 'token0.balanceTokenUser', 0) > 100000000
                      ? formatNumberBro({ number: get(poolInfo, 'token0.balanceTokenUser', 0), mantissa: 2 })
                      : nFormatter(get(poolInfo, 'token0.balanceTokenUser', 0))
                    }
                    {' '}
                    {get(poolInfo, 'token0.tokenInfo.symbol')}
                  </span>
                  <span>{t('pooledSign')}</span>
                </div>
              </Col>
              <Col span={12} >
                <div className="content-detail__item">
                  <span className='lx-medium text-uppercase'>
                    {formatNumberBro({ number: get(poolInfo, 'token1.balanceTokenUser', 0), mantissa: 2 })}
                    {' '}
                    {get(poolInfo, 'token1.tokenInfo.symbol')}
                  </span>
                  <span>{t('pooledSign')}</span>
                </div>
              </Col>
            </Row>
          </div>
          <Div to={linkFarm} className="content-total__end">
            <Row gutter={[8, 8]}>
              <Col span={12} >
                <div className="end-item">
                  <span> {ReduxServices.formatPrice(yourLPFarming)}</span>
                  <lable className='text-content'>{t('yourLPFarming')}</lable>
                </div>
              </Col>
              <Col span={12}>
                <div className="end-item">
                  <span> {ReduxServices.formatPrice(yourReward)}</span>
                  <lable className='text-content'> {t('yourReward')}</lable>
                </div>
              </Col>
            </Row>
          </Div>
        </div>}
    </div>
  )
}

export default ManagePool
