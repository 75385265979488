
import abiFarmV2 from 'common/abi/abiFarmV2'
import ERC20 from 'common/abi/ERC20'
import { convertDecimalToHex, convertWeiToBalance } from 'common/functions'
import { CONTRACT_FARM_STAKE_V2, CONTRACT_FARM_STAKE_V1 } from 'common/poolEvm/constants'
import { useSelector } from 'react-redux'
import { useWallet } from '@coin98-com/wallet-adapter-react'
import useServices from 'controller/Library/useServices'
import get from 'lodash/get'

const { genWeb3, genContract, estimateGasTxs, zeroAddress } = require('controller/Library/evm')
const { useState, useEffect } = require('react')

const useFarmV2 = (lpAddress, gasPrice, isV2, factoryAddress) => {
  const { address } = useWallet()
  const { sendRawTxn } = useServices()

  const chainActive = useSelector((state) => state.chainActive)

  const [balanceCoinStake, setBalanceCoinStake] = useState(0)

  const [isLoading, setIsLoading] = useState(false)

  const web3 = genWeb3(chainActive)

  const addressContract = isV2 ? (factoryAddress || CONTRACT_FARM_STAKE_V2[chainActive]) : CONTRACT_FARM_STAKE_V1[chainActive]

  const contract = genContract(web3, abiFarmV2, addressContract)

  useEffect(() => {
    loadInfo()
  })

  const loadInfo = async () => {
    if (!address) return
    loadBalanceCoinStake()
  }

  const loadBalanceCoinStake = async () => {
    if (lpAddress === zeroAddress) {
      const balance = await web3.eth.getBalance(address)
      setBalanceCoinStake(convertWeiToBalance(balance))
      return
    }

    if (lpAddress) {
      const contractCoinStake = genContract(web3, ERC20, lpAddress)
      const balance = await contractCoinStake.methods.balanceOf(address).call()
      const decimals = await contractCoinStake.methods.decimals().call()
      setBalanceCoinStake(convertWeiToBalance(balance, decimals))
    }
  }

  const enterStaking = async ({
    amount,
    pid,
    callBack,
    callBackFail,
    isReturnEstimateGasLimit,
    value
  }) => {
    try {
      setIsLoading(true)

      const data = contract.methods.deposit(pid, amount).encodeABI()

      const rawTxs = {
        from: address,
        to: addressContract,
        data,
        callBack,
        callBackFail,
        isWaitDone: true,
        value: convertDecimalToHex(value || 0)
      }

      if (isReturnEstimateGasLimit) {
        const gasLimitEst = await estimateGasTxs(rawTxs, web3, chainActive, null, null, callBackFail(isReturnEstimateGasLimit))
        setIsLoading(false)
        return gasLimitEst
      }

      await sendRawTxn(rawTxs)

      setIsLoading(false)
    } catch (err) {
      console.log({ err })
      setIsLoading(false)
    }
  }

  const leaveStaking = async ({
    item,
    amount,
    pid,
    callBack,
    callBackFail,
    isReturnEstimateGasLimit
  }) => {
    try {
      setIsLoading(true)
      let data

      if (item) {
        // check emergencyWithdraw
        const pendingReward = get(item, 'pendingReward', 0)
        const rewarder = get(item, 'rewarder')
        const rewardTokenAddress = get(item, 'rewardTokens[0].address')
        const rewardTokenDecimals = get(item, 'rewardTokens[0].decimal') || get(item, 'rewardTokens[0].decimals')
        const contractCoinReward = genContract(web3, ERC20, rewardTokenAddress)
        const balanceRewarderWei = await contractCoinReward.methods.balanceOf(rewarder).call()
        const balanceRewarder = convertWeiToBalance(balanceRewarderWei, rewardTokenDecimals)

        if (parseFloat(pendingReward) > parseFloat(balanceRewarder)) {
          data = contract.methods.emergencyWithdraw(pid).encodeABI()
        }
      }

      if (!data) data = contract.methods.withdrawAndHarvest(pid, amount).encodeABI()

      const rawTxs = {
        from: address,
        to: addressContract,
        data,
        callBack,
        callBackFail: callBackFail(),
        isWaitDone: true
      }

      if (isReturnEstimateGasLimit) {
        const gasLimitEst = await estimateGasTxs(rawTxs, web3, chainActive, null, null, callBackFail(isReturnEstimateGasLimit))
        setIsLoading(false)
        return gasLimitEst
      }

      await sendRawTxn(rawTxs)

      setIsLoading(false)
    } catch (err) {
      console.log({ err })
      setIsLoading(false)
    }
  }

  const harvest = async ({
    pid,
    callBack,
    callBackFail,
    isReturnEstimateGasLimit
  }) => {
    try {
      setIsLoading(true)

      const data = contract.methods.harvest(pid).encodeABI()
      const rawTxs = {
        from: address,
        to: addressContract,
        data,
        callBack,
        callBackFail: callBackFail(),
        isWaitDone: true
      }

      if (isReturnEstimateGasLimit) {
        const gasLimitEst = await estimateGasTxs(rawTxs, web3, chainActive, null, null, callBackFail(isReturnEstimateGasLimit))
        setIsLoading(false)
        return gasLimitEst
      }

      await sendRawTxn(rawTxs)

      setIsLoading(false)
    } catch (err) {
      console.log({ err })
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    contract,
    enterStaking,
    leaveStaking,
    harvest,
    balanceCoinStake
  }
}

export default useFarmV2
