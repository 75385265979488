import React, { forwardRef } from 'react'

const CustomToggle = ({ children, onClick, disabled }, ref) => {
  const handleClick = (e) => {
    e.preventDefault()
    onClick(e)
  }
  return (
    <div
      className='cursor-pointer d-flex align-items-center'
      ref={ref}
      onClick={disabled
        ? null
        : handleClick }
    >
      {children}
    </div>
  )
}

export default forwardRef(CustomToggle)
