import abiFarmBaryon from 'common/abi/abiFarmBaryon'
import abiStakeContract from 'common/abi/abiStakeContract'
import ERC20 from 'common/abi/ERC20'
import {
  genContract,
  genWeb3,
  generateApprovedData,
  estimateGasTxs,
  getTokenInfo
} from 'controller/Library/evm'
import { useSelector } from 'react-redux'
import { convertBalanceToWei, convertWeiToBalance } from 'common/functions'
import get from 'lodash/get'
import { CONTRACT_MASTER_CHEF } from 'common/constants'
import { BIG_TEN, BIG_ZERO } from 'common/farm/contants'
import BigNumber from 'bignumber.js'
import BaseAPI from 'controller/api/BaseAPI'
import { getPrice } from 'common/poolEvm/function'
import { getFarmApr } from 'common/stake/functions'
import { useWallet } from '@coin98-com/wallet-adapter-react'
import useServices from 'controller/Library/useServices'

const { useState } = require('react')

const useFarmContract = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { address } = useWallet()
  const { sendRawTxn } = useServices()

  const chainActive = useSelector((state) => state.chainActive)
  const web3 = genWeb3(chainActive)
  const contractID = CONTRACT_MASTER_CHEF[chainActive]
  const contract = genContract(web3, abiStakeContract, contractID)

  const getAllInfoFarm = async () => {
    try {
      const response = await BaseAPI.getData('baryonFarm')
      const listFarm = get(response, 'data', [])
      const infos = await Promise.all(
        listFarm.map(async (farm) => {
          const response = await fetchInfoFarm(farm)
          return response.data
        })
      )
      return { isErr: false, data: infos }
    } catch (err) {
      return { isErr: true, data: err }
    }
  }

  const fetchInfoCoinLocal = async (chain, address) => {
    let info = window.wallet.findCoinLocal(chain, address)
    if (!info) {
      info = await getTokenInfo(address, chain)
    }

    return info
  }

  const fetchInfoFarm = async (farm) => {
    try {
      const {
        pid,
        lpAddresses,
        stakedTokenAddress,
        rewardTokenAddress,
        earnTokenAddress
      } = farm
      const contractLp = genContract(web3, abiFarmBaryon, lpAddresses)
      const tokenStakeContract = genContract(web3, ERC20, stakedTokenAddress)
      const tokenRewardContract = genContract(web3, ERC20, rewardTokenAddress)

      const fetchBalanceLpInPool = address
        ? contractLp.methods.balanceOf(address).call()
        : 0
      const fetchFarmUserStakedBalances = address
        ? contract.methods.userInfo(pid, address).call()
        : 0
      const fetchFarmUserEarnings = address
        ? contract.methods.pendingBARY(pid, address).call()
        : 0
      const fetchFarmUserAllowances = address
        ? contractLp.methods.allowance(address, contractID).call()
        : 0
      const fetchFarmInfo = contract.methods.poolInfo(pid).call()
      const fetchTotalAllocPoint = contract.methods.totalAllocPoint().call()
      const fetchLpTokenMasterChef = contractLp.methods
        .balanceOf(contractID)
        .call()
      const fetchLpTokenBalanceMC = contractLp.methods
        .balanceOf(contractID)
        .call()
      const fetchLpTotalSupply = contractLp.methods.totalSupply().call()

      const fetchTokenBalanceLP = tokenStakeContract.methods
        .balanceOf(lpAddresses)
        .call()
      const fetchQuoteTokenBalanceLP = tokenRewardContract.methods
        .balanceOf(lpAddresses)
        .call()

      const fetchInfoAll = await Promise.all([
        fetchInfoCoinLocal(chainActive, stakedTokenAddress),
        fetchInfoCoinLocal(chainActive, rewardTokenAddress),
        fetchInfoCoinLocal(chainActive, earnTokenAddress),
        fetchBalanceLpInPool,
        fetchFarmUserStakedBalances,
        fetchFarmUserEarnings,
        fetchFarmUserAllowances,
        fetchFarmInfo,
        fetchTotalAllocPoint,
        fetchLpTokenMasterChef,
        fetchLpTokenBalanceMC,
        fetchLpTotalSupply,
        fetchTokenBalanceLP,
        fetchQuoteTokenBalanceLP
      ])

      const [
        infoStakeToken,
        infoRewardToken,
        infoEarnToken,
        balanceLpInPool,
        farmUserStakedBalances,
        farmUserEarnings,
        farmUserAllowances,
        farmInfo,
        totalAllocPoint,
        lpTokenMasterChef,
        lpTokenBalanceMC,
        lpTotalSupply,
        tokenBalanceLP,
        quoteTokenBalanceLP
      ] = fetchInfoAll

      const decimalToken0 = get(infoStakeToken, 'decimal', infoStakeToken.decimals)
      const decimalToken1 = get(infoRewardToken, 'decimal', infoRewardToken.decimals)

      const lpTokenBalanceMCBigNumber = new BigNumber(lpTokenBalanceMC)

      const lpTokenRatio = lpTokenBalanceMCBigNumber.div(
        new BigNumber(lpTotalSupply)
      )

      // Raw amount of token 0 in the LP, including those not staked
      const tokenAmountTotal = new BigNumber(tokenBalanceLP).div(
        BIG_TEN.pow(decimalToken0)
      )
      // Raw amount of token 1 in the LP, including those not staked
      const quoteTokenAmountTotal = new BigNumber(quoteTokenBalanceLP).div(
        BIG_TEN.pow(decimalToken1)
      )

      // Amount of token 1 in the LP that are staked in the MC
      const tokenAmountMc = tokenAmountTotal.times(lpTokenRatio)

      // Total staked in LP, in quote token value
      const lpTotalInToken = tokenAmountMc.times(new BigNumber(2))

      const priceToken0 = await getPrice(infoStakeToken, chainActive)
      const priceToken1 = await getPrice(infoRewardToken, chainActive)

      const priceTokenEarn = await getPrice(infoEarnToken, chainActive)
      const totalLiquidity = lpTotalInToken.times(priceToken0)

      const allocPoint = farmInfo
        ? new BigNumber(farmInfo.allocPoint)
        : BIG_ZERO
      const poolWeight = totalAllocPoint
        ? allocPoint.div(new BigNumber(totalAllocPoint))
        : BIG_ZERO

      const farmApr = getFarmApr(
        poolWeight,
        priceTokenEarn,
        totalLiquidity,
        lpAddresses
      )

      const multiplier = allocPoint.div(100)

      const userInfo = {
        balance: parseFloat(convertWeiToBalance(balanceLpInPool, 18)),
        amount: parseFloat(
          convertWeiToBalance(get(farmUserStakedBalances, 'amount', 0), 18)
        ),
        earning: parseFloat(convertWeiToBalance(farmUserEarnings, 18)),
        allowance: parseFloat(farmUserAllowances) > 0
      }

      const data = {
        ...farm,
        ...fetchFarmInfo,
        totalLiquidity: totalLiquidity.toNumber(),
        lpTotalInToken: lpTotalInToken.toNumber(),
        multiplier: multiplier.toNumber(),
        poolWeight: poolWeight.toNumber(),
        totalAllocPoint: totalAllocPoint,
        lpTokenBalanceMC: lpTokenBalanceMCBigNumber.toNumber(),
        stakeToken: {
          ...infoStakeToken,
          price: parseFloat(priceToken0) || 0
        },
        rewardToken: {
          ...infoRewardToken,
          price: parseFloat(priceToken1) || 0
        },
        tokenPriceVsQuote: quoteTokenAmountTotal
          .div(tokenAmountTotal)
          .toNumber(),
        apr: farmApr.cs98RewardsApr,
        earnToken: {
          ...infoEarnToken,
          price: parseFloat(priceTokenEarn)
        },
        userInfo: {
          ...userInfo
        }
      }
      return { isErr: false, data }
    } catch (err) {
      console.log({ err })
      return { isErr: true, data: err }
    }
  }

  const handleActionsFarm = async ({
    gas,
    amount,
    callBack,
    callBackFail,
    poolId,
    type
  }) => {
    try {
      setIsLoading(true)
      const objType = {
        DEPOSIT: 'deposit',
        WITHDRAW: 'withdraw',
        HARVEST: 'deposit'
      }
      const data = contract.methods[objType[type]](poolId, amount).encodeABI()
      const rawTxs = {
        to: contractID,
        data,
        callBack,
        callBackFail,
        isWaitDone: true
      }

      const response = await sendRawTxn(rawTxs)

      setIsLoading(false)
      return { isErr: false, data: response?.data }
    } catch (err) {
      console.log({ err })
      return { isErr: true, data: 'txsFail' }
    }
  }

  const onApproveStaking = async ({ address, callBack, callBackFail, gas }) => {
    try {
      // numApprove === undefined ---> Unlimited
      const dataTxs = generateApprovedData(web3, address, contractID)

      if (dataTxs) {
        const generateTxs = {
          to: address,
          data: dataTxs,
          callBack,
          callBackFail,
          isWaitDone: true
        }

        await sendRawTxn(generateTxs)
      }
    } catch (err) {
      console.log({ err })
    }
  }

  const onEstimateGasApprove = async (gasPrice, address) => {
    const dataTxs = generateApprovedData(web3, address, contractID)
    const generateTxs = {
      from: address,
      to: address,
      data: dataTxs,
      gasPrice: convertBalanceToWei(gasPrice, 9)
    }

    const gasLimitEst = await estimateGasTxs(generateTxs, web3, chainActive)

    return gasLimitEst
  }

  return {
    isLoading,
    getAllInfoFarm,
    handleActionsFarm,
    enableContract: onApproveStaking,
    fetchInfoFarm,
    onEstimateGasApprove,
    fetchInfoCoinLocal
  }
}

export default useFarmContract
