import BaseAPI from 'controller/api/BaseAPI'
import React, { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useWallet } from '@coin98-com/wallet-adapter-react'

const useDataDetailSnapShot = (id) => {
  const [item, setItem] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [dataJoin, setDataJoin] = useState([])
  const { address } = useWallet()

  const isJoined = dataJoin.some((it) => {
    const idItem = item.id
    return it === idItem
  })

  useEffect(() => {
    fetchData()
  }, [id])

  useEffect(() => {
    loadDataJoin()
  }, [address])

  const fetchData = useDebouncedCallback(async () => {
    setIsLoading(true)
    try {
      const res = await BaseAPI.getData('snapshot/campaign/item/' + id)
      setItem(res)
    } catch (e) {
      setItem({})
    }
    setIsLoading(false)
  }, 500)

  const loadDataJoin = async () => {
    try {
      const res = await BaseAPI.getData('snapshot/participant/list', {
        address: address
      })
      setDataJoin(res)
    } catch (e) {
      setDataJoin([])
    }
  }

  return {
    loadDataJoin,
    item,
    isJoined,
    isLoading,
    refreshData: fetchData
  }
}

export default useDataDetailSnapShot
