import React from 'react'
import { LiquidityProvider } from 'context/liquidity/liquidityContext'
import LiquidityScreen from '.'
import { ListTokenContextProvider } from 'context/ListTokenContext'
import ModalServices from 'containers/app/ModalServices'

const LiquidityScreenContent = () => {
  return (
    <LiquidityProvider>
      <ListTokenContextProvider>
        <LiquidityScreen />
        <ModalServices id={'ModalToken'} />
      </ListTokenContextProvider>
    </LiquidityProvider>
  )
}

export default LiquidityScreenContent
