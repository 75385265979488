import { lowerCase } from 'common/functions'
import { LIST_TOKEN_FAVORITE, MAIN_COIN_AMM, TOKEN_HIDE, W_MAIN_COIN } from 'common/poolEvm/constants'
import { getPrice } from 'common/poolEvm/function'
import ReduxServices from 'common/redux'
import BaseAPI from 'controller/api/BaseAPI'
import { compact, uniqBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useWallet } from '@coin98-com/wallet-adapter-react'
import { useDebouncedCallback } from 'use-debounce'
import { chainType } from 'common/constants'

const ListTokenContext = React.createContext({ })
const numSliceCoinLocal = 20

export const ListTokenContextProvider = ({ children }) => {
  const { address } = useWallet()
  const chainActive = useSelector((state) => state.chainActive)
  const listFavoriteTokenRedux = useSelector(
    (state) => state.listFavoriteTokenRedux
  )
  const [isLoading, setIsLoading] = useState(false)
  const [arrDataToken, setArrData] = useState([])

  useEffect(() => {
    onLoadInitial()
  }, [lowerCase(address), chainActive, JSON.stringify(listFavoriteTokenRedux)])

  const getMainCoin = async () => {
    const mainCoin = MAIN_COIN_AMM[chainActive]
    const priceMain = await getPrice(mainCoin, chainActive) || 0
    mainCoin.balance = await ReduxServices.fetchBalanceByChain(chainActive, {
      address: address
    })
    mainCoin.price = priceMain
    mainCoin.value = parseFloat(priceMain) * parseFloat(mainCoin.balance || 0)
    return mainCoin
  }

  const onLoadInitial = useDebouncedCallback(async () => {
    setIsLoading(true)
    const resListToken = await BaseAPI.getData('baryon/token/list', {
      chain: chainActive
    })
    const listCoinLocal = (window.wallet.getCoinFetch(chainActive) || [])
    const listToken = [...resListToken, ...listCoinLocal.slice(0, numSliceCoinLocal)]
    const uniqueListToken = uniqBy(listToken, (data) => data.address)
      .filter((item) => {
        const isAddress = item.address
        const isNotWrap = lowerCase(item.address) !== lowerCase(W_MAIN_COIN[chainActive]?.address)
        return isAddress && isNotWrap
      })

    uniqueListToken.unshift(W_MAIN_COIN[chainActive])

    const tokenByWallet = await window.wallet.getDataTokens(
      chainActive,
      address,
      uniqueListToken
    )
    const listCoinBalance = await Promise.all(tokenByWallet.map(async (item) => {
      const price = await getPrice(item.tokenInfo, chainActive) || 0
      return { ...item.tokenInfo, balance: item.balance, price, value: parseFloat(price) * parseFloat(item.balance || 0) }
    }))

    if (chainType.tomo !== chainActive) {
      const coin = await getMainCoin()
      listCoinBalance.unshift(coin)
    }

    const sortArr = listCoinBalance.sort((a, b) => {
      return parseFloat(b.value) - parseFloat(a.value)
    })

    const listTokenRemain = listCoinLocal.slice(numSliceCoinLocal)

    const newListTokenAll = uniqBy([
      ...sortArr,
      ...uniqueListToken,
      ...listTokenRemain
    ], (data) => data.address)

    // const uniqueNewData = uniqBy(newListTokenAll, (data) => lowerCase(data.address))

    // sort address

    const listAddressFavorite = uniqBy(LIST_TOKEN_FAVORITE[chainActive].concat(listFavoriteTokenRedux), (address) => lowerCase(address))
    const listAddress = newListTokenAll.map(item => item.address)

    const listAddressAll = uniqBy([...listAddressFavorite, ...listAddress], (item) => lowerCase(item))
    const listFinal = compact(listAddressAll.map(address => {
      return newListTokenAll.find(item => lowerCase(item.address) === lowerCase(address))
    }))

    if (chainType.tomo === chainActive) {
      const coin = await getMainCoin()
      listFinal.unshift(coin)
    }

    // hide token
    const objTokenHide = TOKEN_HIDE[chainActive]
    let newList = listFinal.slice()
    if (objTokenHide) {
      newList = listFinal.filter(item => !objTokenHide[lowerCase(item?.address)])
    }

    setArrData(newList)
    setIsLoading(false)
  }, 1000)

  const value = {
    isLoadingListToken: isLoading,
    arrDataToken
  }

  return <ListTokenContext.Provider value={value}>{children}</ListTokenContext.Provider>
}

export const useListTokenContext = () => {
  return React.useContext(ListTokenContext)
}
