import React, { useMemo, useState } from 'react'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import Button from 'components/common/Button'
import IconCustom from '../IconCustom'
import BannerImage from 'components/BannerImage'
import { FacebookShareButton, TelegramShareButton, TwitterShareButton } from 'react-share'
import { upperCaseFirstLetter } from 'common/functions/utils'
import { useSelector } from 'react-redux'
import { MAIN_API } from 'common/constants'

// const SOCICAL_ICON = {
//   twitter: 'twitter_filled',
//   telegram: 'telegram_filled',
//   link: 'link_filled'
// }

const ShareModal = ({ type, poolAddress, token0 = {}, stakedLpAddress, token1 = {}, campDetail = {}, isCompleted = false }) => {
  const { t } = useTranslation()

  const [isCopying, setIsCopying] = useState(false)
  const chainActive = useSelector(state => state.chainActive)

  const shareLink = useMemo(() => {
    if (type === 'swap') {
      return `${window.location.origin}/${type}/${chainActive}/${token0.address}/${token1.address}`
    }

    if (type === 'snapshot') {
      return `${window.location.origin}/${type}/${campDetail.id}`
    }

    return `${window.location.origin}/${type}?chain=${chainActive}&poolAddress=${poolAddress}&completed=${isCompleted}`
  }, [token0, token1, type, poolAddress, campDetail])

  const shareImgLink = useMemo(() => {
    if (type === 'snapshot') {
      return campDetail.image
    }
    if (type === 'farm' || type === 'swap') {
      return `${MAIN_API.SUPPORT_API}baryonCard?type=${type}&token1=${token0.address}&token2=${token1.address}&chain=${chainActive}`
    }
    return `${MAIN_API.SUPPORT_API}baryonCard?type=${type}&token1=${stakedLpAddress}&chain=${chainActive}`
  }, [type, token0, token1, campDetail])

  const handleCopyText = (e) => {
    e.stopPropagation()
    e.preventDefault()
    window.navigator.clipboard.writeText(shareLink)
    setIsCopying(true)
  }

  const handleHoverOutCopy = () => {
    setTimeout(() => {
      setIsCopying(false)
    }, 500)
  }

  return (
    <div className={styles.shareModalContainer}>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <span className='color-normal'>{t(`share${upperCaseFirstLetter(type)}`)}</span>
        <IconCustom icon="web_close" onClick={() => { window.closeModal() }}/>
      </div>
      <BannerImage src={shareImgLink} className='preview-image__bg'/>
      <div className='share-social'>

        <TelegramShareButton
          url={shareLink}
        >
          <div className='share-social__item'>
            <IconCustom icon="web_telegram-fill" className='icon'/>
          </div>
        </TelegramShareButton>

        <TwitterShareButton
          url={shareLink}
        >
          <div className='share-social__item'>
            <IconCustom icon="web_twitter-fill" className='icon'/>
          </div>
        </TwitterShareButton>

        <FacebookShareButton
          url={shareLink}
        >
          <div className='share-social__item'>
            <IconCustom icon="web_facebook-fill" className='icon'/>
          </div>
        </FacebookShareButton>
      </div>

      <div className='link-container'>
        {/* <a href={shareLink} className='link-container__link' target="_blank" rel="noreferrer">
          {shareLink}
        </a> */}
        <div
          className='d-flex justify-content-center align-items-center'
        >
          <Button
            className="button-copy"
            onMouseLeave={handleHoverOutCopy}
            onClick={handleCopyText}
          >
            {
              isCopying &&
              <IconCustom
                type={'default'}
                icon='web_check'
                className="mr-2 icon-check"
              />
            }
            {isCopying ? t('copied') : t('copyLink')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ShareModal
