
import { useEffect, useState } from 'react'
const useScreenWidth = () => {
  const [width, setWidth] = useState()

  const handleResizeWidth = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    setWidth(window.innerWidth)

    window.addEventListener('resize', handleResizeWidth)
    return () => {
      window.removeEventListener('resize', handleResizeWidth)
    }
  }, [])
  return {
    width
  }
}

export default useScreenWidth
