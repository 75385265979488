import images from 'assets/images'
import Button from 'components/common/Button'
import { get } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import './style.scss'

function WalletDetail ({ item }) {
  const { t } = useTranslation()
  const history = useHistory()

  const handleLinkSell = () => {
    window.open('https://pre.dagora.xyz/explorer')
  }

  return (
    <div className='wallet-detail'>
      <div className="wallet-detail__info">
        <img src={get(item, 'img')} alt="" className="image-info" />

        <div className="info-text">
          <div className="info-text__top">
            <div className="text-name">
              {get(item, 'name')}
            </div>

            <div className="text-title">
              {t('dagoraFoxxy')}
            </div>
          </div>

          <div className="info-text__bottom">
            <span>{t('lowestPrice')}</span>

            <div className="price-content">
              <img src={images.iconC98} alt="" className="icon-c98" />

              <div className="price-content__balance">
                {get(item, 'price')}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="wallet-detail__info-token">
        <div className="info-token-id">
          {t('tokenID')}: {get(item, 'tokenID')}
        </div>

        <a href="/">
          <img src={images.iconExplorer} alt="" className="icon-scan" />
        </a>
      </div>

      <div className="wallet-detail__actions">
        <Button
          className='btn-action'
          onClick={handleLinkSell}
        >
          {t('sell')}
        </Button>

        <Button
          className='btn-action'
          type='cancel'
        >
          {t('transfer')}
        </Button>

        <Button
          className='btn-action'
          type='cancel'
        >
          {t('setProfilePic')}
        </Button>
      </div>
    </div>
  )
}

export default WalletDetail
