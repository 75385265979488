import BaseAPI from 'controller/api/BaseAPI'
import get from 'lodash/get'
import React, { useEffect, useState } from 'react'
import { useWallet } from '@coin98-com/wallet-adapter-react'
import { useQuery } from '@tanstack/react-query'

export const sizeSnapShot = 10

const arrStatus = [
  {
    name: 'all',
    value: ''
  },
  {
    name: 'onGoing',
    value: 'onGoing'
  },
  {
    name: 'upComing',
    value: 'upComing'
  },
  {
    name: 'completed',
    value: 'completed'
  },
  {
    name: 'participated',
    value: 'participated'
  }
]

const arrSort = [
  {
    name: 'earliest',
    value: 'desc'
  },
  {
    name: 'latest',
    value: 'asc'
  }
]

const useDataSnapShot = () => {
  const { address } = useWallet()

  // loading join

  const [dataJoin, setDataJoin] = useState([])
  const [status, setStatus] = useState('')
  const [sort, setSort] = useState('desc')
  const [filterChain, setFilterChain] = useState('')

  useEffect(() => {
    loadDataJoin()
  }, [address])

  const fetchData = async (page = 1) => {
    try {
      const body = {
        address: address,
        page,
        size: 100,
        status: status,
        keyword: '',
        sort: sort,
        chain: filterChain
      }
      const res = await BaseAPI.getData('snapshot/campaign', body)

      const newData = get(res, 'data', [])
      return newData
    } catch (e) {
      return []
    }
  }

  const dataQueryAPI = useQuery({
    queryKey: [`snapshot/campaign/${address}/${status}/${sort}/${filterChain}`],

    queryFn: fetchData,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: 50000
  })

  const dataApi = get(dataQueryAPI, 'data', [])
  const isLoadingApi = get(dataQueryAPI, 'isLoading')

  const dataFinal = dataApi.map(item => {
    const cloneItem = { ...item }
    const isJoined = dataJoin.some(it => {
      return it === cloneItem.id
    })
    cloneItem.isJoined = isJoined
    return cloneItem
  })

  const loadDataJoin = async () => {
    try {
      const res = await BaseAPI.getData('snapshot/participant/list', { address: address })
      setDataJoin(res)
    } catch (e) {
      setDataJoin([])
    }
  }
  const handleChangeSelect = (value) => {
    setSort(value)
  }
  const handleSetStatus = (value) => () => {
    setStatus(value)
  }
  return {
    data: dataFinal,
    isLoading: isLoadingApi,
    refreshData: fetchData,
    handleChangeSelect,
    handleSetStatus,
    setFilterChain,
    status,
    arrStatus,
    arrSort,
    filterChain
  }
}

export default useDataSnapShot
