import React, { useState } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import style from './style.module.scss'
import Button from 'components/common/Button'
import images from 'assets/images'

const ModalSnapJoin = ({ onConfirm, onCancle, title }) => {
  const [isLoading, setIsLoading] = useState()
  const { t } = useTranslation()

  const handleConfirm = async () => {
    setIsLoading(true)
    await onConfirm()
    setIsLoading(false)
  }

  return (
    <div className={cn(style['snap-join'])}>
      <figure>
        <img src={images.iconConfirmSnap} className='img-fluid' alt="" />
      </figure>
      <div className='snap-join__content'>
        <h3 className="modal-title heading-3">
          {title}
        </h3>
        <span>
          {t('RUSureJoin')}
        </span>
      </div>
      <div className='button-confirm'>
        <Button onClick={onCancle} type='cancel'>{t('cancel')}</Button>
        <Button onClick={handleConfirm} isLoading={isLoading}>{t('confirm')}</Button>
      </div>
    </div>
  )
}

export default ModalSnapJoin
