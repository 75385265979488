import React, { useState, useRef } from 'react'

import './style.scss'
import cx from 'classnames'

function SwitchButton ({ rounded = false, isToggle, onToggle, className, isBorder = false }) {
  const [isChoose, setIsChoose] = useState(false)

  const sliderCX = cx('slider', {
    rounded: rounded
  })

  return (
    <label className={`switch ${isBorder && 'switch__border'} ${className}`}>
      <input type="checkbox" checked={isToggle} onChange={onToggle} />
      <span className={sliderCX}></span>
    </label>
  )
}

export default SwitchButton
