import images from 'assets/images'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './style.scss'

const BaryonQualities = () => {
  const { t } = useTranslation()

  const dataContent = [
    {
      icon: images.iconPermissionless,
      title: 'permissionless',
      des: 'enableUsers'
    },
    {
      icon: images.iconLiquidity,
      title: 'wellTrusted',
      des: 'offerReliable'
    },
    {
      icon: images.iconAdvanced,
      title: 'advancedSecurity',
      des: 'prioritizeUser'
    },
    {
      icon: images.iconScala,
      title: 'scalability',
      des: 'levergaeBnb'
    },
    {
      icon: images.iconUxUi,
      title: 'distinctive',
      des: 'provideAnEasy'
    }
  ]
  return (
    <div className='qualities-wrapper'>
      <h2 className=" heading-2">
        {t('offeringTheBest')}
      </h2>
      <div className="qualities-content">

        {dataContent.map((item, index) => {
          return (
            <div className="qualities-item" key={index}>
              <figure>
                <img src={item.icon} className='img-fluid' alt="" />
              </figure>
              <h3 className='heading-3'>
                {t(item.title)}
              </h3>
              <p>
                {t(item.des)}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default BaryonQualities
