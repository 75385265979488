/* eslint-disable multiline-ternary */
import images from 'assets/images'
import {
  DEPOSIT,
  HARVEST,
  WITHDRAW
} from 'common/farm/contants'
import Button from 'components/common/Button'
import CountUpComponent from 'components/CountUpComponent'
import get from 'lodash/get'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import InfoFarmBox from '../InfoFarmBox'
import StakePopUp from '../StakePopUp'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import './style.scss'
import useFarmContract from 'common/farm/hooks/useFarmContract'
import {
  convertBalanceToWei,
  formatPrice,
  showNotificationToast,
  upperCase
} from 'common/functions'
import PopupHash from 'components/PopupHash'
import ReduxServices from 'common/redux'
import GasSlider from 'components/GasSlider'
import Approve from 'components/Approve'
import { CONTRACT_MASTER_CHEF } from 'common/constants'
import { useWallet } from '@coin98-com/wallet-adapter-react'

function ItemFarmBox ({ farm, isEmpty, isMobile, gasPriceDefault, fRateFee, isLoadListData }) {
  const contract = useFarmContract()
  const isDarkMode = useSelector((state) => state.themeRedux) === 'dark'
  const chainActive = useSelector((state) => state.chainActive)
  const { address, connected } = useWallet()

  const { t } = useTranslation()
  const [itemExpand, setItemExpand] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [dataFarm, setDatFarm] = useState(farm)
  const [gasSelect, setGasSelect] = useState({ index: 0 })

  // stakeFame
  const lpAddresses = get(dataFarm, 'lpAddresses')

  // update state to update only one farm when to action update
  useEffect(() => {
    setDatFarm(farm)
  }, [farm])

  const handleExpand = () => {
    setItemExpand(!itemExpand)
  }

  const getInfoFarm = async () => {
    const response = await contract.fetchInfoFarm(dataFarm)
    const { isErr, data } = response
    if (isErr) return
    setDatFarm(data)
  }

  const handleGas = (gasSelect) => {
    setGasSelect(gasSelect)
  }

  const handleEnableContract = async () => {
    window.openModal({
      title: t('approveSymbol', { symbol: upperCase(upperCase(renderSymbolLP)) }),
      type: 'confirm',
      noButton: true,
      content: <Approve contractAddress={ CONTRACT_MASTER_CHEF[chainActive] } spender={lpAddresses} load={getInfoFarm} />
    })
  }

  const handleHarvest = async () => {
    const { pid, lpAddresses, userInfo, earnToken } = dataFarm
    const gasLimitEstApprove = await contract.onEstimateGasApprove(
      gasPriceDefault,
      lpAddresses
    )
    const pendingReward = get(userInfo, 'earning', 0)
    window.openModal({
      title: t('harvest'),
      type: 'confirm',
      classNameContent: 'popup-farm-content',
      content: (
        <>
          <div className="harvest-content-popup">
            <div className="harvest-content-popup__content">
              {t('harvesting')}:
              <span>
                &nbsp;
                {`${
                  formatPrice(pendingReward, false, true) +
                  ' ' +
                  upperCase(earnToken.symbol)
                } ~ ${formatPrice(pendingReward * earnToken.price)}`}
              </span>
            </div>
          </div>
          {!isMobile && (
            <div className="gas-select-farm">
              <GasSlider
                onChange={handleGas}
                chain={chainActive}
                gasLimitEst={gasLimitEstApprove}
                gasSelected={gasSelect}
              />
            </div>
          )}
        </>
      ),
      onOk: async () => {
        await contract.handleActionsFarm({
          gas: get(gasSelect, 'gas.value'),
          poolId: pid,
          amount: 0,
          callBack: orderComplete(HARVEST),
          callBackFail,
          type: HARVEST,
          lpAddress: lpAddresses
        })
      }
    })
  }

  const handleOpenFarmStake = (type) => async () => {
    const { lpAddresses } = dataFarm
    const gasLimitEstApprove = await contract.onEstimateGasApprove(
      gasPriceDefault,
      lpAddresses
    )
    window.openModal({
      containerClass: 'modal-stake',
      noIcon: true,
      backgroundTrans: true,
      preventClose: true,
      className: 'modal-content--stake',
      content: (
        <StakePopUp
          isMobile={isMobile}
          gasLimitEstApprove={gasLimitEstApprove}
          symbol={renderSymbolLP}
          handleSubmitActions={handleSubmitActions}
          farm={dataFarm}
          type={type}
          chainActive={chainActive}
        />
      )
    })
  }

  const orderComplete = (type) => (hash) => {
    window.openModal({
      title: t('success'),
      containerClass: 'modal-success',
      content: <PopupHash type={type} hash={hash} />,
      type: 'success',
      okText: t('close')
    })
    getInfoFarm(dataFarm)
  }

  const callBackFail = (data) => {
    showNotificationToast(t(data), 'error', 2000)
    window.closeModal()
  }

  const handleSubmitActions = async (amount, type, gas) => {
    const { pid, lpAddresses, chain } = dataFarm
    const convertAmount = convertBalanceToWei(amount, 18)
    const response = await contract.handleActionsFarm({
      gas: get(gas, 'gas.value'),
      poolId: pid,
      amount: convertAmount,
      callBack: orderComplete(type),
      callBackFail,
      type,
      chainFarm: chain,
      lpAddress: lpAddresses
    })
    return response
  }

  const renderStakeToken = useMemo(() => {
    return get(dataFarm, 'stakeToken')
  }, [dataFarm])

  const renderRewardToken = useMemo(() => {
    return get(dataFarm, 'rewardToken')
  }, [dataFarm])

  const renderEarnToken = useMemo(() => {
    return get(dataFarm, 'earnToken')
  }, [dataFarm])

  const isApprove = useMemo(() => {
    return get(dataFarm, 'userInfo.allowance', false)
  }, [dataFarm])

  const renderSymbolLP = useMemo(() => {
    if (!renderStakeToken || !renderRewardToken) return ''
    return `${upperCase(get(renderStakeToken, 'symbol', ''))} - ${upperCase(
      get(renderRewardToken, 'symbol', '')
    )} LP`
  }, [renderStakeToken, renderRewardToken])

  const renderActions = useMemo(() => {
    const balanceStake = get(dataFarm, 'userInfo.amount', 0)

    const isStaked = balanceStake > 0

    if (!address) {
      const textBtn = !connected ? 'connectWallet' : 'noWallet'

      return (
        <div className={'item-box__actions item-box__actions--farm'}>
          {/* <div className="actions-stake">{renderSymbolLP}</div> */}
          <div className="btn-action">
            <Button
              onClick={ReduxServices.handleConnect(t)}
              className="farm-action farm-action--enable"
            >
              {t(textBtn)}
            </Button>
          </div>
        </div>
      )
    }
    if (!isApprove || !isStaked) {
      return (
        <div className={'item-box__actions item-box__actions--farm'}>
          {/* <div className="actions-stake">{renderSymbolLP}</div> */}
          <div className="btn-action">
            <Button
              isLoading={isLoading}
              onClick={!isApprove ? handleEnableContract : handleOpenFarmStake(DEPOSIT)}
              className="farm-action farm-action--enable"
            >
              {t(!isApprove ? 'enable' : 'stakeLP')}
            </Button>
          </div>
        </div>
      )
    }

    return (
      <div className="stake-detail">

        <div className="stake-detail__right">
          <div className="left-actions">
            <div className="left-value">
              {get(dataFarm, 'userInfo.amount', 0)}
            </div>
            <div className="left-total-value">
              <CountUpComponent
                endNum={
                  parseFloat(get(dataFarm, 'userInfo.amount')) *
                          parseFloat(get(dataFarm, 'stakeToken.price'))
                }
                prefixValue="~ $"
              />
            </div>
          </div>

          <div className="actions-right">

            <div
              onClick={handleOpenFarmStake(WITHDRAW)}
              className="action-btn action-plus"
            >
              <img
                src={images[isDarkMode ? 'minus' : 'minusLight']}
                alt=""
                className="icon-minus"
              />
            </div>

            <div
              onClick={handleOpenFarmStake(DEPOSIT)}
              className="action-btn action-minus"
            >
              <img
                src={images[isDarkMode ? 'add' : 'addLight']}
                alt=""
                className="icon-add"
              />
            </div>

          </div>
        </div>
      </div>
    )
  }, [dataFarm, isLoading, isApprove, address])

  const isDisableHarvest = useMemo(() => {
    const earning = get(dataFarm, 'userInfo.earning', 0)
    return earning <= 0
  }, [dataFarm])

  return (
    <div className={`item-box ${itemExpand && 'item-box--active'}`}>

      <div className="item-box__head">
        {isEmpty || !renderStakeToken ? (
          <div className="head-left head-left--empty">
            <Skeleton
              baseColor={isDarkMode ? '#242424' : '#bbb'}
              circle
              width="5rem"
              height="5rem"
              containerClassName="icon-token"
            />
            <Skeleton
              baseColor={isDarkMode ? '#242424' : '#bbb'}
              circle
              width="5rem"
              height="5rem"
              containerClassName="icon-token"
            />
          </div>
        ) : (
          <div className="head-left">
            <img
              src={get(renderStakeToken, 'image', images[isDarkMode ? 'icoUnknown' : 'icoUnknownLight'])}
              className="icon-token"
              alt=""
            />
            <img
              src={get(renderRewardToken, 'image', images[isDarkMode ? 'icoUnknown' : 'icoUnknownLight'])}
              className={'icon-token '}
              alt=""
            />
          </div>
        )}

        <div className="head-right">
          {isEmpty || !renderSymbolLP ? (
            <div className="head-right__name-tokens head-right__name-tokens--empty">
              <Skeleton
                containerClassName="skeleton-empty"
                baseColor={isDarkMode ? '#242424' : '#bbb'}
                width={'20rem'}
              />
              <Skeleton
                containerClassName="skeleton-empty"
                baseColor={isDarkMode ? '#242424' : '#bbb'}
                width={'10rem'}
              />
            </div>
          ) : (
            <>
              <div className="head-right__name-tokens">{renderSymbolLP}</div>
              <div className="head-right__number-x">
                {get(dataFarm, 'multiplier', 0)}X
              </div>
            </>
          )}
        </div>

      </div>

      <div className="item-box__value-coin">
        {isEmpty || isLoadListData
          ? <>
            <>
              <Skeleton
                containerClassName="skeleton-empty"
                baseColor={isDarkMode ? '#242424' : '#bbb'}
                width={'100%'}
              />
              <Skeleton
                containerClassName="skeleton-empty"
                baseColor={isDarkMode ? '#242424' : '#bbb'}
                width={'100%'}
              />
            </>
          </>
          : (
            <>
              <div className="value-apr">
                <div className="value-apr--text">APR</div>
                <div className="value-apr--number">
                  <CountUpComponent endNum={get(dataFarm, 'apr')} suffix="%" />
                </div>
              </div>

              <div className="value-apr">
                <div className="value-apr--text">Earn</div>
                <div className="value-apr--token">
                  <img
                    src={get(renderEarnToken, 'image')}
                    className="value-apr-icon"
                    alt=""
                  />
                  {get(renderEarnToken, 'symbol')}
                </div>
              </div>
            </>
          )}
      </div>

      <div className="item-box__earn-detail">

        <div className="earn-detail">
          <div className="earn-detail--left">
            <div className="left-title">
              <span>{get(renderEarnToken, 'symbol')}</span> Earned
            </div>

            {isEmpty || isLoadListData ? (
              <>
                <Skeleton
                  containerClassName="skeleton-empty"
                  baseColor={isDarkMode ? '#242424' : '#bbb'}
                  width={'100%'}
                />
                <Skeleton
                  containerClassName="skeleton-empty"
                  baseColor={isDarkMode ? '#242424' : '#bbb'}
                  width={'100%'}
                />
              </>

            )
              : <>
                <div className="left-value">
                  <CountUpComponent
                    endNum={get(dataFarm, 'userInfo.earning', 0)}
                  />
                </div>

                <div className="left-total-value">
                  <CountUpComponent
                    endNum={
                      get(dataFarm, 'userInfo.earning', 0) *
                      get(dataFarm, 'stakeToken.price', 0)
                    }
                    prefixValue="~ $"
                  />
                </div>
              </>
            }

          </div>

          <Button
            onClick={handleHarvest}
            isDisable={isDisableHarvest}
            className="earn-detail--right"
          >
            {t('harvest')}
          </Button>
        </div>

        {isEmpty || isLoadListData
          ? <Skeleton
            containerClassName="skeleton-empty"
            baseColor={isDarkMode ? '#242424' : '#bbb'}
            width={'100%'}

          />
          : renderActions
        }

      </div>

      <div className="actions-down" onClick={handleExpand}>
        <img
          src={images[isDarkMode ? 'iconDownDark' : 'iconDown']}
          className={`icon-down ${itemExpand && 'icon-down--up'}`}
          alt=""
        />
      </div>

      {itemExpand && (
        <div className="item-box__info-show">
          <InfoFarmBox
            renderSymbolLP={renderSymbolLP}
            stakeToken={renderStakeToken}
            quoteToken={renderRewardToken}
            dataFarm={dataFarm}
          />
        </div>
      )}
    </div>
  )
}

export default ItemFarmBox
