import { formatNumberBro, getLength, upperCase } from 'common/functions'
import { get } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import MarketScan from 'common/MarketScan'

import './style.scss'
import RechartLine from 'components/RechartLine'
import { Link } from 'react-router-dom'
import ReduxServices from 'common/redux'
import { getPrice } from 'common/poolEvm/function'
import { useSelector } from 'react-redux'

function CoinItem ({ item, listTopToken }) {
  const chainActive = useSelector((state) => state.chainActive)

  const [dataCoin, setDataCoin] = useState()
  const [priceToken, setPriceToken] = useState(0)
  const chartConvert = get(item, 'chart')
  const dataChartConvert = chartConvert && chartConvert.map(item => {
    return {
      number: item
    }
  })

  const loadInit = async () => {
    const res = await MarketScan.getCoinGeckoDetail(get(item.info, 'id'))
    const price = await getPrice(item?.info, chainActive) || 0
    setPriceToken(price)
    setDataCoin(res || {})
  }

  const renderChart = useMemo(() => {
    if (!chartConvert) {
      return null
    }
    return (
      <RechartLine
        height="40rem"
        dataChart={dataChartConvert}
        hideTick={true}
        percentChart={get(dataCoin, 'market_data.price_change_percentage_24h')}
      />
    )
  }, [item, dataChartConvert])

  const renderClass = useMemo(() => {
    const lengthListToken = getLength(listTopToken)
    if (lengthListToken <= 2) {
      return 'coin-item-container__chart--two-column'
    } else if (lengthListToken <= 3) {
      return 'coin-item-container__chart--three-column'
    } else if (lengthListToken <= 4) {
      return ''
    } else if (lengthListToken <= 6) {
      return 'coin-item-container__chart--six-column'
    }
  }, [getLength(listTopToken)])

  useEffect(() => {
    loadInit()
  }, [JSON.stringify(item)])

  return (
    <Link className='coin-item-container' to={`/swap?chain=${chainActive}&base=${item.address}`}>
      <div className="coin-item-container__coin-info">
        <div className="info-left">
          <img src={get(item.info, 'image') || get(item.info, 'logoURI')} alt="" className="img-icon" />

          <div className="coin-info-name">
            {get(item.info, 'name')}
          </div>

          <div className="coin-info-symbol">
            {upperCase(get(item.info, 'symbol'))}
          </div>
        </div>

        <div className="info-right">
          <div className="info-total-balance">
            {ReduxServices.formatPrice(priceToken)}
          </div>

          <div className={`info-percent info-percent${get(dataCoin, 'market_data.price_change_percentage_24h') >= 0 ? '--up' : '--down'}`}>
            {get(dataCoin, 'market_data.price_change_percentage_24h') >= 0 && '+'}{formatNumberBro({
              number: get(dataCoin, 'market_data.price_change_percentage_24h'),
              mantissa: 2
            })}%
          </div>
        </div>
      </div>

      <div className={`coin-item-container__chart ${renderClass}`}>
        {renderChart}
      </div>
    </Link>
  )
}

export default CoinItem
