import React from 'react'
import { css } from '@emotion/react'
import PuffLoader from 'react-spinners/PuffLoader'
import './style.scss'

const override = css`
  display: block;
  margin: 0 auto;
`

const PuffLoading = ({ color = '#fdbe1d', size = 30, isFullScreen, isCenter, isFormActions, className }) => {
  if (isFullScreen) {
    return (
      <div className="loading-full-screen">
        <PuffLoader color={color} loading={true} css={override} size={45} />
      </div>
    )
  }
  if (isCenter) {
    return (
      <div className={`loading-center ${isFormActions && 'loading-center--form-action'}, ${className}`}>
        <PuffLoader color={color} loading={true} css={override} size={45} />
      </div>
    )
  }
  return <PuffLoader color={color} loading={true} css={override} size={size} />
}

export default PuffLoading
