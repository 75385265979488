import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import style from './style.module.scss'
import { useTranslation } from 'react-i18next'
import HistorySwap from '../HistorySwap'
import SnapshotSwap from '../SnapshotSwap'
import BaseAdapter from 'controller/api/BaseAdapter'
import { get } from 'lodash'
import SwitchButton from 'components/SwitchButton/SwitchButton'
import { getLength, lowerCase } from 'common/functions'
import { useWallet } from '@coin98-com/wallet-adapter-react'

const TableSwapChart = ({ fromCoin, toCoin }) => {
  const { t } = useTranslation()
  const [status, setStatus] = useState('history')
  const [isLoading, setIsLoading] = useState()
  const [listSnapshotSwap, setListSnapshotSwap] = useState([])
  const [isChecked, setIsChecked] = useState(false)
  const [dataCamp, setDataCamp] = useState([])
  const { address } = useWallet()

  const token0 = fromCoin.address
  const token1 = toCoin.address

  const handleChecked = () => {
    setIsChecked(!isChecked)
  }

  const checkCamp = getLength(dataCamp) === 0

  useEffect(() => {
    dataSnap()
  }, [address, fromCoin, toCoin, isChecked])

  const dataSnap = async () => {
    setIsLoading(true)
    const checkAccount = isChecked ? lowerCase(address) : ''
    try {
      const body = {
        keyword: checkAccount,
        token0: token0,
        token1: token1
      }
      const res = await BaseAdapter.getData('baryon/snapshot/swap', body)
      const dataFinal = get(res, 'data.rank', [])
      const checkShowNav = get(res, 'data.campaign', [])
      if (res) {
        setListSnapshotSwap(dataFinal)
        setDataCamp(checkShowNav)
      }
    } catch (e) {}
    setIsLoading(false)
  }

  const tableDataSwap = [...Array(20)].fill(1).map((item, index) => {
    return {
      address: '0x4e281addndhashdhjqji28ccnq2ndjasndjasc1a6da61fd',
      isQualified: true,
      joinDate: '2022-12-05T07:44:31.327Z',
      rank: 1,
      reward: '50000',
      tradingVolume: 1.936729865300642
    }
  })

  const arrStatus = [
    {
      name: 'history'
    },
    {
      name: checkCamp ? '' : 'snapshot'
    }
  ]
  const handleSetStatus = (value) => () => {
    setStatus(value)
  }

  return (
    <div className={cn(style.tableSwapChart)}>
      <div className="table-nav">
        <ul>
          {arrStatus.map((it, index) => {
            const isActive = it.name === status
            return (
              <li key={index} className={cn(isActive ? 'li-active' : '')} onClick={handleSetStatus(it.name)}>
                <span>
                  {t(it.name)}
                </span>
              </li>
            )
          })}
        </ul>
        {(status === 'snapshot' && !checkCamp) && <div className='d-flex align-items-center gap-3'>
          <SwitchButton isToggle={isChecked} onToggle={handleChecked}/>
          <span>
            {t('myRank')}
          </span>
        </div>}
      </div>
      <div className="table-content">
        {status === 'history' && <HistorySwap/>}
        {status === 'snapshot' && <SnapshotSwap listSnapSwap={listSnapshotSwap} isLoading={isLoading}/>}
      </div>
    </div>
  )
}

export default TableSwapChart
