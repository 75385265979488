import { showNotificationToast, sleep } from 'common/functions'
import useApprove from 'common/poolEvm/hooks/useApprove'
import Button from 'components/common/Button'
// import GasSlider from 'components/GasSlider'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import './style.scss'
import { toast } from 'react-toastify'
import { encodeMessErr } from 'controller/Library/evm'
import { useWallet } from '@coin98-com/wallet-adapter-react'

const Approve = ({ contractAddress, spender, load, callBackSuccess, renderSymbol }) => {
  const { t } = useTranslation()
  const chainActive = useSelector(state => state.chainActive)
  const { address } = useWallet()

  const { isLoading: isLoadingApprove, onApprove } = useApprove(chainActive, contractAddress, address, spender)

  const callBackFail = (mess) => {
    const stringResult = encodeMessErr(mess)
    showNotificationToast(t(stringResult), 'error', 2000)
    window.closeModal()
  }

  const handleCancel = () => {
    window.closeModal()
  }

  const callBack = async () => {
    load && load()
    toast.dismiss()
    window.closeModal()
    showNotificationToast(t('approveSuccess'), 'success', 2000)

    await sleep(2000)
    callBackSuccess && callBackSuccess()
  }

  const handleSubmitActions = () => {
    onApprove(callBack, null, callBackFail)
  }

  return (
    <div className='approve-component'>

      <div className="des-approve">{t('confirmApproveSymbol', { symbol: renderSymbol })}</div>

      <div className="approve-actions">
        <Button
          onClick={handleCancel}
          isFullWidth
          type="border-popup"
        >
          {t('cancel')}
        </Button>

        <Button
          isLoading={isLoadingApprove}
          onClick={handleSubmitActions}
          isFullWidth
        //   className="stake-action__actions stake-action__cancel"
        //   isDisable={isDisabled}
        >
          {t('confirm')}
        </Button>
      </div>
    </div>
  )
}

export default Approve
