import { isWindow } from 'common/constants'
import { formatPrice, validateNumber, countDots, numberFormat } from 'common/functions'
import SliderComponent from 'components/Slider'
import { LiquidityContext } from 'context/liquidity/liquidityContext'
import get from 'lodash/get'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BounceLoader } from 'react-spinners'
import './style.scss'

const BoxBalance = ({ poolInfo, setLpAmount, lpAmount, isLoadingPoolInfo, setIsRemoveAll }) => {
  const { t } = useTranslation()

  const liquidityContext = useContext(LiquidityContext)
  const { isRefreshLPAmount, setIsRefreshLPAmount } = liquidityContext

  const balanceOfLp = get(poolInfo, 'balanceOfLp', 0)
  const [widthResize, setWidthResize] = useState(isWindow && window.innerWidth)

  const [valueSlider, setValueSlider] = useState(0)

  useEffect(() => {
    if (isRefreshLPAmount) {
      setValueSlider(0)
      setLpAmount('')
    }
  }, [isRefreshLPAmount])

  const onChangeSlider = (value) => {
    setValueSlider(value)
    setIsRefreshLPAmount(false)
    const lpAmount = (value / 100) * balanceOfLp
    setLpAmount(lpAmount)
  }

  const handleMaxBalance = () => {
    onChangeSlider(100)
  }

  const onChangeInput = (e) => {
    const newAmount = e.target.value.replaceAll(',', '')

    if (newAmount === '') {
      setLpAmount('')
      setValueSlider(0)
      return
    }

    if (!validateNumber(newAmount)) return
    const dotsCount = countDots(newAmount, '\\.')
    if (dotsCount > 1) return

    setLpAmount(newAmount)

    const percentValue = (newAmount / balanceOfLp) * 100
    const finalValue = percentValue > 100 ? 100 : percentValue
    setValueSlider(finalValue)
  }

  const handleResize = () => {
    setWidthResize(window.innerWidth)
  }

  useEffect(() => {
    setIsRemoveAll && setIsRemoveAll(valueSlider === 100)
  }, [valueSlider, isLoadingPoolInfo])

  useEffect(() => {
    isWindow && window.addEventListener('resize', handleResize)
  }, [])

  return (
    <div className='box-balance'>
      <div className="balance-content">
        <input
          placeholder='0.0'
          className='balance-value'
          type="text"
          value={numberFormat(lpAmount)}
          onChange={onChangeInput}
          maxLength="25"
        />
        {/* <NumberFormat
          value={lpAmount}
          thousandSeparator=","
          placeholder='0.0'
          onValueChange={(event) => onChangeInput(event.value)}
          thousandsGroupStyle="thousand"
          prefix=""
          decimalSeparator="."
          displayType="input"
          className='balance-value'
          maxLength={25}
        /> */}

        {/* <div className="balance-content__value">{formatPrice(lpAmount, false, true)}</div> */}
        {/* <div className="balance-content__token">
          <div className="box-icon">
            <img
              src={imgToken0}
              alt=""
              className="box-icon__item"
            />
            <img
              src={imgToken1}
              alt=""
              className="box-icon__item"
            />
          </div>
          <div className="box-symbol">{isLoadingPoolInfo ? <BounceLoader size={widthResize <= 768 ? 10 : 16} color="#bbbbbb" /> : `${symbol0} - ${symbol1}`}</div>
        </div> */}
      </div>

      <div className="box-balance__desc" onClick={handleMaxBalance}>
        <div className="balance">
          {t('poolToken')}: {isLoadingPoolInfo ? <BounceLoader size={widthResize <= 768 ? 10 : 16} color="#bbbbbb" /> : <span>{formatPrice(balanceOfLp, false, true)}</span>}
        </div>
      </div>

      <div className="box-balance__slider">
        <SliderComponent
          min={0}
          max={100}
          tipFormatter={(value) => <div>{`${value}%`}</div>}
          value={valueSlider}
          onChange={onChangeSlider}
          // onAfterChange={onAfterChange}
          marks={{ 0: '0%', 25: '25%', 50: '50%', 75: '75%', 100: '100%' }}
        />
      </div>
    </div>
  )
}

export default BoxBalance
