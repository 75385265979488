import { TYPE_FAVORITE_POOL } from 'common/constants'
import BaseAdapter from 'controller/api/BaseAdapter'
import StoreActions from 'controller/redux/actions/storeActions'
import { cloneDeep, compact } from 'lodash'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const useFavoritePool = () => {
  const [listPoolFavor, setListPoolFavor] = useState([])

  const dispatch = useDispatch()
  const chainActive = useSelector((state) => state.chainActive)

  const listFavoritePoolRedux = useSelector(state => state.listFavoritePoolRedux) // [{}]

  const onChangeListPoolFavor = async (info) => {
    let listFavorPinned = cloneDeep(listFavoritePoolRedux)
    const findTokenAddress = listFavorPinned.find(
      (item) => item.poolAddress === info.poolAddress
    )
    if (findTokenAddress) {
      listFavorPinned = listFavorPinned.filter(
        (item) => item.poolAddress !== findTokenAddress.poolAddress
      )
    } else {
      listFavorPinned.push(info)
    }

    const formatListPool = listFavorPinned.map(item => ({ poolAddress: item.poolAddress, info: item.info }))

    // const newArr = listPoolAddress.filter((item) => {
    //   if (typeof item === 'object') return null
    //   return item
    // })

    const resPool = await BaseAdapter.postData('favorite/take', {
      type: TYPE_FAVORITE_POOL[chainActive],
      bonusValue: compact(formatListPool)
    })

    if (resPool && resPool.success) {
      dispatch(StoreActions.setListFavoritePoolRedux(formatListPool))
    }

    // dispatch(StoreActions.setListFavoritePoolRedux(formatListPool))
  }

  return {
    listPoolFavor,
    onChangeListPoolFavor
  }
}

export default useFavoritePool
