import React, { useState, useEffect, useContext } from 'react'
import './style.scss'
import AddLiquidity from 'pages/LiquidityScreen/components/AddLiquidity'
import RemoveLiquidity from './components/RemoveLiquidity'
import { Col, Row } from 'antd'
import YourLiquidity from './components/YourLiquidity'
import { useTranslation } from 'react-i18next'
import ManagePool from './components/ManagePool'
import { LiquidityContext } from 'context/liquidity/liquidityContext'
import cn from 'classnames'

const LiquidityScreen = () => {
  const { t } = useTranslation()
  const [status, setStatus] = useState('add')

  const liquidityContext = useContext(LiquidityContext)
  const { isSelectedCoin, handlePushStateUrl, onSelectFromToCoin } = liquidityContext

  const handleSetStatus = (value) => () => {
    setStatus(value)
  }

  useEffect(() => {
    document.title = 'Liquidity | Baryon'
  })

  const onBackToListPool = () => {
    handlePushStateUrl({
      token0Mint: null,
      token1Mint: null,
      typeLiquidity: status
    })
    onSelectFromToCoin({}, {})
  }
  const arrStatus = [
    {
      name: 'add',
      value: 'add'
    },
    {
      name: 'remove',
      value: 'remove'
    }
  ]
  const renderStatus = () => {
    return arrStatus.map((item, index) => {
      const isActive = item.value === status
      return (
        <li
          key={index}
          className={isActive ? 'active' : ''}
          onClick={handleSetStatus(item.value)}
        >
          <div className="nav-item lx-medium">
            <span>{t(item.name)}</span>
          </div>
        </li>
      )
    })
  }

  return (
    <div className="liquidity-screen">
      <div className='liquidity-screen__container'>
        <Row gutter={[24, 24]} >
          <Col xs={24} lg={12} >
            <div className="item-left">
              {!isSelectedCoin ? <YourLiquidity status={status}/> : <ManagePool onBack={onBackToListPool}/>}
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className={cn(status === 'remove' && 'h-100', 'item-right')}>
              <div className="item-right__container">
                <div className="right-tab">
                  <ul>{renderStatus()}</ul>
                </div>
                <div className="right-content">
                  {status === 'add' && <AddLiquidity handleSetStatus={handleSetStatus} />}
                  {status === 'remove' && <RemoveLiquidity />}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default LiquidityScreen
