const images = {

  // explore

  imgSwap: require('assets/images/background/swap.png').default,
  imgFarm: require('assets/images/background/farm.png').default,
  imgStake: require('assets/images/background/stake.png').default,

  iconAdvanced: require('assets/images/background/advanced.svg').default,
  iconUxUi: require('assets/images/background/uiux.svg').default,
  iconLiquidity: require('assets/images/background/liquidity.svg').default,
  iconScala: require('assets/images/background/scalability.svg').default,
  iconPermissionless: require('assets/images/background/composability.svg').default,

  iconInfoLiquidity: require('assets/images/iconsBaryon/iconInfoLiquidityBaryon.svg').default,
  iconMenu: require('assets/images/icons/menu.svg').default,

  // logo
  logoLuna: require('assets/images/logo/lunaLogo.png').default,
  logoLunaCircle: require('assets/images/logo/lg_circle_luna.svg').default,
  logoSaros: require('assets/images/logo/saros_logo-04.svg').default,
  logoSarosDark: require('assets/images/logo/saros_logo-04-dark.svg').default,
  logoSarosText: require('assets/images/logo/logo-saros-text.svg').default,
  logoSarosTextDark: require('assets/images/logo/logo-saros-text-dark.svg').default,
  logoEN: require('assets/images/logo/flag_usa.svg').default,
  logoVN: require('assets/images/logo/flag_vn.png'),
  logoBaryon: require('assets/images/logo/baryon.svg').default,
  logoSingal: require('assets/images/logo/baryonlogo.png').default,

  // banner
  ballToken: require('assets/images/background/ballToken.png').default,
  lastBall: require('assets/images/background/lastBall.png').default,
  ballSmall: require('assets/images/background/ballSmall.png').default,
  bigBall: require('assets/images/background/bigBall.png').default,
  ballDark: require('assets/images/background/ballDark.png').default,
  netWorkMobile: require('assets/images/background/networkMobile.svg').default,
  logoSarosPopUp: require('assets/images/background/logoSarosPopUp.svg').default,
  baryonRewordLight: require('assets/images/backgroundBaryon/bgRewardsLightV2.png').default,
  baryonRewordDark: require('assets/images/backgroundBaryon/bgRewardsDarkV2.png').default,
  baryonMain: require('assets/images/background/background-cover-banner.svg').default,
  baryonRight: require('assets/images/background/banner-right.svg').default,
  bannerLanding: require('assets/images/background/bannerlandingwebm.webm').default,
  bannerLandingMP4: require('assets/images/background/baryonlandingmp4.mp4').default,
  bannerFarm: require('assets/images/background/baryonfarmwebm.webm').default,
  bannerFarmGif: require('assets/images/background/baryonfarmgif.gif').default,
  bannerStakeGif: require('assets/images/background/baryonstakegif.gif').default,
  bannerStake: require('assets/images/background/baryonstakewebm.webm').default,
  bannerSnapGit: require('assets/images/background/brsnapgit.gif').default,
  bannerSnapWebm: require('assets/images/background/baryonsnap.webm').default,

  // banner example
  bannerEx: require('assets/images/banners/bannerEx.png').default,
  bannerEx2: require('assets/images/banners/bannerEx2.png').default,
  bannerEx3: require('assets/images/banners/bannerEx3.png').default,
  bannerEx4: require('assets/images/banners/bannerEx4.png').default,

  // sponsors
  hashed: require('assets/images/sponsors/hashed.png').default,
  cryptomind: require('assets/images/sponsors/cryptomind.png').default,
  kdoo: require('assets/images/sponsors/kdoo.png').default,
  portico: require('assets/images/sponsors/portico.png').default,
  spartan: require('assets/images/sponsors/spartan.png').default,
  bgv: require('assets/images/sponsors/bgv.png').default,
  asymVentures: require('assets/images/sponsors/asymVentures.svg').default,
  K300: require('assets/images/sponsors/k300.png').default,
  alamedaSearch: require('assets/images/sponsors/alamedaReasearch.png').default,
  impossipleFinance: require('assets/images/sponsors/impossipleFinance.svg')
    .default,
  asymmVentures: require('assets/images/sponsors/asymmVentures.svg').default,
  logoSolana: require('assets/images/sponsors/logoSolana.svg').default,
  logoGenblock: require('assets/images/sponsors/logoGenblock.svg').default,
  kyros: require('assets/images/sponsors/kyros.svg').default,
  evernew: require('assets/images/sponsors/evernew.png').default,

  hashedDark: require('assets/images/sponsors/hashedDark.svg').default,
  cryptomindDark: require('assets/images/sponsors/cryptomindDark.svg').default,
  // kdooDark: require('assets/images/sponsors/kdooDark.svg').default,
  // porticoDark: require('assets/images/sponsors/porticoDark.svg').default,
  spartanDark: require('assets/images/sponsors/spartanDark.svg').default,
  bgvDark: require('assets/images/sponsors/bgvDark.svg').default,
  // asymVenturesDark: require('assets/images/sponsors/asymVenturesDark.svg').default,
  K300Dark: require('assets/images/sponsors/k300Dark.svg').default,
  alamedaSearchDark: require('assets/images/sponsors/alamedaReasearchDark.svg').default,
  impossipleFinanceDark: require('assets/images/sponsors/impossipleFinanceDark.svg')
    .default,
  asymmVenturesDark: require('assets/images/sponsors/asymmVenturesDark.svg').default,
  logoSolanaDark: require('assets/images/sponsors/logoSolanaDark.svg').default,
  logoGenblockDark: require('assets/images/sponsors/logoGenblockDark.svg').default,
  kyrosDark: require('assets/images/sponsors/kyrosDark.svg').default,
  evernewDark: require('assets/images/sponsors/evernewDark.svg').default,

  // coinGecko: require('assets/images/sponsors/coinGecko.png').default,
  // kyberNetwork: require('assets/images/sponsors/kyberNetwork.png').default,
  // linkPad: require('assets/images/sponsors/linkPad.png').default,
  // multiCoin: require('assets/images/sponsors/multiCoin.png').default,
  // parafi: require('assets/images/sponsors/parafi.png').default,

  // token
  solana: require('assets/images/token/solana.svg').default,
  solanaDark: require('assets/images/token/solanaDark.svg').default,
  tokenFida: require('assets/images/token/tokenFida.svg').default,
  tokenMedia: require('assets/images/token/tokenMedia.svg').default,
  tokenRay: require('assets/images/token/tokenRay.svg').default,
  tokenRayDark: require('assets/images/token/tokenRayDark.svg').default,
  tokenScope: require('assets/images/token/tokenScope.png').default,
  tokenSRM: require('assets/images/token/tokenSRM.svg').default,
  tokenSRMDark: require('assets/images/token/tokenSRMDark.svg').default,
  tokenStep: require('assets/images/token/tokenStep.png').default,
  chartUp: require('assets/images/token/chartUp.png').default,
  tokenMercurial: require('assets/images/token/mercurial.svg').default,
  solStarter: require('assets/images/token/solstarter.svg').default,
  tokenSolStarter: require('assets/images/token/tokenSolStarter.svg').default,
  solStarterWhite: require('assets/images/token/solStarterWhitte.png').default,

  // icons
  iconWalletAdd: require('assets/images/icons/wallet-add.svg').default,
  iconCapital: require('assets/images/icons/iconCapital.svg').default,
  iconCheck: require('assets/images/icons/iconCheck.svg').default,
  iconComposability: require('assets/images/icons/iconComposability.svg')
    .default,
  iconFriendly: require('assets/images/icons/iconFriendly.svg').default,
  iconLowCost: require('assets/images/icons/iconLowCost.svg').default,
  iconPermisson: require('assets/images/icons/iconPermisson.svg').default,
  iconScalibility: require('assets/images/icons/iconScalibility.svg').default,
  iconTelegram: require('assets/images/icons/iconTelegram.svg').default,
  iconTwitter: require('assets/images/icons/iconTwitter.svg').default,
  iconArrow: require('assets/images/icons/icon-arrow.svg').default,
  iconArrowDown: require('assets/images/icons/iconArrowDown.svg').default,

  iconSetting: require('assets/images/icons/iconSetting.svg').default,
  iconSettingYellow: require('assets/images/icons/iconSettingYellow.svg').default,
  iconSettingDark: require('assets/images/icons/iconSettingDark.svg').default,
  iconDropdown: require('assets/images/icons/iconDropdown.svg').default,
  iconDropdownDark: require('assets/images/icons/iconDropdownDark.svg').default,
  iconSearchBlack: require('assets/images/icons/iconSearch.svg').default,
  iconSwap: require('assets/images/icons/iconSwap.svg').default,
  iconSwapDark: require('assets/images/icons/iconSwapDark.svg').default,
  iconSolWallet: require('assets/images/icons/iconSolWallet.svg').default,
  loadingLottie: require('assets/images/icons/loadingLotties.json').default,
  iconError: require('assets/images/icons/iconError1.svg').default,
  iconSend: require('assets/images/icons/iconSend.svg').default,
  iconSuccessDark: require('assets/images/icons/iconSuccess.svg').default,
  iconSync: require('assets/images/icons/iconSync.svg').default,
  iconSearchs: require('assets/images/icons/search.svg').default,
  iconClose: require('assets/images/icons/iconClose.svg').default,
  iconCloseLight: require('assets/images/icons/iconCloseLight.svg').default,
  iconClose2: require('assets/images/icons/iconClose2.svg').default,
  iconNoti: require('assets/images/icons/iconNoti.svg').default,
  successIcon: require('assets/images/icons/successIcon1.svg').default,
  errIcon: require('assets/images/icons/iconErr.svg').default,
  iconWarningYell: require('assets/images/icons/iconWarningYell.svg').default,
  ballModal: require('assets/images/icons/ballModal.svg').default,
  iconWarningErr: require('assets/images/icons/iconWarningErr.svg').default,
  iconAlertConfirm: require('assets/images/icons/alertConfirm.svg').default,
  iconBallWarning: require('assets/images/icons/iconBallWarning.svg').default,
  iconBallWarningDark: require('assets/images/icons/iconBallWarningDark.svg').default,
  iconBallWarningBaryon: require('assets/images/icons/iconBallWarningBaryon.svg').default,

  halfShape: require('assets/images/icons/half-shape-2.svg').default,
  iconClaim: require('assets/images/icons/icon-claim.svg').default,
  arrowLeft: require('assets/images/icons/arrow-left-circle.svg').default,
  iconSolana: require('assets/images/tokenSwap/sol.png').default,
  icoUnknown: require('assets/images/tokenSwap/unknown.svg').default,
  icoUnknownLight: require('assets/images/tokenSwap/unknownLight.svg').default,
  iconSearchGrey: require('assets/images/icons/iconSearchGrey.svg').default,
  iconPagiantionLeft: require('assets/images/icons/iconPagiantionLeft.svg')
    .default,
  iconFavorite: require('assets/images/icons/iconFavorite.svg').default,
  iconScan: require('assets/images/icons/iconScan.svg').default,
  iconGrowDown: require('assets/images/icons/iconGrowDown.svg').default,
  iconGrowUp: require('assets/images/icons/iconGrowUp.svg').default,
  iconCheckWhite: require('assets/images/icons/iconCheckWhite.svg').default,
  iconBackLeft: require('assets/images/icons/iconBackLeft.svg').default,
  iconPlus: require('assets/images/icons/iconPlus.svg').default,
  iconDropdownGrey: require('assets/images/icons/iconDropdownGrey.svg').default,

  iconUFO: require('assets/images/icons/iconUFO.svg').default,
  iconUFODark: require('assets/images/icons/iconUFODark.svg').default,
  iconLoad: require('assets/images/icons/iconLoad.svg').default,
  iconPlusAdd: require('assets/images/icons/addPlus.svg').default,
  iconEmptyTable: require('assets/images/icons/iconEmptyTable.svg').default,
  iconEmptyChart: require('assets/images/icons/iconEmptyChart.svg').default,
  iconEmptyToken: require('assets/images/icons/iconEmptyToken.svg').default,
  iconRightDefault: require('assets/images/icons/arrowright.svg').default,
  iconRightColor: require('assets/images/icons/arrowrightcolor.svg').default,
  iconRightColorBlack: require('assets/images/icons/arrowrightblack.svg').default,
  iconEmpty: require('assets/images/icons/empty.png').default,
  iconConfirmSnap: require('assets/images/icons/snapshot.svg').default,

  // launchpad
  iconCaretBlue: require('assets/images/icons/iconCaretBlue.svg').default,
  iconCaretRightPurple: require('assets/images/icons/iconCaretRightPurple.svg')
    .default,
  iconClock: require('assets/images/icons/iconClock.svg').default,
  iconCopy: require('assets/images/icons/iconCopy.svg').default,
  loadingSol: require('assets/images/icons/loadingSol.svg').default,

  // defi
  lunaFarm: require('assets/images/defi/lunaFarm.png').default,
  lunaPad: require('assets/images/defi/lunaPad.png').default,
  lunaStack: require('assets/images/defi/lunaStack.png').default,
  lunaSwap: require('assets/images/defi/baryon.svg').default,

  // token swap
  swapSolana: require('assets/images/tokenSwap/swapSolana.png').default,
  iconHistory: require('assets/images/icons/iconHistory.svg').default,
  iconHistoryYellow: require('assets/images/icons/iconHistoryYellow.svg').default,
  chart: require('assets/images/icons/chart.svg').default,
  chartClose: require('assets/images/icons/chartClose.svg').default,
  chartYellow: require('assets/images/icons/chartYellow.svg').default,
  chartDark: require('assets/images/icons/chartDark.svg').default,
  chartCloseYellow: require('assets/images/icons/chartCloseYellow.svg').default,
  iconChange: require('assets/images/icons/iconChange.svg').default,
  iconCollapse: require('assets/images/icons/iconCollapse.svg').default,
  iconExpand: require('assets/images/icons/iconExpand.svg').default,

  // background
  bgBlueBlur: require('assets/images/background/bg-blue-blur.svg').default,
  shape1: require('assets/images/background/shape-1.svg').default,
  backgroundSaros: require('assets/images/background/bg-saros.svg').default,

  thumnailSnap: require('assets/images/background/thumnail-sub.png').default,

  // snap shot

  snapCoverBottom: require('assets/images/background/snapshot-bottom.png').default,
  snapGlobalHd: require('assets/images/background/global-hd.png').default,
  snapGlobal: require('assets/images/background/global.png').default,

  // light - dark
  iconMoon: require('assets/images/icons/iconMoon.svg').default,
  iconSun: require('assets/images/icons/iconSun.svg').default,

  // search
  iconClear: require('assets/images/icons/iconClear.svg').default,

  // icon token example
  iconBTC: require('assets/images/icons/IconBTC.png').default,
  iconETH: require('assets/images/icons/IconETH.png').default,
  // pool detail
  iconVerify: require('assets/images/icons/iconVerify.svg').default,
  iconVerifyCheck: require('assets/images/icons/iconVerifyCheck.svg').default,

  ballNetworkDark: require('assets/images/background/ballNetworkDark1.png').default,

  // farm
  iconDown: require('assets/images/icons/iconDown.svg').default,
  iconDownDark: require('assets/images/icons/iconDownDark.svg').default,
  iconViewBlock: require('assets/images/icons/iconViewBlock.svg').default,
  iconPairInfo: require('assets/images/icons/iconPairInfo.svg').default,
  iconC98BNB: require('assets/images/icons/iconC98-BNB.svg').default,
  iconCancelPass: require('assets/images/icons/iconCancelPass.svg').default,
  iconCopyLine: require('assets/images/icons/IconCopyLine.svg').default,

  iconSortDown: require('assets/images/icons/iconSortDown.svg').default,
  iconSortUp: require('assets/images/icons/iconSortUp.svg').default,

  // -------------------------------> BARYON ICON <-------------------------------
  // background
  bgWallYellow: require('assets/images/backgroundBaryon/bgWallYellow.png').default,
  bgIllus01: require('assets/images/backgroundBaryon/bgIllus01.png').default,
  ballLogo: require('assets/images/backgroundBaryon/ballLogo.png').default,
  lightYellow: require('assets/images/backgroundBaryon/lightYellow.png').default,
  networkText: require('assets/images/backgroundBaryon/networkText.png').default,
  baryonText: require('assets/images/backgroundBaryon/baryonText.png').default,
  bgStar: require('assets/images/backgroundBaryon/bgStar.png').default,
  bgStar1: require('assets/images/backgroundBaryon/bgStar1.png').default,
  bgStar2: require('assets/images/backgroundBaryon/bgStar2.png').default,
  bgRewards: require('assets/images/backgroundBaryon/bgRewards.png').default,

  // icons
  baryonFarm: require('assets/images/iconsBaryon/baryonFarm.png').default,
  baryonStake: require('assets/images/iconsBaryon/baryonStake.png').default,
  baryonSwap: require('assets/images/iconsBaryon/baryonSwap.png').default,
  arrowRight: require('assets/images/iconsBaryon/arrowRight.svg').default,
  iconCapitalBaryon: require('assets/images/iconsBaryon/iconCapital.svg').default,
  iconComposabilityBaryon: require('assets/images/iconsBaryon/iconComposability.svg').default,
  iconFriendlyBaryon: require('assets/images/iconsBaryon/iconFriendly.svg').default,
  iconLowCostBaryon: require('assets/images/iconsBaryon/iconLowCost.svg').default,
  iconPermission: require('assets/images/iconsBaryon/iconPermission.svg').default,
  iconScalability: require('assets/images/iconsBaryon/iconScalability.svg').default,
  teleBaryon: require('assets/images/iconsBaryon/teleBaryon.svg').default,
  twitterBaryon: require('assets/images/iconsBaryon/twitterBaryon.svg').default,

  logoBlack: require('assets/images/banners/logoBlack.png').default,

  // <----> lightmode <----->
  bgWallYellowLight: require('assets/images/backgroundBaryon/light/bgWallYellowLight.png').default,
  bgIllus01Light: require('assets/images/backgroundBaryon/light/bgIllus01Light.png').default,
  ballLogoLight: require('assets/images/backgroundBaryon/light/ballLogoLight.png').default,
  lightYellowLight: require('assets/images/backgroundBaryon/light/lightYellowLight.png').default,
  networkTextLight: require('assets/images/backgroundBaryon/light/networkTextLight.png').default,
  baryonTextLight: require('assets/images/backgroundBaryon/light/baryonTextLight.png').default,
  arrowRightLight: require('assets/images/iconsBaryon/light/arrowRightLight.svg').default,
  add: require('assets/images/iconsBaryon/add.svg').default,
  minus: require('assets/images/iconsBaryon/minus.svg').default,
  addLight: require('assets/images/iconsBaryon/addLight.svg').default,
  minusLight: require('assets/images/iconsBaryon/minusLight.svg').default,
  logoBlackLight: require('assets/images/banners/logoBlackLight.png').default,

  // footer
  docsFooter: require('assets/images/iconsBaryon/docslight.svg').default,
  docsFooterBlack: require('assets/images/iconsBaryon/docdark.svg').default,
  teleFooter: require('assets/images/iconsBaryon/telelight.svg').default,
  teleFooterBlack: require('assets/images/iconsBaryon/teledark.svg').default,
  twitterFooter: require('assets/images/iconsBaryon/twitterlight.svg').default,
  twitterFooterBlack: require('assets/images/iconsBaryon/twdark.svg').default,
  discordFooter: require('assets/images/iconsBaryon/discorwhite.svg').default,
  discordFooterBlack: require('assets/images/iconsBaryon/discorddark.svg').default,
  coin98Footer: require('assets/images/iconsBaryon/coin98Footer.svg').default,
  iconBlogFt: require('assets/images/iconsBaryon/blogwhite.svg').default,
  iconBlogFtBlack: require('assets/images/iconsBaryon/blogblack.svg').default,

  // active
  docsFooterActive: require('assets/images/iconsBaryon/docsFooterActive.svg').default,
  teleFooterActive: require('assets/images/iconsBaryon/teleFooterActive.svg').default,
  twitterFooterActive: require('assets/images/iconsBaryon/twitterFooterActive.svg').default,
  discordFooterActive: require('assets/images/iconsBaryon/discordFooterActive.svg').default,
  coin98FooterActive: require('assets/images/iconsBaryon/coin98FooterActive.svg').default,
  iconBlogFtActive: require('assets/images/iconsBaryon/iconBlogFtActive.svg').default,

  // profile
  iconC98: require('assets/images/logo/iconC98.svg').default,
  imgNft: require('assets/images/banners/imgNft.png').default,
  imgNft1: require('assets/images/banners/imgNft1.png').default,
  imgNft2: require('assets/images/banners/imgNft2.png').default,
  imgNft3: require('assets/images/banners/imgNft3.png').default,
  imgNft4: require('assets/images/banners/imgNft4.png').default,
  imgNft5: require('assets/images/banners/imgNft5.png').default,
  webCam: require('assets/images/iconsBaryon/webCam.svg').default,
  webWallet: require('assets/images/iconsBaryon/webWallet.svg').default,
  fox1: require('assets/images/iconsBaryon/fox1.png').default,
  fox2: require('assets/images/iconsBaryon/fox2.png').default,
  foxAngry: require('assets/images/iconsBaryon/foxAngry.png').default,
  fox1Light: require('assets/images/iconsBaryon/light/fox1Light.png').default,
  fox2Light: require('assets/images/iconsBaryon/light/fox2Light.png').default,
  foxAngryLight: require('assets/images/iconsBaryon/light/foxAngryLight.png').default,
  dagoraLogo: require('assets/images/logo/dagoraLogo.png').default,
  dagoraLogoLight: require('assets/images/logo/dagoraLogoLight.png').default,
  iconRefresh: require('assets/images/iconsBaryon/iconRefresh.svg').default,
  iconExplorer: require('assets/images/iconsBaryon/iconExplorer.svg').default,
  arrowLeftBaryon: require('assets/images/iconsBaryon/arrowLeftBaryon.svg').default,
  tickBaryon: require('assets/images/iconsBaryon/tickBaryon.svg').default,
  avatarBaryon: require('assets/images/iconsBaryon/avatarBaryon.svg').default,
  avatarBaryonLight: require('assets/images/iconsBaryon/avatarBaryonLight.svg').default,
  iconInfo: require('assets/images/icons/web_info.svg').default,

  bannerTitleLottie: require('assets/images/backgroundBaryon/bannerTitle.json'),
  bannerTitleLottieLight: require('assets/images/backgroundBaryon/light/bannerTitleLight.json'),
  darkBlur: require('assets/images/backgroundBaryon/darkBlur.png').default,
  lightBlur: require('assets/images/backgroundBaryon/light/lightBlur.png').default,
  darkBlur2: require('assets/images/backgroundBaryon/darkBlur2.png').default,
  lightBlur2: require('assets/images/backgroundBaryon/light/lightBlur2.png').default,
  bgHalfDark: require('assets/images/backgroundBaryon/bgHalfDark.png').default,
  bgHalfLight: require('assets/images/backgroundBaryon/light/bgHalfLight.png').default,
  starDown: require('assets/images/iconsBaryon/starDown.png').default,
  starDownLight: require('assets/images/iconsBaryon/light/starDownLight.png').default,
  darkCircleBlur: require('assets/images/backgroundBaryon/darkCircleBlur.png').default,
  lightCircleBlur: require('assets/images/backgroundBaryon/light/lightCircleBlur.png').default,
  darkCircleBlur2: require('assets/images/backgroundBaryon/darkCircleBlur2.png').default,
  lightCircleBlur2: require('assets/images/backgroundBaryon/light/lightCircleBlur2.png').default
}

export default images
