import DropdownCurrency from 'components/Header/DropdownCurrency'
import DropdownLang from 'components/Header/DropdownLang'
import Footer from 'pages/HomeScreen/components/Footer'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import IconCustom from '../IconCustom'

import './style.scss'

const SidebarMenuMobile = ({ menus, isOpen, chooseUrl, setIsOpenMenu }) => {
  const { t } = useTranslation()

  const handleNavigate = () => {
    setIsOpenMenu(false)
  }

  return (
    <div className={`sidebar-menu-mobile ${isOpen && 'sidebar-menu-mobile--open'}`} >
      <div className="sidebar-wrapper">
        <div className='sidebar-menu'>
          {menus.map((item, index) => (
            <Link to={item.url} key={index} onClick={handleNavigate} className={`menu-item lx-regular ${chooseUrl === item.url && 'menu-item--active'}`}>
              <span>
                {t(item.name)}
              </span>
              <IconCustom type={'default'} className='size-icon' icon='web_arrow_right'/>
            </Link>
          ))}
        </div>
        <div className="sidebar-convenient">
          <div className="convenient-item">
            <span>
              {t('language')}
            </span>
            <DropdownLang/>
          </div>
          <div className="convenient-item">
            <span>
              {t('currency')}
            </span>
            <DropdownCurrency/>
          </div>
        </div>
        <div className="sidebar-social">
          <Footer/>
        </div>
      </div>
    </div>
  )
}

export default SidebarMenuMobile
