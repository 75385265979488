export default {
  desNotificationStake: 'Your rewards will be harvested before staking updated number of tokens.\n Do you want to continue?',
  notification: 'Notification',
  desConvertWtomo: 'TOMO has been rebranded to VIC and it is no longer supported on Baryon. Please withdraw your WTOMO to convert to VIC.',
  end: 'End',
  addLiquidityBaryon: 'Add Liquidity',
  removeLiquidityBaryon: 'Remove Liquidity',
  confirmApproveSymbol: 'You are about to approve Baryon to access your {{symbol}} token.\n Are you sure you want to continue approving?',
  warning: 'Warning',
  sureContinue: 'Are you sure you want to continue?',
  free: 'Free',
  networkFee: 'Network Fee',
  notSupportChain: 'Connected wallet is not in supported chain',
  chain: '',
  myRank: 'My rank',
  reward: 'Reward',
  status: 'Status',
  rank: 'Rank',
  getStarted: 'Get Started',
  redefineYourTradingExperience: 'Redefine your Trading experience',
  yourTrusted: 'Your trusted DEX on EVM Blockchains to swap, stake, farm, and build on top.',
  stakeFarm: 'Stake Farm',

  // từ mới

  dataWillAuto: 'Data will auto-refresh after 20 seconds. Click this Pie to fetch the latest data',
  half: 'Half',
  sort: 'Sort',
  pleaseSwitchChain: 'Please Switch Binance Smart Chain',
  noLiquidityToRemove: 'No Liquidity To Remove',
  totalSupply: 'Total Supply',
  marketCap: 'Market Cap',
  staked: 'Staked',
  allTokens: 'All Tokens',
  copied: 'Copied',
  unclaimedRewards: 'Unclaimed Rewards',
  unStakeFarm: 'UnStake Farm',
  yourLPFarming: 'Your LP Farming',
  yourReward: 'Your Reward',
  poolToken: 'Pool Token',
  share: 'Share',
  transactions: 'Transactions',
  topTokens: 'Top Tokens',
  topPools: 'Top Pools',
  viewExplorer: 'View explorer',
  selectLiquidity: 'Select liquidity to remove',
  changeWallet: 'Change Wallet',
  totalAddressActive: 'Total Active Addresses',
  liquidityProvider: 'Liquidity Provider',
  shareSwap: 'Share Swap',
  shareFarm: 'Share Farm',
  shareStake: 'Share Stake',
  shareSnapshot: 'Share Snapshot',
  copyLink: 'Copy Link',
  address: 'Address',
  theme: 'Theme',
  allTimeLow: 'All Time Low',
  allTimeHigh: 'All Time High',
  dayVolumn: '24h Volume',
  dayLiquidity: '24h Liquidity',
  pooledSign: 'Pooled',
  yourPoolToken: 'Your Pool Token',
  yourPoolShare: 'Your Pool Share',
  managePool: 'Manage Pool',
  farming: 'Farming',
  selectToken: 'Select Token',
  poolStaked: 'Pool Staked',
  poolFarming: 'Pool Farming',
  totalValueLocked: 'Total Value Locked',
  allLiquidityShare: 'All Liquidity Share',
  backToWallet: 'Back to Wallet',
  recentlyAdded: 'Recently Added',
  gainer: 'Gainer',
  trending: 'Trending',
  popular: 'Popular',
  topLiquidity: 'Top Liquidity',
  topVolume24h: 'Top Volume 24h',
  tokenValue: 'Token Value',
  lpValue: 'LP Value',
  estValue: 'Est Value',
  poolShare: 'Pool Share',
  token2Amount: 'Token 2 Amount',
  token1Amount: 'Token 1 Amount',
  searchWallet: 'Search Wallet/Account',
  volumeFee: '24h Volume Fee',
  accountStaked: 'Account Staked',
  accountFarming: 'Account Farming',
  liquidityProviders: 'Liquidity Providers',
  wallet: 'Wallet',
  currency: 'Currency',
  language: 'Language',
  en: 'English',
  vi: 'Vietnamese',
  unstakeNow: 'Unstake now!',
  unstakedPools: 'unstaked pools',
  youHave: 'You have',
  link: 'Link',
  ascending: 'Ascending',
  decreasing: 'Decreasing',
  searchByAddress: 'Search by Address',
  joinDate: 'Join Date',
  tradingVolume: 'Trading Volume',
  campEnd: 'Camp Ended',
  joined: 'Joined',
  addressAlreadyJoin: 'Address Already Join',
  addressNull: 'No wallet found',
  error: 'Error',
  joinSuccess: 'Join Success',
  errMess: 'Join faild',
  RUSureJoin: 'Are you sure you want to join this snapshot?',
  stablecoinTradingCompetition: 'Stablecoin Trading Competition',
  upComing: 'Upcoming',
  global: 'Global',
  twitter: 'Twitter',
  telegram: 'Telegram',
  noTitle: 'Rank',
  duration: 'Duration',
  participated: 'Participated',
  joinNow: 'Join Now',
  earliest: 'Earliest',
  upTo: 'Up to',
  totalReward: 'Total Rewards',
  onGoing: 'On Going',
  participateIn: 'Participate in trading tournaments to win massive rewards with Baryon',
  activityCampaigns: 'Activity Campaigns',
  copyRight: '© 2022, All Rights Reserved',
  provideAnEasy: 'Provide an easy-to-navigate user interface with elegant design',
  levergaeBnb: 'Leverage EVM Blockchains like BNB Chain and Viction for quick and safe transactions with low gas fees',
  prioritizeUser: 'Prioritize user security. Audits are conducted by Inspex',
  offerReliable: 'Offer reliable liquidity of tokens for all users',
  permissionless: 'Permissionless',
  distinctive: 'Distinctive UX/UI',
  scalability: 'Scalability',
  advancedSecurity: 'Advanced Security',
  wellTrusted: 'Well-trusted liquidity foundation',
  offeringTheBest: 'Offering The Best Qualities',
  stakeToEarn: 'Stake to earn passive income.<br/> No impermanent loss',
  earnExtra: 'Earn extra rewards on your liquidity',
  swapHidden: 'Swap hidden gems safely in a snap',
  stakeNow: 'Stake Now',
  swapNow: 'Swap Now',
  exoloreTrending: 'Explore Trending Tokens on EVM Blockchains',
  exploreBaryon: 'Explore Baryon',
  enableUsers: 'Enable users to trade without giving up control of their funds',
  ended: 'Ended',
  unstake: 'Unstake',
  farmEnded: 'Farm Ended',
  stakeEnded: 'Stake Ended',
  connectToSee: 'Connect to see your pool',
  totalReceive: 'Total Receive',
  viewProjectSite: 'View Project Site',
  walletNotActive: 'Wallet not active',
  harvesting: 'Harvesting',
  txsHarvest: 'Harvesting: {{amount}} ~ {{amountUSD}}',
  harvestSymbol: 'Harvest {{symbol}}',
  amountAboveLimit: 'Amount above limit',
  insufficientFunds: 'Insufficient Funds',
  earned: 'Earned',
  totalStaked: 'Total staked',
  latest: 'Latest',
  hot: 'Hot',
  multiplier: 'Multiplier',
  lackGas: 'Confirm the transaction and make sure you are paying enough gas!',
  txsTypeComplete: 'You have successfully {{type}}',
  estimateFee: 'Estimate Fee',
  txtFee: '{{amount}} ~ {{amountUSD}}',
  slowest: 'Very Slow',
  fastest: 'Super Fast',
  standard: 'Standard',
  gasWar: 'Gas War',
  starWar: 'Star War',
  slow: 'Slow',
  fast: 'Fast',
  collect: 'Collect',
  stakeInPool: 'Stake Tokens',
  unStake: 'Unstake Tokens',
  totalStake: 'Total Staked',
  seeTokenInfo: 'See token info',
  viewContract: 'View Contract',
  stakeSuccess: 'Stake Success',
  unStakeSuccess: 'Unstake Success',
  enableContract: 'Enable contract',
  harvestFarm: 'Harvest',
  unStakeLP: 'Unstake LP Tokens',
  stakeLP: 'Stake',
  yourFrontrun: 'Your transaction may be Frontrun',
  yourFrontrun2: 'Your transaction may be front-running',
  confirmApprove: 'Approval Confirmation',
  tradeSuccess: 'Order Successfully',
  increaseSlippage: 'Swap failed, try increasing your slippage tolerance',
  pleaseWaitApprove: 'Please wait a moment for approval',
  rate: 'Rate',
  priceImpact: 'Price Impact',
  route: 'Route',
  importPool: 'Import Pool',
  importPoolDesc: 'Import an existing pool',
  connectWalletFindPools: 'Connect to a wallet to find pools',
  selectATokenToFind: 'Select a token to find your liquidity.',
  noPoolFound: 'No pool found.',
  poolFound: 'Pool Found!',
  manageThisPool: 'Manage this pool.',
  youDontHaveLiquidity: 'You don’t have liquidity in this pool yet.',
  sureApprove: 'Are you sure approve?',
  approveSymbol: 'Approve {{symbol}}',
  approve: 'Approve',
  approveSuccess: 'Approve Successfully',
  // 6-12-21
  sarosPool: 'Saros Pool',
  permissionLessPool: 'Permissionless Pools',
  priceImpactMustBeLessThan: 'Price impact must be less than 50%',
  fees: 'Fees',
  apr: 'APR',
  notFoundScreen: '404 Not FoundScreen',
  emptyData: 'Empty Data',
  emptyDataTable: 'Data table is empty',
  backTo: 'Back To',
  youAreAddLiquidity: 'You will receive',
  pooled: 'Pooled {{symbol}}',
  youAreTheFirst: 'You are the first liquidity provider',
  theRatioOf: 'The ratio of tokens you add will set the price of this pool',
  onceYouAre: 'Once you are happy with the rate click supply to review',
  removeLiquidity: 'Remove {{token0}} - {{token1}} Liquidity',
  removeLiquidityDesc: 'To receive {{token0}} and {{token1}}',
  balance: 'Balance',
  youWillReceive: 'You Will Receive',
  name: 'Name',
  price: 'Price',
  priceChange: 'Price Change',
  lpRewardFee: 'LP Reward Fees 24h',
  lpRewardApr: 'LP Reward APR',
  add: 'Add',
  create: 'Create',
  transaction24h: 'Transaction 24h',
  confirmRemove: 'Confirm Remove',
  lpTokensIn: 'LP Tokens In Your Wallet',
  confirmCreate: 'Confirm Create',
  comingSoon: 'Coming Soon',
  lpTokens: 'LP Tokens',
  overview: 'Overview',
  pools: 'Pools',
  tokens: 'Tokens',
  loading: 'Loading',
  inputAnAmount: 'Input an amount',
  youAreVerify: 'You are verify a pool',
  unVerify: 'You are unverify a pool',
  pleaseResetWallet: 'Please Reset Wallet',
  totalLp: 'Total Lp',
  trendingPools: 'Trending Pools',
  allPools: 'All Pools',
  createPool: 'Create Pool',
  // 11-11-21
  remove: 'Remove',
  addLiquidityInstead: 'Add Liquidity Instead',
  enterAmount: 'Enter Amount',
  createPoolAndSupply: 'Create Pool & Supply',
  confirmSupply: 'Confirm Supply',
  youAreCreatingPool: 'You are creating a pool',
  rates: 'Rates',
  tokenPool: '{{symbol}} Pool Tokens',
  youWillReceived: 'You Will Receive',
  supply: 'Supply',
  shareOfPool: 'Share of Pool',
  priceAndPool: 'Prices and pool share',
  amount: 'Amount',
  addLiquidityDesc: 'Add liquidity to receive LP tokens',
  addLiquidity: 'Add Liquidity',
  findOtherLpToken: 'Find Other LP Tokens',
  dontSeePool: "Don't see a pool you joined?",
  noLiquidity: 'No liquidity found',
  removeLiquidityTo: 'Remove liquidity to receive tokens back',
  yourLiquidity: 'Your Liquidity',
  success: 'Success',
  yourOrderHasBeen: 'Your order has been completed!',
  estimateGasFee: 'Estimate Gas Fee',
  saveSetting: 'Save Settings',
  close: 'Close',
  viewTxs: 'View Transaction',
  transactionSubmitted: 'Transaction Submitted',
  confirm: 'Confirm',
  estimate: 'Estimate',
  slippageTolerance: 'Slippage Tolerance',
  setting: 'Setting',
  convert: 'Convert',
  convertWSOL: 'Convert SOL To wSOL',
  priceImpactMustLessThan: 'Price impact must be less than 50%',
  minimumBalance:
    'A minimum balance of 0.05 SOL is recommended to avoid failed transactions',
  exceedsLimit: 'Exceeds desired slippage limit',
  setup: 'Setup',
  setupDone: 'Setup Done',
  dueLimitSol:
    'Due to limits on Solana transaction sizes, this step is required to create wrapped SOL (wSOL) in your wallet before swapping.',
  topPool: 'Top Pool',
  inputSearchText: 'Input SearchText',
  pool: 'Pool',
  liquidity: 'Liquidity',
  volume24h: 'Volume 24h',
  volume7d: 'Volume 7d',
  fee24h: 'Fee 24H',
  yearFee: '1 Year Fees/Liquidity',
  backToPool: 'Back to pools',
  totalTokenLocked: 'Total Tokens Locked',
  volume: 'Volume',
  transaction: 'Transaction',
  all: 'All',
  adds: 'Adds',
  removes: 'Removes',
  action: 'Action',
  totalValue: 'Total Value',
  totalAmount: 'Total Amount',
  tokenAmount: 'Token Amount',
  tokenAmountFrom: 'Token Amount From',
  tokenAmountTo: 'Token Amount To',
  account: 'Account',
  time: 'Time',
  farm: 'Farm',
  staking: 'Staking',
  poolNotFound: 'Pool Not Found',
  showLabelConnectWallet: 'Connect Wallet',
  gasSolNotEnough: 'Not enough SOL for gas',
  impactLarge: 'You might lose {{number}}% of your assets due to price impact',
  tradeDone: 'Tokens have been deposited into your wallet',
  received: '{{symbol}} Received !',
  swapConfirmation: 'Swap Confirmation',
  txsFee: '{{amount}} ~ {{amountUSD}}',
  sizeTooSmall: 'Size too small',
  tradeErrFund: 'Order error, insufficient funds',
  timeOutTxs:
    'Transaction timed out or unconfirmed, please wait a moment and try again.',
  feeErr: 'Your balance or gas fee is not enough',
  amountErr: 'Must be less than or equal to spendable amount',
  swap: 'Swap',
  yourPassword: 'Your Password',
  password: 'Password',
  pleaseWaitOrder: 'Please wait a moment for order to be completed',
  txsFail: 'Please try again after a few minutes',
  unlockWallet: 'Unlock Wallet',
  wrongPass: 'Invalid password',
  tryLeft: 'You have {{num}} time(s) left',
  walletHasBeenReset: 'Your wallet has been reset',
  onlyOneChanceReset:
    'You only have one chance to remain. If you wrong your wallet will be reset!',
  unlock: 'Unlock',
  youPay: 'You Pay',
  youReceive: 'You Receive',
  reviewOrder: 'Review Order',
  swappingToken: 'Swapping tokens…',
  depositToken:
    '{{symbol}} will be deposited into your wallet once the transaction is complete',
  disconnectWallet: 'Disconnect Wallet ?',
  disconnect: 'Disconnect',
  chooseWallet: 'Please Choose Your Wallet',
  noWallet: 'No Wallet',
  coin98NotInstall: 'Coin98 Wallet has not been installed',
  installCoin98: 'Install Coin98 Wallet for management wallet ',
  connectWallet: 'Connect Wallet',
  searchByToken: 'Search by token name, ticker, or address',
  gasNotEnough: 'Not enough {{name}} for gas ',
  tooLarge:
    'You have submitted too many transactions, please consider to reduce.',
  // 24/12/2021 update
  searchPoolOrToken: 'Search pools or tokens',
  cancel: 'Cancel',
  // farming update
  searchHere: 'Search Here',
  sarosFarming: 'Saros Farm',
  farmingDescription: 'Farm LP tokens to earn rewards',
  stakeDescription: 'Stake tokens to earn rewards',
  stakeTotal: 'Total Value Staked',
  myReward: 'My Rewards',
  active: 'Active',
  completed: 'Completed',
  stakeOnly: 'Staked',
  searchFarm: 'Search Farm',
  harvest: 'Harvest',
  historyTransactions: 'History Transaction',
  default: 'Default',
  tradingView: 'Trading View',
  verify: 'Verify',
  verified: 'Verified',
  stake: 'Stake',
  farmNow: 'Farm Now',
  enable: 'Enable',
  viewOnBlock: 'View on block explorer',
  pairInfo: 'Pair Info Detail',
  getC98BNB: 'Get C98 - BNB LP',
  copyAddress: 'Copy address',
  copyAddressFail: 'Copy address fail',
  dark: 'Dark',
  light: 'Light',
  metafi: "MetaFi's Fundamental Blocks",
  transactionSpeed: 'Default Transaction Speed',
  openChart: 'Open Chart',
  closeChart: 'Close Chart',
  history: 'History',
  item: 'Item',
  activity: 'Activity',
  saleOnDagora: 'Sale On Dagora',
  buyFoxie: 'Buy Foxie',
  on: 'On',
  filterBy: 'Filter By',
  listed: 'Listed',
  delisted: 'Delisted',
  modified: 'Modified',
  sold: 'Sold',
  event: 'Event',
  from: 'From',
  to: 'To',
  date: 'Date',
  editProfile: 'Edit Profile',
  changePic: 'Change Profile Picture',
  removePic: 'Remove Profile Picture',
  chooseNewCollect: 'Choose a new Collectible to use as your profile picture',
  detail: 'Detail',
  dagoraFoxxy: 'Dagora Foxxy',
  tokenID: 'Token ID',
  useProfileAvatar: 'You’re using this NFT as your Baryon Profile picture',
  descriptionDetailPopUp: "Removing it will suspend your profile, and you won't be able to earn points, participate in team activities, or be eligible for new NFT drops",
  textFooter: 'Go to your profile page to continue',
  lowestPrice: 'Lowest Price',
  setProfilePic: 'Set as Profile Pic',
  transfer: 'Transfer',
  back: 'Back',
  sell: 'Sell',
  confirmTitle: 'This will suspend your profile and send your Collectible back to your wallet',
  confirmDes: "While your profile is suspended, you won't be able to earn points, but your achievements and points will stay associated with your profile",
  confirmFt: 'Cost to reactivate in the future: 0.5 C98',
  understand: 'I understand'
}
