import { Trade, JSBI, Token, CurrencyAmount, TokenAmount } from '@uniswap/sdk'
import { CHAIN_ID, W_MAIN_COIN, tradeType } from 'common/poolEvm/constants'
import { parseUnits } from '@ethersproject/units'
import { convertBalanceToWei, convertDecimalToHex, getLength, roundingNumber } from 'common/functions'

const wrappedCurrency = (currency, chain) => {
  return new Token(CHAIN_ID[chain], currency.address || W_MAIN_COIN[chain]?.address, currency?.decimals || currency?.decimal, currency.symbol, currency.name)
}

const wrappedAmount = (value, currency) => {
  const typedValueParsed = parseUnits(roundingNumber(value, currency.decimals).toString(), currency.decimals).toString()
  if (typedValueParsed !== '0') {
    return currency instanceof Token
      ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
      : CurrencyAmount.ether(JSBI.BigInt(typedValueParsed))
  }
}

export function exactTradeAmount (chain, allowedPairs, currencyIn, currencyOut, amountInput = 1, isFromInput = true) {
  try {
    const tokenIn = wrappedCurrency(currencyIn, chain)
    const tokenOut = wrappedCurrency(currencyOut, chain)

    const parsedAmount = wrappedAmount(amountInput.toString(), isFromInput ? tokenIn : tokenOut)

    const typeSwap = isFromInput ? 'bestTradeExactIn' : 'bestTradeExactOut'

    if (parsedAmount && tokenOut && getLength(allowedPairs) > 0) {
      let bestTradeSoFar

      for (let i = 1; i <= 3; i++) {
        const currentTrade = Trade[typeSwap](allowedPairs, isFromInput ? parsedAmount : tokenIn,
          isFromInput ? tokenOut : parsedAmount, { maxHops: i, maxNumResults: 1 })[0]
        if (currentTrade) {
          if (!bestTradeSoFar || (parseFloat(bestTradeSoFar.executionPrice.toSignificant(6)) < parseFloat(currentTrade.executionPrice.toSignificant(6)))) {
            bestTradeSoFar = currentTrade
          }
        }
      }

      return bestTradeSoFar
    }
    return null
  } catch (error) {
    console.log('🚀 ~ file: AMM.js:47 ~ exactTradeAmount ~ error:', error)
  }
}

export function swapCallParameters (isExactIn, trade, to, options, isEtherIn, isEtherOut, typeTrade) {
  const amountIn = convertDecimalToHex(convertBalanceToWei(trade.maximumAmountIn(options.allowedSlippage).toSignificant(8), trade.inputAmount.currency.decimals))
  const amountOut = convertDecimalToHex(convertBalanceToWei(trade.minimumAmountOut(options.allowedSlippage).toSignificant(8), trade.outputAmount.currency.decimals))

  const ZERO_HEX = '0x'

  let mainSymbol = 'ETH'

  switch (typeTrade) {
  case tradeType.pangolinSwap:
    mainSymbol = 'AVAX'
    break
  case tradeType.traderJoe:
    mainSymbol = 'AVAX'
    break
  }

  const path = trade.route.path.map(function (token) {
    return token.address
  })
  const deadline = 'ttl' in options ? '0x' + (Math.floor(new Date().getTime() / 1000) + options.ttl).toString(16) : '0x' + options.deadline.toString(16)
  const useFeeOnTransfer = Boolean(options.feeOnTransfer)
  let methodName
  let args
  let value
  let numApprove

  if (isExactIn) {
    if (isEtherIn) {
      methodName = useFeeOnTransfer ? `swapExact${mainSymbol}ForTokensSupportingFeeOnTransferTokens` : `swapExact${mainSymbol}ForTokens`

      args = [amountOut, path, to, deadline]
      value = amountIn
    } else if (isEtherOut) {
      methodName = useFeeOnTransfer ? `swapExactTokensFor${mainSymbol}SupportingFeeOnTransferTokens` : `swapExactTokensFor${mainSymbol}`

      args = [amountIn, amountOut, path, to, deadline]
      value = ZERO_HEX
    } else {
      methodName = useFeeOnTransfer ? 'swapExactTokensForTokensSupportingFeeOnTransferTokens' : 'swapExactTokensForTokens'

      args = [amountIn, amountOut, path, to, deadline]
      value = ZERO_HEX
    }

    numApprove = args[0]
  } else {
    // if (ammAddressV2) {
    //   const tradeSelected = findTrade(typeTrade)
    //   const provider = computeTradePriceBreakdown(trade, tradeSelected.chain, typeTrade)
    //   amountIn = parseFloat(convertHexToDecimal(amountIn)) + parseFloat(convertBalanceToWei(provider.realizedLPFee.divide(2).toSignificant(trade.inputAmount.currency.decimals), trade.inputAmount.currency.decimals))
    //   amountIn = convertDecimalToHex(amountIn)
    // }

    if (isEtherIn) {
      methodName = `swap${mainSymbol}ForExactTokens`

      args = [amountOut, path, to, deadline]
      value = amountIn
    } else if (isEtherOut) {
      methodName = `swapTokensForExact${mainSymbol}`

      args = [amountOut, amountIn, path, to, deadline]
      value = ZERO_HEX
    } else {
      methodName = 'swapTokensForExactTokens'

      args = [amountOut, amountIn, path, to, deadline]
      value = ZERO_HEX
    }
    numApprove = args[1]
  }

  // if (ammAddressV2) {
  //   args.unshift(AMM_ADDRESS[typeTrade])
  // }

  return {
    numApprove,
    // isV2: ammAddressV2,
    methodName: methodName,
    args: args,
    value: value
  }
}
