import images from 'assets/images'
import Checkbox from 'components/Checkbox'
import Button from 'components/common/Button'
import { get } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import './style.scss'

function ChangeImage ({ handleStatusEdit }) {
  const { t } = useTranslation()
  const [chooseImg, setChooseImg] = useState()

  const arrItem = [
    {
      id: 1,
      name: 'Foxxi XVI',
      img: images.imgNft
    },
    {
      id: 2,
      name: 'Foxxi XI',
      img: images.imgNft1
    },
    {
      id: 3,
      name: 'Foxxi X',
      img: images.imgNft3
    },
    {
      id: 4,
      name: 'Foxxi VI',
      img: images.imgNft4
    },
    {
      id: 5,
      name: 'Foxxi XV',
      img: images.imgNft5
    }
  ]

  const handleChooseItem = (item) => () => {
    if (!chooseImg) {
      setChooseImg(item)
    } else {
      if (chooseImg.id === item.id) {
        setChooseImg('')
      } else {
        setChooseImg(item)
      }
    }
  }

  const renderItemChoose = () => {
    return arrItem.map(item => {
      return (
        <div className="item-choose" key={item.id} onClick={handleChooseItem(item)}>
          {/* <div className="item-choose__left">
            <div className="checkbox-div">
              <img src={images.tickBaryon} alt="" className={`tick-choose ${get(chooseImg, 'id') === item.id && 'tick-choose--active'}`} />
            </div>

            <div className="name-text">
              {item.name}
            </div>
          </div> */}

          <div className="checkbox-div">
            <Checkbox
              text={get(item, 'name')}
              itemChoose={get(chooseImg, 'id') === item.id}
            />
          </div>

          <div className="item-choose__right">
            <img src={item.img} alt="" className="image-avatar" />
          </div>
        </div>
      )
    })
  }

  return (
    <div className='change-image'>
      <div className="change-image__description">
        {t('chooseNewCollect')}
      </div>

      <div className="change-img__list-item">
        {renderItemChoose()}
      </div>

      <div className="change-img__action">
        <Button
          className='btn-confirm-change btn-confirm-change--back'
          type='cancel'
          onClick={handleStatusEdit(false)}
        >
          {t('back')}
        </Button>

        <Button
          className='btn-confirm-change'
          isDisable={!chooseImg}
        >
          {t('confirm')}
        </Button>
      </div>
    </div>
  )
}

export default ChangeImage
