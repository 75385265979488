import React from 'react'
import cn from 'classnames'
import style from './style.module.scss'
import { useTranslation } from 'react-i18next'
import images from 'assets/images'

const EmptyData = ({ src = 'iconEmpty', classNames, titleEmpty = 'emptyData' }) => {
  const { t } = useTranslation()
  return (
    <div className={cn(style['empty-data'], classNames)}>
      <figure>
        <img src={images[src]} className='img-fluid' alt="empty data"/>
        {/* <span>
          {t(titleEmpty)}
        </span> */}
      </figure>
    </div>
  )
}

export default EmptyData
