import images from 'assets/images'
import Button from 'components/common/Button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import './styles.scss'

function MenuItemBaryon ({ item, isBottom }) {
  const history = useHistory()
  const { t } = useTranslation()
  const isDarkMode = useSelector(state => state.themeRedux) === 'dark'

  const handleChangeRoute = (link) => () => {
    if (!item.disable) {
      history.push(link)
    }
  }
  return (
  // <a className={`menu-item-baryon ${item.disable && 'menu-item-baryon--disable'} ${isBottom && 'menu-item-baryon--bottom'}`} href={item.link}>
  //   <img src={item.icon} alt="" className={`icon-menu ${isBottom && 'icon-menu--bottom-icon'}`} />

  //   <div className="menu-item-baryon__right">
  //     <div className="menu-item-baryon__text">
  //       <div className="text-title">
  //         {item.name}
  //       </div>

  //       <div className="text-description">
  //         {item.des}
  //       </div>
  //     </div>
  //   </div>

  //   {!isBottom
  //     ? <>
  //       <img src={images[isDarkMode ? 'darkBlur2' : 'lightBlur2']} alt="" className="icon-circle icon-circle--circle1" />
  //       <img src={images[isDarkMode ? 'darkBlur2' : 'lightBlur2']} alt="" className="icon-circle icon-circle--circle2" />
  //     </>
  //     : <img src={images[isDarkMode ? 'darkBlur2' : 'lightBlur2']} alt="" className="icon-circle icon-circle--circle-bottom" />
  //   }
  // </a>

    <div className={'explore-item'} >
      <div className="content-top">
        <figure>
          <img src={item.icon} className='img-fluid' alt="" />
        </figure>
        <Link to={item.link}>
          <Button className='explore-button'>
            {t(item.button)}
          </Button>
        </Link>
      </div>
      <div className="content-bottom">
        <h3>
          {t(item.name)}
        </h3>
        <p dangerouslySetInnerHTML={{ __html: t(item.des) }}/>
      </div>
    </div>
  )
}

export default MenuItemBaryon
