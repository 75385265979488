import { chainType, CHAIN_DATA, isDapp } from 'common/constants'
import ReduxServices from 'common/redux'
import Web3 from 'web3'
import { convertBalanceToWei, convertDecimalToHex, convertHexToDecimal, convertWeiToBalance, getLength, lowerCase, sleep } from 'common/functions'
import GasServices from 'common/GasServices'
import get from 'lodash/get'
import { DeviceUUID } from 'device-uuid'
import ERC20 from 'common/abi/ERC20'
import { MAIN_COIN_AMM, NAME_VERSION_PERMIT, PERMIT_HELPER, W_MAIN_COIN } from 'common/poolEvm/constants'
import Uniswap from 'common/abi/Uniswap'
import { JSBI, Percent } from '@uniswap/sdk'
import { findTrade, getPrice } from 'common/poolEvm/function'
import { swapCallParameters } from './AMM'
import C98Swap from 'common/abi/C98Swap'
import VRC25 from 'common/abi/VRC25'
import { ethers } from 'ethers'
import PermitHelper from 'common/abi/PermitHelper'
import { SETTING_LOCAL } from 'common/WalletServices'
import abiWrapToken from 'common/abi/abiWrapToken'

export const unlimitedHex = 'ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
export const zeroAddress = '0x0000000000000000000000000000000000000000'
export const BIPS_BASE = JSBI.BigInt(10000)
const sizeTooSmall = 'sizeTooSmall'

export const getPriceLp = async ({ token0, token1, chain, balanceToken0Pool = 0, balanceToken1Pool = 0, totalLp }) => {
  const priceToken0 = await getPrice(token0, chain)

  if (priceToken0 > 0) {
    return (priceToken0 * balanceToken0Pool * 2) / totalLp
  }
  if (token1) {
    const priceToken1 = await getPrice(token1, chain)
    if (priceToken1 > 0) {
      return (priceToken1 * balanceToken1Pool * 2) / totalLp
    }
    return 0
  }
  return 0
}

export const genWeb3 = (chain, rpcLink) => {
  let provider

  if (rpcLink) {
    provider = rpcLink
  } else {
    provider = window.wallet.findSetting(chain)
  }

  if (!provider) {
    provider = SETTING_LOCAL[chain]
  }

  const web3 = new Web3()
  web3.setProvider(new web3.providers.HttpProvider(provider))

  return web3
}

export const convertCheckSUM = (address) => {
  return Web3.utils.toChecksumAddress(address)
}

export const genContract = (web3, minABI, contractAddress) => {
  return new web3.eth.Contract(minABI, contractAddress)
}

export const encodeABI = (contract, name, params) => {
  return contract.methods[name](...params).encodeABI()
}

export const generateApprovedData = (web3, contractAddress, spender, numApprove, method = 'approve') => {
  const contract = genContract(web3, ERC20, contractAddress)
  const dataTx = encodeABI(contract, method, [spender, numApprove ? ((numApprove.startsWith && numApprove.startsWith('0x')) ? numApprove : convertDecimalToHex(numApprove)) : convertHexToDecimal(unlimitedHex)])
  return dataTx
}

export const generatePermitData = (web3, tokenAddress, spender, numApprove, sign, owner) => {
  const contract = genContract(web3, PermitHelper, PERMIT_HELPER)
  const number = numApprove ? ((numApprove.startsWith && numApprove.startsWith('0x')) ? numApprove : convertDecimalToHex(numApprove)) : convertHexToDecimal(unlimitedHex)
  const dataTx = contract.methods.permitERC20(tokenAddress, owner, spender, number, sign?.deadline, sign?.v, sign?.r, sign?.s).encodeABI()
  return dataTx
}

export const getGasPrice = async (web3, chain) => {
  const web3Run = web3 || genWeb3(chain)
  const gasPrice = await web3Run.eth.getGasPrice()
  return gasPrice
}

export const encodeMessErr = (mess) => {
  const newErr = mess?.mess || mess?.message || mess || 'txsFail'
  const stringResult = lowerCase(newErr).toString()

  mess = stringResult

  switch (true) {
  case stringResult.includes('insufficient funds'):
    mess = 'tradeErrFund'
    break

  case stringResult.includes('t.startswith'):
    mess = 'Please unlock extension'
    break
  case stringResult.includes('invalid start time'):
    mess = 'Invalid start time'
    break

  case stringResult.includes('gas required exceeds allowance'):
    mess = 'lackGas'
    break

  case stringResult.includes('transfer amount exceeds balance'):
    mess = 'Transfer amount exceeds balance'
    break

  case stringResult.includes('reward balances must be greater than 0'):
    mess = 'Reward Balances must be greater than 0'
    break

  case stringResult.includes('insufficient_output_amount'):
  case stringResult.includes('excessive_input_amount'):
    mess = 'increaseSlippage'
    break

  case stringResult.includes('insufficient_b_amount'):
  case stringResult.includes('insufficient_a_amount'):
    mess = 'Please increase in Slippage'
    break
  case stringResult.includes('user rejected signing'):
  case stringResult.includes('user rejected the request'):
    mess = 'User rejected signing'
    break
  }

  return mess
}

export const estimateGasTxs = (rawTransaction, web3, chain = chainType.ether, defaultValue, isReturnError, callBackFail) => {
  return new Promise(async (resolve, reject) => {
    const web3Run = web3 || genWeb3(chain)
    if (rawTransaction.valueDirect) {
      rawTransaction.value = rawTransaction.valueDirect
    }
    web3Run.eth.estimateGas(rawTransaction).then(res => {
      const limitGas = 21000
      resolve(res < limitGas ? limitGas : res)
    }).catch((err) => {
      callBackFail && callBackFail(err)
      if (isReturnError) {
        const e = err.toString()
        const realMessage = e.split('Returned error:')[1]
        reject(new Error(realMessage))
      }

      try {
        const convertErr = err.toString()

        if (convertErr.includes('max fee per gas')) {
          const splitGas = convertErr.match(/[0-9]+/g)
          const gasEst = splitGas[splitGas.length - 2]

          rawTransaction.gasPrice = convertDecimalToHex((gasEst * 1.05).toFixed(0))
          estimateGasTxs(rawTransaction, web3, chain, defaultValue).then(res => {
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        } else {
          if (convertErr.includes('chainId')) {
            delete rawTransaction.chainId
            estimateGasTxs(rawTransaction, web3, chain, defaultValue).then(res => {
              resolve(res)
            }).catch(err => {
              reject(err)
            })
          } else {
            if (defaultValue) {
              return resolve(defaultValue)
            }
            reject(err)
          }
        }
      } catch (error) {
        reject(err)
      }
    })
  })
}

export async function awaitTransactionSignatureConfirmation (
  web3,
  hash,
  callback,
  timeout = 50000
) {
  let done = false
  const result = await new Promise((resolve, reject) => {
    (async () => {
      setTimeout(() => {
        if (done) {
          return
        }
        done = true
        const timeout = { timeout: true }
        reject(timeout)
      }, timeout)
      while (!done) {
        // eslint-disable-next-line no-loop-func
        (async () => {
          try {
            web3.eth.getTransactionReceipt(hash, (err, txReceipt) => {
              if (txReceipt || err) {
                done = true
                resolve({ txReceipt, err })
              }
            })
          } catch (e) {
            if (!done) {
              console.log('REST connection error: txid', hash, e)
            }
          }
        })()
        await sleep(500)
      }
    })()
  })
  done = true

  callback && callback(hash)

  return result
}

export const postBaseSendTxs = async (chain, privateKey, generateTxs, isWaitDone, callback, callbackFail, isGetData, chainCustom) => {
  return new Promise(async (resolve, reject) => {
    try {
      // const addressWalletActive = ReduxServices.getReduxState('accountSol')
      const addressWalletActive = '0x37F463F7fD920DdC7c4a6415bCadfEe1d18F9849'

      const web3Run = genWeb3(chain, chainCustom)
      // const findWallet = isDapp ? { address: addressWalletActive } : ReduxServices.getReduxState('walletActive')
      const findWallet = isDapp ? { address: addressWalletActive } : { address: addressWalletActive }
      const nonce = await web3Run.eth.getTransactionCount(findWallet.address)

      const { to, data, gasPrice, gasLimit, buffGasLimit = 1, value, percent = 1, nounce, valueDirect } = generateTxs

      const rawTransaction = {
        nonce: nounce || nonce,
        to,
        from: findWallet.address,
        data: data || '0x',
        value: '0x0'
      }

      const chainSelected = CHAIN_DATA[chain]

      if (chain === 'rinkeby') {
        rawTransaction.chainId = '0x4'
      } else {
        // if (chainCustom) {
        //   rawTransaction.chainId = convertDecimalToHex(window.wallet.findCustomNetwork(chainCustom))
        // } else {
        rawTransaction.chainId = chainSelected.chainId
      }

      // Send from Dapps
      if (valueDirect) {
        rawTransaction.value = valueDirect
      } else {
        if (value) {
          rawTransaction.value = convertDecimalToHex(convertBalanceToWei(value))
        }
      }

      rawTransaction.gas = convertDecimalToHex(gasLimit || (
        ((await estimateGasTxs(rawTransaction, web3Run)) * buffGasLimit).toFixed(0)
      ))

      if (chain === chainType.avax || !chainSelected || chainSelected.isL2) {
        const gasPrice = await getGasPrice(web3Run)
        rawTransaction.gasPrice = gasPrice
      } else {
        if (gasPrice || gasPrice === '0x0') {
          const convertGas = gasPrice.toString()
          rawTransaction.gasPrice = convertGas?.startsWith('0x') ? convertGas : convertDecimalToHex(convertGas)
        } else {
          const gasPriceDefault = await GasServices.getGasByType(chain, true)
          // const gasPriceDefault = 4

          rawTransaction.gasPrice = convertDecimalToHex(convertBalanceToWei((gasPriceDefault * percent).toFixed(6), 9))
        }
      }

      if (rawTransaction.chainId) {
        rawTransaction.chainId = convertHexToDecimal(rawTransaction.chainId)
      }

      // Supported new hardfork london
      if (get(chainSelected, 'isSupportedEIP1559')) {
        rawTransaction.maxFeePerGas = rawTransaction.gasPrice
        rawTransaction.maxPriorityFeePerGas = chain === chainType.heco ? '0x861c4680' : '0x59682f00'

        if (parseFloat(convertHexToDecimal(rawTransaction.maxPriorityFeePerGas)) > parseFloat(convertHexToDecimal(rawTransaction.maxFeePerGas))) {
          rawTransaction.maxFeePerGas = rawTransaction.maxPriorityFeePerGas + 1
        }
        delete rawTransaction.chainId
        delete rawTransaction.gasPrice
      }

      // const a = 1
      // if (a === 1) return false

      if (chainType.kardia === chain) {
        const uuid = new DeviceUUID().get()
        const deviceId = ReduxServices.getReduxState('deviceId')

        const decryptPrivateKey = await window.coin98?.provider?.request({ method: 'aes_decrypt_coin98', params: { data: privateKey, uuid, deviceId } })
        const cb = () => {}
        window.wallet.kardiaClient.transfer(decryptPrivateKey, generateTxs, callback || cb)
          .then(hash => {
            resolve(hash)
          })
          .catch(err => {
            reject(err)
          })
        return true
      }

      if (isDapp) {
        const returnData = { gasLimit: rawTransaction.gasLimit, data: rawTransaction.data }
        // dapp
        // const chainActive = ReduxServices.getReduxState('chainActive')
        window.coin98?.provider?.request({
          method: 'eth_sendTransaction',
          params: [rawTransaction]
        }, function (err, response) {
          if (err) {
            reject(encodeMessErr(err))
          } else {
            returnData.hash = response.result
            !isWaitDone && resolve(response.result)
          }
        }).then(async (hash) => {
          // confirmationHash(hash)
          isWaitDone && await awaitTransactionSignatureConfirmation(web3Run, hash)
          !isWaitDone && awaitTransactionSignatureConfirmation(web3Run, hash, callback)
          isWaitDone && callback && callback(hash)
          isWaitDone && resolve(hash)
        }).catch((err) => {
          callbackFail && callbackFail(err)
          isWaitDone && resolve(returnData.hash)
        })
      } else {
        // web
        const uuid = new DeviceUUID().get()
        const deviceId = ReduxServices.getReduxState('deviceId')

        const decryptPrivateKey = await window.coin98?.provider?.request({ method: 'aes_decrypt_coin98', params: { data: privateKey, uuid, deviceId } })

        const rawHashTxs = await web3Run.eth.accounts.signTransaction(rawTransaction, decryptPrivateKey)
        const signedTransaction = rawHashTxs.rawTransaction

        const runSignedTxs = () => {
          const returnData = { gasLimit: rawTransaction.gasLimit, data: rawTransaction.data }
          web3Run.eth.sendSignedTransaction(signedTransaction, (err, hash) => {
            if (err) {
              reject(encodeMessErr(err))
            } else {
              returnData.hash = hash
              !isWaitDone && resolve(isGetData ? returnData : returnData.hash)
            }
          }).on('confirmation', (confirms) => {

          }).then((responseFinal) => {
            callback && callback(isGetData ? returnData : returnData.hash)
            isWaitDone && resolve(isGetData ? returnData : returnData.hash)
          }).catch((err) => {
            callbackFail && callbackFail(err)
            isWaitDone && resolve(isGetData ? returnData : returnData.hash)
          })
        }
        runSignedTxs()
      }
    } catch (err) {
      callbackFail && callbackFail(err)
      reject(encodeMessErr(err))
    }
  })
}

export const getTokenInfo = (contractAddress, chain = chainType.ether) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (chain === chainType.tron) {
        const tronWeb = window.wallet.tronClient

        if (tronWeb.isAddress(contractAddress)) {
          return tronWeb.contract().at(contractAddress).then(contract => {
            contract.symbol().call().then(symbol => {
              contract.name().call().then(name => {
                contract.decimals().call().then(decimals => {
                  resolve({ name, decimals, symbol })
                }).catch(() => {
                  resolve({ name, decimals: 6, symbol })
                })
              }).catch(() => {
                resolve({ name: '', decimals: 6, symbol })
              })
            }).catch(() => {
              resolve(false)
            })
          }).catch(() => resolve(false))
        } else {
          const tradeobj = await tronWeb.trx.getTokenByID(contractAddress)
          if (tradeobj) {
            resolve({ name: tradeobj.name, decimals: tradeobj.precision, symbol: tradeobj.abbr })
          }
        }
      }

      const web3 = genWeb3(chain)
      const contract = genContract(web3, ERC20, contractAddress)

      contract.methods.symbol().call().then(symbol => {
        contract.methods.name().call().then(name => {
          contract.methods.decimals().call().then(decimals => {
            resolve({ name, decimals, symbol, address: contractAddress })
          }).catch(() => {
            resolve({ name, decimals: 18, symbol, address: contractAddress })
          })
        }).catch(() => {
          resolve({ name: '', decimals: 18, symbol, address: contractAddress })
        })
      }).catch(() => {
        resolve(false)
      })
    } catch (error) {
      resolve(false)
    }
  })
}

export const checkNumApproved = async (web3, contractAddress, owner, spender) => {
  try {
    const contract = genContract(web3, ERC20, contractAddress)
    const payload = await callContract(contract, 'allowance', [owner, spender])
    return payload
  } catch (error) {
    return null
  }
}

const callContract = (contract, name, params) => {
  return contract.methods[name](...params).call()
}

export const estimateGasSwapAMM = async (addressSwap, path, gasPrice, typeTrade, chain = chainType.ether) => {
  try {
    const web3 = genWeb3(chain)
    const cloneAccount = {
      ether: '0x73BCEb1Cd57C711feaC4224D062b0F6ff338501e',
      binanceSmart: '0xF68a4b64162906efF0fF6aE34E2bB1Cd42FEf62d',
      heco: '0xdaf88b74fca1246c6144bc846aaa3441ed095191',
      avax: '0x84D34f4f83a87596Cd3FB6887cFf8F17Bf5A7B83',
      matic: '0x3507e4978e0Eb83315D20dF86CA0b976c0E40CcB',
      celo: '0xf6436829Cf96EA0f8BC49d300c536FCC4f84C4ED',
      tomo: '0x37F463F7fD920DdC7c4a6415bCadfEe1d18F9849'
    }

    const addressClone = cloneAccount[chain]

    const contract = genContract(web3, Uniswap, addressSwap)
    const dataTxs = encodeABI(contract, 'swapExactETHForTokens', [0, path, addressClone, convertDecimalToHex('1898061806')])

    const generateTxs = {
      from: addressClone,
      to: addressSwap,
      data: dataTxs,
      gasPrice: convertBalanceToWei(gasPrice, 9),
      value: convertDecimalToHex(convertBalanceToWei(0.1))
    }

    const gasLimitEst = await estimateGasTxs(generateTxs, web3, chain, 80000)
    return gasLimitEst * 0.8
  } catch (error) {
    return 64000
  }
}

export const sendSwapAMM = ({
  addressSwap,
  address,
  trade,
  numDeadLine,
  slippage = 50,
  callBack,
  gasPrice,
  onEstimateGasConfirm,
  chain = chainType.ether,
  isUnlimitedApprove,
  typeTrade,
  receivedValue,
  isFromInput,
  callBackFail,
  sendRawTxn,
  isSponsorGas,
  isCoin98,
  callBackDataFirst
}) => {
  return new Promise(async (resolve, reject) => {
    if (parseFloat(receivedValue) <= 0) {
      return reject(sizeTooSmall)
    }

    const receiverAddress = address

    try {
      const web3 = genWeb3(chain)

      const inputCoin = trade.inputAmount.currency
      const outputCoin = trade.outputAmount.currency

      const mainCoin = MAIN_COIN_AMM[chain]

      // Celo chain use tokens only
      // remove wrap inputCoin.symbol === ('W' + mainCoin.symbol)
      const isEtherIn = (lowerCase(inputCoin.symbol) === lowerCase(mainCoin.symbol))
      const isEtherOut = (lowerCase(outputCoin.symbol) === lowerCase(mainCoin.symbol))

      const gasPriceConvertEst = convertBalanceToWei(gasPrice, 9)
      const tradeSelected = findTrade(typeTrade)
      const latestBlock = await web3.eth.getBlock('latest')
      const deadline = latestBlock.timestamp + numDeadLine

      const stateSwap = {
        feeOnTransfer: false,
        allowedSlippage: new Percent(JSBI.BigInt(Math.floor(slippage)), BIPS_BASE),
        recipient: receiverAddress,
        [tradeSelected.isTTL ? 'ttl' : 'deadline']: deadline
      }

      let swapCallMap = [swapCallParameters(isFromInput, trade, receiverAddress, stateSwap, isEtherIn, isEtherOut, typeTrade)]

      if (isFromInput) {
        swapCallMap.push(swapCallParameters(isFromInput, trade, receiverAddress, Object.assign(stateSwap, { feeOnTransfer: true }), isEtherIn, isEtherOut, typeTrade))
      }
      const swapCallFirst = swapCallMap[0]

      const contractSwap = genContract(web3, swapCallFirst.isV2 ? C98Swap : Uniswap, swapCallFirst.isV2 || addressSwap)

      swapCallMap = swapCallMap.map(parameters => ({ parameters, contract: contractSwap }))

      if (swapCallFirst.isV2) {
        addressSwap = swapCallFirst.isV2
      }

      const {
        parameters: { methodName, numApprove, args, value },
        contract
      } = swapCallMap[0]

      const dataTxsSwap = encodeABI(contract, methodName, args)

      const onSendFinalTxsUni = async (gasLimitEst, realDataTx, realValue) => {
        onEstimateGasConfirm(gasLimitEst * convertWeiToBalance(gasPrice, 9), async () => {
          const swapData = {
            to: addressSwap,
            data: realDataTx,
            // gasPrice: gasPriceConvertEst,
            value: isEtherIn ? realValue : '0x0',
            callBack,
            callBackFail,
            isWaitDone: true,
            callBackDataFirst,
            isGasFree: isSponsorGas
          }

          sendRawTxn(swapData)

          // postBaseSendTxs(chain, '', swapData, false, callBack).then(res => {
          //   resolve(res)
          // }).catch(err => {
          //   reject(err)
          // })
        })
      }

      const onSendTxsUni = async () => {
        try {
          estimateGasTxs({
            from: receiverAddress,
            to: addressSwap,
            data: dataTxsSwap,
            gasPrice: gasPriceConvertEst,
            value: isEtherIn ? value : 0
          }, web3).then(resultGas => {
            onSendFinalTxsUni(resultGas, dataTxsSwap, value)
          }).catch((err) => {
            if (swapCallMap[1]) {
              const {
                parameters: { methodName: methodNameFee, args: argsFee, value: valueFee },
                contract
              } = swapCallMap[1]

              const dataTxsSwapFee = encodeABI(contract, methodNameFee, argsFee)

              estimateGasTxs({
                from: receiverAddress,
                to: addressSwap,
                data: dataTxsSwapFee,
                gasPrice: gasPriceConvertEst,
                value: isEtherIn ? valueFee : 0
              }, web3).then(resultGasFee => {
                onSendFinalTxsUni(resultGasFee, dataTxsSwapFee, valueFee)
              }).catch((err) => {
                reject(err)
              })
            } else {
              reject(err)
            }
          })
        } catch (error) {
          reject(error)
        }
      }
      if (!isEtherIn) {
        const amountConvertCompare = convertWeiToBalance(convertHexToDecimal(numApprove), inputCoin.decimals)
        sendApprovedToken({
          web3,
          tokenAddress: inputCoin.address,
          decimals: inputCoin.decimals,
          spender: addressSwap,
          owner: address,
          numApprove: amountConvertCompare,
          chain,
          isLimitedApproved: !isUnlimitedApprove,
          onEstimateGasConfirm,
          gasPrice,
          sendRawTxn,
          isSponsorGas,
          deadline,
          callBack: onSendTxsUni,
          callBackFail,
          isCoin98
        })
      } else {
        onSendTxsUni()
      }
    } catch (error) {
      console.log('Swap AMM error ', error)
      reject(error)
    }
  })
}

const sendApprovedToken = async ({
  web3,
  tokenAddress,
  decimals = 18,
  spender,
  owner,
  numApprove,
  chain,
  isLimitedApproved,
  onEstimateGasConfirm,
  gasPriceSelect,
  sendRawTxn,
  isSponsorGas,
  deadline,
  callBack,
  callBackFail,
  isCoin98
}) => {
  try {
    if (getLength(tokenAddress) === 0) return callBack()

    const web3Current = web3 || genWeb3(chain)
    let currentApproved = await checkNumApproved(web3Current, tokenAddress, owner, spender)
    let isApproveSponsorGas = isSponsorGas

    currentApproved = currentApproved ? parseFloat(currentApproved) : 0

    const approvedConvert = convertBalanceToWei(numApprove, decimals)
    const isNoNeedApprove = currentApproved >= approvedConvert
    const newAmountApprove = isLimitedApproved ? approvedConvert : convertHexToDecimal(unlimitedHex)
    let dataTxs

    if (!isNoNeedApprove) {
      if (isSponsorGas && chain === chainType.tomo) {
        await createPermit({ chain, tokenAddress, ownerAddress: owner, spenderAddress: spender, amount: newAmountApprove, deadline, isCoin98 })
          .then(result => {
            dataTxs = generatePermitData(web3Current, tokenAddress, spender, newAmountApprove, result, owner)
          })
          .catch(err => {
            isApproveSponsorGas = false
            console.log('🚀 ~ file: evm.js:666 ~ err:', err)
            dataTxs = generateApprovedData(web3Current, tokenAddress, spender, newAmountApprove)
          })
      } else {
        dataTxs = generateApprovedData(web3Current, tokenAddress, spender, newAmountApprove)
      }
    }

    if (dataTxs) {
      const generateTxs = {
        to: isApproveSponsorGas ? PERMIT_HELPER : tokenAddress,
        data: dataTxs,
        isWaitDone: true,
        isGasFree: isSponsorGas
      }
      const callApproved = async () => {
        const res = await sendRawTxn(generateTxs)

        if (res?.isError) {
          return callBackFail(res?.error)
        }
        await sleep(4000)
        callBack(res?.data)
      }
      if (onEstimateGasConfirm) {
        estimateGasTxs({
          from: owner,
          to: generateTxs.to,
          data: dataTxs,
          gasPrice: convertBalanceToWei(gasPriceSelect, 9)
        }, web3, chain, null, false, callBackFail).then(gasLimitEst => {
          onEstimateGasConfirm(gasLimitEst * convertWeiToBalance(gasPriceSelect, 9), callApproved, 'confirmApprove')
        })
      } else {
        callApproved()
      }
    } else {
      callBack()
    }
  } catch (error) {
    callBackFail(error)
  }
}

export async function createPermit ({ chain, tokenAddress, ownerAddress, spenderAddress, amount, isCoin98 }) {
  const web3 = genWeb3(chain)
  const contractToken = genContract(web3, VRC25, tokenAddress)
  const nonce = await contractToken.methods.nonces(ownerAddress).call()
  const nameToken = await contractToken.methods.name().call()
  const latestBlock = await web3.eth.getBlock('latest')
  const deadline = latestBlock.timestamp + 1200

  const nameVersionPermit = NAME_VERSION_PERMIT[nameToken]

  if (!nameVersionPermit) {
    throw new TypeError('contract name not found')
  }

  const domain = {
    name: nameVersionPermit.name,
    version: nameVersionPermit.version,
    chainId: 88,
    verifyingContract: tokenAddress
  }

  const types = {
    Permit: [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
      { name: 'value', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'deadline', type: 'uint256' }
    ]
  }

  const value = {
    owner: ownerAddress,
    spender: spenderAddress,
    value: amount,
    nonce,
    deadline
  }

  const provider = new ethers.providers.Web3Provider(isCoin98 ? window?.coin98?.provider : window.ethereum)
  // await provider.send('eth_requestAccounts', [])
  const signer = provider.getSigner()

  const signature = await signer._signTypedData(domain, types, value)

  const ecdsaSignature = {
    r: '0x' + signature.substring(2, 66),
    s: '0x' + signature.substring(66, 130),
    v: parseInt(signature.substring(130, 132), 16),
    deadline
  }
  return ecdsaSignature
}

export const onWrapUnWrapToken = async ({ addressWToken, addressWallet, chain, callBack, callBackFail, sendRawTxn, amount, isWrap }) => {
  try {
    const addressContract = addressWToken || W_MAIN_COIN[chain]?.address
    const web3 = genWeb3(chain)
    const contract = genContract(web3, abiWrapToken, addressContract)
    let data
    if (isWrap) {
      data = contract.methods.deposit().encodeABI()
    } else {
      data = contract.methods.withdraw(convertBalanceToWei(amount)).encodeABI()
    }

    const rawTxs = {
      from: addressWallet,
      to: addressContract,
      value: isWrap ? convertDecimalToHex(convertBalanceToWei(amount)) : '',
      data,
      callBack,
      callBackFail,
      isWaitDone: true
    }

    await sendRawTxn(rawTxs)
  } catch (err) {
    console.log('🚀 ~ file: evm.js:808 ~ onWrapToken ~ err:', err)
  }
}
