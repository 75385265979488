import React from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'

const ItemTable = ({ data, columns, isLink }) => {
  const Div = ({ children, ...props }) => {
    return <div {...props}>{children}</div>
  }
  const A = ({ children, ...props }) => {
    return <a {...props} target="_blank" rel="noreferrer" href={data.href}>{children}</a>
  }
  return (
    <>
      {columns.map((col) => {
        if (col.hide) return null
        const Render = data.href ? (isLink ? Link : A) : Div
        return (
          <td
            key={col.dataIndex}
          >
            <Render to={data.href} className={cn(`justify-${col.align}`)} >
              {col.render ? col.render(data[`${col.dataIndex}`]) : data[`${col.dataIndex}`]}
            </Render>
          </td>
        )
      }
      )}
    </>
  )
}

export default ItemTable
