import React, { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/common/Button'

import './style.scss'
import PopupHash from 'components/PopupHash'
import get from 'lodash/get'
import { convertBalanceToWei, renderAmountSlippage, roundingNumber, showNotificationToast, upperCase, formatPrice, convertWeiToBalance } from 'common/functions'

import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import useRouterContract from 'common/poolEvm/hooks/useRouterContract'
import images from 'assets/images'
import BaseAPI from 'controller/api/BaseAPI'
import { encodeMessErr } from 'controller/Library/evm'
import AboutToken from 'components/common/AboutToken'
import { useWallet } from '@coin98-com/wallet-adapter-react'
import useServices from 'controller/Library/useServices'

export const typeConfirmPool = {
  createPool: {
    title: 'youAreCreatingPool',
    value: 'createPool',
    nameButton: 'confirmCreate'
  },
  addLiquidity: {
    title: 'youWillReceived',
    value: 'addLiquidity',
    nameButton: 'supply'
  },
  receive: {
    title: 'youWillReceived',
    value: 'receive',
    nameButton: 'remove'
  }
}

const ConfirmOrder = ({
  fromCoin,
  toCoin,
  fromCoinAmount,
  toCoinAmount,
  type,
  shareOfPool,
  lpTokenAmount,
  getAmount,
  slippage = 0.1,
  poolInfo,
  isRemoveAll,
  isRemoveFullLp,
  callback,
  isRouterWtomo
}) => {
  const { t } = useTranslation()
  const { address } = useWallet()
  const { sendRawTxn } = useServices()
  const chainActive = useSelector((state) => state.chainActive)

  const [isLoading, setIsLoading] = useState(false)
  const [dataRawTxs, setDataRawTxs] = useState(null)

  const isReceive = type === typeConfirmPool.receive.value
  const isCreate = type === typeConfirmPool.createPool.value

  const decimalsFromCoin = fromCoin.decimal || fromCoin.decimals
  const decimalsToCoin = toCoin.decimal || toCoin.decimals

  const { addLiquidity, removeLiquidity } = useRouterContract(chainActive, isRouterWtomo)

  useEffect(() => {
    renderOnClick()
  }, [type])

  const renderFromSymbol = useMemo(() => {
    return upperCase(get(fromCoin, 'symbol', ''))
  }, [fromCoin])

  const renderToSymbol = useMemo(() => {
    return upperCase(get(toCoin, 'symbol', ''))
  }, [toCoin])

  const renderFromIcon = useMemo(() => {
    return get(fromCoin, 'logoURI') || get(fromCoin, 'image', images.icoUnknown)
  }, [fromCoin])

  const renderToIcon = useMemo(() => {
    return get(toCoin, 'logoURI') || get(toCoin, 'image', images.icoUnknown)
  }, [toCoin])

  const renderOnClick = () => {
    if (type === typeConfirmPool.createPool.value) handleCreatePool()
    if (type === typeConfirmPool.addLiquidity.value) handleAddLiquidity()
    if (type === typeConfirmPool.receive.value) handleWithdraw()
  }

  const onSendTxs = () => {
    setIsLoading(true)
    sendRawTxn({ ...dataRawTxs, callBack: handleTxsSuccess, callBackFail: onTradeFail, isWaitDone: true })
  }

  const handleCreatePool = async () => {
    setIsLoading(true)

    addLiquidity({
      tokenA: get(fromCoin, 'address', ''),
      tokenB: get(toCoin, 'address', ''),
      amountADesired: convertBalanceToWei(fromCoinAmount, decimalsFromCoin),
      amountBDesired: convertBalanceToWei(toCoinAmount, decimalsToCoin),
      amountAMin: convertBalanceToWei(fromCoinAmount, decimalsFromCoin),
      amountBMin: convertBalanceToWei(toCoinAmount, decimalsToCoin),
      account: address
    }).then(({ rawTxs, gasLimitEst }) => {
      setDataRawTxs(rawTxs)
      setIsLoading(false)
    }).catch(err => onTradeFail(err))
  }

  const handleAddLiquidity = async () => {
    setIsLoading(true)

    const token0AmountMin = parseFloat(fromCoinAmount) - renderAmountSlippage(fromCoinAmount, slippage)

    const token1AmountMin = parseFloat(toCoinAmount) - renderAmountSlippage(toCoinAmount, slippage)

    addLiquidity({
      tokenA: get(fromCoin, 'address', ''),
      tokenB: get(toCoin, 'address', ''),
      amountADesired: convertBalanceToWei(fromCoinAmount, decimalsFromCoin),
      amountBDesired: convertBalanceToWei(toCoinAmount, decimalsToCoin),
      amountAMin: convertBalanceToWei(token0AmountMin, decimalsFromCoin),
      amountBMin: convertBalanceToWei(token1AmountMin, decimalsToCoin),
      account: address
    }).then(({ rawTxs, gasLimitEst }) => {
      setDataRawTxs(rawTxs)
      setIsLoading(false)
    }).catch(err => onTradeFail(err))
  }

  const handleWithdraw = async () => {
    const token0AmountMin = fromCoinAmount - renderAmountSlippage(fromCoinAmount, slippage)
    const token1AmountMin = toCoinAmount - renderAmountSlippage(toCoinAmount, slippage)

    setIsLoading(true)

    const lpTokenAmountV1 = isRemoveAll ? get(poolInfo, 'balanceOfLpWei') : convertBalanceToWei(lpTokenAmount)
    const lpTokenRemoveFull = convertWeiToBalance(+get(poolInfo, 'balanceOfLpWei', 0) - 1000000)

    removeLiquidity({
      tokenA: get(fromCoin, 'address', ''),
      tokenB: get(toCoin, 'address', ''),
      lpTokenAmount: isRemoveFullLp ? convertBalanceToWei(lpTokenRemoveFull) : lpTokenAmountV1,
      amountAMin: convertBalanceToWei(token0AmountMin, decimalsFromCoin),
      amountBMin: convertBalanceToWei(token1AmountMin, decimalsToCoin),
      account: address,
      slippage
    }).then(({ rawTxs, gasLimitEst }) => {
      setDataRawTxs(rawTxs)
      setIsLoading(false)
    }).catch(err => onTradeFail(err))
  }

  const orderComplete = ({ hash }) => {
    window.openModal({
      title: t('success'),
      containerClass: 'modal-success',
      content: <PopupHash hash={hash} />,
      type: 'success',
      okText: t('close')
    })
  }

  const onTradeFail = (textMess = 'txsFail') => {
    toast.dismiss()
    window.closeModal()
    const text = encodeMessErr(textMess)
    showNotificationToast(t(text), 'error', 2000)
    setIsLoading(false)
  }

  const handleCancelModal = () => {
    window.closeModal()
  }

  const handleTxsSuccess = async (hash) => {
    try {
      await BaseAPI.postData('baryon/liquidity', {
        hash,
        chain: chainActive
      })

      setTimeout(() => {
        toast.dismiss()
        orderComplete({ hash })
        setIsLoading(false)
      }, 1500)

      if (typeof callback === 'function') {
        callback()
      }
    } catch (err) {
      onTradeFail()
    }
  }
  return (

    <div className="confirm-order">
      <div className="confirm-order__title">
        {t(typeConfirmPool[type].title)}
      </div>

      {!isReceive
        ? <div className="confirm-order__deposit">
          {!isCreate && <div className="deposit-amount">{formatPrice(lpTokenAmount, false, true)}</div>}
          <div className="deposit-coin">
            <div>
              <AboutToken className={'deposit-coin__item'} imgToken={renderFromIcon}/>
            </div>
            <div>
              <AboutToken className={'deposit-coin__item'} imgToken={renderToIcon}/>
            </div>
          </div>
          <div className="deposit-symbol">
            {t('tokenPool', {
              symbol: `${renderFromSymbol}/${renderToSymbol}`
            })}{' '}
          </div>
        </div>
        : <div className="confirm-order__remove">

          <div className="remove-item">
            <div className="remove-item__number">{formatPrice(fromCoinAmount, false, true)}</div>
            <div className="remove-item__box-coin">
              <div className="symbol">{renderFromSymbol}</div>
              <div>
                <AboutToken className={'box-coin-img'} imgToken={renderFromIcon}/>
              </div>
            </div>
          </div>
          <div className="plus">+</div>

          <div className="remove-item">
            <div className="remove-item__number">{formatPrice(toCoinAmount, false, true)}</div>
            <div className="remove-item__box-coin">
              <div className="symbol">{renderToSymbol}</div>
              <div>
                <AboutToken className={'box-coin-img'} imgToken={renderToIcon}/>
              </div>
            </div>
          </div>

        </div>

      }

      <div className={`confirm-order__info ${isReceive && 'confirm-order__info--remove'}`}>

        {isReceive
          ? <div className="info-item">
            <p>{renderFromSymbol}/{renderToSymbol} </p>
            <div className="info-item__balance">
              <span>{formatPrice(lpTokenAmount, false, true)}</span>
              <div>
                <AboutToken className={'balance-img'} imgToken={renderFromIcon}/>
              </div>
              <div>
                <AboutToken className={'balance-img'} imgToken={renderToIcon}/>
              </div>
            </div>
          </div>
          : <>
            <div className="info-item">
              <p>{renderFromSymbol} {!isReceive ? 'Deposited' : ''} </p>
              <div className="info-item__balance">
                <span>{formatPrice(fromCoinAmount, false, true)}</span>
                <div>
                  <AboutToken className={'balance-img'} imgToken={renderFromIcon}/>
                </div>
              </div>
            </div>
            <div className="info-item">
              <p>{renderToSymbol} {!isReceive ? 'Deposited' : ''}</p>
              <div className="info-item__balance">
                <span>{formatPrice(toCoinAmount, false, true)}</span>
                <div>
                  <AboutToken className={'balance-img'} imgToken={renderToIcon}/>
                </div>
              </div>
            </div>
          </>
        }
        <div className="info-item info-item--column">
          <p>{t('rates')}</p>
          <div className="info-item__balance info-item__balance--column">
            <div className="balance-item">
              1 {upperCase(fromCoin.symbol)} = {formatPrice(getAmount(fromCoin, 1), false, true)} {upperCase(toCoin.symbol)}
            </div>
            <div className="balance-item">
              1 {upperCase(toCoin.symbol)} = {formatPrice(getAmount(toCoin, 1), false, true)} {upperCase(fromCoin.symbol)}
            </div>
          </div>
        </div>

        {!isReceive &&
        <div className="info-item">
          <p>{t('shareOfPool')}</p>
          <div className="info-item__balance">
            <span>{roundingNumber(shareOfPool, shareOfPool < 0.01 ? 4 : 2)} %</span>
          </div>
        </div>
        }

      </div>
      <div className="box-button-confirm">
        <Button
          onClick={handleCancelModal}
          isFullWidth
          type="border-popup"
          className="confirm-order__actions confirm-order__cancel"
        >
          {t('cancel')}
        </Button>

        <Button
          isLoading={isLoading}
          onClick={onSendTxs}
          isFullWidth
          className="confirm-order__actions"
        >
          {t(typeConfirmPool[type].nameButton)}
        </Button>
      </div>

    </div>
  )
}

export default ConfirmOrder
