import React from 'react'
import cn from 'classnames'
import style from './style.module.scss'
import { useTranslation } from 'react-i18next'

const StatusItem = ({ number, className, status = 'onGoing' }) => {
  const { t } = useTranslation()
  return (
    <div className={cn(style['status-item'], className, status)}>
      {number && <span>{number}</span>}
      {status && <span>{t(status)}</span>}
    </div>
  )
}

export default StatusItem
