import React from 'react'
import BannerTitle from './components/BannerTitle'
import BaryonMenu from './components/BaryonMenu'
import BaryonQualities from './components/BaryonQualities'
import EcosystemToken from './components/EcosystemToken'

import './style.scss'

function HomeScreen () {
  return (
    <div className='home-container'>

      <BannerTitle />

      <EcosystemToken />

      <BaryonMenu />
      <BaryonQualities/>

    </div>
  )
}

export default HomeScreen
