import React, { useMemo } from 'react'
import cn from 'classnames'
import style from './style.module.scss'
import { formatAddress, formatBilion, formatNumberBro, getLength, nFormatter } from 'common/functions'
import { useTranslation } from 'react-i18next'
import { get, random } from 'lodash'
import AboutToken from 'components/common/AboutToken'
import { CustomTable } from 'components/CustomTable'
import ReduxServices from 'common/redux'
import { useSelector } from 'react-redux'
import Pagination from 'pages/PoolScreen/components/Pagination'
import { useHistory } from 'react-router-dom'
import { getTokeInfoPool } from 'common/poolEvm/function'
const LIMIT = 10
const TableLiquidity = ({ address, tableData, isLoading, totalPage, onChangePage, currPage, onClickRow }) => {
  const { t } = useTranslation()
  const chainActive = useSelector((state) => state.chainActive)

  const history = useHistory()
  const currencyRedux = useSelector(state => state.currencyRedux)
  const columnsDefault = [
    {
      dataIndex: 'index',
      title: '',
      width: 5,
      className: 'center'
    },
    {
      dataIndex: 'account',
      title: t('account'),
      width: 20,
      render: (val) => {
        return (
          <span className='text-link'>
            {formatAddress(val, 10)}
          </span>
        )
      }
    },
    {
      title: t('pair'),
      dataIndex: 'pair',
      width: 15,
      render: (val) => {
        const { token0, token1 } = val
        const newToken0 = getTokeInfoPool(token0, chainActive)
        const newToken1 = getTokeInfoPool(token1, chainActive)
        return (
          <div className='d-flex align-items-center text-uppercase'>
            <AboutToken imgToken={get(newToken0, 'image') || get(newToken0, 'logoURI')} alt="" className="token-img token-img--token-1 img-24 mr-1" />
            <AboutToken imgToken={get(newToken1, 'image') || get(newToken1, 'logoURI')} alt="" className="token-img token-img--token-2 img-24 mr-2" />
            <span className='mobile-hidden'>{get(newToken0, 'symbol')} / { get(newToken1, 'symbol')}</span>
          </div>
        )
      }
    },
    {
      title: t('token1Amount'),
      dataIndex: 'token0Amount',
      width: 15,
      align: 'right',
      render: (val) => {
        const { tokenInfo, amount } = val
        return (
          <div className='text-uppercase truncate-table-text'>{formatBilion(amount)}  {get(tokenInfo, 'symbol')}</div>
        )
      }
    },
    {
      title: t('token2Amount'),
      dataIndex: 'token1Amount',
      width: 15,
      align: 'right',
      render: (val) => {
        const { tokenInfo, amount } = val
        return (
          <span className='text-uppercase truncate-table-text'>{formatBilion(amount, 2)} {get(tokenInfo, 'symbol')}</span>
        )
      }
    },
    {
      title: t('lpValue'),
      dataIndex: 'value',
      width: 15,
      align: 'right',
      render: (val) => {
        return <div>{ReduxServices.formatPrice(val)}</div>
      }
    },
    {
      title: t('poolShare'),
      dataIndex: 'poolShare',
      align: 'right',
      width: 20,
      render: (val) => {
        const valFormat = val * 100
        return <div>{formatNumberBro({ number: valFormat, mantissa: 2 })}%</div>
      }
    }
  ]

  const dataLPTable = useMemo(() => {
    if (getLength(tableData) === 0) return []
    return tableData.map((item, index) => {
      return {
        index: LIMIT * (currPage - 1) + index + 1,
        poolAddress: get(item, 'poolAddress'),
        account: item.address,
        pair: get(item, 'poolInfo', {}),
        token0Amount: { tokenInfo: get(item, 'poolInfo.token0', {}), amount: item.token0Amount },
        token1Amount: { tokenInfo: get(item, 'poolInfo.token1', {}), amount: item.token1Amount },
        value: item.amountUSD,
        poolShare: item.poolShare
      }
    })
  }, [tableData, currencyRedux])

  const handleChangePage = (val) => {
    const { selected } = val
    onChangePage && onChangePage(selected + 1)
  }

  const handleClickRow = (row) => {
    // onNavigateToDetailWallet(row)
    onClickRow && onClickRow(row)
  }

  return (
    <div className={cn(style['table-liquidity'])}>
      <CustomTable
        isLoading={isLoading}
        columns={columnsDefault}
        dataSource={dataLPTable}
        onClickRow={handleClickRow}
      />

      <div className='d-flex justify-content-center'>
        {
          getLength(tableData) > 0 &&
          <Pagination
            forcePage={currPage - 1}
            totalPage={totalPage}
            handleSelectedPage={handleChangePage}
          />
        }
      </div>

    </div>
  )
}

export default TableLiquidity
