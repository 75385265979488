import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import CoinItem from './CoinItem'
import BaseAPI from 'controller/api/BaseAPI'

import './style.scss'
import { STABLE_COIN } from 'common/poolEvm/constants'
import get from 'lodash/get'
import { getLength, lowerCase } from 'common/functions'
import { useTranslation } from 'react-i18next'

function EcosystemToken () {
  const chainActive = useSelector(state => state.chainActive)
  const { t } = useTranslation()

  const [listTopToken, setListTopToken] = useState([])

  const length = getLength(listTopToken)

  useEffect(() => {
    loadListCoin()
  }, [chainActive])

  const loadListCoin = async () => {
    const response = await BaseAPI.postData('baryon/token/top', {
      chain: chainActive,
      page: 1,
      size: 20
    })
    const data = get(response, 'data')
    if (data) {
      const newData = data.filter(item => !STABLE_COIN.includes(lowerCase(item.info.symbol)))

      const listToken = newData.slice(0, 8)
      const mapListToken = listToken.map(item => {
        const newItem = { ...item }
        if (get(item, 'info.id') === '') {
          newItem.info.id = '0'
        }
        return newItem
      })
      const chartData = await BaseAPI.getData('coin/cgk/chart', {
        isMinimize: true,
        idList: mapListToken.map((itm) => itm.info.id),
        day: 7,
        interval: 3600
      }) || []

      const newListTopToken = mapListToken.map(item => {
        const findChart = chartData.find((data) => data.id === item.info.id)
        return { ...item, chart: get(findChart, 'data') }
      })
      setListTopToken(newListTopToken.splice(0, 8) || [])
    }
  }

  const renderItemCoin = useMemo(() => {
    return listTopToken.map((item, i) => {
      return <CoinItem key={i} item={item} listTopToken={listTopToken} />
    })
  }, [listTopToken])

  const renderClass = useMemo(() => {
    const lengthListToken = getLength(listTopToken)
    if (lengthListToken <= 3) {
      return 'ecosystem-token__list-coin--flex'
    } else if (lengthListToken <= 4) {
      return ''
    } else if (lengthListToken <= 6) {
      return 'ecosystem-token__list-coin--six-column'
    }
  }, [length])

  if (length === 0) {
    return (
      <></>
    )
  }

  return (
    <div className='ecosystem-token'>
      <h2 className="ecosystem-token__title heading-2">
        {t('exoloreTrending')}
      </h2>

      <div className={`ecosystem-token__list-coin ${renderClass}`}>
        {renderItemCoin}
      </div>
    </div>
  )
}

export default EcosystemToken
