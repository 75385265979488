import React, { useState, useEffect } from 'react'
import ItemTablePool from 'pages/PoolScreen/components/ItemTablePool'
import Pagination from 'pages/PoolScreen/components/Pagination'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { isWindow } from 'common/constants'
import images from 'assets/images'
import IconCustom from 'components/common/IconCustom'

const columnsDefault = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: 20,
    icon: ''
  },
  {
    title: 'Age',
    dataIndex: 'age',
    width: 40,
    icon: ''
  },
  {
    title: 'Address',
    dataIndex: 'address',
    width: 40,
    icon: ''
  }
]

const dataSourceDefault = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park'
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park'
  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park'
  },
  {
    key: '4',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park'
  }
]

const TablePool = ({
  columns,
  dataSource,
  isLoading,
  totalPage,
  handleSelectedPage,
  initialPage,
  isTransactions,
  tabTxt,
  isTokens,
  isPools
}) => {
  const { t } = useTranslation()
  const isEmptyData = !isLoading && !dataSource.length
  const [widthScreen, setWidthScreen] = useState(isWindow && window.innerWidth)

  const [currentTab, setCurrentTab] = useState(tabTxt)

  const isShowPagination = (currentTab === tabTxt) && (totalPage > 1)

  const renderBodyTable = () => {
    if (isLoading) {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((row) => (
        <div key={row}>
          <ItemTablePool isLoading={true} columns={columns} isTransactions />
        </div>
      ))
    }
    return dataSource.map((row) => (
      <div key={row.key} className="body-row">
        <ItemTablePool
          isLoading={isLoading}
          columns={columns}
          data={row}
          isTokens={isTokens}
          isPools={isPools}
          // isTransactions
        />
      </div>
    ))
  }

  const handleResize = () => {
    setWidthScreen(window.innerWidth)
  }

  useEffect(() => {
    isWindow && window.addEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    setCurrentTab(tabTxt)
  }, [tabTxt])

  return (
    <>
      <div className="table-pool">
        <div className={`table-pool__head ${isTransactions && 'table-pool__head--transaction'}`}>
          {columns.map((col, index) => (
            <div
              style={{ width: `${col.width}%` }}
              key={col.dataIndex}
              className={`head-item table-item__${col.dataIndex} head-item--${col.align} ${isTransactions && 'head-item__transactions'} ${col.dataIndex === 'totalValue' && 'head-item__total-value-title'}`}
            >
              <p>{col.title}</p>
              {col.icon || null}
            </div>
          ))}
        </div>
        <div
          className={`table-pool__body ${
            isEmptyData ? 'table-pool__body--empty' : ''
          }`}
        >
          {isEmptyData && <img src={images.iconEmptyTable} className='empty-icon' alt="" />}
          {isEmptyData ? t('emptyDataTable') : renderBodyTable()}
        </div>
      </div>

      {isShowPagination && (
        <div className="table-pool__pagination">
          <Pagination
            initialPage={initialPage}
            handleSelectedPage={handleSelectedPage}
            totalPage={totalPage}
          />
        </div>
      )}
    </>
  )
}

export default TablePool
