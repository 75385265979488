import React from 'react'
import { useTranslation } from 'react-i18next'
import './style.scss'

const NotfoundScreen = () => {
  const { t } = useTranslation()
  return <div className="not-found-screen">{t('notFoundScreen')}</div>
}

export default NotfoundScreen
