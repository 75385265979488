import React from 'react'
import { formatAddress } from 'common/functions'
import images from 'assets/images'

import './style.scss'
import Button from 'components/common/Button'
import { useTranslation } from 'react-i18next'
import ProfilePopUp from '../ProfilePopUp'
import { useSelector } from 'react-redux'

function ProfileHead () {
  const { t } = useTranslation()
  const isDarkMode = useSelector(state => state.themeRedux) === 'dark'

  const handlePopUp = () => {
    window.openModal({
      containerClass: 'modal-edit-profile',
      noIcon: true,
      backgroundTrans: true,
      className: 'modal-content--edit-profile',
      content: (
        <ProfilePopUp isEditProfile/>
      )
    })
  }

  const handleLinkDagora = () => {

  }

  return (
    <div className='profile-head'>
      <div className="profile-head--top">
        <img src={images[isDarkMode ? 'fox1' : 'fox1Light']} alt="" className="icon-fox icon-fox--icon-1" />
        <img src={images[isDarkMode ? 'fox2' : 'fox2Light']} alt="" className="icon-fox icon-fox--icon-2" />
        <img src={images[isDarkMode ? 'foxAngry' : 'foxAngryLight']} alt="" className="icon-fox icon-fox--icon-3" />
      </div>

      <div className="profile-head--bottom">
        <div className="bottom-left">
          <div className="info-avatar">
            {/* avatarBaryon && avatarBaryonLight: is img default when img no data */}
            <img src={images[isDarkMode ? 'avatarBaryon' : 'avatarBaryonLight']} alt="" className={`info-avatar--icon--no-data ${images.nftasda && 'info-avatar--icon'}`} onClick={handlePopUp} />
          </div>

          <div className="info-text">
            <div className="info-text__text-name">
            @Defender
            </div>

            <div className="info-text__text-address">
              {formatAddress('5UrM9csUEDBeBqMZTuuZyHRNhbRW4vQ1MgKJDrKU1U2v')}
            </div>
          </div>
        </div>

        <div className="bottom-right">
          <a href="https://pre.dagora.xyz/" target='_blank' rel="noreferrer">
            <Button
              className='btn-buy'
            >
              {t('buyFoxie')}
            </Button>
          </a>

          <div className="bottom-right__dagora-logo">
            {t('on')}
            <img src={images[isDarkMode ? 'dagoraLogo' : 'dagoraLogoLight']} alt="" className="logo-dagora" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileHead
