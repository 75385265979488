import React, { useEffect, useMemo, useRef, useState } from 'react'
import InfoTotalValue from './InfoTotalValue'
import MenuBar from './MenuBar'
import MyReward from './MyReward'

import './style.scss'
import ItemStakeCoin from './ItemStakeCoin'
import { useSelector } from 'react-redux'
import BaseAPI from 'controller/api/BaseAPI'
import get from 'lodash/get'
import { genContract, genWeb3, getPriceLp } from 'controller/Library/evm'
import { convertWeiToBalance, getLength, lowerCase, upperCase } from 'common/functions'
import { generateId } from 'common/functions/utils'
import ERC20 from 'common/abi/ERC20'
import { getPrice } from 'common/poolEvm/function'
import ItemFarmBox from './ItemFarmBox'

import { getPoolAprMulti, totalRewardPerDay } from 'common/stake/functions'
import images from 'assets/images'
import { CONTRACT_FARM_STAKE_V2, CONTRACT_FARM_STAKE_V1, W_MAIN_COIN_STAKE, MAIN_COIN_AMM } from 'common/poolEvm/constants'
import usePairs from 'common/poolEvm/hooks/usePairs'
import abiFarmV2 from 'common/abi/abiFarmV2'
import { useWallet } from '@coin98-com/wallet-adapter-react'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useDebouncedCallback } from 'use-debounce'

export const SORT_APR = 'SORT_APR'
export const SORT_LIQUIDITY = 'SORT_LIQUIDITY'

function FarmScreenMulti ({ isStake }) {
  const search = new URL(window.location.href).search
  const query = new URLSearchParams(search)
  const poolAddress = query.get('poolAddress')
  const isCompleted = (query.get('completed') === 'true')

  const chainActive = useSelector((state) => state.chainActive)
  const { address } = useWallet()

  const [listStakeData, setListStakeData] = useState([])
  const [isActive, setIsActive] = useState(!isCompleted)
  const [isStakedOnly, setIsStakedOnly] = useState(false)
  const [txtSearch, setTxtSearch] = useState('')

  const [typeSort, setTypeSort] = useState(SORT_LIQUIDITY)
  const [isSortHightToLow, setIsSortHightToLow] = useState(true)

  const { getLp: getInfoLp } = usePairs(chainActive, address)

  const loadListStake = async () => {
    const res = await BaseAPI.getData(isStake ? 'baryon/stake' : 'baryon/farm', { chain: chainActive })
    const dataApi = get(res, 'data', [])
    const mapList = await Promise.all(dataApi.map(async item => {
      const infoLp = await getInfoLp(null, null, item.stakedLpAddress)

      const token0 = get(infoLp, 'token0.tokenInfo')
      const token1 = get(infoLp, 'token1.tokenInfo')

      let tokenInfoStaked

      if (lowerCase(item.stakedLpAddress) === lowerCase(W_MAIN_COIN_STAKE[chainActive]?.address)) {
        tokenInfoStaked = W_MAIN_COIN_STAKE[chainActive]
      } else {
        tokenInfoStaked = await window.wallet.fetchInfoCoin(chainActive, item.stakedLpAddress)
      }

      const priceStake = await loadPriceStake(infoLp, tokenInfoStaked)

      const rewardTokenAddress = get(item, 'rewardTokens', [])
      const rewardTokens = await Promise.all(rewardTokenAddress.map(async (itemTokenAddress, index) => {
        let token
        if (lowerCase(itemTokenAddress) === lowerCase(W_MAIN_COIN_STAKE[chainActive]?.address)) {
          token = W_MAIN_COIN_STAKE[chainActive]
        } else {
          token = await window.wallet.fetchInfoCoin(chainActive, itemTokenAddress)
        }
        const priceCoinEarn = await getPrice(token, chainActive)
        const rateReward = item.rewardMultipliers[index]
        return { ...token, price: priceCoinEarn, rateReward }
      }))

      const timestampNow = Math.floor(new Date().getTime() / 1000)
      const isCompleted = timestampNow >= parseInt(item.rewardsExpiration)

      return {
        ...item,
        tokenInfoStaked,
        priceStake,
        token0,
        token1,
        poolLimitPerUser: convertWeiToBalance(item.poolLimitPerUser),
        rewardTokens,
        isCompleted
      }
    }))
    return mapList
  }

  const dataQueryAPI = useQuery({
    queryKey: [`baryon/${isStake ? 'stake' : 'farm'}/${chainActive}`],

    queryFn: loadListStake,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: 50000
  })
  const listStakeApi = get(dataQueryAPI, 'data', [])
  const isLoadingApi = get(dataQueryAPI, 'isLoading')

  useEffect(() => {
    document.title = isStake ? 'Stake | Baryon' : 'Farm | Baryon'
  })

  useEffect(() => {
    loadSymbolFilterLP()
  }, [chainActive])

  useEffect(() => {
    loadInitialTotal()
  }, [chainActive, JSON.stringify(listStakeApi), address])

  // memo

  const totalValueStake = useMemo(() => {
    const mapData = listStakeData.map(item => item.totalStakedUSD)

    if (getLength(mapData) === 0) return 0
    const total = mapData.reduce((accumulator, item) => accumulator + item)
    return total
  }, [listStakeData])

  const totalMyRewards = useMemo(() => {
    const mapData = listStakeData.map(item => item.totalRewardUSD)

    if (getLength(mapData) === 0) return 0
    const total = mapData.reduce((accumulator, item) => accumulator + item)
    return total
  }, [listStakeData])

  const loadFilterData = (isParamCompleted) => {
    const newList = listStakeData.filter(item => {
      const isCompleted = item.isCompleted === isParamCompleted
      const isStaked = item.balanceStaked > 0

      const arrAddressRewardToken = get(item, 'rewardTokens', []).map(item => lowerCase(item.address))
      const arrSymbolRewardToken = get(item, 'rewardTokens', []).map(item => lowerCase(item.symbol))
      const symbolLp = get(item, 'token0.symbol', '') + '-' + get(item, 'token1.symbol', '')

      const isSearch = (lowerCase(get(item, 'token0.symbol', '')).includes(lowerCase(txtSearch))) ||
       (lowerCase(get(item, 'token1.symbol', '')).includes(lowerCase(txtSearch))) ||
       (lowerCase(get(item, 'token0.address', '')).includes(lowerCase(txtSearch))) ||
       (lowerCase(get(item, 'token1.address', '')).includes(lowerCase(txtSearch))) ||
       (lowerCase(get(item, 'tokenInfoStaked.address', '')).includes(lowerCase(txtSearch))) ||
       (lowerCase(get(item, 'tokenInfoStaked.symbol', '')).includes(lowerCase(txtSearch))) ||
       (lowerCase(symbolLp) === (lowerCase(txtSearch))) ||
       arrAddressRewardToken.includes(lowerCase(txtSearch)) ||
       arrSymbolRewardToken.includes(lowerCase(txtSearch)) ||

        (txtSearch === '')
      return isCompleted && (isStakedOnly ? isStaked : true) && isSearch
    })

    const sortList = newList.sort((a, b) => {
      const keySort = typeSort === SORT_APR ? 'apr' : 'totalStakedUSD'
      if (isSortHightToLow) {
        return get(b, `${keySort}`, 0) - get(a, `${keySort}`, 0)
      }
      return get(a, `${keySort}`, 0) - get(b, `${keySort}`, 0)
    })

    return sortList
  }

  const listDataActive = loadFilterData(false)
  const listDataCompleted = loadFilterData(true)

  const listStakeDataFinal = useMemo(() => {
    return isActive ? listDataActive : listDataCompleted
  })

  const listStakeApiFinal = useMemo(() => {
    const newList = listStakeApi.filter(item => item.isCompleted === !isActive)
    return newList
  })

  const totalActive = getLength(listDataActive)

  const totalCompleted = getLength(listDataCompleted)

  const listRender = useMemo(() => {
    const listResult = getLength(listStakeData) > 0 ? listStakeDataFinal : listStakeApiFinal
    return listResult
  }, [listStakeData, listStakeApiFinal, listStakeDataFinal])

  const listCompleted = listStakeData.filter(item => {
    return (item?.isCompleted && (item?.balanceStaked > 0))
  })

  const isEmptyData = getLength(listRender) === 0

  // func

  const loadSymbolFilterLP = async () => {
    if (isStake) {
      const tokenInfoStaked = await window.wallet.fetchInfoCoin(chainActive, poolAddress)
      const isWrap = lowerCase(tokenInfoStaked?.address) === lowerCase(W_MAIN_COIN_STAKE[chainActive]?.address)
      const symbol = isWrap ? MAIN_COIN_AMM[chainActive]?.symbol : get(tokenInfoStaked, 'symbol', '')
      setTxtSearch(upperCase(symbol))
      return
    }

    const infoLp = await getInfoLp(null, null, poolAddress)
    const token0Symbol = get(infoLp, 'token0.tokenInfo.symbol')
    const token1Symbol = get(infoLp, 'token1.tokenInfo.symbol')
    let symbolLp = ''

    if (token0Symbol && token1Symbol) {
      symbolLp = `${upperCase(token0Symbol)}-${upperCase(token1Symbol)}`
    }
    setTxtSearch(symbolLp)
  }

  const loadPriceStake = async (infoLp, tokenInfoStaked) => {
    const token0 = get(infoLp, 'token0.tokenInfo')
    const token1 = get(infoLp, 'token1.tokenInfo')
    let priceStake = 0
    if (token0) {
      priceStake = await getPriceLp({
        token0,
        token1,
        chain: chainActive,
        totalLp: get(infoLp, 'totalSupplyLp', 0),
        balanceToken0Pool: get(infoLp, 'token0.totalBalancePool', 0),
        balanceToken1Pool: get(infoLp, 'token1.totalBalancePool', 0)
      })
    } else {
      priceStake = await getPrice(tokenInfoStaked, chainActive)
    }
    return priceStake
  }

  const getPendingReward = async (contractFinal, item) => {
    try {
      return await contractFinal.methods.pendingReward(item.pid, address).call()
    } catch (error) {
      return 0
    }
  }

  const loadInitialTotal = useDebouncedCallback(async (isReload) => {
    const web3 = genWeb3(chainActive)
    const newDataMap = isReload ? listStakeData : listStakeApi

    const newArrStake = await Promise.all(newDataMap.map(async (item, index) => {
      // not reload completed
      if (isReload && item?.isCompleted) {
        return item
      }

      const decimalStaked = get(item, 'tokenInfoStaked.decimal') || get(item, 'tokenInfoStaked.decimals', 18)

      const decimal = get(item.rewardTokens[0], 'decimal') || (get(item.rewardTokens[0], 'decimals', 18))
      const isDecimal = parseInt(decimal) === 18
      const isV2 = get(item, 'isV2')

      let contractAddress = CONTRACT_FARM_STAKE_V1[chainActive]

      if (isV2) {
        contractAddress = get(item, 'factoryAddress') || CONTRACT_FARM_STAKE_V2[chainActive]
      }

      const contractFinal = genContract(web3, abiFarmV2, contractAddress)

      let pendingReward = 0
      let balanceStaked = 0
      let balanceStakedWei = 0
      const balanceCoinStake = 0

      if (address) {
        const newPendingReward = await getPendingReward(contractFinal, item)
        pendingReward = isV2 ? convertWeiToBalance(convertWeiToBalance(newPendingReward, 18), decimal) : (isDecimal ? convertWeiToBalance(newPendingReward, 12) : newPendingReward)
        const resInfoStaked = await contractFinal.methods.userInfo(item.pid, address).call()
        balanceStaked = convertWeiToBalance(resInfoStaked.amount, decimalStaked)

        balanceStakedWei = resInfoStaked.amount
      }

      const contractCoinStake = genContract(web3, ERC20, item.stakedLpAddress)
      const newTotalStaked = await contractCoinStake.methods.balanceOf(contractAddress).call()
      const totalStaked = convertWeiToBalance(newTotalStaked, decimalStaked)

      // arr token reward
      const newRewardTokens = item.rewardTokens.map(token => {
        const amountTokenReward = isV2 ? pendingReward : pendingReward * token.rateReward
        const rewardUSD = amountTokenReward * token.price
        const rewardPerSecondsToken = (item.rewardPerSeconds * token.rateReward)
        const rewardUSDPerBlockToken = rewardPerSecondsToken * token.price

        const totalRewardAmountPerDay = totalRewardPerDay(rewardPerSecondsToken)

        return {
          ...token,
          amountTokenReward,
          rewardUSD,
          rewardUSDPerBlockToken,
          totalRewardAmountPerDay,
          rewardPerSecondsToken
        }
      })

      const arrRewardUSD = newRewardTokens.map(token => token.rewardUSD)
      const arrRewardUSDPerBlockToken = newRewardTokens.map(token => token.rewardUSDPerBlockToken)
      const totalRewardUSD = arrRewardUSD.reduce((accumulator, item) => parseFloat(accumulator) + parseFloat(item))
      const rewardUSDPerBlock = arrRewardUSDPerBlockToken.reduce((accumulator, item) => parseFloat(accumulator) + parseFloat(item))

      const isNotPrice = (rewardUSDPerBlock === 0) || (rewardUSDPerBlock === '0') || (item.priceStake === '0') || (item.priceStake === 0)

      const arrRewardToken = newRewardTokens.map(token => token.rewardPerSecondsToken)
      const rewardTokenPerBlock = arrRewardToken.reduce((accumulator, item) => parseFloat(accumulator) + parseFloat(item))

      const currentTime = dayjs(new Date()).unix()
      const rewardsStartTime = +get(item, 'rewardsStartTime', 0)
      const isStartTime = currentTime >= rewardsStartTime

      return {
        ...item,
        isStartTime,
        pendingReward,
        totalStaked,
        balanceStaked,
        balanceStakedWei,
        balanceCoinStake,
        totalStakedUSD: totalStaked * item.priceStake,
        totalRewardUSD: totalRewardUSD,
        rewardTokens: newRewardTokens,
        isNotPrice,
        apr: item.isCompleted ? 0 : getPoolAprMulti(isNotPrice ? rewardTokenPerBlock : rewardUSDPerBlock, item.priceStake, totalStaked, isNotPrice) || 0
      }
    }))

    setListStakeData(newArrStake)
  }, 500)

  const refTop = useRef()
  const lenghtData = getLength(listRender)

  useEffect(() => {
    const eventListener = () => {
      const heightScreen = screen?.height
      const current = window.scrollY
      const scrollEnd = document.documentElement.scrollHeight - heightScreen - 150

      // 74 HEIGHT FOOTER
      if (current >= scrollEnd && refTop.current) {
        refTop.current.classList.add('sticky-subheader')
      } else {
        refTop.current.classList.remove('sticky-subheader')
      }
    }
    window.addEventListener('scroll', eventListener)
    return () => {
      window.removeEventListener('scroll', eventListener)
    }
  }, [refTop])

  const handleSort = (type) => () => {
    setTypeSort(type)
    if (type === typeSort) {
      setIsSortHightToLow(!isSortHightToLow)
    } else {
      setIsSortHightToLow(true)
    }
  }

  const onGoTop = () => {
    const current = window.scrollY
    const heightScreen = screen?.height
    const scrollEnd = document.documentElement.scrollHeight - heightScreen - 150
    if (current >= scrollEnd) {
      window.scrollTo({
        behavior: 'smooth',
        top: 0
      })
    } else {
      window.scrollTo({
        behavior: 'smooth',
        top: document.documentElement.scrollHeight
      })
    }
  }

  return (
    <div className="farm-screen-background">
      <div className="farm-screen-v2">

        {lenghtData > 3 && <div ref={refTop} className='bounce-top' onClick={onGoTop}>
          <div className='bounce-detail' >
            <div> <span className="icon-web_arrow-down"></span> </div>
          </div>
        </div>}
        <div className="farm-screen-v2__head">
          <InfoTotalValue isFarm={!isStake} totalValueStake={totalValueStake}/>
          <MyReward totalMyRewards={totalMyRewards}/>
        </div>
        <div className="farm-screen-v2__menu-bar">
          <MenuBar
            isActive={isActive}
            setIsActive={setIsActive}
            isStakedOnly={isStakedOnly}
            setIsStakedOnly={setIsStakedOnly}
            txtSearch={txtSearch}
            setTxtSearch={setTxtSearch}
            totalActive={totalActive}
            totalCompleted={totalCompleted}
            numStakedCompleted={getLength(listCompleted)}
            isFarm={!isStake}
            handleSort={handleSort}
            typeSort={typeSort}
            isSortHightToLow={isSortHightToLow}
            loadInitialTotal={loadInitialTotal}
          />
        </div>

        <div className={`farm-screen-v2__content ${(isEmptyData && !isLoadingApi) ? 'farm-screen-v2__content--empty-data' : ''}`}>

          { isLoadingApi
            ? [1, 2, 3].map((empty) => <ItemFarmBox key={empty} isEmpty />)
            : isEmptyData
              ? <div className='data-empty'>
                <img src={images.iconEmptyTable} alt="" className="icon-empty-data" />
                {/* {t('emptyData')} */}
              </div>
              : listRender.map((item, i) => {
                return <ItemStakeCoin
                  key={item?.hash}
                  item={item}
                  i={i}
                  isFarm={true}
                  loadInitialTotal={loadInitialTotal}
                  isLoadListData={false}
                />
              })
          }
        </div>
      </div>
    </div>
  )
}

export default FarmScreenMulti
