import PageDropdown from 'components/common/PageDropdown'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import style from './style.module.scss'
import IconCustom from 'components/common/IconCustom'
import useScreenWidth from 'common/customHooks/useScreenWidth'
import { CHAIN_DATA, LIST_CHAIN_SUPPORT } from 'common/constants'
import StoreActions from 'controller/redux/actions/storeActions'
import get from 'lodash/get'
import { useWallet } from '@coin98-com/wallet-adapter-react'
import { showNotificationToast } from 'common/functions'
import { ADD_CHAIN } from 'common/poolEvm/constants'
import classNames from 'classnames'

export const onAddChain = (chain, wallet) => async () => {
  const currentWallet = wallet?.adapter?.name === 'Coin98 Ethereum' ? window.coin98.provider : window.ethereum
  return await currentWallet.request({
    method: 'wallet_addEthereumChain',
    params: [
      ADD_CHAIN[chain]
    ]
  })
}

const DropdownChain = () => {
  const { t } = useTranslation()
  const { switchNetwork, connected, wallet } = useWallet()

  const chainActive = useSelector(state => state.chainActive)
  const [selectObjChain, setSelectObjChain] = useState({ value: chainActive, label: <IconCustom icon={CHAIN_DATA[chainActive].image} className="color-theme"/> })
  const dispatch = useDispatch()
  const { width } = useScreenWidth()

  useEffect(() => {
    setSelectObjChain({ value: chainActive, label: <IconCustom icon={CHAIN_DATA[chainActive].image} className="color-theme"/> })
  }, [chainActive])

  const isLargeScreen = width > 1440
  const LIST_CHAIN = LIST_CHAIN_SUPPORT.map(item => {
    const dataChain = CHAIN_DATA[item]

    return {
      labelRender: <IconCustom icon={dataChain?.image} className="color-theme"/>,
      value: dataChain?.chain,
      render: (<div className='d-flex align-items-center'>
        <div>
          <IconCustom icon={dataChain?.image} className="token-img token-img--token-1 img-24 mr-3 color-theme" />
        </div>
        <div className='currency-info'>
          <div className='d-flex align-items-center'>
            <div className='text-xs color-grey'>{dataChain?.name}</div>
          </div>
          <IconCustom icon='web_tick_circle_check' className={classNames('color-theme mr-3', chainActive === dataChain?.chain && 'color-theme--active')}/>
        </div>
      </div>)
    }
  })

  const onChangeChain = async (val) => {
    if (!connected) {
      setSelectObjChain(val)
      dispatch(StoreActions.setChainActive(val?.value))
      return true
    }

    const chainId = get(CHAIN_DATA[val?.value], 'chainId')
    const res = await switchNetwork(chainId, onAddChain(val?.value, wallet))

    if (get(res, 'isError')) {
      showNotificationToast('User rejected the request', 'error', 2000)
      return false
    }
    return true
  }

  return (
    <div className={style['dropdown-warpper']}>
      <PageDropdown
        isAsyncChange
        isWaitingChange={true}
        tooltipText={isLargeScreen ? t('chain') : null}
        toggleclassName="chain-toggle"
        menuClassName="menu-chain"
        dropdownItemClassName="item-curency"
        options={LIST_CHAIN}
        defaultValue={selectObjChain}
        onChange={onChangeChain}
        isAwaitChange={true}
      />
    </div>
  )
}

export default DropdownChain
