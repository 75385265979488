import StoreActions from 'controller/redux/actions/storeActions'
import { store } from 'controller/redux/store/configureStore'

export const generateId = () => {
  let text = ''
  const possible = 'abcdefghijklmnopqrstuvwxyz'
  for (let i = 0; i < 16; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}

export const clearLoadingPool = async () => {
  await store.dispatch(StoreActions.setLoadingPool({}))
}

export const upperCaseFirstLetter = (lower) => {
  if (lower) {
    const upper = lower.replace(/^\w/, function (chr) {
      return chr.toUpperCase()
    })
    return upper
  }
  return ''
}
