import images from 'assets/images'
import Button from 'components/common/Button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import './style.scss'

function BannerTitle () {
  const { t } = useTranslation()

  return (
    <div className="banner-title-container">

      <div className="banner-title-box">
        <div className="box-container">
          <div className="box-left">
            <h1 className="left-heading heading-1">
              {t('redefineYourTradingExperience')}
            </h1>
            <div className="left-description">
              {t('yourTrusted')}
            </div>

            <div className="left-actions">
              <Link className="actions-btn" to="/swap">
                <Button className="btn-learn-home">{t('getStarted')}</Button>
              </Link>
            </div>
          </div>
          <div className="box-right">
            <div className="right-img">
              <video
                loop
                autoPlay
                muted
                playsInline
                preload="true"
              >
                <source src={images.bannerLanding} type="video/webm" />
                <source src={images.bannerLandingMP4} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BannerTitle
