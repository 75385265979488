import React from 'react'
import cn from 'classnames'
import style from './style.module.scss'

const IconCustom = ({ className, icon = 'web_token-issuer-new', type, ...props }) => {
  return (
    <div className={cn(style['icon-custom'], className, `icon-${type}`)} {...props} >
      <span className={`icon-${icon}`}></span>
    </div>
  )
}

export default IconCustom
