import React from 'react'
import Lottie from 'react-lottie-segments'
import images from 'assets/images'

const Loading = ({ size = 35, speed = 1, className, ...props }) => {
  return (
    <div className={`loading-wr ${className}`}>
      <Lottie
        isClickToPauseDisabled={true}
        options={{
          loop: true,
          autoplay: true,
          animationData: images.loadingLottie
        }}
        height={size}
        width={size}
        speed={speed}
        {...props}
      />
    </div>
  )
}

export default Loading
