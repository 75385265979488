import images from 'assets/images'
import Button from 'components/common/Button'
import React from 'react'
import { useTranslation } from 'react-i18next'

import './style.scss'

function ContentEdit ({ handleStatusEdit }) {
  const { t } = useTranslation()

  return (
    <div className='content-edit'>
      <div className="content-edit__img-profile">
        <img src={images.imgNft} alt="" className="image-avatar" />
      </div>

      <div className="content-edit__actions">
        <Button
          className='btn-action-img btn-action-img--change-img'
          onClick={handleStatusEdit('edit')}
        >
          {t('changePic')}
        </Button>

        <Button
          className='btn-action-img btn-action-img--remove-img'
          type='cancel'
          onClick={handleStatusEdit('remove')}
        >
          {t('removePic')}
        </Button>
      </div>
    </div>
  )
}

export default ContentEdit
