import images from 'assets/images'
import React, { useEffect } from 'react'
import cn from 'classnames'
import style from './style.module.scss'

const VideoBox = ({ className, type = 'video/webm', src }) => {
  useEffect(() => {
    const el = document.getElementById('prevent-video')
    if (el) {
      el.addEventListener('contextmenu', (e) => {
        e.preventDefault()
      })
    }
  }, [])
  return (
    <video id='prevent-video' className={cn(style['video-box'], className)} loop autoPlay muted playsInline preload="true">
      <source src={src} type={type} />
    </video>
  )
}

export default VideoBox
