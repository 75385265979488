import images from 'assets/images'
import { isWindow } from 'common/constants'
import { convertWeiToBalance, showNotificationToast, upperCase, formatPrice, lowerCase } from 'common/functions'
import { ROUTER_SPONSOR_GAS, MAIN_COIN_AMM, W_MAIN_COIN, CONTRACT_ROUTER, W_MAIN_COIN_TOMO_OLD, CONTRACT_ROUTER_OLD } from 'common/poolEvm/constants'
import useApprove from 'common/poolEvm/hooks/useApprove'
import usePairs from 'common/poolEvm/hooks/usePairs'
import Button from 'components/common/Button'
import EmptyData from 'components/common/EmptyData'
import PuffLoading from 'components/common/PuffLoading'
import { LiquidityContext } from 'context/liquidity/liquidityContext'
import get from 'lodash/get'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { BounceLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import ActionsRight from '../ActionsRight'
import ConfirmOrder, { typeConfirmPool } from '../ConfirmOrder'
import BoxBalance from './BoxBalance'
import './style.scss'
import { useWallet } from '@coin98-com/wallet-adapter-react'
import { useMainBalanceContext } from 'context/MainBalanceContext'
import { encodeMessErr } from 'controller/Library/evm'

const RemoveLiquidity = () => {
  const liquidityContext = useContext(LiquidityContext)
  const { setIsRefreshLPAmount, isSelectedCoin, fromCoin, toCoin, isRefreshLPAmount, loadLiquidityAccount } = liquidityContext
  const { reloadMainBalance } = useMainBalanceContext()

  const { t } = useTranslation()
  const { address } = useWallet()

  const settingRef = useRef(null)
  const chainActive = useSelector((state) => state.chainActive)

  const mainCoin = MAIN_COIN_AMM[chainActive]
  const wrapMainCoin = W_MAIN_COIN[chainActive]

  const isFromMain = !fromCoin?.address && (lowerCase(fromCoin?.symbol) === lowerCase(mainCoin?.symbol))
  const isToMain = !toCoin?.address && (lowerCase(toCoin?.symbol) === lowerCase(mainCoin?.symbol))

  const newAddressFromCoin = { ...fromCoin, address: isFromMain ? wrapMainCoin?.address : fromCoin.address }
  const newAddressToCoin = { ...toCoin, address: isToMain ? wrapMainCoin?.address : toCoin.address }

  const { isLoading: isLoadingPoolInfo, poolInfo, getAmount, loadInitial: loadResetPool } = usePairs(chainActive, address, newAddressFromCoin?.address, newAddressToCoin?.address)

  const isWTomoFrom = (lowerCase(get(poolInfo, 'token0.tokenInfo.address', '')) === lowerCase(W_MAIN_COIN_TOMO_OLD.address))
  const isWTomoTo = (lowerCase(get(poolInfo, 'token1.tokenInfo.address', '')) === lowerCase(W_MAIN_COIN_TOMO_OLD.address))

  const routerAddress = (isWTomoFrom || isWTomoTo)
    ? (CONTRACT_ROUTER_OLD[chainActive] || CONTRACT_ROUTER[chainActive])
    : (ROUTER_SPONSOR_GAS[chainActive] || CONTRACT_ROUTER[chainActive])
  // const routerAddress = CONTRACT_ROUTER[chainActive]

  const { payload: approval, onApprove, isLoading: isLoadingApprove, onEstimateGasApprove } = useApprove(chainActive, get(poolInfo, 'poolAddress'), address, routerAddress)

  const [lpAmount, setLpAmount] = useState('')
  const [slippage, setSlippage] = useState(0.5)

  const numApproveLp = parseFloat(convertWeiToBalance(approval))
  const isApproveLp = numApproveLp !== 0 && numApproveLp >= (lpAmount || 0)

  const [widthResize, setWidthResize] = useState(isWindow && window.innerWidth)

  const totalSupplyLp = get(poolInfo, 'totalSupplyLp', 0)
  const poolAddress = get(poolInfo, 'poolAddress')

  const [isRemoveAll, setIsRemoveAll] = useState(false)

  const totalBalancePoolToken0 = get(poolInfo, 'token0.totalBalancePool')
  const totalBalancePoolToken1 = get(poolInfo, 'token1.totalBalancePool')
  const shareOfPool = get(poolInfo, 'shareOfPool', 0)

  const token0Amount = (totalBalancePoolToken0 * lpAmount) / totalSupplyLp
  const token1Amount = (totalBalancePoolToken1 * lpAmount) / totalSupplyLp

  const isPoolExist = !!poolInfo.poolAddress
  const balanceOfLp = get(poolInfo, 'balanceOfLp', 0)

  const token0 = isWTomoFrom ? mainCoin : get(poolInfo, 'token0.tokenInfo', {})
  const token1 = isWTomoTo ? mainCoin : get(poolInfo, 'token1.tokenInfo', {})

  const imgToken0 = get(token0, 'logoURI') || get(token0, 'image', images.icoUnknown)
  const imgToken1 = get(token1, 'logoURI') || get(token1, 'image', images.icoUnknown)
  const symbol0 = upperCase(get(token0, 'symbol'))
  const symbol1 = upperCase(get(token1, 'symbol'))

  const isDisable = lpAmount === 0 || lpAmount === '' || lpAmount > balanceOfLp
  const isFullLp = lpAmount >= totalSupplyLp

  useEffect(() => {
    isWindow && window.addEventListener('resize', handleResize)
  }, [])

  const onReset = () => {
    setIsRefreshLPAmount && setIsRefreshLPAmount(!isRefreshLPAmount)
    loadResetPool()
    loadLiquidityAccount()
  }

  const handleConfirmOrder = () => {
    window.openModal({
      preventClose: true,
      isCustomModal: true,
      className: 'modal-confirmorder',
      content: (
        <ConfirmOrder
          poolInfo={poolInfo}
          type={typeConfirmPool.receive.value}
          fromCoin={token0}
          toCoin={token1}
          fromCoinAmount={token0Amount}
          toCoinAmount={token1Amount}
          poolAddress={poolAddress}
          shareOfPool={shareOfPool}
          lpTokenAmount={lpAmount}
          getAmount={getAmount}
          slippage={parseFloat(slippage)}
          isRemoveAll={isRemoveAll && !isFullLp}
          isRemoveFullLp={isFullLp}
          callback={onReset}
          isRouterWtomo={isWTomoFrom || isWTomoTo}
        />
      )
    })
  }

  const onChangeSlippage = (slippage) => () => {
    setSlippage(slippage)
  }

  const handleSaveSetting = () => {
    if (settingRef && settingRef.current) {
      settingRef.current.handleSaveSetting()
      window.closeModal()
    }
  }

  const handleResize = () => {
    setWidthResize(window.innerWidth)
  }

  const callBackFail = (mess) => {
    const stringResult = encodeMessErr(mess)
    showNotificationToast(t(stringResult), 'error', 2000)
    window.closeModal()
  }

  const handleApprove = async () => {
    const gasLimitEstApprove = await onEstimateGasApprove()
    const callBack = () => {
      toast.dismiss()
      window.closeModal()
      showNotificationToast(t('approveSuccess'), 'success', 2000)
    }

    window.openModal({
      title: t('approveSymbol', { symbol: 'LP' }),
      type: 'confirm',
      onOk: async () => {
        await onApprove(callBack, null, callBackFail)
      }
    })
  }

  const renderRemoveContent = (
    <div className='remove-liquidity'>
      <div className="remove-liquidity__content">
        <BoxBalance
          poolInfo={poolInfo}
          setLpAmount={setLpAmount}
          lpAmount={lpAmount}
          isLoadingPoolInfo={isLoadingPoolInfo}
          setIsRemoveAll={setIsRemoveAll}
        />

        <div className="box-will-receive">
          <div className="box-will-receive__title">{t('youWillReceive')}</div>
          <div className="box-will-receive__content">
            <div className="box-icon">
              <img
                src={imgToken0}
                alt=""
              />
              {symbol0}
            </div>
            <div className="balance">{formatPrice(token0Amount, false, true)}</div>
          </div>

          <div className="box-will-receive__content">
            <div className="box-icon">
              <img
                src={imgToken1}
                alt=""
              />
              {symbol1}
            </div>
            <div className="balance">{formatPrice(token1Amount, false, true)}</div>
          </div>
        </div>

        <div className="box-rate">
          <div className="box-rate__title">{t('rates')}</div>
          <div className="box-rate__content">
            <div className="rate-value">1 {symbol0} = {isLoadingPoolInfo ? <span> <BounceLoader size={widthResize <= 768 ? 10 : 16} color="#bbbbbb" /> </span> : formatPrice(getAmount(token0, 1), false, true)} {symbol1}</div>
            <div className="rate-value">1 {symbol1} = {isLoadingPoolInfo ? <span> <BounceLoader size={widthResize <= 768 ? 10 : 16} color="#bbbbbb" /> </span> : formatPrice(getAmount(token1, 1), false, true)} {symbol0}</div>
          </div>
        </div>
      </div>

      { !isApproveLp &&
      <Button
        isDisable={isDisable}
        onClick={handleApprove}
        isFullWidth
        className="btn-approve"
        isLoading={isLoadingApprove}
      >
        {t('approveSymbol', { symbol: 'LP' })}
      </Button>
      }

      <Button
        isDisable={isDisable || !isApproveLp}
        onClick={handleConfirmOrder}
        isFullWidth
        className="remove-liquidity__actions"
      >
        {t('remove')}
      </Button>

    </div>
  )

  return (
    <div className='remove-liquidity-container'>
      <div className='header-right'>
        <ActionsRight
          onReloadBalance={() => { reloadMainBalance() }}
          settingRef={settingRef}
          onChangeSlippage={onChangeSlippage}
          handleSaveSetting={handleSaveSetting}
          slippage={slippage}
        />
      </div>

      {isLoadingPoolInfo
        ? <div className='liquidity-cover'>
          <PuffLoading isCenter/>
        </div>
        : isSelectedCoin && isPoolExist
          ? renderRemoveContent
          : <EmptyData classNames='liquidity-cover' titleEmpty={t('noLiquidityToRemove')}/>
      }

      {/* <LiquidityInfo pool={poolInfo} isShowInFo isLoadingPoolInfo={isLoadingPoolInfo}/> */}

    </div>

  )
}

export default RemoveLiquidity
