export default {
  confirmApproveSymbol: 'Bạn sắp chấp thuận cho Baryon truy cập vào token {{symbol}} của bạn.\n Bạn có chắc chắn muốn tiếp tục phê duyệt không?',
  sureContinue: 'Bạn có chắc chắn muốn tiếp tục không?',
  myRank: 'Thứ tự của tôi',
  reward: 'Phần thưởng',
  status: 'Trạng thái',
  rank: 'Thứ tự',
  getStarted: 'Trải nghiệm',
  redefineYourTradingExperience: 'Tái định nghĩa trải nghiệm giao dịch của bạn',
  yourTrusted: 'Nền tảng sàn giao dịch phi tập trung đáng tin cậy trên EVM Blockchains để bạn swap, stake, farm và phát triển trên đó.',
  stakeFarm: 'Stake Farm',

  // tu mới
  dataWillAuto:
    'Dữ liệu sẽ được tự động cập nhật sau 20 giây. Nhấp vào Pie để cập nhật dữ liệu sớm nhất',
  half: 'Một nửa',
  sort: 'Lọc',
  pleaseSwitchChain: 'Vui lòng chuyển sang Binance Smart Chain',
  noLiquidityToRemove: 'Không có thanh khoản để gỡ bỏ',
  totalSupply: 'Tổng cung',
  marketCap: 'Vốn hoá thị trường',
  staked: 'Đã stake',
  allTokens: 'Tất cả tokens',
  copied: 'Đã sao chép',
  unclaimedRewards: 'Phần thưởng chưa nhận',
  unStakeFarm: 'UnStake Farm',
  yourLPFarming: 'LP Farming của bạn',
  yourReward: 'Phần thưởng của bạn',
  poolToken: 'Pool token',
  share: 'Chia sẻ',
  transactions: 'Giao dịch ',
  topTokens: 'Top Token',
  topPools: 'Top Pool',
  viewExplorer: 'Xem Explorer',
  selectLiquidity: 'Chọn thanh khoản để rút',
  changeWallet: 'Đổi ví',
  totalAddressActive: 'Tổng các ví đang hoạt động',
  liquidityProvider: 'Người cung cấp thanh khoản',
  shareSwap: 'Chia sẻ Swap',
  shareFarm: 'Chia sẻ Farm',
  shareStake: 'Chia sẻ Stake',
  shareSnapshot: 'Chia sẻ Snapshot',
  copyLink: 'Sao chép liên kết',
  address: 'Địa chỉ',
  theme: 'Chủ đề',
  allTimeLow: 'Thấp nhất lịch sử',
  allTimeHigh: 'Cao nhất lịch sử',
  dayVolumn: 'KL giao dịch 24h',
  dayLiquidity: 'Thanh khoản 24h',
  pooledSign: 'Pooled',
  yourPoolToken: 'Token trong Pool',
  yourPoolShare: '% trong Pool',
  managePool: 'Quản lí pool',
  farming: 'Farming',
  selectToken: 'Chọn token',
  poolStaked: 'Pool đã Stake',
  poolFarming: 'Pool đang Farm',
  totalValueLocked: 'Tổng giá trị bị khoá',
  backToWallet: 'Trở về Ví',
  recentlyAdded: 'Đã thêm gần đây',
  gainer: 'Token Tăng',
  trending: 'Xu hướng',
  popular: 'Phổ biến',
  topLiquidity: 'Top thanh khoản',
  topVolume24h: 'Top KL giao dịch 24h',
  tokenValue: 'Giá trị token',
  lpValue: 'Giá trị LP',
  estValue: 'Giá trị ước tính',
  poolShare: 'Pool Share',
  token2Amount: 'Số lượng token 2',
  token1Amount: 'Số lượng token 1',
  searchWallet: 'Tìm Ví/Tài khoản',
  volumeFee: 'Lượng phí GD 24h',
  accountStaked: 'Tài khoản stake',
  accountFarming: 'Tài khoản farm',
  liquidityProviders: 'Nhà cung cấp thanh khoản',
  wallet: 'Ví',
  currency: 'Tiền tệ',
  language: 'Ngôn ngữ',
  en: 'Tiếng Anh',
  vi: 'Tiếng Việt',
  unstakeNow: 'Unstake ngay!',
  unstakedPools: 'Pool đã unstake',
  youHave: 'Bạn có',
  link: 'Link',
  ascending: 'Tăng dần',
  decreasing: 'Giảm dần',
  searchByAddress: 'Tìm kiếm theo địa chỉ',
  joinDate: 'Ngày tham gia',
  tradingVolume: 'Khối lượng giao dịch',
  campEnd: 'Chiến dịch đã kết thúc',
  joined: 'Đã tham gia',
  addressAlreadyJoin: 'Địa chỉ này đã tham gia',
  addressNull: 'Không tìm thấy ví',
  error: 'Lỗi',
  joinSuccess: 'Tham gia thành công',
  errMess: 'Tham gia thất bại',
  RUSureJoin: 'Bạn có chắc muốn tham gia Hoạt động này không?',
  stablecoinTradingCompetition: 'Cuộc thi giao dịch Stablecoin',
  upComing: 'Sắp ra mắt',
  global: 'Toàn cầu',
  twitter: 'Twitter',
  telegram: 'Telegram',
  noTitle: 'Xếp hạng',
  duration: 'Thời gian',
  participated: 'Đã tham gia',
  joinNow: 'Tham gia ngay',
  earliest: 'Sớm nhất',
  upTo: 'Up to',
  totalReward: 'Tổng thưởng',
  onGoing: 'Đang diễn ra',
  participateIn: 'Tham gia các Cuộc thi Giao dịch với giải thưởng vô cùng hấp dẫn đến từ Baryon',
  activityCampaigns: 'Chiến dịch',
  copyRight: '© 2022, All Rights Reserved',
  provideAnEasy: 'Mang đến giao diện thân thiện với người dùng',
  levergaeBnb: 'Khai thác tốc độ giao dịch nhanh chóng, an toàn, tối ưu phí gas trên BNB Chain và Viction',
  prioritizeUser: 'Ưu tiên tính bảo mật của người dùng. Audit bởi Inspex',
  offerReliable: 'Cung cấp nguồn thanh khoản đáng tin cậy cho tất cả người dùng',
  permissionless: 'Không cần cấp quyền',
  distinctive: 'Giao diện UI/UX khác biệt',
  scalability: 'Khả năng mở rộng',
  advancedSecurity: 'Bảo mật năng cao',
  wellTrusted: 'Nền tảng thanh khoản đáng tin cậy',
  offeringTheBest: 'Cung cấp các dịch vụ tốt nhất',
  stakeToEarn: 'Stake để nhận thu nhập thụ động.<br/> Không impermanent loss',
  earnExtra: 'Kiếm thêm phần thưởng từ thanh khoản',
  swapHidden: 'Swap hidden gem một cách an toàn chỉ trong tích tắc',
  stakeNow: 'Stake Ngay',
  swapNow: 'Swap Ngay',
  exoloreTrending: 'Khám phá các token nổi bật trên EVM Blockchains',
  exploreBaryon: 'Tìm hiểu thêm về Baryon',
  enableUsers: 'Cho phép người dùng giao dịch mà không mất quyền kiểm soát tài sản',
  ended: 'Thời gian kết thúc',
  unstake: 'Unstake',
  farmEnded: 'Farm đã kết thúc',
  stakeEnded: 'Stake đã kết thúc',
  connectToSee: 'Kết nối ví để thấy pool của bạn',
  totalReceive: 'Tổng nhận',
  viewProjectSite: 'Xem trang web dự án ',
  walletNotActive: 'Ví chưa kích hoạt',
  harvesting: 'Đang thu hoạch',
  txsHarvest: 'Thu hoạch: {{amount}} ~ {{amountUSD}}',
  harvestSymbol: 'Thu hoạch {{symbol}}',
  amountAboveLimit: 'Số lượng trên mức cho phép ',
  insufficientFunds: 'Không đủ số dư',
  earned: 'Kiếm được',
  totalStaked: 'Tổng giá trị đã stake ',
  latest: 'Muộn nhất',
  hot: 'Hot',
  multiplier: 'Hệ số nhân',
  lackGas: 'Xác nhận giao dịch và đảm bảo bạn đang có đủ phí gas',
  txsTypeComplete: 'Bạn đã {{type}} thành công',
  estimateFee: 'Phí ước tính ',
  txtFee: '{{amount}} ~ {{amountUSD}}',
  slowest: 'Rất chậm',
  fastest: 'Siêu nhanh',
  standard: 'Tiêu chuẩn',
  gasWar: 'Gas War',
  starWar: 'Star War',
  slow: 'Chậm',
  fast: 'Nhanh',
  collect: 'Thu gom',
  stakeInPool: 'Stake Token',
  unStake: 'Unstake Token',
  totalStake: 'Tổng giá trị được stake',
  seeTokenInfo: 'Xem thông tin token',
  viewContract: 'Xem smart contract',
  stakeSuccess: 'Stake thành công',
  unStakeSuccess: 'Unstake thành công ',
  enableContract: 'Cho phép contract',
  harvestFarm: 'Thu hoạch',
  unStakeLP: 'Unstake LP token',
  stakeLP: 'Stake',
  yourFrontrun: 'Giao dịch của bạn có thể bị frontrun',
  yourFrontrun2: 'Giao dịch của bạn có thể đang bị frontrun ',
  confirmApprove: 'Xác nhận phê duyệt',
  tradeSuccess: 'Đặt hàng thành công',
  increaseSlippage: 'Swap không thành công, hãy thử tăng độ trượt giá ',
  pleaseWaitApprove: 'Xin vui lòng chờ trong giây lát để chấp thuận',
  rate: 'Tỉ giá',
  priceImpact: 'Biến động giá',
  route: 'Route',
  importPool: 'Nhập pool ',
  importPoolDesc: 'Nhập pool đang sẵn có ',
  connectWalletFindPools: 'Kết nối ví để tìm pool',
  selectATokenToFind: 'Chọn 1 token để tìm thanh khoản của bạn',
  noPoolFound: 'Không tìm thấy pool!',
  poolFound: 'Đã tìm thấy Pool!',
  manageThisPool: 'Quản lý pool',
  youDontHaveLiquidity: 'Bạn chưa có thanh khoản trong pool này ',
  sureApprove: 'Bạn có chắc sẽ chấp nhận không?',
  approveSymbol: 'Chấp thuận {{symbol}}',
  approve: 'Chấp thuận',
  approveSuccess: 'Chấp thuận thành công',
  permissionLessPool: 'Pool không cần cấp phép',
  priceImpactMustBeLessThan: 'Tác động giá phải thấp hơn 50%',
  fees: 'Phí',
  apr: 'APR',
  notFoundScreen: 'Không tìm thấy màn hình',
  emptyData: 'Dữ liệu trống',
  emptyDataTable: 'Bảng dữ liệu trống ',
  backTo: 'Trở về',
  youAreAddLiquidity: 'Bạn sẽ được nhận',
  pooled: 'Đã thêm vào pool ',
  youAreTheFirst: 'Bạn là người cung cấp thanh khoản đầu tiên',
  theRatioOf: 'Tỉ lệ token bạn thêm vào sẽ thiết lập mức giá cho pool này ',
  onceYouAre: 'Nếu đồng ý với tỉ giá nhấn Cung cấp để xem ',
  removeLiquidity: 'Rút thanh khoản {{token0}} - {{token1}}',
  removeLiquidityDesc: 'Nhận {{token0}} và {{token1}}',
  balance: 'Số dư',
  youWillReceive: 'Bạn sẽ được nhận',
  name: 'Tên',
  price: 'Giá',
  priceChange: 'Giá thay đổi',
  lpRewardFee: 'Phí phần thưởng LP 24h',
  lpRewardApr: 'APR Phần thưởng LP',
  add: 'Thêm',
  create: 'Tạo',
  transaction24h: 'Giao dịch 24h',
  confirmRemove: 'Xác nhận rút',
  lpTokensIn: 'LP token trong ví',
  confirmCreate: 'Xác nhận tạo ',
  comingSoon: 'Sắp ra mắt',
  lpTokens: 'LP token',
  overview: 'Tổng quan',
  pools: 'Pool',
  tokens: 'Token',
  loading: 'Đang tải',
  inputAnAmount: 'Nhập số lượng',
  youAreVerify: 'Bạn đang xác nhận pool ',
  unVerify: 'Bạn bỏ xác nhận pool ',
  pleaseResetWallet: 'Vui lòng thiết lập lại ví ',
  totalLp: 'Tổng LP ',
  trendingPools: 'Pool Xu Hướng ',
  allPools: 'Tất cả pool',
  createPool: 'Tạo pool',
  remove: 'Rút',
  addLiquidityInstead: 'Thêm thanh khoản ',
  enterAmount: 'Nhập số lượng ',
  createPoolAndSupply: 'Tạo pool & cung cấp thanh khoản ',
  confirmSupply: 'xác nhận cung cấp',
  youAreCreatingPool: 'Bạn đang tạo pool',
  rates: 'Tỉ giá',
  tokenPool: '{{symbol}} token trong pool',
  youWillReceived: 'Bạn sẽ được nhận',
  supply: 'Cung cấp',
  shareOfPool: 'Share của Pool',
  priceAndPool: 'Giá và pool share',
  amount: 'Số lượng',
  addLiquidityDesc: 'Thêm thanh khoản để nhận token LP',
  addLiquidity: 'Thêm thanh khoản',
  findOtherLpToken: 'Tìm token LP khác',
  dontSeePool: 'Không tìm thấy pool mà bạn đã tham gia?',
  noLiquidity: 'Không tìm thấy thanh khoản',
  removeLiquidityTo: 'Rút thanh khoản để nhận lại token',
  yourLiquidity: 'Thanh khoản của bạn',
  success: 'Thành công',
  yourOrderHasBeen: 'Giao dịch thanh công',
  estimateGasFee: 'Phí gas dự tính ',
  saveSetting: 'Lưu cài đặt ',
  close: 'Đóng',
  viewTxs: 'Xem giao dịch',
  transactionSubmitted: 'Giao dịch đã được thực hiện',
  confirm: 'Xác nhận',
  estimate: 'Ước lượng',
  slippageTolerance: 'Độ trượt giá ',
  setting: 'Cài đặt',
  convert: 'Chuyển đổi',
  convertWSOL: 'Chuyển đổi SOL thành wSOL',
  priceImpactMustLessThan: 'Biến động giá phải thấp hơn 50%',
  minimumBalance: 'Số dư tối thiểu cần 0,05 SOL để tránh giao dịch thất bại ',
  exceedsLimit: 'Vượt quá mức trượt giá mong muốn ',
  setup: 'Cài đặt',
  setupDone: 'Hoàn tất cài đặt',
  dueLimitSol: 'Vì kích cỡ giao dịch trên Solana bị giới hạn, bước này yêu cầu phải tạo wSOL trên ví của bạn  trước khi swap',
  topPool: 'Top Pool ',
  inputSearchText: 'Nhập thông tin tìm kiếm ',
  pool: 'Pool',
  liquidity: 'Thanh khoản',
  volume24h: 'Khối lượng 24h',
  volume7d: 'Khối lượng 7 ngày',
  fee24h: 'Phí trong 24h',
  yearFee: '1 năm phí/thanh khoản',
  backToPool: 'Trở lại pool',
  totalTokenLocked: 'Tổng số token bị khoá',
  volume: 'Khối lượng',
  transaction: 'Giao dịch',
  all: 'Tất cả',
  adds: 'Thêm',
  removes: 'Rút',
  action: 'Thao tác',
  totalValue: 'Tổng giá trị',
  totalAmount: 'Tổng số lượng',
  tokenAmount: 'Tổng số token',
  tokenAmountFrom: 'Số lượng token từ ',
  tokenAmountTo: 'Số lượng token đến',
  account: 'Tài khoản',
  time: 'Thời gian',
  farm: 'Farm',
  staking: 'Staking',
  poolNotFound: 'Không tìm thấy pool',
  showLabelConnectWallet: 'Kết nối ví',
  gasSolNotEnough: 'Không đủ BNB để trả phí gas ',
  impactLarge: 'Bạn có thể mất {{number}}% tài sản của mình do biến động giá.',
  tradeDone: 'Token đã được gửi vào ví của bạn ',
  received: 'Đã nhận {{symbol}} ',
  swapConfirmation: 'Thông tin Swap',
  txsFee: '{{amount}} ~ {{amountUSD}}',
  sizeTooSmall: 'Giao dịch quá ít',
  tradeErrFund: 'Lỗi đặt lệnh, không đủ số dư ',
  timeOutTxs: 'Giao dịch hết thời gian hoặc chưa được xác nhận, vui lòng đợi một lát và thử lại ',
  feeErr: 'Số dư hoặc phí gas của bạn không đủ',
  amountErr: 'Phải nhỏ hơn hoặc bằng số tiền bạn đang có trong ví',
  swap: 'Swap',
  yourPassword: 'Mật khẩu của bạn',
  password: 'Mật khẩu',
  pleaseWaitOrder: 'Vui lòng chờ một lát để giao dịch được hoàn tất',
  txsFail: 'Thử lại lần nữa sau vài phút',
  unlockWallet: 'Mở khoá ví',
  wrongPass: 'Mật khẩu không tồn tại',
  tryLeft: 'Bạn còn {{num}} lần ',
  walletHasBeenReset: 'Ví của bạn vừa được thiết lập lại',
  onlyOneChanceReset: 'Bạn chỉ có một cơ hội nữa. Nếu nhập sai, ví của bạn sẽ được thiết lập lại!',
  unlock: 'Mở khoá',
  youPay: 'Bạn phải trả',
  youReceive: 'Bạn được nhận',
  reviewOrder: 'Xem lại giao dịch',
  swappingToken: 'Swap token...',
  depositToken: '{{symbol}} sẽ được gửi về ví của bạn khi giao dịch hoàn tất ',
  disconnectWallet: 'Bạn muốn ngưng kết nối ví',
  disconnect: 'Ngưng kết nối',
  chooseWallet: 'Hãy chọn ví của bạn',
  noWallet: 'Không có ví nào',
  coin98NotInstall: 'Coin98 Wallet chưa được cài đặt',
  installCoin98: 'Cài đặt Coin98 Wallet để quản lý.',
  connectWallet: 'Kết nối ví',
  searchByToken: 'Tìm kiếm theo tên token, ticker hoặc địa chỉ ',
  gasNotEnough: 'Không đủ {{name}} để trả phí gas',
  tooLarge: 'Bạn đã yêu cầu quá nhiều giao dịch, hãy cân nhắc giảm số lượng',
  searchPoolOrToken: 'Tìm pool hoặc token',
  cancel: 'Huỷ bỏ',
  searchHere: 'Tìm kiếm ở đây',
  farmingDescription: 'Farm LP token để nhận thưởng ',
  stakeDescription: 'Stake token để nhận thưởng ',
  stakeTotal: 'Tổng giá trị được stake',
  myReward: 'Phần thưởng của tôi',
  active: 'Đang hoạt động',
  completed: 'Đã hoàn thành',
  stakeOnly: 'Staked',
  searchFarm: 'Tìm kiếm farm',
  harvest: 'Thu hoạch',
  historyTransactions: 'Lịch sử giao dịch',
  default: 'Mặc định ',
  tradingView: 'Cửa số giao dịch',
  verify: 'Xác nhận',
  verified: 'Được xác nhận',
  stake: 'Stake',
  farmNow: 'Farm Ngay',
  enable: 'Cho phép',
  viewOnBlock: 'Xem trên Block Explorer',
  pairInfo: 'Chi tiết cặp giao dịch ',
  getC98BNB: 'Cung cấp C98 - BNB LP ',
  copyAddress: 'Sao chép địa chỉ',
  copyAddressFail: 'Sao chép địa chỉ thất bại',
  dark: 'Tối',
  light: 'Sáng',
  metafi: 'Các nền tảng chính của MetaFi',
  transactionSpeed: 'Tốc độ giao dịch',
  openChart: 'Mở biểu đồ',
  closeChart: 'Đóng biểu đồ',
  history: 'Lịch sử',
  item: 'Mục',
  activity: 'Hoạt động',
  saleOnDagora: 'Giảm giá trên Dagora',
  buyFoxie: 'Mua Foxie',
  on: 'Trên',
  filterBy: 'Lọc bởi',
  listed: 'Được niêm yết',
  delisted: 'Đã xóa niêm yết',
  modified: 'Điều Chỉnh',
  sold: 'Đã bán',
  event: 'Sự Kiện',
  from: 'Từ',
  to: 'Đến',
  date: 'Ngày',
  editProfile: 'Chỉnh sửa trang cá nhân',
  changePic: 'Đổi ảnh đại diện ',
  removePic: 'Xoá ảnh đại diện ',
  chooseNewCollect: 'Chọn một NFT mới để sử dụng làm ảnh đại diện của bạn',
  detail: 'Chi tiết',
  dagoraFoxxy: 'Dagora Foxxy',
  tokenID: 'Token ID',
  useProfileAvatar: 'Bạn đang sử dụng NFT này làm ảnh Đại diện Baryon của mình',
  descriptionDetailPopUp: 'Tài khoản của bạn sẽ bị đình chỉ khi bạn xóa ảnh đại diện. Bạn sẽ không thể nhận điểm thưởng, tham gia các hoạt động nhóm hoặc đủ điều kiện để nhận NFT mới',
  textFooter: 'Chuyển đến trang hồ sơ của bạn để tiếp tục',
  lowestPrice: 'Giá thấp nhất',
  setProfilePic: 'Đặt làm Ảnh Đại Diện',
  transfer: 'Chuyển đi',
  back: 'Quay lại',
  sell: 'Bán',
  confirmTitle: 'Thao tác này sẽ đình chỉ tài khoản của bạn. NFT của bạn sẽ được gửi về ví.',
  confirmDes: 'Bạn sẽ không thể nhận thêm điểm thưởng khi tài khoản đang bị đình chỉ nhưng thành tích và điểm thưởng của bạn sẽ vẫn được liên kết với tài khoản.',
  confirmFt: 'Chi phí để kích hoạt lại trong tương lai: 0,5 C98',
  understand: 'Tôi đã hiểu'
}
