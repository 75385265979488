import images from 'assets/images'
import { getLength } from 'common/functions'
import { get } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ProfilePopUp from '../ProfilePopUp'
import ItemNft from './ItemNft'

import './style.scss'
import TableList from './TableList'

function ProfileItems () {
  const { t } = useTranslation()

  const [menuChoose, setMenuChoose] = useState('item')

  const arrItem = [
    {
      id: 1,
      img: images.ballLogo,
      name: 'Ball Lgo',
      price: 100.5,
      isProfile: false,
      event: 'sold',
      from: 'terra1rlt2jarzvswux8hy9s6fuza35jgn0v7slzxecm',
      to: '5UrM9csUEDBeBqMZTuuZyHRNhbRW4vQ1MgKJDrKU1U2v',
      date: 1646216056,
      tokenID: 23551
    },
    {
      id: 2,
      img: images.imgNft,
      name: 'Foxxy XIV',
      price: 10.5,
      isProfile: true,
      event: 'listed',
      from: 'terra1rlt2jarzvswux8hy9s6fuza35jgn0v7slzxecm',
      to: '5UrM9csUEDBeBqMZTuuZyHRNhbRW4vQ1MgKJDrKU1U2v',
      date: 1646216056,
      tokenID: 23551
    },
    {
      id: 3,
      img: images.imgNft1,
      name: 'Foxxy',
      price: 5,
      isProfile: false,
      event: 'modified',
      from: 'terra1rlt2jarzvswux8hy9s6fuza35jgn0v7slzxecm',
      to: '5UrM9csUEDBeBqMZTuuZyHRNhbRW4vQ1MgKJDrKU1U2v',
      date: 1646216056,
      tokenID: 23551
    },
    {
      id: 4,
      img: images.imgNft2,
      name: 'Foxxy X',
      price: 2.5,
      isProfile: false,
      event: 'delisted',
      from: 'terra1rlt2jarzvswux8hy9s6fuza35jgn0v7slzxecm',
      to: '5UrM9csUEDBeBqMZTuuZyHRNhbRW4vQ1MgKJDrKU1U2v',
      date: 1646216056,
      tokenID: 23551
    },
    {
      id: 5,
      img: images.imgNft3,
      name: 'Foxxy IV',
      price: 15.3,
      isProfile: false,
      event: 'modified',
      from: 'terra1rlt2jarzvswux8hy9s6fuza35jgn0v7slzxecm',
      to: '5UrM9csUEDBeBqMZTuuZyHRNhbRW4vQ1MgKJDrKU1U2v',
      date: 1646216056,
      tokenID: 23551
    },
    {
      id: 6,
      img: images.imgNft4,
      name: 'Foxxy XV',
      price: 1.5,
      isProfile: false,
      event: 'modified',
      from: 'terra1rlt2jarzvswux8hy9s6fuza35jgn0v7slzxecm',
      to: '5UrM9csUEDBeBqMZTuuZyHRNhbRW4vQ1MgKJDrKU1U2v',
      date: 1646216056,
      tokenID: 23551
    },
    {
      id: 7,
      img: images.imgNft5,
      name: 'Foxxy V',
      price: 0.5,
      isProfile: false,
      event: 'modified',
      from: 'terra1rlt2jarzvswux8hy9s6fuza35jgn0v7slzxecm',
      to: '5UrM9csUEDBeBqMZTuuZyHRNhbRW4vQ1MgKJDrKU1U2v',
      date: 1646216056,
      tokenID: 23551
    },
    {
      id: 8,
      img: images.ballLogo,
      name: 'Ball Lgo',
      price: 100.5,
      isProfile: false,
      event: 'delisted',
      from: 'terra1rlt2jarzvswux8hy9s6fuza35jgn0v7slzxecm',
      to: '5UrM9csUEDBeBqMZTuuZyHRNhbRW4vQ1MgKJDrKU1U2v',
      date: 1646216056,
      tokenID: 23551
    },
    {
      id: 9,
      img: images.imgNft,
      name: 'Foxxy XIV',
      price: 10.5,
      isProfile: false,
      event: 'sold',
      from: 'terra1rlt2jarzvswux8hy9s6fuza35jgn0v7slzxecm',
      to: '5UrM9csUEDBeBqMZTuuZyHRNhbRW4vQ1MgKJDrKU1U2v',
      date: 1646216056,
      tokenID: 23551
    },
    {
      id: 10,
      img: images.imgNft1,
      name: 'Foxxy',
      price: 5,
      isProfile: false,
      event: 'listed',
      from: 'terra1rlt2jarzvswux8hy9s6fuza35jgn0v7slzxecm',
      to: '5UrM9csUEDBeBqMZTuuZyHRNhbRW4vQ1MgKJDrKU1U2v',
      date: 1646216056,
      tokenID: 23551
    },
    {
      id: 11,
      img: images.imgNft2,
      name: 'Foxxy X',
      price: 2.5,
      isProfile: false,
      event: 'listed',
      from: 'terra1rlt2jarzvswux8hy9s6fuza35jgn0v7slzxecm',
      to: '5UrM9csUEDBeBqMZTuuZyHRNhbRW4vQ1MgKJDrKU1U2v',
      date: 1646216056,
      tokenID: 23551
    },
    {
      id: 12,
      img: images.imgNft3,
      name: 'Foxxy IV',
      price: 15.3,
      isProfile: false,
      event: 'delisted',
      from: 'terra1rlt2jarzvswux8hy9s6fuza35jgn0v7slzxecm',
      to: '5UrM9csUEDBeBqMZTuuZyHRNhbRW4vQ1MgKJDrKU1U2v',
      date: 1646216056,
      tokenID: 23551
    },
    {
      id: 13,
      img: images.imgNft4,
      name: 'Foxxy XV',
      price: 1.5,
      isProfile: false,
      event: 'listed',
      from: 'terra1rlt2jarzvswux8hy9s6fuza35jgn0v7slzxecm',
      to: '5UrM9csUEDBeBqMZTuuZyHRNhbRW4vQ1MgKJDrKU1U2v',
      date: 1646216056,
      tokenID: 23551
    },
    {
      id: 14,
      img: images.imgNft5,
      name: 'Foxxy V',
      price: 0.5,
      isProfile: false,
      event: 'delisted',
      from: 'terra1rlt2jarzvswux8hy9s6fuza35jgn0v7slzxecm',
      to: '5UrM9csUEDBeBqMZTuuZyHRNhbRW4vQ1MgKJDrKU1U2v',
      date: 1646216056,
      tokenID: 23551
    }
  ]

  const handleChooseMenu = (item) => () => {
    setMenuChoose(item)
  }

  const handelOpenDetail = (item) => () => {
    window.openModal({
      containerClass: 'modal-edit-profile',
      noIcon: true,
      backgroundTrans: true,
      className: 'modal-content--edit-profile',
      content: (
        <ProfilePopUp item={item}/>
      )
    })
  }

  const renderItem = () => {
    return arrItem.map((item, i) => {
      return <ItemNft key={i} item={item} handelOpenDetail={handelOpenDetail} />
    })
  }

  const renderItemMenu = () => {
    if (menuChoose === 'item') {
      return (
        <div className="profile-items__list-items">
          {renderItem()}
        </div>
      )
    } else {
      return <TableList arrItem={arrItem} />
    }
  }

  return (
    <div className='profile-items'>
      <div className="profile-items__menu-bar">
        <div className={`menu-item ${menuChoose === 'item' && 'menu-item--active'}`} onClick={handleChooseMenu('item')}>
          {t('item')} <span>({getLength(arrItem)})</span>
        </div>

        <div className={`menu-item ${menuChoose === 'activity' && 'menu-item--active'}`} onClick={handleChooseMenu('activity')}>
          {t('activity')}
        </div>
      </div>

      {renderItemMenu()}
    </div>
  )
}

export default ProfileItems
