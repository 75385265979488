import React, {
  createContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { compact, get, unionBy } from 'lodash'

import { CONTRACT_FARM_STAKE_V2, CONTRACT_FARM_STAKE_V1, MAIN_COIN_AMM } from 'common/poolEvm/constants'
import { useSelector } from 'react-redux'
import { convertWeiToBalance, lowerCase } from 'common/functions'
import { genContract, genWeb3 } from 'controller/Library/evm'
import ERC20 from 'common/abi/ERC20'
import BaseAPI from 'controller/api/BaseAPI'
import usePairs from 'common/poolEvm/hooks/usePairs'
import abiFarmV2 from 'common/abi/abiFarmV2'
import { useWallet } from '@coin98-com/wallet-adapter-react'

const LiquidityContext = createContext()

const LiquidityProvider = ({ children }) => {
  const search = new URL(window.location.href).search
  const query = new URLSearchParams(search)
  const token0Mint = query.get('token0')
  const token1Mint = query.get('token1')

  const [listLiquidityPool, setListLiquidityPool] = useState([])
  const [isLoadingListPool, setIsLoadinglistPool] = useState(true)
  const [listPoolFarm, setListPoolFarm] = useState([])
  const [isRefreshLPAmount, setIsRefreshLPAmount] = useState(false)
  const chainActive = useSelector((state) => state.chainActive)
  const { address } = useWallet()

  const { getInfoPoolAddress } = usePairs(chainActive, address)

  const [fromCoin, setFromCoin] = useState({})
  const [toCoin, setToCoin] = useState({})

  const mainCoin = MAIN_COIN_AMM[chainActive]

  //
  useEffect(() => {
    if (token0Mint && token1Mint) {
      getTokenDetail()
    }
  }, [token0Mint, token1Mint, chainActive])

  useEffect(() => {
    setFromCoin({})
    setToCoin({})
  }, [chainActive])

  useEffect(() => {
    if (address) {
      loadLiquidityAccount(true)
    } else {
      setIsLoadinglistPool(false)
      setListLiquidityPool([])
    }
  }, [lowerCase(address), chainActive])

  const isSelectedCoin = useMemo(() => {
    return !!(fromCoin?.symbol && toCoin?.symbol)
  }, [JSON.stringify(fromCoin), JSON.stringify(toCoin)])

  const getTokenDetail = async () => {
    const result = await loadToken(token0Mint, token1Mint)
    if (result) {
      const { token0, token1 } = result
      setFromCoin(token0 || {})
      setToCoin(token1 || {})
    }
  }

  const loadListPoolFarm = async () => {
    const res = await BaseAPI.getData('baryon/farm', { chain: chainActive })

    if (res) {
      const listPoolFarm = get(res, 'data')
      const web3 = genWeb3(chainActive)

      const newArrStake = await Promise.all(listPoolFarm.map(async (item, index) => {
        const decimalStaked = get(item, 'tokenInfoStaked.decimal') || get(item, 'tokenInfoStaked.decimals', 18)
        const isV2 = get(item, 'isV2')

        let contractAddress = CONTRACT_FARM_STAKE_V1[chainActive]

        if (isV2) {
          contractAddress = get(item, 'factoryAddress') || CONTRACT_FARM_STAKE_V2[chainActive]
        }

        const contractFinal = genContract(web3, abiFarmV2, contractAddress)
        let balanceStaked = 0
        if (address) {
          const resInfoStaked = await contractFinal.methods.userInfo(item.pid, address).call()
          balanceStaked = convertWeiToBalance(resInfoStaked.amount, decimalStaked)
        }
        if (balanceStaked > 0) {
          return item.stakedLpAddress
        }
      }))

      return compact(newArrStake)
    }
  }

  const loadLiquidityAccount = async () => {
    setIsLoadinglistPool(true)

    const listPoolFarm = await loadListPoolFarm()
    const listPoolAddress = await BaseAPI.getData('baryon/pool/list', { chain: chainActive })
    const listTokenLP = await window.wallet.getListLp(chainActive, address, listPoolAddress)
    const mapListLp = listTokenLP.map(item => lowerCase(item.address))
    const newListPoolFarm = listPoolFarm.map(item => lowerCase(item.address))

    const newListLp = compact(unionBy(mapListLp, newListPoolFarm))

    const listLpInfo = await Promise.all(newListLp.map(async item => {
      const pool = await getInfoPoolAddress(item)
      return pool
    }))

    const newData = listLpInfo.sort((a, b) => { return parseFloat(b.balanceOfLp) - parseFloat(a.balanceOfLp) })
    setListPoolFarm(listPoolFarm || [])
    setListLiquidityPool(newData || [])
    setIsLoadinglistPool(false)
  }

  const onSelectFromToCoin = (from, to) => {
    setFromCoin(from)
    setToCoin(to)
  }

  const onSelectToken = ({ isFrom, token, typeLiquidity = 'add' }) => {
    let addressToken0 = get(fromCoin, 'address')
    let addressToken1 = get(toCoin, 'address')
    let addressToken = token.address

    if (addressToken === mainCoin?.address) addressToken = mainCoin?.symbol

    if (isFrom) {
      if (lowerCase(addressToken1) === lowerCase(addressToken)) {
        addressToken1 = addressToken0
        setToCoin(fromCoin)
      }
      setFromCoin(token)

      if (addressToken1 === mainCoin?.address) addressToken1 = mainCoin?.symbol

      window.history.pushState({}, 'baryonLiquidity', !addressToken1 ? '/liquidity' : `/liquidity/${typeLiquidity}?chain=${chainActive}&token0=${addressToken || ''}&token1=${addressToken1 || ''}`)
      return
    }

    if (lowerCase(addressToken0) === lowerCase(addressToken)) {
      addressToken0 = addressToken1
      setFromCoin(toCoin)
    }

    if (addressToken0 === mainCoin?.address) addressToken0 = mainCoin?.symbol
    setToCoin(token)

    window.history.pushState({}, 'baryonLiquidity', !addressToken0 ? '/liquidity' : `/liquidity/${typeLiquidity}?chain=${chainActive}&token0=${addressToken0 || ''}&token1=${addressToken || ''}`)
  }

  const handlePushStateUrl = ({ token0Mint, token1Mint, typeLiquidity }) => {
    if (!token0Mint || !token1Mint) {
      return window.history.pushState('', '', '/liquidity')
    }

    return window.history.pushState(
      '',
      'baryonLiquidity',
      `/liquidity/${typeLiquidity}?chain=${chainActive}&token0=${token0Mint}&token1=${token1Mint}`
    )
  }

  const loadToken = async (addressMint0, addressMint1) => {
    const listCoinLocal = window.wallet.getCoinFetch(chainActive)

    let token0, token1
    if ((addressMint0 === mainCoin.address) || (addressMint0 === mainCoin.symbol)) token0 = mainCoin
    if ((addressMint1 === mainCoin.address) || (addressMint1 === mainCoin.symbol)) token1 = mainCoin

    if (!token0) token0 = listCoinLocal.find(item => (lowerCase(addressMint0) === lowerCase(item.address)))
    if (!token1) token1 = listCoinLocal.find(item => (lowerCase(addressMint1) === lowerCase(item.address)))

    if (!token0) {
      const isValidAddress = addressMint0 ? window.wallet.validateBlockChainAddress(addressMint0, chainActive) : false
      if (isValidAddress) {
        const web3 = genWeb3(chainActive)

        const contractToken0 = genContract(web3, ERC20, addressMint0)
        const decimal = await contractToken0.methods.decimals().call()
        const name = await contractToken0.methods.name().call()
        const symbol = await contractToken0.methods.symbol().call()
        token0 = { address: addressMint0, decimal, name, symbol }
      } else {
        token0 = {}
      }
    }

    if (!token1) {
      const isValidAddress = addressMint1 ? window.wallet.validateBlockChainAddress(addressMint1, chainActive) : false

      if (isValidAddress) {
        const web3 = genWeb3(chainActive)
        const contractToken1 = genContract(web3, ERC20, addressMint1)
        const decimal = await contractToken1.methods.decimals().call()
        const name = await contractToken1.methods.name().call()
        const symbol = await contractToken1.methods.symbol().call()
        token1 = { address: addressMint1, decimal, name, symbol }
      } else {
        token1 = {}
      }
    }
    return { token0, token1 }
  }

  return (
    <LiquidityContext.Provider
      value={{
        loadLiquidityAccount,
        handlePushStateUrl,
        loadToken,
        listPoolFarm,
        listLiquidityPool,
        isLoadingListPool,
        isRefreshLPAmount,
        setIsRefreshLPAmount,
        onSelectToken,
        fromCoin,
        toCoin,
        onSelectFromToCoin,
        isSelectedCoin
      }}
    >
      {children}
    </LiquidityContext.Provider>
  )
}

export { LiquidityContext, LiquidityProvider }
