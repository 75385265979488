import images from 'assets/images'
import {
  convertDateMoment,
  formatPrice,
  showNotificationToast,
  upperCase
} from 'common/functions'
import { STAKE, UN_STAKE } from 'common/stake/constants'
import Button from 'components/common/Button'
import { get, lowerCase } from 'lodash'
import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import InfoFarmBox from '../InfoFarmBox'

import StakePopUp from '../StakePopUp'

import './style.scss'
import useApprove from 'common/poolEvm/hooks/useApprove'
import ReduxServices from 'common/redux'
import Approve from 'components/Approve'
import HarvestPopup from '../HarvestPopup'
import Skeleton from 'react-loading-skeleton'
import { CHAIN_DATA, isDapp } from 'common/constants'
import { CONTRACT_FARM_STAKE_V2, CONTRACT_FARM_STAKE_V1, W_MAIN_COIN_STAKE } from 'common/poolEvm/constants'
import Tooltip from 'components/Tooltip'
import IconCustom from 'components/common/IconCustom'
import ShareModal from 'components/common/ShareModal'
import { useWallet, WALLETS_NAME } from '@coin98-com/wallet-adapter-react'
import { useWalletModal } from '@coin98-com/wallet-adapter-react-ui'
import { zeroAddress } from 'controller/Library/evm'

function ItemStakeCoin ({ item, loadInitialTotal, isLoadListData }) {
  const token0 = get(item, 'token0')
  const token1 = get(item, 'token1')
  const hash = get(item, 'hash', '')
  const isStake = !token0
  const { openWalletModal } = useWalletModal()

  const { t } = useTranslation()

  const isDarkMode = useSelector((state) => state.themeRedux) === 'dark'
  const chainActive = useSelector((state) => state.chainActive)
  const { address, connected, selectWallet } = useWallet()

  const stakedLpAddress = get(item, 'stakedLpAddress')
  const stakedToken = lowerCase(get(item, 'stakedLpAddress')) === lowerCase(W_MAIN_COIN_STAKE[chainActive].address) ? zeroAddress : get(item, 'stakedLpAddress')
  const rewardTokens = get(item, 'rewardTokens')
  const priceStake = get(item, 'priceStake')
  const totalRewardUSD = get(item, 'totalRewardUSD')
  const rewardsExpiration = get(item, 'rewardsExpiration')
  const isStartTime = get(item, 'isStartTime')

  const isCompleted = get(item, 'isCompleted', false)
  const isV2 = get(item, 'isV2', false)
  const factoryAddress = get(item, 'factoryAddress')

  // info farm
  const isStakeFarm = true

  const tokenInfoStaked = get(item, 'tokenInfoStaked')

  const key = token0 && token1 ? `${JSON.stringify(token0)}${JSON.stringify(token1)}${hash}` : (isStake ? `${JSON.stringify(tokenInfoStaked)}` : JSON.stringify(Math.random()))

  const [itemExpand, setItemExpand] = useState(() => {
    return get(window, ['state', key]) || false
  })

  const iconToken1 = get(isStake ? tokenInfoStaked : token0, 'image') || get(isStake ? tokenInfoStaked : token0, 'logoURI', images[isDarkMode ? 'icoUnknown' : 'icoUnknownLight'])
  const iconToken2 = get(token1, 'image') || get(token1, 'logoURI', images[isDarkMode ? 'icoUnknown' : 'icoUnknownLight'])

  const symbolRewordToken = useMemo(() => {
    let symbol = ''
    rewardTokens.forEach(element => {
      symbol = symbol + (symbol ? ', ' : '') + upperCase(element.symbol)
    })
    return symbol
  })

  const renderSymbolLP = isStake
    ? upperCase(get(tokenInfoStaked, 'symbol', ''))
    : `${upperCase(get(token0, 'symbol', ''))} - ${upperCase(
      get(token1, 'symbol', '')
    )} LP`

  // const renderTitle = isStake
  //   ? `Stake ${upperCase(get(tokenInfoStaked, 'symbol', ''))} Earn ${symbolRewordToken}`
  //   : `Stake ${upperCase(get(token0, 'symbol', ''))} - ${upperCase(
  //     get(token1, 'symbol', '')
  //   )} LP`
  const renderTitle = isStake
    ? `Stake ${upperCase(get(tokenInfoStaked, 'symbol', ''))} Earn ${symbolRewordToken}`
    : `${upperCase(get(token0, 'symbol', ''))} - ${upperCase(
      get(token1, 'symbol', '')
    )} LP`

  const renderTitleApprove = isStake
    ? `${upperCase(get(tokenInfoStaked, 'symbol', ''))}`
    : `${upperCase(get(token0, 'symbol', ''))} - ${upperCase(
      get(token1, 'symbol', '')
    )} LP`

  //

  const poolLimitPerUser = get(item, 'poolLimitPerUser')
  const pendingReward = get(item, 'pendingReward', 0)
  const balanceStaked = get(item, 'balanceStaked', 0)
  const apr = get(item, 'apr', 0)

  const { payload: approval, isLoading: isLoadingApprove } = useApprove(chainActive, stakedToken, address, isV2 ? (factoryAddress || CONTRACT_FARM_STAKE_V2[chainActive]) : CONTRACT_FARM_STAKE_V1[chainActive])

  const isNotApproval = approval === '0'
  const isDisableHarvest = pendingReward === '0' || pendingReward === 0
  const isDisablePlus = (parseFloat(balanceStaked) >= parseFloat(poolLimitPerUser)) && (parseFloat(poolLimitPerUser) > 0)

  const scanLp = `${CHAIN_DATA[chainActive].scan}/address/${stakedLpAddress}`

  const checkEnd = balanceStaked === 0 && isCompleted

  const handleExpand = () => {
    const nextState = !itemExpand
    setItemExpand(nextState)

    if (!window.state) {
      window.state = { }
    }

    window.state[key] = nextState
  }

  const onShowShareModal = () => {
    window.openModal({
      isCustomModal: true,
      containerClass: 'modal-stake',
      noIcon: true,
      backgroundTrans: true,
      className: 'modal-content--stake',
      content: <ShareModal
        type={isStake ? 'stake' : 'farm'}
        token0={token0}
        token1={token1}
        stakedLpAddress={isStake && stakedLpAddress}
        poolAddress={stakedLpAddress}
        isCompleted={isCompleted}
      />,
      onCancel: () => {
        console.log('cancel')
      }
    })
  }

  const openPopupFarmStake = (type) => () => {
    window.openModalFirst({
      // okText: t('disconnect'),
      containerClass: 'modal-noti',
      title: t('notification'),
      type: 'warning',
      content: <div className='notification'>
        { t('desNotificationStake')}
      </div>,
      onOk: handleOpenFarmStake(type)
    })
  }

  const handleOpenFarmStake = (type) => () => {
    window.openModalFirst({
      containerClass: 'modal-stake',
      noIcon: true,
      backgroundTrans: true,
      className: 'modal-content--stake',
      preventClose: true,
      content: (
        <StakePopUp
          item={item}
          type={type}
          loadInitialTotal={loadInitialTotal}
        />
      )
    })
  }

  const onConnect = async () => {
    if (!isDapp) return openWalletModal()
    try {
      const objChain = ReduxServices.findChainId(window.coin98?.provider?.chainId)

      // const address = await window?.coin98?.provider?.request({ method: 'eth_accounts' })
      if (objChain?.chainId) {
        selectWallet(WALLETS_NAME.coin98Ether, objChain?.chainId)
      } else {
        showNotificationToast(t('notSupportChain'), 'error', 2000)
      }
    } catch (error) {
      console.log('🚀 ~ file: index.js:93 ~ onConnect ~ error:', error)
    }
  }

  const handleHarvest = (isUnStake) => () => {
    if (!isDisableHarvest || isUnStake) {
      window.openModal({
        containerClass: 'modal-stake-harvest',
        title: t('harvest'),
        type: 'confirm',
        className: 'modal-content--harvest-stake',
        noButton: true,
        content: <HarvestPopup item={item} load={loadInitialTotal} isUnStake={isUnStake}/>
      })
    }
  }

  const handleApprove = async () => {
    window.openModal({
      title: t('confirmApprove'),
      type: 'confirm',
      noButton: true,
      content: <Approve renderSymbol={upperCase(renderTitleApprove)} callBackSuccess={handleOpenFarmStake(STAKE)} contractAddress={stakedLpAddress} spender={isV2 ? (factoryAddress || CONTRACT_FARM_STAKE_V2[chainActive]) : CONTRACT_FARM_STAKE_V1[chainActive]} load={loadInitialTotal} />
    })
  }

  const renderAction = () => {
    if (balanceStaked !== 0) {
      return (
        <div className="stake-detail__right">
          <div className="left-actions">
            <div className="left-value">
              {formatPrice(balanceStaked, false, true)}
            </div>

            <div className="left-total-value">
              {/* <CountComponent number={ReduxServices.formatPrice(balanceStaked * priceStake)}/> */}
              {ReduxServices.formatPrice(balanceStaked * priceStake)}
              {/* {formatPrice(balanceStaked * priceStake)} */}
            </div>
          </div>

          <div className="actions-right">

            {isCompleted
              ? <Button
                onClick={handleHarvest(true)}
                isDisable={ isLoadListData}
                className="earn-detail--right"
              >
                {t('unstake')}
              </Button>
              : <>
                <div
                  onClick={openPopupFarmStake(UN_STAKE)}
                  className={'action-btn'}
                >
                  {/* <img
                    src={images[isDarkMode ? 'minus' : 'minusLight']}
                    alt=""
                    className="icon-minus"
                  /> */}
                  <IconCustom type={'default'} icon='web_remove'/>
                </div>
                <div
                  onClick={(isDisablePlus || isCompleted) ? () => {} : openPopupFarmStake(STAKE) }
                  className={`action-btn ${isDisablePlus || isCompleted ? 'disable' : ''}`}
                >
                  {/* <img
                    src={images[isDarkMode ? 'add' : 'addLight']}
                    alt=""
                    className="icon-add"
                  /> */}
                  <IconCustom type={'default'} icon='web_add'/>
                </div>
              </>
            }

          </div>
        </div>
      )
    } else {
      const textStake = 'stakeLP'
      const textConnectWallet = !connected ? 'connectWallet' : ''
      const renderTextBtn = textConnectWallet || (address ? (isNotApproval ? 'enable' : textStake) : 'noWallet')

      return (<div className="stake-detail__right">
        <Button
          onClick={address ? (isNotApproval ? handleApprove : handleOpenFarmStake(STAKE)) : onConnect}
          className="farm-action farm-action--enable"
          isLoading={isLoadingApprove}
          isDisable={!isStartTime}
          // type="border-popup"
        >
          {t(isStartTime ? renderTextBtn : 'comingSoon')}
        </Button>
      </div>)
    }
  }

  const renderEarnDetail = () => {
    if (!connected) {
      return (
        <div className="item-box__earn-detail">
          <img className='icon-wallet-add' src={images[isStake ? 'imgStake' : 'imgFarm']} />
          <div className='connect-to-see'>{t('connectToSee')}</div>
          <div className="stake-detail">
            {renderAction()}
          </div>

        </div>
      )
    }

    if (checkEnd) {
      return (
        <div className="item-box__earn-detail farm-end">
          <img className='img-farm-ended' src={images[isStake ? 'imgStake' : 'imgFarm']}/>
          <div className='connect-to-see'>{t(isStake ? 'stakeEnded' : 'farmEnded')}</div>
        </div>
      )
    }

    return (
      <div className="item-box__earn-detail">

        <div className="earn-detail">

          <div className="earn-detail--left">
            <div className="left-title">
              {/* <span>{symbolEarning}</span> Earned */}
              {t('unclaimedRewards')}
            </div>
            {!isLoadListData
              ? <>
                <div className="left-value">
                  {/* {formatPrice(totalRewardUSD)} */}
                  {/* <CountComponent number={ReduxServices.formatPrice(totalRewardUSD)}/> */}
                  {ReduxServices.formatPrice(totalRewardUSD)}
                </div>

                {/* <div className="left-total-value">$ {formatPrice(totalRewardUSD, false, true)}</div> */}

              </>
              : <>
                <Skeleton
                  containerClassName="skeleton-empty"
                  baseColor={isDarkMode ? '#242424' : '#bbb'}
                  width={'100%'}
                />

                <Skeleton
                  containerClassName="skeleton-empty"
                  baseColor={isDarkMode ? '#242424' : '#bbb'}
                  width={'100%'}
                />
              </>

            }

          </div>

          {!isCompleted && <Button
            onClick={handleHarvest()}
            isDisable={isDisableHarvest || isLoadListData}
            className="earn-detail--right"
          >
            {t('harvest')}
          </Button>}

        </div>

        <div className="stake-detail">
          <div className="stake-detail__left">
            <a className="left-title text-uppercase" href={scanLp} target='_blank' rel="noreferrer">
              <span>{renderSymbolLP}</span> {t('staked')}
            </a>
          </div>

          {isLoadListData
            ? <Skeleton
              containerClassName="skeleton-empty"
              baseColor={isDarkMode ? '#242424' : '#bbb'}
              width={'100%'}

            />
            : renderAction()}

        </div>
      </div>
    )
  }

  return (
    <div
      className={`item-box item-box__stake ${
        itemExpand && 'item-box--active'
      } ${item.isEarn && 'item-box--active-earn'} stake-farm`}
    >
      <div className={'item-box__head'}>
        <div className="head-left">
          <img
            src={iconToken1}
            className={`icon-token ${isStake && 'token-stake'}`}
            alt=""
          />
          {!isStake && <img
            src={iconToken2}
            className={'icon-token'}
            alt=""
          />}

        </div>

        <div className="head-right">
          <a className="head-right__name-tokens lx-regular" href={scanLp} target='_blank' rel="noreferrer">
            <span>
              {renderTitle}
            </span>
            <span className="icon-web_arrow_right"></span>
          </a>

          {/* {!isStake && <div className="head-right__farm-tokens">{'Earn ' + symbolRewordToken}</div>} */}

        </div>

        <IconCustom
          className='icon-share-container'
          icon="app_share"
          onClick={onShowShareModal}
        />
      </div>

      <div className="item-box__value-coin">

        {isLoadListData
          ? <>
            <Skeleton
              containerClassName="skeleton-empty"
              baseColor={isDarkMode ? '#242424' : '#bbb'}
              width={'100%'}
            />
            <Skeleton
              containerClassName="skeleton-empty"
              baseColor={isDarkMode ? '#242424' : '#bbb'}
              width={'100%'}
            />

            <Skeleton
              containerClassName="skeleton-empty"
              baseColor={isDarkMode ? '#242424' : '#bbb'}
              width={'100%'}
            />
          </>

          : <>
            <div className="value-apr">
              <div className="value-apr--text">APR</div>

              <div className="value-apr--number">
                <div className="number-percent">{formatPrice(apr, false, true)}</div>%
              </div>
            </div>

            <div className="value-apr">
              <div className="value-apr--text">Earn</div>
              <div className="value-apr--token">

                {rewardTokens.map((item, index) => {
                  return (
                    <img key={index}
                      src={get(item, 'image') || (images[isDarkMode ? 'icoUnknown' : 'icoUnknownLight'])}
                      className="value-apr-icon"
                      alt=""
                    />
                  )
                })}

                <div className="tooltip-coin-reward">

                  {rewardTokens.map((item, index) => {
                    return (
                      <div key={index} className='item-coin'>
                        <img
                          src={get(item, 'image') || (images[isDarkMode ? 'icoUnknown' : 'icoUnknownLight'])}
                          alt=""
                        />
                        <span> {`${checkEnd ? 0 : formatPrice(item.totalRewardAmountPerDay, false, true)}  ${upperCase(get(item, 'symbol'))}/day`}</span>
                      </div>
                    )
                  })}

                </div>

              </div>
            </div>

            <div className="value-apr">
              <div className="value-apr--text">{t('end')}</div>

              <div className="value-apr--number">
                <div className="number-percent">{convertDateMoment(rewardsExpiration * 1000, 'DD/MM/YYYY HH:mm')}</div>
              </div>
            </div>

          </>

        }

      </div>

      {renderEarnDetail()}

      <div className="actions-down" onClick={handleExpand}>
        {
          !checkEnd
            ? <>
              <img
                src={images[isDarkMode ? 'iconDownDark' : 'iconDown']}
                className={`icon-down ${itemExpand && 'icon-down--up'}`}
                alt=""
              />
              <Tooltip textContent={!itemExpand ? 'Open pool' : 'Close pool'} />
            </>
            : <span className='d-block' style={{ height: '16px' }}></span>
        }

      </div>

      {itemExpand && !checkEnd && (

        <div className="item-box__info-show">

          <InfoFarmBox
            renderSymbolLP={renderSymbolLP}
            dataFarm={item}
            isStakeFarm={isStakeFarm}
            token0={token0}
            token1={token1}
            stakeToken={{ address: stakedLpAddress }}
          />

        </div>
      )}
    </div>
  )
}

export default ItemStakeCoin
