import { convertWeiToBalance, formatNumberBro, getItemStorage, getLength, lowerCase, scientificToDecimal, setItemStorage, upperCase } from 'common/functions'
import BaseAPI from 'controller/api/BaseAPI'
import SupportAPI from 'controller/api/SupportAPI'
import { convertCheckSUM, genContract, genWeb3, getTokenInfo, zeroAddress } from 'controller/Library/evm'
import { CONTRACT_FACTORY, MAIN_COIN_AMM, STABLE_COIN_CHAIN, tradeType, TRADE_DATA, TRADE_DEFAULT_CHAIN, W_MAIN_COIN, W_MAIN_COIN_TOMO_OLD, MAIN_COIN_VIC_OLD, NAME_COIN_OLD } from './constants'
import { exactTradeAmount } from 'controller/Library/AMM'
import { CurrencyAmount, JSBI, Percent, Pair, TokenAmount, Token } from '@uniswap/sdk'
import { NUM_CHAIN_ID_TO_CHAIN, chainType } from 'common/constants'
import abiFactoryMdex from 'common/abi/abiFactoryMdex'
import abiLp from 'common/abi/abiLp'
import get from 'lodash/get'
import useSocket from 'common/customHooks/useSocket'

export const formatCommonType = (payload, typeTrade) => {
  try {
    return payload.map(item => {
      const token1 = item.token1.token
      const token2 = item.token2.token

      return new Pair(
        new TokenAmount(new Token(token1.chainId, token1.address, token1.decimals, token1.symbol, token1.name), item.token1.amount),
        new TokenAmount(new Token(token2.chainId, token2.address, token2.decimals, token2.symbol, token2.name), item.token2.amount), token1.chainId
      )
    })
  } catch (error) {
    console.log('error', error)
  }
}

export const exactCommonPair = async (typeTrade, base, pair) => {
  const newBase = { ...base }
  const newPair = { ...pair }

  const chain = NUM_CHAIN_ID_TO_CHAIN[newBase?.chainId || newPair?.chainId]
  const isMainBase = (lowerCase(newBase?.symbol) === lowerCase(MAIN_COIN_AMM[chain]?.symbol)) && (lowerCase(newBase?.address) === lowerCase(MAIN_COIN_AMM[chain].address))
  const isMainPair = (lowerCase(newPair?.symbol) === lowerCase(MAIN_COIN_AMM[chain]?.symbol)) && (lowerCase(newPair?.address) === lowerCase(MAIN_COIN_AMM[chain].address))

  if (isMainBase) newBase.address = W_MAIN_COIN[chain].address
  if (isMainPair) newPair.address = W_MAIN_COIN[chain].address

  const resCommon = await SupportAPI.postGetCommonPair(typeTrade, newBase, newPair)
  if (getLength(resCommon) > 0) {
    const commonPair = formatCommonType(resCommon, typeTrade)

    return commonPair
  }
  return []
}

export const postAMMLocal = async (tradeSelected, txtSearch, coinInfoAMM, isCanDelete = true) => {
  const addressSum = convertCheckSUM(txtSearch)

  const findServer = await BaseAPI.getData('ammMarket/check', {
    v2: true,
    address: addressSum,
    typeTrade: tradeSelected.key
  })

  let isCanTrade = coinInfoAMM ? true : !!findServer

  let coinOut = coinInfoAMM || findServer

  if (!findServer && !coinInfoAMM) {
    const mainCoin = MAIN_COIN_AMM[tradeSelected.chain]

    const onChainInfo = await getTokenInfo(txtSearch, tradeSelected.chain)

    coinOut = {
      isCanDelete,
      isLocal: true,
      name: onChainInfo.name,
      symbol: onChainInfo.symbol,
      decimals: onChainInfo.decimals,
      address: addressSum
    }
    const allowPair = await exactCommonPair(tradeSelected.key, coinOut, mainCoin)
    if (getLength(allowPair) > 0) {
      isCanTrade = exactTradeAmount(tradeSelected.chain, allowPair, coinOut, mainCoin, '100000000000000000000')
    }
  }

  if (isCanTrade && !findServer) {
    const newLocalData = (getItemStorage('LIST_LOCAL_' + tradeSelected.chain) || []).slice()

    const findIdx = newLocalData.findIndex(item => item.address === addressSum)
    if (findIdx === -1) {
      newLocalData.push(coinOut)
      setItemStorage(newLocalData, 'LIST_LOCAL_' + tradeSelected.chain)
    }
  }

  return { coinOut, isCanTrade }
}

export function computeTradePriceBreakdown (trade, chain, typeTrade) {
  try {
    const BASE_FEE = new Percent(JSBI.BigInt(30), JSBI.BigInt(10000))
    const BASE_FEE_BSC = new Percent(JSBI.BigInt(typeTrade === tradeType.pancakeSwapV2 ? 30 : 20), JSBI.BigInt(10000))

    const ONE_HUNDRED_PERCENT = new Percent(JSBI.BigInt(10000), JSBI.BigInt(10000))

    if (typeTrade === tradeType.pancakeSwapV2) {
      const BASE_FEE_BSC_V2 = new Percent(JSBI.BigInt(25), JSBI.BigInt(10000))

      const INPUT_FRACTION_AFTER_FEE_OTHER = {
        ether: ONE_HUNDRED_PERCENT.subtract(BASE_FEE),
        heco: ONE_HUNDRED_PERCENT.subtract(BASE_FEE),
        binanceSmart: ONE_HUNDRED_PERCENT.subtract(BASE_FEE_BSC_V2)
      }

      const INPUT_FRACTION_AFTER_FEE = {
        ether: ONE_HUNDRED_PERCENT.subtract(BASE_FEE),
        heco: ONE_HUNDRED_PERCENT.subtract(BASE_FEE),
        binanceSmart: ONE_HUNDRED_PERCENT.subtract(BASE_FEE_BSC)
      }

      const realizedLPFeeOther = !trade
        ? undefined
        : ONE_HUNDRED_PERCENT.subtract(
          trade.route.pairs.reduce(
            (currentFee) => currentFee.multiply(INPUT_FRACTION_AFTER_FEE_OTHER[chain]),
            ONE_HUNDRED_PERCENT
          )
        )

      const realizedLPFee = !trade
        ? undefined
        : ONE_HUNDRED_PERCENT.subtract(
          trade.route.pairs.reduce(
            (currentFee) => currentFee.multiply(INPUT_FRACTION_AFTER_FEE[chain]),
            ONE_HUNDRED_PERCENT
          )
        )

      const priceImpactWithoutFeeFraction = trade && realizedLPFee ? trade.priceImpact.subtract(realizedLPFee) : undefined
      const priceImpactWithoutFeePercent = priceImpactWithoutFeeFraction
        ? new Percent(priceImpactWithoutFeeFraction?.numerator, priceImpactWithoutFeeFraction?.denominator)
        : undefined

      const realizedLPFeeAmount =
          realizedLPFeeOther &&
          trade &&
          (trade.inputAmount instanceof TokenAmount
            ? new TokenAmount(trade.inputAmount.token, realizedLPFeeOther.multiply(trade.inputAmount.raw).quotient)
            : CurrencyAmount.ether(realizedLPFeeOther.multiply(trade.inputAmount.raw).quotient))

      return { priceImpact: priceImpactWithoutFeePercent, realizedLPFee: realizedLPFeeAmount }
    }

    const INPUT_FRACTION_AFTER_FEE = {
      [chainType.ether]: ONE_HUNDRED_PERCENT.subtract(BASE_FEE),
      [chainType.heco]: ONE_HUNDRED_PERCENT.subtract(BASE_FEE),
      [chainType.tomo]: ONE_HUNDRED_PERCENT.subtract(BASE_FEE),
      [chainType.binanceSmart]: ONE_HUNDRED_PERCENT.subtract(BASE_FEE_BSC),
      [chainType.avax]: ONE_HUNDRED_PERCENT.subtract(BASE_FEE),
      [chainType.matic]: ONE_HUNDRED_PERCENT.subtract(BASE_FEE),
      [chainType.celo]: ONE_HUNDRED_PERCENT.subtract(BASE_FEE),
      [chainType.bitkub]: ONE_HUNDRED_PERCENT.subtract(BASE_FEE),
      [chainType.ancient8]: ONE_HUNDRED_PERCENT.subtract(BASE_FEE),
      [chainType.ancient8Mainnet]: ONE_HUNDRED_PERCENT.subtract(BASE_FEE)
    }

    const realizedLPFee = !trade
      ? undefined
      : ONE_HUNDRED_PERCENT.subtract(
        trade.route.pairs.reduce(
          (currentFee) => currentFee.multiply(INPUT_FRACTION_AFTER_FEE[chain]),
          ONE_HUNDRED_PERCENT
        )
      )

    const priceImpactWithoutFeeFraction = trade && realizedLPFee ? trade.priceImpact.subtract(realizedLPFee) : undefined
    const priceImpactWithoutFeePercent = priceImpactWithoutFeeFraction
      ? new Percent(priceImpactWithoutFeeFraction?.numerator, priceImpactWithoutFeeFraction?.denominator)
      : undefined

    const realizedLPFeeAmount =
        realizedLPFee &&
        trade &&
        (trade.inputAmount instanceof TokenAmount
          ? new TokenAmount(trade.inputAmount.token, realizedLPFee.multiply(trade.inputAmount.raw).quotient)
          : CurrencyAmount.ether(realizedLPFee.multiply(trade.inputAmount.raw).quotient))

    return { priceImpact: priceImpactWithoutFeePercent, realizedLPFee: realizedLPFeeAmount }
  } catch (error) {
    console.log('error', error)
    return { priceImpact: 0, realizedLPFee: 0 }
  }
}

export function formatPriceImpact (priceImpact) {
  const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))

  const formatNum = priceImpact ? priceImpact.toSignificant(6) : 0

  return priceImpact ? (priceImpact.lessThan(ONE_BIPS) ? 0.01 : formatNum) : 0
}

export const findTrade = (key) => {
  return TRADE_DATA.find(item => lowerCase(item.key) === lowerCase(key))
}

export const formatExecutionPrice = (trade, baseCoin, pairCoin, isSwap = true) => {
  if (!trade) {
    return ''
  }
  return `1 ${upperCase(baseCoin.symbol)} ≈ ${formatNumberBro({ number: trade.executionPrice.toSignificant(6) })} ${upperCase(pairCoin.symbol)}`
}

export const renderAMMImage = (coin, chain = chainType.ether) => {
  if (coin) {
    if (getLength(coin.ownLogo) > 0) {
      return coin.ownLogo
    }

    const isLogoURI = getLength(coin.logoURI) > 0 || getLength(coin.icon)
    const url = coin.logoURI || coin.icon
    if (isLogoURI && !url.includes('.svg')) {
      if (url.includes('ipfs')) {
        const idIPFS = url.replace('ipfs://', '')
        return `https://cloudflare-ipfs.com/ipfs/${idIPFS}`
      }
      if (url.includes('https')) {
        return url
      }
    }

    if (isLogoURI) {
      return url
    }

    let nameChain = 'ethereum'

    switch (chain) {
    case chainType.binanceSmart:
      nameChain = 'smartchain'
      break
    case chainType.heco:
      nameChain = 'heco'
      break
    }

    return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${nameChain}/assets/${convertCheckSUM(coin.address)}/logo.png`
  }
}

export const getLocalPair = async (address, chain, sAmount) => {
  try {
    const tokenInfo = address === '' ? MAIN_COIN_AMM[chain] : await window.wallet.findCoinLocal(chain, address)

    if (address !== '') {
      tokenInfo.address = address
    }
    const size = convertWeiToBalance(sAmount, tokenInfo.decimals)
    const commonPair = await exactCommonPair(tradeType.baryonHeco, tokenInfo, STABLE_COIN_CHAIN[chain])
    if (!commonPair) return { size: 0, volume: 0 }

    const tradeBase = exactTradeAmount(chain, commonPair, tokenInfo, STABLE_COIN_CHAIN[chain], parseFloat(size).toFixed(6))
    return tradeBase ? { size, volume: tradeBase.outputAmount.toSignificant(6) } : { size: 0, volume: 0 }
  } catch (error) {
    return { size: 0, volume: 0 }
  }
}

export const getPricePoolBaryon = async (chain, tokenFrom, tokenTo, amount) => {
  if (!tokenFrom.address || !tokenTo.address) return 0

  const web3 = genWeb3(chain)

  const contractID = CONTRACT_FACTORY[chain]
  const contract = genContract(web3, abiFactoryMdex, contractID)

  const poolAddress = await contract.methods.getPair(tokenFrom, tokenTo.address).call()
  if (poolAddress === zeroAddress) return 0

  const contractTokenLp = genContract(web3, abiLp, poolAddress)
  const addressToken0 = await contractTokenLp.methods.token0().call()
  const getReserves = await contractTokenLp.methods.getReserves().call()

  const isFrom = lowerCase(tokenFrom.address) === lowerCase(addressToken0)

  const token0 = isFrom ? tokenFrom : tokenTo
  const token1 = isFrom ? tokenTo : tokenFrom

  const totalToken0 = parseFloat(convertWeiToBalance(getReserves._reserve0, token0.decimals || token0.decimal))
  const totalToken1 = parseFloat(convertWeiToBalance(getReserves._reserve1, token1.decimals || token1.decimal))

  const rateFromTo = totalToken0 / totalToken1

  let fAmount
  if (isFrom) fAmount = parseFloat(amount || 0) / rateFromTo
  else fAmount = parseFloat(amount || 0) * rateFromTo

  return scientificToDecimal(fAmount.toString())
}

export const findCoinGeckoPrice = async (cgkId) => {
  const { emitCoinMarket } = useSocket()

  // .then(item => {
  //   console.log('🚀 ~ file: function.js:288 ~ emitCoinMarket ~ item:', item)
  // })
  // let oldData = await getItemStorage('coinGeckoPrice')
  // if (!oldData) {
  //   oldData = await SupportAPI.getCoinGecko()
  //   setItemStorage(oldData, 'coinGeckoPrice')
  // }
  const findInCgk = await emitCoinMarket(cgkId)

  return get(findInCgk, 'current_price', 0)
}

export const getPrice = async (token, chain) => {
  try {
    const newToken = { ...token }
    if (!newToken.decimals) {
      newToken.decimals = newToken.decimal
    }
    let price = await findCoinGeckoPrice(token.cgkId || token.id)
    if (price === 0) {
      // price = await getPricePoolBaryon(chain, token, STABLE_COIN_CHAIN[chain], 1)
      const commonPair = await exactCommonPair(TRADE_DEFAULT_CHAIN[chain], newToken, STABLE_COIN_CHAIN[chain])
      const tradeBase = exactTradeAmount(chain, commonPair, newToken, STABLE_COIN_CHAIN[chain])
      price = tradeBase ? tradeBase.outputAmount.toSignificant(6) : 0
    }

    if (price === 0) {
      const commonPair = await exactCommonPair(TRADE_DEFAULT_CHAIN[chain], newToken, MAIN_COIN_AMM[chain])
      const tradeBase = exactTradeAmount(chain, commonPair, newToken, MAIN_COIN_AMM[chain])
      const priceTokenPairMain = tradeBase ? tradeBase.outputAmount.toSignificant(6) : 0
      const priceMain = await findCoinGeckoPrice(MAIN_COIN_AMM[chain]?.id)
      price = parseFloat(priceTokenPairMain) * parseFloat(priceMain)
    }

    return price
  } catch (err) {
    return 0
  }
}

export const getTokeInfoPool = (token, chain) => {
  const newChain = token?.chain || chain
  const name = newChain === chainType.tomo ? NAME_COIN_OLD[lowerCase(token?.address)] : ''
  const newToken = { ...token }
  if (name) {
    newToken.symbol = name
  }

  return newToken
}

export const parseNumber = (str) => {
  return parseFloat(str.toString().replace(/,/g, ''))
}
