import React, { useEffect, useState } from 'react'
import './style.scss'
import { animated, useSpring } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import { extract2D, generatePasswordMatrix } from 'common/functions'

let isTouched
const ConfirmMatrix = ({
  size = 6,
  itemSize = 55,
  gap = 0,
  isConfirm,
  onSetCoordinate,
  coordinate = {},
  onConfirm = () => {},
  isValidate,
  num,
  ...props
}) => {
  const [password, setPassword] = useState(null)
  const [isDrag] = useState(false)

  const handleSetCoordinate = (coors) => () => {
    onSetCoordinate(coors)
  }

  const renderPasswordMatrix = () => {
    if (password) {
      return (
        <div
          className="password-nums-wrap"
          style={{
            gridTemplateColumns: `repeat(auto-fit,minmax(${itemSize}px,1fr))`,
            rowGap: gap,
            columnGap: gap
          }}
        >
          {password.map((row, i) =>
            row.map((col, idx) => {
              return (
                <div
                  className={`password-num-cell ${num === col && 'active'}`}
                  style={{ height: `${itemSize}px` }}
                  key={`password-${i}-${idx}`}
                >
                  {col}
                </div>
              )
            })
          )}
        </div>
      )
    }

    return null
  }

  useEffect(() => {
    const arrPassword = generatePasswordMatrix(size)

    setPassword(arrPassword)
  }, [])

  const [{ x, y }, set] = useSpring(() => ({ x: 0, y: 0 }))
  if (password) {
    const d = Math.floor(password.length / 2) * itemSize
    const e = Math.floor(d / itemSize) * itemSize
    set({ x: -e, y: -e, reset: true })
  }

  const bind = useGesture(
    {
      onDrag: ({ down, movement: [mx, my], offset }) => {
        window.requestAnimationFrame(() => {
          if (
            Math.abs(offset[0]) >= itemSize / 2 ||
            Math.abs(offset[1]) >= itemSize / 2
          ) {
            isTouched = true
          }
          // Snap to Grid
          if (down) {
            const isOutLeft = itemSize * 24 - 100 < Math.abs(mx)
            const itOutBottom = itemSize * 23 - 100 < Math.abs(my)
            if (isOutLeft || itOutBottom) {
              // REVERSE drag
            } else {
              set({ x: mx, y: my })
            }
          } else {
            const snapX = Math.round(x.get() / itemSize, 0) * itemSize
            const snapY = Math.round(y.get() / itemSize, 0) * itemSize
            set({ x: snapX > 0 ? 0 : snapX, y: snapY > 0 ? 0 : snapY })
          }
        })
      },
      onDragEnd: () => {
        const snapX = Math.abs(Math.round(x.get() / itemSize, 0))
        const snapY = Math.abs(Math.round(y.get() / itemSize, 0))

        // Submit Password
        const d = extract2D(password, snapX, snapY, snapX + 6, snapY + 9)
        if (typeof onConfirm === 'function' && isTouched) {
          onConfirm(d)
        }
      }
    },
    {
      drag: {
        initial: () => [x.get(), y.get()],
        delay: 0
      }
    }
  )

  if (!password) return null

  // Styles
  const wrapSize = size * itemSize + size * gap
  const wrapSizeHeight = wrapSize + 3 * itemSize
  const passwordWrapSize =
    password.length * itemSize + (password.length - 1) * gap

  return (
    <div
      className={
        'choose-cordinate password' + (isDrag && !isConfirm ? ' dragging' : '')
      }
    >
      <div
        className="password-wrapper"
        style={{ width: wrapSize, height: wrapSizeHeight }}
      >
        <div
          className="password-holder"
          style={{
            gridTemplateColumns: `repeat(auto-fit, minmax(${itemSize}px,1fr))`,
            height: wrapSizeHeight
          }}
        >
          {[...Array(size + 3).fill([...Array(size).fill(1)])].map(
            (row, index) =>
              row.map((col, idx) => (
                <div
                  className={`password-holder-item ${
                    coordinate.x === index &&
                    coordinate.y === idx &&
                    (isValidate ? 'active-outline' : 'active')
                  }`}
                  key={`holder-${index}-${idx}`}
                  onClick={handleSetCoordinate({ x: index, y: idx })}
                ></div>
              ))
          )}
        </div>

        {!onSetCoordinate && (
          <animated.div
            className="transform-div"
            {...bind()}
            style={{
              width: passwordWrapSize,
              height: passwordWrapSize,
              x,
              y,
              position: 'relative',
              zIndex: 1
            }}
          >
            {renderPasswordMatrix()}
          </animated.div>
        )}
      </div>
    </div>
  )
}

export default ConfirmMatrix
