import { useWallet } from '@coin98-com/wallet-adapter-react'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { genContract, genWeb3 } from './evm'
import get from 'lodash/get'
import { CONTRACT_TRC21_ISSUER } from 'common/poolEvm/constants'
import Trc21IssuerABI from 'common/abi/Trc21IssuerABI'
import { chainType } from 'common/constants'

const useServices = () => {
  const { sendTransaction, address, selectedChainId } = useWallet()
  const chainActive = useSelector((state) => state.chainActive)

  const sendRawTxn = async ({ value = '0x0', valueDirect, to, data = '0x', callBack, callBackFail, isWaitDone, callBackDataFirst, isGasFree }) => {
    try {
      const web3 = genWeb3(chainActive)

      let isNewGasFree = isGasFree
      if ((isNewGasFree === undefined) && (chainActive === chainType.tomo)) {
        const contract = genContract(web3, Trc21IssuerABI, CONTRACT_TRC21_ISSUER)
        const balanceSponsor = await contract.methods
          .getTokenCapacity(to)
          .call()
        isNewGasFree = parseInt(balanceSponsor) > 10000000000000000
      }

      const transactionParameters = {
        from: address,
        to,
        value: valueDirect || value,
        data,
        chainId: selectedChainId,
        isGasFree: isNewGasFree
        // gas: 137932
        // gas: convertDecimalToHex(137932)
      }

      const res = await sendTransaction(transactionParameters)

      // isError
      if (res?.isError) {
        callBackFail && callBackFail(res?.error)
        return res
      }

      callBackDataFirst && callBackDataFirst(res?.data)

      // isWaitDone : false
      if (!isWaitDone) {
        callBack && callBack(res?.data)
        return res
      }

      // check receipt

      const receipt = await intervalUntilDone(
        () => web3.eth.getTransactionReceipt(res?.data),
        1000,
        300
      )
      const status = get(receipt, 'status', '0x0')
      if (status === '0x0' || !status) {
        callBackFail && callBackFail('txsFail')
        return {
          data: '',
          error: 'txsFail',
          isError: true
        }
      }

      callBack && callBack(res?.data)
      return res
    } catch (error) {
      callBackFail && callBackFail('txsFail')
      return {
        data: '',
        error: 'txsFail',
        isError: true
      }
    }
  }

  const intervalUntilDone = (fn, time = 1000, limit = 60) => {
    const now = dayjs().unix()
    return new Promise((resolve) => {
      const timer = setInterval(async () => {
        const isExpired = dayjs().unix() - now >= limit
        if (isExpired) {
          timer && clearInterval(timer)
          resolve({})
        }

        try {
          const res = await fn()
          if (res) {
            clearInterval(timer)
            resolve(res)
          }
        } catch (e) {
          console.log({ e })
          resolve({})
        }
      }, time)
    })
  }

  return {
    sendRawTxn
  }
}

export default useServices
