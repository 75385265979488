import images from 'assets/images'
import { isWindow, SCAN_URL } from 'common/constants'
import { convertDateMomentHistory, formatPrice, getLength, upperCase, lowerCase } from 'common/functions'
import AboutToken from 'components/common/AboutToken'
import IconCustom from 'components/common/IconCustom'
import { CustomTable } from 'components/CustomTable'
import BaseAPI from 'controller/api/BaseAPI'
import get from 'lodash/get'
import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { onViewTxs } from 'ultils/functions'
import { useWallet } from '@coin98-com/wallet-adapter-react'

import './style.scss'

let isScroll = true

function HistorySwap () {
  const { t } = useTranslation()
  const { address } = useWallet()
  const [isLoadMore, setIsLoadMore] = useState(false)
  const chainActive = useSelector(state => state.chainActive)
  const [dataHistory, setDataHistory] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingFirst, setLoadingFirst] = useState(false)
  const [page, setPage] = useState(1)
  const [widthResize, setWidthResize] = useState(isWindow && window.innerWidth)

  const refLoadMore = useRef()

  const handleResize = () => {
    setWidthResize(window.innerWidth)
  }

  useEffect(() => {
    loadFirst()
    isWindow && window.addEventListener('resize', handleResize)
  }, [])

  const loadFirst = async () => {
    setLoadingFirst(true)
    await loadInitial(true)()
    setLoadingFirst(false)
  }

  const loadInitial = (isNotLoadMore, listToken) => async () => {
    if (page !== 'max' && !isLoading) {
      isScroll = false
      setIsLoading(true)

      const newPage = isNotLoadMore ? 1 : parseInt(page) + 1
      const result = await onLoadReturnData(newPage, isNotLoadMore, listToken)

      const isMax = result === 'max'
      if (result && result.data && !isMax) {
        setDataHistory(result.data)
      }

      if (isMax && page === 1) {
        setDataHistory([])
      }

      setPage(!isMax ? newPage : 'max')

      setTimeout(() => {
        setIsLoading(false)
      }, 300)
      isScroll = true
    }
  }

  const onLoadReturnData = async (page = 1, isNotLoadMore, listToken) => {
    try {
      const params = {
        address: lowerCase(address),
        page,
        size: 20,
        chain: chainActive
      }

      const res = address ? await BaseAPI.getData('baryon/swap/history', params) : ''
      if (res && getLength(res.data) > 0) {
        const newData = [...res.data]

        let oldData
        if (!isNotLoadMore) {
          oldData = dataHistory.slice()
          Array.prototype.push.apply(oldData, newData)
        }
        const data = isNotLoadMore ? newData : oldData
        return { data }
      } else {
        return 'max'
      }
    } catch (e) {
    }
  }

  const columnsDefault = [{
    title: '',
    dataIndex: 'token1',
    width: 20,
    render: (val) => {
      const { image, amount, symbol } = val
      return (
        <div className='d-flex align-items-center gap-3 text-start'>
          <div>
            <AboutToken className={'img-token'} imgToken={image}/>
          </div>
          <div className='d-flex flex-column'>
            <span>
              {formatPrice(amount, null, true)}
            </span>
            <span className='text-content text-12'>
              {upperCase(symbol)}
            </span>
          </div>
        </div>
      )
    }
  },
  {
    title: '',
    dataIndex: 'icon',
    width: 15
  },
  {
    title: '',
    dataIndex: 'token2',
    width: 20,
    render: (val) => {
      const { image, amount, symbol } = val
      return (
        <div className='d-flex align-items-center gap-3 text-start'>
          <div>
            <AboutToken className={'img-token'} imgToken={image}/>
          </div>
          <div className='d-flex flex-column'>
            <span>
              {formatPrice(amount, null, true)}
            </span>
            <span className='text-content text-12'>
              {upperCase(symbol)}
            </span>
          </div>
        </div>
      )
    }
  },
  {
    title: '',
    dataIndex: 'time',
    width: 20,
    align: 'right',
    render: (val) => {
      return (
        <span className='text-content text-nowrap'>
          {convertDateMomentHistory(get(val), 'HH:mm DD/MM/YY')}
        </span>
      )
    }
  }]

  const dataLPTable = useMemo(() => {
    if (getLength(dataHistory) === 0) return []
    return dataHistory.map((item, index) => {
      return {
        index: index,
        token1: {
          image: get(item, 'token0.image') || get(item, 'token0.logoURI', images.icoUnknown),
          amount: get(item, 'amount0', 0),
          symbol: get(item, 'token0.symbol', '')
        },
        icon: <IconCustom type={'default'} icon='web_swap'/>,
        token2: {
          image: get(item, 'token1.image') || get(item, 'token1.logoURI', images.icoUnknown),
          amount: get(item, 'amount1', 0),
          symbol: get(item, 'token1.symbol', '')
        },
        href: `${SCAN_URL[chainActive]}/tx/${get(item, 'hash')}`
      }
    })
  }, [JSON.stringify(dataHistory), address])

  const onScrollEnd = async () => {
    if (refLoadMore.current) {
      const height = refLoadMore.current.scrollHeight
      const scrollTop = refLoadMore.current.scrollTop
      const clientHeight = refLoadMore.current.clientHeight

      if (scrollTop + clientHeight >= height - 50 && isScroll) {
        setIsLoadMore(true)
        await loadInitial()()
        setIsLoadMore(false)
      }
    }
  }
  const renderListItem = () => {
    if (getLength(dataHistory) === 0) {
      return (
        <div className="item-history-empty">
          <img src={images.iconEmptyTable} className='icon-empty-history' alt="" />
          {t('emptyData')}
        </div>
      )
    } else {
      return dataHistory.map((item, i) => {
        return (
          <div className="item-history" key={i} onClick={onViewTxs(get(item, 'hash'), chainActive)}>
            <div className="item-history__info-token">
              <div className="info-token-box">
                <img src={get(item, 'token0.image') || get(item, 'token0.logoURI', images.icoUnknown)} className='icon-token-history' alt="" />

                <div className="info-value-token">
                  <div className='info-value-token__balance'>
                    {formatPrice(get(item, 'amount0', 0), null, true)}
                    {/* 0.981492487498112948719843289463278482374 */}
                  </div>
                  <span className='symbol-text-name'>
                    {upperCase(get(item, 'token0.symbol', ''))}
                    {/* C98HTTEST */}
                  </span>
                </div>
              </div>

              <div className='icon-swap'>
                <IconCustom type={'default'} icon='web_swap'/>
              </div>

              <div className="info-token-box">
                <img src={get(item, 'token1.image') || get(item, 'token1.logoURI', images.icoUnknown)} className='icon-token-history' alt="" />

                <div className="info-value-token">
                  <div className='info-value-token__balance'>
                    {formatPrice(get(item, 'amount1', 0), null, true)}
                    {/* 0.198243729147982147192847891417984178941 */}
                  </div>
                  <span className='symbol-text-name'>
                    {upperCase(get(item, 'token1.symbol', ''))}
                    {/* C98HTTEST */}
                  </span>
                </div>
              </div>
            </div>

            <div className="item-history__info-time">

              {convertDateMomentHistory(get(item, 'createdAt', ''), widthResize <= 768 ? 'HH:mm DD/MM/YY' : 'HH:mm DD/MM/YY')}
            </div>
          </div>
        )
      })
    }
  }

  useEffect(() => {
    setPage(1)
    setTimeout(() => {
      loadFirst()
    }, 100)
  }, [address, JSON.stringify(dataHistory), chainActive])

  useEffect(() => {
    window.onReloadPage = loadFirst
  }, [])

  return (
    <div className='history-swap'>
      {/* <div className="history-swap__title">
        {t('historyTransactions')}

        <img src={images.iconClose2} className='icon-close' alt="" onClick={handleClose} />
      </div> */}

      {/* <div className="history-swap__list-history" ref={refLoadMore} onScroll={onScrollEnd}>
        {isLoadingFirst ? <PuffLoading isCenter /> : renderListItem()}
      </div> */}

      <CustomTable
        isLoadMore={isLoadMore}
        onScroll={onScrollEnd}
        refTable={refLoadMore}
        isLoading={isLoadingFirst}
        columns={columnsDefault}
        dataSource={dataLPTable}
      />
    </div>
  )
}

export default HistorySwap
