import { KEY_ACTION } from '../lib/constants'
export default class StoreActions {
  static setSession (payload) {
    return {
      type: KEY_ACTION.SET_IS_SESSION,
      payload
    }
  }

  static setLang (payload) {
    return {
      type: KEY_ACTION.SET_LANG,
      payload
    }
  }

  static setConfigRedux (payload) {
    return {
      type: KEY_ACTION.SET_CONFIG,
      payload
    }
  }

  static setDeviceId (payload) {
    return {
      type: KEY_ACTION.SET_DEVICE_ID,
      payload
    }
  }

  static setThemeRedux (payload) {
    return {
      type: KEY_ACTION.SET_THEME_MODE,
      payload
    }
  }

  static setLoadingPool (payload) {
    return {
      type: KEY_ACTION.SET_LOADING_POOL,
      payload
    }
  }

  static setChainActive (payload) {
    return {
      type: KEY_ACTION.SET_CHAIN_ACTIVE,
      payload
    }
  }

  static setSignature (payload) {
    return {
      type: KEY_ACTION.SET_SIG,
      payload
    }
  }

  static setCurrency (payload) {
    return {
      type: KEY_ACTION.SET_CURRENCY_REDUX,
      payload
    }
  }

  static setListFavoriteTokenRedux (payload) {
    return {
      type: KEY_ACTION.SET_LIST_FAVORITE_TOKEN,
      payload
    }
  }

  static setListFavoritePoolRedux (payload) {
    return {
      type: KEY_ACTION.SET_LIST_FAVORITE_POOL,
      payload
    }
  }
}
