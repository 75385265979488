import {
  convertWeiToBalance,
  formatNumberBro,
  getLength,
  lowerCase,
  nFormatter,
  roundingNumber
} from 'common/functions'
import { cloneDeep, compact, get } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import images from '../../../../assets/images'
import './style.scss'
import { useSelector } from 'react-redux'
import ReduxServices from 'common/redux'
import { genContract, genWeb3, getTokenInfo } from 'controller/Library/evm'
import FavoriteToken from 'pages/PoolInfoScreen/components/FavoriteToken'
import AboutToken from 'components/common/AboutToken'
import IconCustom from 'components/common/IconCustom'
import { CustomTable } from 'components/CustomTable'
import { getPrice } from 'common/poolEvm/function'
import useFavoriteToken from 'common/customHooks/useFavoriteToken'
import { generateId } from 'common/functions/utils'
import { useDebounce } from 'react-use'
import ERC20 from 'common/abi/ERC20'
import { useListTokenContext } from 'context/ListTokenContext'
import { useWallet } from '@coin98-com/wallet-adapter-react'

const ListTokenBaryon = ({ selectToken }) => {
  const { t } = useTranslation()
  const refInput = useRef()
  const { address } = useWallet()

  const chainActive = useSelector((state) => state.chainActive)

  const { isLoadingListToken, arrDataToken } = useListTokenContext()

  const listFavoriteTokenRedux = useSelector(
    (state) => state.listFavoriteTokenRedux
  )

  const [txtSearch, setTxtSearch] = useState('')

  const [dataSourceDefault, setDataSourceDefault] = useState(arrDataToken)
  const [dataFavorite, seDataFavorite] = useState([])

  const { onChangeListTokenFavor } = useFavoriteToken()

  useEffect(() => {
    loadData()
  }, [JSON.stringify(arrDataToken)])

  useEffect(() => {
    const loadFavorite = async () => {
      const listCoinInfo = await Promise.all(
        listFavoriteTokenRedux.map(async (item) => {
          return await window.wallet.fetchInfoCoin(chainActive, item)
        })
      )

      seDataFavorite(listCoinInfo.filter(item => item))
    }

    loadFavorite()
  }, [JSON.stringify(listFavoriteTokenRedux)])

  const loadData = async () => {
    if (getLength(arrDataToken) === 0) {
      return setDataSourceDefault([])
    }

    const isValidAddress = txtSearch
      ? window.wallet.validateBlockChainAddress(txtSearch, chainActive)
      : false

    let listFilter = []
    const cloneArr = cloneDeep(arrDataToken)

    if (txtSearch) {
      listFilter = cloneArr.filter((item) => {
        const address = lowerCase(item.address)
        const symbol = lowerCase(item.symbol)
        const name = lowerCase(item.name)
        return (
          address.indexOf(lowerCase(txtSearch)) >= 0 ||
          symbol.indexOf(lowerCase(txtSearch)) >= 0 ||
          name.indexOf(lowerCase(txtSearch)) >= 0
        )
      })
    } else {
      listFilter = cloneArr
    }

    if (getLength(listFilter) === 0 && isValidAddress) {
      const infoCoin = await getTokenInfo(txtSearch, chainActive)
      const decimals = get(infoCoin, 'decimals', 18)
      const web3 = genWeb3(chainActive)
      const contractToken = genContract(web3, ERC20, txtSearch)
      const balance = await contractToken.methods
        .balanceOf(address)
        .call()
      const convertBalance = convertWeiToBalance(balance, decimals)
      const itemPrice = await getPrice(infoCoin, chainActive)
      const value = ReduxServices.formatPrice(itemPrice * convertBalance)

      if (get(infoCoin, 'name', '')) {
        listFilter = [
          {
            address: infoCoin?.address,
            decimals,
            name: infoCoin?.name,
            symbol: infoCoin?.symbol,
            chain: chainActive,
            balance: convertBalance,
            value: value,
            price: itemPrice
          }
        ]
      }
    }

    setDataSourceDefault(listFilter)
  }

  useDebounce(loadData, 500, [txtSearch])

  const dataListTokenFinal = useMemo(() => {
    return dataSourceDefault.slice(0, 100).map((item) => {
      return {
        ...item,
        key: get(item, 'address') || generateId(),
        token: {
          image: get(item, 'image') || get(item, 'logoURI'),
          address: get(item, 'address'),
          name: get(item, 'name'),
          symbol: get(item, 'symbol')
        },
        balance: roundingNumber(get(item, 'balance'), 4),
        value: ReduxServices.formatPrice(get(item, 'value', '0'))
      }
    })
  }, [JSON.stringify(dataSourceDefault)])

  const columnsDefault = [
    {
      dataIndex: 'token',
      width: 40,
      icon: '',
      title: t('token'),
      render: (val, row) => {
        if (!val) return null
        const { address, image, symbol, name } = val
        const isFavorToken = handleFavorite(address)
        return (
          <div className="table-item__token">
            <IconCustom
              className={`${isFavorToken ? 'icon-like' : ''}`}
              icon={`${isFavorToken ? 'web_like_active' : 'web_like'}`}
              onClick={handleLike(address)}
            />
            <div className="table-item__token-item">
              <div>
                <AboutToken imgToken={image} />
              </div>
              <div className="token-item__detail">
                <span className="text-uppercase">{symbol}</span>
                <span className="truncate">{name}</span>
              </div>
            </div>
          </div>
        )
      }
    },
    {
      dataIndex: 'balance',
      title: t('balance'),
      width: 30,
      icon: '',
      align: 'right',
      render: (val) => {
        return <span className='balance'>{nFormatter(val, 2)}</span>
      }
    },
    {
      dataIndex: 'value',
      title: t('value'),
      width: 30,
      icon: '',
      align: 'right'
    }
  ]

  // func

  const handleLike = (address) => (e) => {
    e.stopPropagation()
    onChangeListTokenFavor(address)
  }

  const handleFavorite = (mintAddress) => {
    return listFavoriteTokenRedux.includes(mintAddress)
  }

  const handleCancel = () => {
    window.closeModalToken()
  }

  const handleFocusInput = () => {
    refInput.current.focus()
  }

  const handleChangeSearch = (e) => {
    const { value } = e.target
    setTxtSearch(value)
  }

  const onSelectToken = (val) => {
    selectToken && selectToken(val)
  }

  const onSelectFavoriteToken = (data) => {
    selectToken && selectToken(data)
  }

  return (
    <div className="list-token-liquidity">
      <div className="list-top">
        <div className="list-token-header">
          <h3 className="heading-3">{t('selectToken')}</h3>
          <div onClick={handleCancel}>
            <IconCustom icon="web_close" />
          </div>
        </div>
        <div className="list-token-liquidity__box-wrapper">
          <div className="list-token-liquidity__box-search">
            <img
              onClick={handleFocusInput}
              src={images.iconSearchGrey}
              alt=""
            />
            <input
              ref={refInput}
              onChange={handleChangeSearch}
              value={txtSearch}
              placeholder={t('searchByToken')}
            />
          </div>
        </div>
        <FavoriteToken
          isModal
          className={'favorite-modal'}
          data={dataFavorite}
          onCLickItem={onSelectFavoriteToken}
        />
      </div>
      <div className="table-token">
        <CustomTable
          onClickRow={onSelectToken}
          className={'table-token__inner'}
          columns={columnsDefault}
          dataSource={dataListTokenFinal}
          isLoading={isLoadingListToken}
        />
      </div>
    </div>
  )
}

export default ListTokenBaryon
