import images from 'assets/images'
import React, { useEffect, useState, useMemo, useRef, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import ActionsRight from 'pages/LiquidityScreen/components/ActionsRight'
import './style.scss'
import SelectToken from 'pages/LiquidityScreen/components/SelectToken'
import Button from 'components/common/Button'
import ConfirmOrder, { typeConfirmPool } from 'pages/LiquidityScreen/components/ConfirmOrder'
import { useSelector } from 'react-redux'
import { convertWeiToBalance, countDots, escapeRegExp, formatPrice, inputRegex, lowerCase, roundingNumber, scientificToDecimal, showNotificationToast, upperCase } from 'common/functions'
import { get } from 'lodash'
import { BounceLoader } from 'react-spinners'
import { ROUTER_SPONSOR_GAS, MAIN_COIN_AMM, SLIPPAGE, W_MAIN_COIN, CONTRACT_ROUTER, W_MAIN_COIN_TOMO_OLD } from 'common/poolEvm/constants'
import ReduxServices from 'common/redux'
import usePairs from 'common/poolEvm/hooks/usePairs'
import useApprove from 'common/poolEvm/hooks/useApprove'
import { toast } from 'react-toastify'

import { LiquidityContext } from 'context/liquidity/liquidityContext'
import { useWallet } from '@coin98-com/wallet-adapter-react'
import { useMainBalanceContext } from 'context/MainBalanceContext'
import { encodeMessErr } from 'controller/Library/evm'

const AddLiquidity = ({ handleSetStatus }) => {
  const liquidityContext = useContext(LiquidityContext)
  const { onSelectToken, fromCoin, toCoin, setIsRefreshLPAmount, isRefreshLPAmount, loadLiquidityAccount } = liquidityContext
  const { reloadMainBalance } = useMainBalanceContext()

  const { t } = useTranslation()
  const { address, connected } = useWallet()
  const chainActive = useSelector((state) => state.chainActive)
  const settingRef = useRef(null)

  const [fromCoinAmount, setFromCoinAmount] = useState('')
  const [toCoinAmount, setToCoinAmount] = useState('')
  const [balanceFromTo, setBalanceFromTo] = useState({ from: 0, to: 0 })
  const [slippage, setSlippage] = useState(SLIPPAGE)

  // custom hook

  const mainCoin = MAIN_COIN_AMM[chainActive]
  const wrapMainCoin = W_MAIN_COIN[chainActive]

  const isFromMain = !fromCoin?.address && (lowerCase(fromCoin?.symbol) === lowerCase(mainCoin?.symbol))
  const isToMain = !toCoin?.address && (lowerCase(toCoin?.symbol) === lowerCase(mainCoin?.symbol))

  const newAddressFromCoin = { ...fromCoin, address: isFromMain ? wrapMainCoin?.address : fromCoin.address }
  const newAddressToCoin = { ...toCoin, address: isToMain ? wrapMainCoin?.address : toCoin.address }

  const feeEst = 0.0008

  const { isLoading: isLoadingPool, poolInfo, getAmount } = usePairs(chainActive, address, newAddressFromCoin.address, newAddressToCoin.address)

  const routerAddress = ROUTER_SPONSOR_GAS[chainActive] || CONTRACT_ROUTER[chainActive]
  // const routerAddress = CONTRACT_ROUTER[chainActive]

  const { payload: approvalA, onApprove: onApproveFrom, isLoading: isLoadingApproveFrom } = useApprove(chainActive, fromCoin.address, address, routerAddress, isFromMain)
  const { payload: approvalB, onApprove: onApproveTo, isLoading: isLoadingApproveTo } = useApprove(chainActive, toCoin.address, address, routerAddress, isToMain)

  const numApproveTokenA = parseFloat(convertWeiToBalance(approvalA, fromCoin?.decimals || fromCoin?.decimal))
  const numApproveTokenB = parseFloat(convertWeiToBalance(approvalB, toCoin?.decimals || toCoin?.decimal))

  const isApproveA = numApproveTokenA !== 0 && numApproveTokenA >= (fromCoinAmount || 0)
  const isApproveB = numApproveTokenB !== 0 && numApproveTokenB >= (toCoinAmount || 0)

  const isSelectedTokenAB = (!!fromCoin?.symbol && !!toCoin?.symbol)
  const isWTOMO = isSelectedTokenAB && ((lowerCase(newAddressFromCoin?.address) === lowerCase(W_MAIN_COIN_TOMO_OLD.address)) || (lowerCase(newAddressToCoin?.address) === lowerCase(W_MAIN_COIN_TOMO_OLD.address)))

  const isPoolExist = !!poolInfo.poolAddress

  const token0address = get(poolInfo, 'token0.tokenInfo.address') || wrapMainCoin?.address

  const isToken0 = lowerCase(token0address) === lowerCase(get(newAddressFromCoin, 'address'))
  const token0Amount = isToken0 ? fromCoinAmount : toCoinAmount
  const lpTokenAmount = !isPoolExist ? 0 : (parseFloat(token0Amount) * poolInfo.totalSupplyLp) / get(poolInfo, 'token0.totalBalancePool')

  const isEmpty = useMemo(() => {
    return !Number(fromCoinAmount) || !Number(toCoinAmount)
  }, [fromCoinAmount, toCoinAmount])

  const isAmountErr = parseFloat(fromCoinAmount) > parseFloat(balanceFromTo.from) || parseFloat(toCoinAmount) > parseFloat(balanceFromTo.to)

  const shareOfPool = useMemo(() => {
    if (!isPoolExist && !isEmpty) return 100
    const percentPool = (parseFloat(lpTokenAmount) / (parseFloat(poolInfo.totalSupplyLp) + parseFloat(lpTokenAmount))) * 100

    if (percentPool > 100) return 100
    return percentPool || 0
  })

  const renderFromSymbol = useMemo(() => {
    return upperCase(get(fromCoin, 'symbol', ''))
  }, [fromCoin])

  const renderToSymbol = useMemo(() => {
    return upperCase(get(toCoin, 'symbol', ''))
  }, [toCoin])

  const renderTextButton = useMemo(() => {
    if (isAmountErr) return 'amountErr'
    if (!fromCoinAmount || !toCoinAmount) return 'enterAmount'
    return 'supply'
  }, [fromCoinAmount, toCoinAmount, isAmountErr])

  const typeLiquidity = useMemo(() => {
    if (!isPoolExist) return typeConfirmPool.createPool.value
    else return typeConfirmPool.addLiquidity.value
  }, [isPoolExist])

  // useEffect

  useEffect(() => {
    onReloadBalance()
  }, [fromCoin, toCoin, connected, address])

  useEffect(() => {
    if (isPoolExist && fromCoinAmount) {
      setToCoinAmount(getAmount(fromCoin, fromCoinAmount))
    }
  }, [isLoadingPool])

  const onReloadBalance = async () => {
    try {
      if (!connected) {
        setBalanceFromTo({ from: 0, to: 0 })
        return 0
      }
      reloadMainBalance()

      const fromAddress = get(fromCoin, 'address', '')
      const toAddress = get(toCoin, 'address', '')

      const isFromMain = fromAddress === mainCoin.address
      const isToMain = toAddress === mainCoin.address

      let balanceFrom = 0; let balanceTo = 0
      if (fromCoin?.symbol) {
        balanceFrom = await ReduxServices.fetchBalanceByChain(
          chainActive,
          { address: address },
          isFromMain ? '' : fromCoin
        )
      }

      if (toCoin?.symbol) {
        balanceTo = await ReduxServices.fetchBalanceByChain(
          chainActive,
          { address: address },
          isToMain ? '' : toCoin
        )
      }

      if (balanceFrom) {
        const fromAmount = parseFloat(
          isFromMain ? parseFloat(balanceFrom) - feeEst : balanceFrom
        )
        balanceFrom = fromAmount <= 0 ? 0 : fromAmount
        const toAmount = parseFloat(
          isToMain ? parseFloat(balanceTo) - feeEst : balanceTo
        )
        balanceTo = toAmount <= 0 ? 0 : toAmount
      }

      setBalanceFromTo({ from: balanceFrom, to: balanceTo })
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeInput = ({ isFrom, value }) => {
    if (value === '') {
      if (isFrom) {
        setFromCoinAmount('')
        isPoolExist && setToCoinAmount('')
      } else {
        setToCoinAmount('')
        isPoolExist && setFromCoinAmount('')
      }
      return
    }

    if (!inputRegex.test(escapeRegExp(value))) return

    const dotsCount = countDots(value, '\\.')
    if (dotsCount === 1) {
      const decimalCount = value.length - value.indexOf('.') - 1
      if (decimalCount > 6) return
    }

    if (isFrom) {
      setFromCoinAmount(value)

      isPoolExist && setToCoinAmount(getAmount(fromCoin, value))
    } else {
      setToCoinAmount(value)
      isPoolExist && setFromCoinAmount(getAmount(toCoin, value))
    }
  }

  const callBackFail = (mess) => {
    const stringResult = encodeMessErr(mess)
    showNotificationToast(t(stringResult), 'error', 2000)
    window.closeModal()
  }

  const handleApprove = (isFrom, token) => async () => {
    const callBack = () => {
      toast.dismiss()
      window.closeModal()
      showNotificationToast(t('approveSuccess'), 'success', 2000)
    }

    window.openModal({
      title: t('approveSymbol', { symbol: upperCase(token.symbol) }),
      type: 'confirm',
      onOk: async () => {
        isFrom ? await onApproveFrom(callBack, null, callBackFail) : await onApproveTo(callBack, null, callBackFail)
      }
    })
  }

  const handleSelectToken = ({ isFrom, token }) => {
    onReset(true)
    onSelectToken({ isFrom, token })
  }

  const onReset = (isNotLoadLiquidity) => {
    setFromCoinAmount('')
    setToCoinAmount('')
    setIsRefreshLPAmount && setIsRefreshLPAmount(!isRefreshLPAmount)
    !isNotLoadLiquidity && loadLiquidityAccount()
  }

  const handleConfirmOrder = () => {
    window.openModal({
      preventClose: true,
      isCustomModal: true,
      className: 'modal-confirmorder',
      content: (
        <ConfirmOrder
          type={typeLiquidity}
          fromCoin={fromCoin}
          toCoin={toCoin}
          fromCoinAmount={fromCoinAmount}
          toCoinAmount={toCoinAmount}
          shareOfPool={shareOfPool}
          lpTokenAmount={lpTokenAmount}
          isToken0={isToken0}
          getAmount={isPoolExist ? getAmount : getAmountNotPool}
          slippage={parseFloat(slippage)}
          callback={onReset}
          // poolAddress
        />
      )
    })
  }

  const onChangeSlippage = (slippage) => () => {
    setSlippage(slippage)
  }

  const handleSaveSetting = () => {
    if (settingRef && settingRef.current) {
      settingRef.current.handleSaveSetting()
      window.closeModal()
    }
  }

  const getAmountNotPool = (coin) => {
    const isFrom = lowerCase(get(coin, 'address')) === lowerCase(get(fromCoin, 'address'))
    const rate = parseFloat(fromCoinAmount) / parseFloat(toCoinAmount)
    return isFrom ? scientificToDecimal(roundingNumber(1 / rate, 4)) : scientificToDecimal(roundingNumber(rate, 4))
  }

  if (isWTOMO) {
    return (
      <div className="add-liquidity">
        <div className="header-right">
          <ActionsRight
            onReloadBalance={onReloadBalance}
            settingRef={settingRef}
            onChangeSlippage={onChangeSlippage}
            handleSaveSetting={handleSaveSetting}
            slippage={slippage}
          />
        </div>
        <div className='wtomo-convert'>
          <img src={images.iconWarningErr} className="modal-icon" />
          <span>{t('desConvertWtomo')}</span>
          <Button
            onClick={handleSetStatus('remove')}
          >
            OK
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="add-liquidity">
      <div className="header-right">
        <ActionsRight
          onReloadBalance={onReloadBalance}
          settingRef={settingRef}
          onChangeSlippage={onChangeSlippage}
          handleSaveSetting={handleSaveSetting}
          slippage={slippage}
        />
      </div>
      <div className="add-liquidity__content">
        <div className="content-select-token">
          <SelectToken
            valueInput={fromCoinAmount}
            isFrom
            handleChangeInput={handleChangeInput}
            handleSelectToken={handleSelectToken}
            coinSelected={fromCoin}
            coinCompare={toCoin}
            isDisable={isLoadingPool}
            balance={balanceFromTo.from}
            maxLength={'13'}
          />
        </div>
        <div className="content-icon">
          <img src={images.iconPlus} alt="" />
        </div>
        <div className="content-select-token">
          <SelectToken
            valueInput={toCoinAmount}
            handleChangeInput={handleChangeInput}
            handleSelectToken={handleSelectToken}
            coinSelected={toCoin}
            // listToken={listToken}
            coinCompare={fromCoin}
            isDisable={isLoadingPool}
            balance={balanceFromTo.to}
          />
        </div>

        {!isLoadingPool && !isPoolExist && get(fromCoin, 'symbol') && get(toCoin, 'symbol') &&
           <div className="content-create-info">
             <img src={images.iconInfoLiquidity} alt="" />
             <div className="content-create-info__title">{t('youAreTheFirst')}</div>
             <div className="content-create-info__content">{t('theRatioOf')}</div>
             <div className="content-create-info__content">{t('onceYouAre')}</div>
           </div>
        }

        {(isApproveA && isApproveB && (toCoinAmount > 0 || fromCoinAmount > 0)) && <div
          className={'content-info content-info--active'}
        >
          {/* ${ !isDisabled ? 'content-info--active' : ''} */}

          <div className="content-info__label">{t('priceAndPool')}</div>
          <div className="content-info__list">
            <div className="list-item">
              <div className="list-item__number">
                {isLoadingPool
                  ? <div className="bounce-load">
                    <BounceLoader size={16} color="#bbbbbb" />
                  </div>
                  : isPoolExist ? formatPrice(getAmount(toCoin, 1), false, true) : formatPrice(getAmountNotPool(toCoin), false, true)
                }

              </div>
              <div className="list-item__symbol">
                {renderFromSymbol} Per {renderToSymbol}
              </div>
            </div>
            <div className="list-item">
              <div className="list-item__number">
                {isLoadingPool
                  ? <div className="bounce-load">
                    <BounceLoader size={16} color="#bbbbbb" />
                  </div>
                  : isPoolExist ? formatPrice(getAmount(fromCoin, 1), false, true) : formatPrice(getAmountNotPool(fromCoin), false, true)
                }

              </div>
              <div className="list-item__symbol">
                {renderToSymbol} Per  {renderFromSymbol}
              </div>
            </div>
            <div className="list-item">
              <div className="list-item__number">
                {roundingNumber(shareOfPool, 4)} %
              </div>
              <div className="list-item__symbol">{t('shareOfPool')}</div>
            </div>
          </div>
        </div> }

      </div>

      <div className="add-liquidity__approve">
        {!isApproveA && fromCoin?.symbol && <Button
          isDisable={isAmountErr || isEmpty}
          onClick={handleApprove(true, fromCoin)}
          isFullWidth
          className="add-liquidity__actions"
          isLoading={isLoadingApproveFrom}
        >
          {t('approveSymbol', { symbol: upperCase(fromCoin.symbol) })}
        </Button>}

        {!isApproveB && toCoin?.symbol && <Button
          isDisable={isAmountErr || isEmpty}
          onClick={handleApprove(false, toCoin)}
          isFullWidth
          className="add-liquidity__actions"
          isLoading={isLoadingApproveTo}
        >
          {t('approveSymbol', { symbol: upperCase(toCoin.symbol) })}
        </Button>}

      </div>

      <Button
        isDisable={isAmountErr || isEmpty || !isApproveA || !isApproveB}
        onClick={handleConfirmOrder}
        isFullWidth
        className="add-liquidity__actions"
      >
        {t(renderTextButton)}
      </Button>
    </div>
  )
}

export default AddLiquidity
