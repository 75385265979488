import React from 'react'
import ReactNumberTicker from 'react-animate-number-ticker'
import 'react-animate-number-ticker/dist/index.css'

const CountComponent = ({ number, children, classNames }) => {
  return (
    <div className={classNames}>
      <ReactNumberTicker number={number}/>
      {children}
    </div>

  )
}

export default CountComponent
