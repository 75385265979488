import React from 'react'
import cn from 'classnames'
import images from 'assets/images'
import style from './style.module.scss'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'

const AboutToken = ({ imgToken, className, isLoading, type }) => {
  const isDarkMode = useSelector((state) => state.themeRedux) === 'dark'
  return (
    <figure className={cn(style['about-token'], className, `about-token--${type}`)}>
      {isLoading
        ? <Skeleton
          containerClassName="skeleton-circle"
          baseColor={isDarkMode ? '#242424' : '#bbb'}
          width={'100%'}/>
        : <img src={imgToken || images[isDarkMode ? 'icoUnknown' : 'icoUnknownLight']} className='img-fluid' alt=""/>}
    </figure>
  )
}

export default AboutToken
