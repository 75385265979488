import { SCAN_TX } from 'common/constants'
import {
  DEPOSIT,
  ENABLE_CONTRACT,
  HARVEST,
  SWAP,
  UNWRAP,
  WITHDRAW,
  WRAP
} from 'common/farm/contants'
import { STAKE, UN_STAKE } from 'common/stake/constants'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import './style.scss'

const PopupHash = ({ hash, type }) => {
  const chainActive = useSelector((state) => state.chainActive)
  const { t } = useTranslation()

  const renderDescription = useMemo(() => {
    switch (type) {
    case DEPOSIT:
      return 'Deposit'
    case STAKE:
      return 'staked'
    case UN_STAKE:
      return 'unstaked'
    case WITHDRAW:
      return 'Withdraw'
    case HARVEST:
      return 'Harvest'
    case ENABLE_CONTRACT:
      return 'Enable'
    case SWAP:
      return 'swapped'
    case WRAP:
      return 'Wrap'
    case UNWRAP:
      return 'Unwrap'
    default:
      return 'Transaction'
    }
  }, [type])

  const handleOpenTxsHash = () => {
    window.open(SCAN_TX[chainActive] + hash)
    window.closeModal()
  }
  return (
    <div className="submit-content">
      <p>{t('txsTypeComplete', { type: renderDescription })}</p>
      <a
        onClick={handleOpenTxsHash}
        target="_blank"
        href={`${SCAN_TX[chainActive] + hash}`}
        alt="link txs"
        rel="noreferrer"
      >
        {t('viewTxs')}
      </a>
    </div>
  )
}

export default PopupHash
