import React, { useContext, useState } from 'react'

const defaultLPInfo = {
  value: null, label: 'All Liquidity Share'
}

const defaultValue = {
  lpInfo: defaultLPInfo,
  temp: [],
  setLPInfo: () => {},
  setTemp: () => {}
}

const PoolInfoContext = React.createContext(defaultValue)

export const PoolInfoContextProvider = ({ children }) => {
  const [lpInfo, setLPInfo] = useState(defaultLPInfo)
  const [temp, setTemp] = useState([])

  return <PoolInfoContext.Provider value={{
    lpInfo,
    setLPInfo,
    temp,
    setTemp
  }}>{children}</PoolInfoContext.Provider>
}

export const usePoolInfo = () => {
  return useContext(PoolInfoContext)
}
