import React, { useEffect, useState } from 'react'
import './style.scss'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { escapeRegExp, inputRegex } from 'common/functions'

const Setting = ({ onChangeSlippage, slippage, gasLimitEst = 150000, gasSelected, onChangeGas, isShowGas, isSwap }, ref) => {
  const listSetting = [0.5, 1, 5, 10]
  const { t } = useTranslation()
  const [selected, setSelected] = useState(slippage)
  const [gasSelect, setGasSelect] = useState({})

  useEffect(() => {
    ref.current = {
      handleSaveSetting: handleSaveSetting
    }
  }, [selected, gasSelect])

  const handleGas = gasSelect => {
    setGasSelect(gasSelect)
  }

  const handleChangeSlippage = (slippage) => () => {
    const newStr = slippage.split(',').join('.')
    if (!newStr || !inputRegex.test(escapeRegExp(newStr)) || isNaN(parseFloat(newStr))) {
      return setSelected('')
    }
    if (parseFloat(newStr) >= 25) {
      return setSelected('25')
    }
    if (newStr.toString().length > 4) {
      return setSelected(parseFloat(newStr).toFixed(2))
    }
    setSelected(newStr)
  }

  const handleSaveSetting = () => {
    onChangeSlippage(selected.toString())()
    onChangeGas && onChangeGas(gasSelect)
  }

  return (
    <div className="setting-container">
      {isSwap && <h4 className="setting-container__title">
        {t('slippageTolerance')}
      </h4>}
      <div className={`setting ${isSwap && 'setting--swap'}`}>

        <div className="setting__list">
          {listSetting.map((slip) => (
            <div
              onClick={handleChangeSlippage(slip.toString())}
              key={slip}
              className={`setting-item ${
                parseFloat(selected) === slip ? 'setting-item--active' : ''
              }`}
            >
              {slip}%
            </div>
          ))}
        </div>
        <div className={`setting__input ${isSwap && 'setting__input--swap'}`}>
          <input
            value={selected}
            onChange={(event) => handleChangeSlippage(event.target.value)()}
            placeholder="1.5%"
            type="text"
          />
        </div>
      </div>

      {/* {isShowGas &&
        <>
          <h4 className="setting-container__title">
            {t('transactionSpeed')}
          </h4>

          <GasSlider onChange={handleGas} chain={chainActive} gasLimitEst={gasLimitEst} gasSelected={gasSelected} />
        </>
      } */}
    </div>
  )
}

export default React.forwardRef(Setting)
