import BigNumber from 'bignumber.js'
import { BLOCKS_PER_YEAR, CS98_PER_YEAR, secondPerYear, secondPerDay } from '../constants'
// update asdasdasda
/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerBlock Amount of new cake allocated to the pool for each new block
 * @returns Null if the APR is NaN or infinite.
 */

export const getPoolApr = (
  stakingTokenPrice,
  rewardTokenPrice,
  totalStaked,
  tokenPerBlock
) => {
  const totalRewardPricePerYear = new BigNumber(parseFloat(rewardTokenPrice))
    .times(tokenPerBlock)
    .times(BLOCKS_PER_YEAR)
  const totalStakingTokenInPool = new BigNumber(
    parseFloat(stakingTokenPrice)
  ).times(totalStaked)
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

export const getPoolAprMulti = (
  rewardPerBlock,
  stakingLpPrice,
  totalStaked,
  isNotPrice
) => {
  const totalRewardPricePerYear = new BigNumber(parseFloat(rewardPerBlock))
    .times(secondPerYear)
  const totalStakingTokenInPool = isNotPrice
    ? new BigNumber(
      parseFloat(totalStaked)
    )
    : new BigNumber(
      parseFloat(stakingLpPrice)
    ).times(totalStaked)
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

export const totalRewardPerDay = (rewardPerSecondToken) => {
  const totalRewardPricePerDay = new BigNumber(parseFloat(rewardPerSecondToken))
    .times(secondPerDay)
  return totalRewardPricePerDay.toNumber()
}

/**
 * Get farm APR value in %
 * @param poolWeight allocationPoint / totalAllocationPoint
 * @param cakePriceUsd Cake price in USD
 * @param poolLiquidityUsd Total pool liquidity in USD
 * @param farmAddress Farm Address
 * @returns Farm Apr
 */
export const getFarmApr = (
  poolWeight,
  cs98PriceUsd,
  poolLiquidityUsd,
  farmAddress
) => {
  const yearlyCS98RewardAllocation = poolWeight
    ? poolWeight.times(CS98_PER_YEAR)
    : new BigNumber(NaN)
  const cs98RewardsApr = yearlyCS98RewardAllocation
    .times(cs98PriceUsd)
    .div(poolLiquidityUsd)
    .times(100)

  let cs98RewardsAprAsNumber = null
  if (!cs98RewardsApr.isNaN() && cs98RewardsApr.isFinite()) {
    cs98RewardsAprAsNumber = cs98RewardsApr.toNumber()
  }
  // const lpRewardsApr = lpAprs[farmAddress?.toLocaleLowerCase()] ?? 0
  // return { cakeRewardsApr: cakeRewardsAprAsNumber, lpRewardsApr }
  return { cs98RewardsApr: cs98RewardsAprAsNumber }
}
