import InputSearch from 'components/InputSearch'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TableLiquidity from './TableLiquidity'
import { cloneDeep, get } from 'lodash'
import { getLength } from 'common/functions'
import { useSelector } from 'react-redux'
import BaseAdapter from 'controller/api/BaseAdapter'
import TableFarmStake from './TableFarmStake'
import PageDropdown from 'components/common/PageDropdown'
import styles from './style.module.scss'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'
import { usePoolInfo } from 'pages/PoolInfoScreen/context/PoolInfoContext'
import usePrevious from 'common/customHooks/usePrevious'

const LIST_TYPE = [
  {
    name: 'Liquidity Providers',
    value: 'liquidityProviders'
  },
  {
    name: 'Farm',
    value: 'farm'

  },
  {
    name: 'Stake',
    value: 'stake'

  }
]

const LIMIT = 10

const cachedOverviewTable = {
  farm: [],
  stake: [],
  liquidityProviders: []
}

const totalRefDefault = {
  farm: 1,
  stake: 1,
  liquidityProviders: 1
}

const sortTypeDefault = {
  farm: 'amountUSD',
  stake: 'amountUSD',
  liquidityProviders: 'amountUSD'
}

const OverviewBoard = ({ address }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const chainActive = useSelector(state => state.chainActive)

  // tabbar
  const [selectedTab, setSelectedTab] = useState('liquidityProviders')
  const [sort, setSort] = useState('amountUSD')
  const [txtSearch, setTxtSearch] = useState('')
  const { setLPInfo, temp } = usePoolInfo()

  // table
  const [isLoading, setIsLoading] = useState()
  const [dataFetched, setDataFetched] = useState([])
  const [total, setTotal] = useState()
  const [currPage, setCurrPage] = useState(1)

  const isMountedLPTable = selectedTab === 'liquidityProviders'
  const isMountedFarmStakeTable = selectedTab !== 'liquidityProviders'
  const isStake = selectedTab === 'stake'
  const FARM_STAKE_SORT = [
    {
      label: isStake ? 'tokenValue' : 'lpValue',
      value: 'amountUSD'
    },
    {
      label: 'APR',
      value: 'apr'
    }
  ]

  const LP_SORT = [
    {
      label: 'lpValue',
      value: 'amountUSD'
    },
    {
      label: 'poolShare',
      value: 'poolShare'
    }
  ]

  const overviewDataRef = useRef(cachedOverviewTable)
  const totalPageRef = useRef(totalRefDefault)
  const sortTypeRef = useRef(sortTypeDefault)
  const previousChain = usePrevious(chainActive)
  const timer = useRef()

  useEffect(() => {
    getListOverviewData()
  }, [selectedTab, address, currPage, chainActive])

  useEffect(() => {
    overviewDataRef.current = cachedOverviewTable
    totalPageRef.current = totalRefDefault
    sortTypeRef.current = sortTypeDefault
  }, [chainActive])

  const getListOverviewData = async (props) => {
    const isTriggerLoad = get(props, 'isTriggerLoad', true)
    const typeSort = get(props, 'typeSort', '')
    const page = get(props, 'page', '')

    setIsLoading(isTriggerLoad)
    const cacheDataOverview = overviewDataRef.current[selectedTab]
    const cacheTotal = totalPageRef.current[selectedTab]

    if ((previousChain === chainActive) && (getLength(cacheDataOverview) > 0 && getLength(txtSearch) === 0 && !address && currPage === 1 && !typeSort)) {
      setTotal(cacheTotal)
      setDataFetched(cacheDataOverview)
      setIsLoading(false)
      return
    }

    const bodyQuery = {
      page: page || currPage,
      size: LIMIT,
      sortType: typeSort || sort,
      chain: chainActive
    }

    if (getLength(txtSearch) > 0) {
      const isValidAddress = window.wallet.validateBlockChainAddress(txtSearch, chainActive)
      if (!isValidAddress) {
        setIsLoading(false)
        setDataFetched([])
        return
      }
      bodyQuery.address = txtSearch
    }

    if (isMountedFarmStakeTable) {
      bodyQuery.type = selectedTab
    }

    if (getLength(address) > 0) {
      bodyQuery.address = address
    }

    const response = await BaseAdapter.getData(
      isMountedLPTable
        ? 'baryon/wallet/filter/lp'
        : 'baryon/wallet/filter/farmStake'
      , bodyQuery)

    if (!response) return
    const data = response.data
    if (getLength(txtSearch) === 0 && getLength(address) === 0 && currPage === 1) {
      // only cache at page 1 and when no address search
      setOverviewDataRef(selectedTab, get(data, 'data'))
      setTotalPageRef(selectedTab, get(data, 'total'))
    }
    setTotal(get(data, 'total'))
    setDataFetched(get(data, 'data'))
    setIsLoading(false)
  }

  const getOverviewInitData = async () => {
    const bodyQuery = {
      page: 1,
      size: LIMIT,
      sortType: sort,
      type: selectedTab,
      chain: chainActive
    }
    setIsLoading(true)

    const response = await BaseAdapter.getData(
      isMountedLPTable
        ? 'baryon/wallet/filter/lp'
        : 'baryon/wallet/filter/farmStake'
      , bodyQuery)
    if (!response) return
    const data = response.data
    setOverviewDataRef(selectedTab, get(data, 'data'))
    setTotalPageRef(selectedTab, get(data, 'total'))
    setTotal(get(data, 'total'))
    setDataFetched(get(data, 'data'))
    setIsLoading(false)
  }

  const setOverviewDataRef = (tab, data) => {
    const newDataRef = cloneDeep(overviewDataRef.current)
    newDataRef[tab] = data
    overviewDataRef.current = newDataRef
  }

  const setTotalPageRef = (tab, data) => {
    const newDataRef = cloneDeep(totalPageRef.current)
    newDataRef[tab] = data
    totalPageRef.current = newDataRef
  }

  const setSortTypeRef = (tab, data) => {
    const newDataRef = cloneDeep(sortTypeRef.current)
    newDataRef[tab] = data
    sortTypeRef.current = newDataRef
  }

  const onChooseTab = (tab) => () => {
    setSelectedTab(tab)
    setCurrPage(1)
    setSort(sortTypeRef.current[tab])

    // if (tab === 'liquidityProviders') {
    //   setSort(LP_SORT[0].value)
    // } else {
    //   // setSort(FARM_STAKE_SORT[0].value)
    // }
  }

  const onChangeSort = (sort) => {
    setSort(sort.value)

    setSortTypeRef(selectedTab, sort.value)

    setOverviewDataRef(selectedTab, [])
    getListOverviewData({ typeSort: sort.value })
  }

  const handleClearSearch = () => {
    clearDataSearch()
  }

  const clearDataSearch = () => {
    const cacheData = overviewDataRef.current[selectedTab]
    const cachedTotal = totalPageRef.current[selectedTab]

    if (getLength(cacheData) === 0) {
      getOverviewInitData()
    }
    setTotal(cachedTotal)
    setDataFetched(cacheData)
    setTxtSearch('')
    setCurrPage(1)
  }

  const handleSearchInput = (e) => {
    setIsLoading(true)
    const { value } = e.target
    setTxtSearch(value)
    setCurrPage(1)

    clearTimeout(timer.current)
    if (getLength(value) === 0) {
      setIsLoading(false)
      clearDataSearch()
    } else if (getLength(value) > 0) {
      setTxtSearch(value)
      const isValidAddress = window.wallet.validateBlockChainAddress(value, chainActive)
      if (!isValidAddress) {
        setIsLoading(false)
        setDataFetched([])
        return
      }

      const bodyQuery = {
        page: 1,
        size: LIMIT,
        sortType: sort,
        address: value,
        chain: chainActive
      }

      if (isMountedFarmStakeTable) {
        bodyQuery.type = selectedTab
      }

      timer.current = setTimeout(async () => {
        const response = await BaseAdapter.getData(
          isMountedLPTable
            ? 'baryon/wallet/filter/lp'
            : 'baryon/wallet/filter/farmStake', bodyQuery)
        if (response) {
          const data = response.data
          setDataFetched(data.data)
          setIsLoading(false)
          setTotal(data.total)
        } else {
          setIsLoading(false)
        }
      }, 500)
    }
  }

  const handleSetStatus = (value) => () => {
    setSelectedTab(value)
  }

  const handleChangePage = (val) => {
    setCurrPage(val)
    if (val === 1) {
      getListOverviewData({ page: 1 })
    } /// page 1
  }

  const onClickRow = (row) => {
    setCurrPage(1)
    if (address) return
    const rowAddress = get(row, 'account')
    history.push(`/wallet/${rowAddress}`)

    // onNavigateToDetailWallet(row)
  }

  const onClickLPRow = (rowLP) => {
    // onNavigateToDetailWallet(rowLP)
    setCurrPage(1)

    // Change chart
    const poolAddress = get(rowLP, 'poolAddress')
    // find value
    const findValue = temp.find(it => {
      return it.value === poolAddress
    })
    findValue && setLPInfo(findValue)

    if (address) {
      window.scrollTo(0, 0)
      return
    }
    const rowAddress = get(rowLP, 'account')
    history.push(`/wallet/${rowAddress}`)

    // const token0 = get(rowLP, 'token0Amount.tokenInfo', {})
    // const token1 = get(rowLP, 'token1Amount.tokenInfo', {})
    // setLPContextInfo({
    //   value: get(rowLP, 'poolAddress'),
    //   label: (
    //     <div className='d-flex align-items-center uppercase'>
    //       <TokenImage src={get(token0, 'icon')} alt="" className="token-img token-img--token-1 img-24 mr-1" />
    //       <TokenImage src={get(token1, 'icon')} alt="" className="token-img token-img--token-2 img-24 mr-2" />
    //       <span>{get(token0, 'symbol')} / { get(token1, 'symbol')}</span>
    //     </div>
    //   )
    // })
  }

  const DROPDOWN_ARR = isMountedLPTable ? LP_SORT : FARM_STAKE_SORT
  const defItem = DROPDOWN_ARR.find(item => item.value === sort)

  const renderStatus = () => {
    return LIST_TYPE.map((item, index) => {
      const isActive = item.value === selectedTab
      return (
        <li
          key={index}
          className={isActive ? 'active' : ''}
          onClick={onChooseTab(item.value)}
        >
          <div className="nav-item lx-medium">
            <span>{t(item.name)}</span>
          </div>
        </li>
      )
    })
  }

  return (
    <div className={cn(styles.walletTable, 'wallet-table') }>
      <div className="wallet-table--header">
        <div className="header-left">
          <ul>{renderStatus()}</ul>
        </div>
        <div className="header-right">
          { getLength(address) === 0 && <div className="header-right__input">
            <InputSearch
              handleClear={handleClearSearch}

              placeHolder={t('searchByAddress')}
              initValue={txtSearch}
              handleChange={handleSearchInput}
            />
          </div>}
          <div className="header-right__select">
            <PageDropdown
              className="right-toggle-down"
              toggleclassName="wallet-toggle"
              menuClassName="right-menu-down"
              options={DROPDOWN_ARR}
              onChange={onChangeSort}
              defaultValue={defItem}
              translate
            />

          </div>
        </div>
      </div>
      <div className="wallet-table--content">
        <div className="content-table">
          {
            selectedTab === 'liquidityProviders' &&
            <TableLiquidity
              isLoading={isLoading}
              currPage={currPage}
              totalPage={total}
              tableData={dataFetched}
              onChangePage={handleChangePage}
              onClickRow={onClickLPRow}
            />
          }
          {selectedTab === 'farm' &&
            <TableFarmStake
              isLoading={isLoading}
              currPage={currPage}
              totalPage={total}
              tableData={dataFetched}
              onChangePage={handleChangePage}
              onClickRow={onClickRow}
              isStake={false}
            />
          }
          {selectedTab === 'stake' &&
            <TableFarmStake
              isLoading={isLoading}
              currPage={currPage}
              totalPage={total}
              tableData={dataFetched}
              onChangePage={handleChangePage}
              onClickRow={onClickRow}
              isStake={true}
            />
          }
        </div>
      </div>

    </div>
  )
}

export default OverviewBoard
