import React from 'react'
import cn from 'classnames'
import style from './style.module.scss'
import { Col, Row } from 'antd'
import { Link } from 'react-router-dom'
import AboutToken from 'components/common/AboutToken'
import StatusItem from 'components/common/StatusItem'
import { formatBilion, formatNumberBro } from 'common/functions'
import { get } from 'lodash'
import ReduxServices from 'common/redux'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { getTokeInfoPool } from 'common/poolEvm/function'
import { useSelector } from 'react-redux'

const dataPopular = [{
  symbol: 'C98',
  name: 'Coin98',
  value: '837473838',
  symbol2: 'BNB',
  total: '10.11'
},
{
  symbol: 'C98',
  name: 'Coin98',
  value: '837473838',
  symbol2: 'BNB',
  total: '10.11'
},
{
  symbol: 'C98',
  name: 'Coin98',
  value: '837473838',
  symbol2: 'BNB',
  total: '10.11'
}]

const ContainerShow = ({ isLoading, title, isPool, isGainer, className, data = dataPopular }) => {
  const { t } = useTranslation()
  const chainActive = useSelector((state) => state.chainActive)
  const renderRightCol = (item = {}) => {
    if (title === 'topVolume24h') {
      return ReduxServices.formatPrice(item.volume24h)
    }
    if (title === 'trending') {
      return ReduxServices.formatPrice(item.volume24h)
    }
    if (title === 'recentlyAdded') {
      return ReduxServices.formatPrice(item.volume24h)
    }
    if (title === 'gainer') {
      const isPositive = parseFloat(item.price_change_percentage_24h) > 0
      return (
        <span className={isPositive ? 'color-success' : 'color-danger'}>
          {isPositive ? '+' : ''}{formatNumberBro({ number: item.price_change_percentage_24h, mantissa: 2 })}%
        </span>
      )
    }
    return ReduxServices.formatPrice(item.liquidity)
  }

  const renderContent = () => {
    if (isLoading) {
      return (
        <>
          <Skeleton className="skeleton-main-image" />
          <Skeleton className="skeleton-main-image" />
          <Skeleton className="skeleton-main-image" />
        </>
      )
    }
    return (
      <ul>
        {data.map((item, index) => {
          const token0 = getTokeInfoPool(get(item, 'info.token0', {}), chainActive)
          const token1 = getTokeInfoPool(get(item, 'info.token1', {}), chainActive)
          return (
            <li key={index}>
              <div className='detail-inner'>
                <Link to={isPool ? `/pool/${get(item, 'poolAddress')}` : `/token/${get(item, 'address')}`} className='detail-inner--left'>
                  {isPool
                    ? <>
                      <div>
                        <AboutToken imgToken={get(token0, 'image') || get(token0, 'logoURI')} type={'default'}/>
                      </div>
                      <div>
                        <AboutToken imgToken={get(token1, 'image') || get(token1, 'logoURI') } type={'default'}/>
                      </div>
                      <span className='text-uppercase'>
                        {get(token0, 'symbol')} / {get(token1, 'symbol')}
                      </span>
                    </>
                    : <>
                      <div>
                        <AboutToken imgToken={get(item, 'info.image') || get(item, 'info.logoURI') } type={'default'}/>
                      </div>
                      <span>
                        {get(item, 'info.name') }
                      </span>
                      <span className='text-content text-uppercase'>
                        {get(item, 'info.symbol') }
                      </span>
                    </>}
                </Link>
                <div className='detail-inner--right'>
                  {renderRightCol(item)}
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    )
  }
  return (
    <div className={cn(style['show-item'], className)}>
      <h4>
        {t(title)}
      </h4>
      <div className='show-item--detail'>
        {renderContent()}
      </div>
    </div>
  )
}

export default ContainerShow
