import BigNumber from 'bignumber.js'

export const DEPOSIT = 'DEPOSIT'
export const WITHDRAW = 'WITHDRAW'
export const HARVEST = 'HARVEST'
export const ENABLE_CONTRACT = 'ENABLE_CONTRACT'
export const SWAP = 'SWAP'
export const WRAP = 'WRAP'
export const UNWRAP = 'UNWRAP'

export const BIG_ZERO = new BigNumber(0)
export const BIG_ONE = new BigNumber(1)
export const BIG_NINE = new BigNumber(9)
export const BIG_TEN = new BigNumber(10)
