import images from 'assets/images'
import InputSearch from 'components/InputSearch'
import SwitchButton from 'components/SwitchButton/SwitchButton'
import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ProcessCircle from 'components/common/ProcessCircle'
import cn from 'classnames'
// import { useSelector } from 'react-redux'
// import { arrSort } from '..'

import './style.scss'
import IconCustom from 'components/common/IconCustom'
import Tooltip from 'components/Tooltip'
import { SORT_APR, SORT_LIQUIDITY } from '..'

function MenuBar ({
  isActive,
  setIsActive,
  setIsStakedOnly,
  isStakedOnly,
  txtSearch,
  setTxtSearch,
  totalCompleted,
  totalActive,
  isFarm,
  numStakedCompleted = 0,
  handleSort,
  isSortHightToLow,
  typeSort,
  loadInitialTotal

}) {
  const { t } = useTranslation()
  // const isDarkMode = useSelector(state => state.themeRedux) === 'dark'

  const [widthResize, setWidthResize] = useState(window.innerWidth)
  const [isOpenSearchMobile, setIsOpenSearchMobile] = useState(false)

  const [itemHover, setItemHover] = useState(true)

  const isShortLiquidity = typeSort === SORT_LIQUIDITY
  const countTimeReload = null

  // const [isOpenDropdown, setIsOpenDropdown] = useState(false)

  const handleChooseItem = (isActive) => () => {
    setIsActive(isActive)
  }

  const onReload = () => {
    loadInitialTotal(true)
  }

  const handleChoose = () => {
    setIsStakedOnly(!isStakedOnly)
  }
  const handleChooseComplet = () => {
    setIsStakedOnly(true)
    setItemHover(!itemHover)
  }
  const handleHover = (status) => () => {
    setItemHover(status)
  }

  const handleClear = () => {
    setTxtSearch('')
    setIsOpenSearchMobile(false)
  }

  const handleChange = (e) => {
    const { value } = e.target
    setTxtSearch(value)
  }

  const handleOpenSearch = (status) => () => {
    setIsOpenSearchMobile(status)
  }

  const handleResize = () => {
    setWidthResize(window.innerWidth)
  }

  // const handleHover = (status) => () => {
  //   setIsOpenDropdown(status)
  // }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [])

  // useEffect(() => {
  //   getLength(txtSearch) > 0 && setIsOpenSearchMobile(false)
  // }, [txtSearch])

  return (
    <div className="menu-bar-farm-multi">
      {!isOpenSearchMobile && (
        <div className="menu-bar-farm-multi--left">
          <div className="status-component">
            <div
              className={`status-component__item  ${
                isActive && 'status-component__item--active'
              }`}
              onClick={handleChooseItem(true)}
            >
              {t('active')}
              <span
                className={`status-component__item--total ${
                  isActive && 'total-active'
                }`}
              >
                {` (${totalActive})`}
              </span>
            </div>
            <div
              className={`status-component__item ${
                !isActive && 'status-component__item--active'
              }`}
              onClick={handleChooseItem(false)}
              onMouseEnter={handleHover(true)}
            >
              {t('completed')}
              <span
                className={`status-component__item--total ${
                  !isActive && 'total-active'
                }`}
              >{` (${totalCompleted})`}</span>
              {numStakedCompleted > 0 && (
                <div className="staked-completed">
                  <span className="icon-web_info"></span>
                  {itemHover && (
                    <div
                      className={cn(
                        'staked-completed__hint',
                        !isStakedOnly && 'hint-active'
                      )}
                      onClick={handleChooseComplet}
                    >
                      {t('youHave')}{' '}
                      <span>
                        {numStakedCompleted} {t('unstakedPools')}.
                      </span>{' '}
                      {t('unstakeNow')}{' '}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="switch-btn">
            <SwitchButton isToggle={isStakedOnly} onToggle={handleChoose} />
            {t('stakeOnly')}
          </div>
        </div>
      )}

      <div className="menu-bar-farm-multi--right">
        <div className='d-flex align-items-center right-bla__short'>
          <span>
            {t('sort')}:
          </span>
          <button className={isShortLiquidity ? 'button-active' : ''} onClick={handleSort(SORT_LIQUIDITY)}>
            <span>
              {t('liquidity')}
            </span>
            <IconCustom className={cn(!isSortHightToLow && 'icon-active')} type={'default'} icon='web_arrow_up'/>
          </button>
                  |
          <button className={!isShortLiquidity ? 'button-active' : ''} onClick={handleSort(SORT_APR)}>
            <span>
              {t('apr')}
            </span>
            <IconCustom className={cn(!isSortHightToLow && 'icon-active')} type={'default'} icon='web_arrow_up'/>
          </button>
        </div>
        <InputSearch
          // placeHolder={t(`${isFarm ? 'AURA - BUSD' : 'searchHere'}`)}
          placeHolder={t(`${'searchHere'}`)}
          handleChange={handleChange}
          initValue={txtSearch}
          handleClear={handleClear}
        />
        <div>
          <Tooltip content={t('dataWillAuto')} className='pieloading-tooltip'>
            <ProcessCircle
              radius={14}
              stroke={2}
              onReload={onReload}
              onResetCountDown={onReload}
              countTimeReload={countTimeReload}
            />
          </Tooltip>
        </div>
      </div>

      {/* {widthResize >= 768
        ? <div className="menu-bar-farm-multi--right"> */}
      {/* <div
            className="dropdown-farm"
            onMouseEnter={handleHover(true)}
            onMouseLeave={handleHover(false)}
          >
            <div className="dropdown-farm__title">
              <div className="title-text">{t(typeSortStake)}</div>

              <img src={images[isDarkMode ? 'iconDownDark' : 'iconDown']} alt="" className="title-icon" />
            </div>

            <div className={`dropdown-farm__list-items-farm ${isOpenDropdown && 'dropdown-farm__list-items-farm--open'}`}>
              <div className="list-items-content">

                {arrSort.map((item, index) => {
                  return (
                    <div key={index} className="list-items-content__item" onClick={handleSort(item)}>
                      {t(item)}
                    </div>
                  )
                })}

              </div>
            </div>

          </div> */}

      {/* <InputSearch
            placeHolder={t('searchHere')}
            handleChange={handleChange}
            initValue={txtSearch}
            handleClear={handleClear}
          />
        </div>
        : <>
          {!isOpenSearchMobile && <img src={images.iconSearchGrey} alt="" className='icon-search-mobile' onClick={handleOpenSearch(true)} />}
          {isOpenSearchMobile && <div className="menu-bar-farm-multi--search-mobile">
            <InputSearch
              placeHolder={t('searchHere')}
              handleChange={handleChange}
              initValue={txtSearch}
              handleClear={handleClear}
              setIsOpenSearchMobile={setIsOpenSearchMobile}
              widthResize={widthResize}
              isFarmStake
            />
          </div>}
        </>
      } */}
    </div>
  )
}

export default MenuBar
