import BaseAPI from 'controller/api/BaseAPI'
import { KEY_STORE } from 'common/constants/keystore'
import {
  convertWeiToBalance,
  formatBilion,
  formatNumberBro,
  getItemStorage,
  getLength,
  lowerCase,
  nFormatter,
  removeItemStorage,
  scientificToDecimal,
  setItemStorage,
  showNotificationToast
} from './functions'
import Web3Services from 'controller/api/web3'
import { DeviceUUID } from 'device-uuid'
import { store } from 'controller/redux/store/configureStore'
import StoreActions from 'controller/redux/actions/storeActions'
import { chainType, CURRENCY_SYMBOL, CHAIN_DATA, isDapp } from './constants'
import initState from 'controller/redux/lib/initState'
import { CONTRACT_FARM_STAKE_V1, CONTRACT_FARM_STAKE_V2, selectDefaultChain } from 'common/poolEvm/constants'
import get from 'lodash/get'
import BaseAdapter from 'controller/api/BaseAdapter'
import { getPrice } from './poolEvm/function'
import { genContract, genWeb3 } from 'controller/Library/evm'
import abiFarmV2 from './abi/abiFarmV2'

const ethUtil = require('ethereumjs-util')
const ethSigUtils = require('eth-sig-util')

export default class ReduxServices {
  static async resetRedux () {
    const storageRedux = [
      { action: StoreActions.setConnected, init: initState.boolFalse }
    ]
    storageRedux.forEach((itm) => {
      this.callDispatchAction(itm.action(itm.init))
    })
    removeItemStorage(KEY_STORE.PASSWORD_LOCK)
  }

  static async refreshAllData () {
    await this.refreshConfig()
    // await this.generateSpamToken()
  }

  static getConfigRedux (key) {
    try {
      const { settingRedux } = store.getState()

      if (settingRedux) {
        return settingRedux[key]
      }
    } catch (error) {
      return null
    }
  }

  static getReduxState (field) {
    return store.getState()[field]
  }

  static genGasStandard () {
    const settingRedux = this.getReduxState('settingRedux')
    return settingRedux.standardGwei
  }

  static findChainId = (chainId) => {
    const arrChain = Object.values(CHAIN_DATA)
    const objChain = arrChain.find(item => item.chainId === chainId)
    return objChain
  }

  static async fetchDataAccountDapp (t) {
    // const objChain = this.findChainId(window.coin98?.provider?.chainId)
    // if (objChain && objChain.chain === selectDefaultChain) {
    //   const response = await window.coin98?.provider?.request({
    //     method: 'eth_accounts'
    //   })

    //   if (
    //     response && response !== 'noConnectWallet'
    //   ) {
    //     this.callDispatchAction(StoreActions.setConnected(true))
    //     return { address: response[0] }
    //   }
    // } else {
    //   showNotificationToast(t('pleaseSwitchChain'), 'error', 2000)
    // }

    // this.callDispatchAction(StoreActions.setConnected(false))
    // return null
  }

  static async generateSpamToken () {
    const spamToken = getItemStorage(KEY_STORE.SPAM_TOKEN)
    if (spamToken) return spamToken
    if (!spamToken) {
      const result = await BaseAPI.getData('configure')
      if (result) {
        const convert = Web3Services.convertAsciiToString(result)
        const spamToken = await Web3Services.generateSpamToken(convert, 948202)
        setItemStorage(spamToken.toLowerCase(), KEY_STORE.SPAM_TOKEN)
        return spamToken
      }
      return null
    }
  }

  static async getAllSolanaToken () {
    const response = await BaseAPI.getData('solanaTokenV2')
    if (response) {
      setItemStorage(response, 'TOKEN_SOLANA')
      return response
    }
    return null
  }

  static async createTokenWithWallet (walletAddress) {
    const JWT_TOKEN_ADAPTER = getItemStorage(KEY_STORE.JWT_TOKEN_ADAPTER)
    if ((get(JWT_TOKEN_ADAPTER, 'walletAddress') !== walletAddress) || !JWT_TOKEN_ADAPTER?.token) {
      const deviceID = new DeviceUUID().get()
      const response = await BaseAdapter.postData('user/device', {
        device: walletAddress || deviceID
      })

      if (response) {
        const code = get(response, 'data.code')
        setItemStorage({ token: code, walletAddress }, KEY_STORE.JWT_TOKEN_ADAPTER)
        setItemStorage(get(response, 'data.challenge'), KEY_STORE.JWT_TOKEN)
        return code
      }
    }
  }

  static convertToFiat (number, decimals = 2, isBilion, isNoCurrency = false) {
    const currSymbol = CURRENCY_SYMBOL.USD
    let numberFiat = number

    if (isBilion) {
      numberFiat = formatBilion(number, 0)
    } else {
      numberFiat = formatNumberBro({ number: numberFiat, mantissa: decimals })
    }
    if (isNoCurrency) return numberFiat

    return currSymbol + numberFiat
  }

  static async refreshConfig () {
    const response = await BaseAPI.postData('config/typeV2', {
      setting: ['dappListV6', 'balancesLoad', 'standardGwei', 'addressRefSol', 'baryonAdmin']
    })
    if (response) {
      this.callDispatchAction(StoreActions.setConfigRedux(response))
    }
  }

  static async getOnesignalId () {
    if (window.OneSignal) {
      const userId = await window.OneSignal.getUserId()
      return userId
    } else {
      return null
    }
  }

  static async refreshNotification () {
    try {
      const webDeviceId = await this.getOnesignalId()

      await BaseAPI.postData('device', { id: webDeviceId.userId })
    } catch (e) {
      console.log('Notification error')
    }
  }

  static async callDispatchAction (action) {
    store.dispatch(action)
  }

  static async fetchBalanceByChain (chain, wallet, selectedToken, bnbId) {
    if (!wallet || (wallet && getLength(Object.keys(wallet)) === 0)) {
      return 0
    }

    let balance = 0
    if (selectedToken && getLength(selectedToken.address) > 0 && chain !== chainType.terra) {
      balance = await window.wallet.fetchTokenBalance(selectedToken.address,
        selectedToken.walletAddress || wallet.address, selectedToken.decimal, chain, selectedToken.baseAddress, wallet.chainCustom)
    } else {
      balance = await window.wallet.fetchMainBalance(wallet.address, chain, bnbId, selectedToken ? selectedToken.denom : (chain === chainType.terra ? 'uluna' : ''), false, wallet)
    }

    let realBalance = 0
    if (balance) {
      if (chain === chainType.binance) {
        if (balance.free) {
          realBalance = balance
        }
        if (balance[0] && balance[0].symbol) {
          realBalance = balance[0]
        }
      } else {
        realBalance = scientificToDecimal(balance)
      }
    }

    return realBalance
  }

  static installWallet (t) {
    window.openModal({
      title: t('coin98NotInstall'),
      content: t('installCoin98'),
      type: 'warning',
      onOk: async () => {
        window.open(
          'https://chrome.google.com/webstore/detail/coin98-wallet/aeachknmefphepccionboohckonoeemg'
        )
        window.closeModal()
      }
    })
  }

  static syncWallet = async () => {
    // const walletList = await window.coin98?.provider?.request({ method: 'sync_wallet' })
    // const typePassword = await window.coin98?.provider?.request({ method: 'get_type_password' })
    // const session = await window.coin98?.provider?.request({ method: 'get_session' })

    // ReduxServices.handleSetDataWallet(walletList)
    // this.callDispatchAction(StoreActions.setSession(session))
    // setItemStorage(typePassword, KEY_STORE.TYPE_PASSWORD)
  }

  static handleSetDataWallet = (walletList) => {
    // const storeState = store.getState()
    // let newWalletActive = walletList.find(item =>
    //   (item.chain === selectDefaultChain && item.isActive))
    // const listWalletAddress = uniqBy(walletList, (item) => {
    //   return `${item.address}`
    // })

    // if (!newWalletActive) {
    //   newWalletActive = walletList.find(item => item.chain === selectDefaultChain)
    // }

    // this.callDispatchAction(StoreActions.setWalletActive(newWalletActive || {}))
    // this.callDispatchAction(StoreActions.setChainActive(get(newWalletActive, 'chain', selectDefaultChain)))
  }

  static loadInitial = async () => {
    // const isConnectFirst = this.getReduxState('isConnectFirst')
    // const isCoin98 = get(window, 'coin98.provider.isCoin98', false)
    // if (isCoin98) {
    //   const uuid = new DeviceUUID().get()
    //   // check connect first
    //   if (!isConnectFirst) {
    //     await BaseAPI.postData('connect', { id: uuid })
    //   }
    //   // connect_coin98
    //   try {
    //     const resConnect = await window.coin98?.provider.request({
    //       method: 'connect_coin98',
    //       params: { txtConnect: 'autoConnect', uuid }
    //     })

    //     if (resConnect && resConnect !== 'noConnectWallet') {
    //       this.callDispatchAction(StoreActions.setDeviceId(resConnect))
    //       this.callDispatchAction(StoreActions.setIsConnectFirst(true))
    //       this.callDispatchAction(StoreActions.setConnected(true))
    //       ReduxServices.syncWallet()
    //     } else {
    //       this.callDispatchAction(StoreActions.setWalletActive({}))
    //       ReduxServices.resetRedux()
    //     }
    //   } catch (e) {
    //     ReduxServices.resetRedux()
    //     this.callDispatchAction(StoreActions.setWalletActive({}))
    //   }
    // } else {
    //   ReduxServices.resetRedux()
    //   this.callDispatchAction(StoreActions.setWalletActive({}))
    // }
  }

  static loadInitialMobile = async (t) => {
    // const isCoin98 = get(window, 'coin98.provider.isCoin98', false)
    // if (isCoin98) {
    //   await ReduxServices.fetchDataAccountDapp(t)
    // } else {
    //   ReduxServices.resetRedux()
    //   ReduxServices.installWallet(t)
    // }
  }

  static handleConnect = (t) => () => {
    const isCoin98 = get(window, 'coin98.provider.isCoin98', false)

    if (!isCoin98) {
      ReduxServices.installWallet(t)
    } else {
      if (isDapp) {
        ReduxServices.loadInitialMobile(t)
      } else {
        ReduxServices.loadInitial()
      }
    }
  }

  static loadSig = async (chainActive, privateKey) => {
    try {
      const { walletActive } = store.getState()

      let newPrivateKey = privateKey

      const msg = 'Store Multi-chain Cryptoassets'
      const uuid = new DeviceUUID().get() // id của máy
      const deviceId = ReduxServices.getReduxState('deviceId') // id ex trả về

      if (!newPrivateKey) {
        newPrivateKey = walletActive?.privateKey
      }

      const decryptedPrivateKey = await window.ethereum.request({ method: 'aes_decrypt_coin98', params: { data: newPrivateKey, uuid, deviceId } })
      if (!decryptedPrivateKey) {
        // setItemStorage('', KEY_STORE.SIGNATURE)
        ReduxServices.callDispatchAction(StoreActions.setSignature(''))
        throw new Error('Validate user fail, please login again')
      }
      const message = msg.startsWith('0x') ? ethUtil.toBuffer(msg) : Buffer.from(msg, 'utf-8')
      const msgHash = ethUtil.hashPersonalMessage(message)
      let sig = ethUtil.ecsign(msgHash, Buffer.from(decryptedPrivateKey.replace('0x', ''), 'hex'))

      sig = ethUtil.bufferToHex(ethSigUtils.concatSig(sig.v, sig.r, sig.s))

      ReduxServices.callDispatchAction(StoreActions.setSignature(sig))
      return sig
    } catch (error) {
      ReduxServices.callDispatchAction(StoreActions.setSignature(''))
      return false
    }
  }

  static loadYourLp = async ({ poolAddress, addressWallet, priceLp, callBack, chainActive }) => {
    const res = await BaseAPI.getData('baryon/farm', { chain: chainActive })
    const filterPool = get(res, 'data', []).filter(item => lowerCase(item.stakedLpAddress) === lowerCase(poolAddress))

    const newArrTotal = await Promise.all(filterPool.map(async (item, index) => {
      const rewardTokenAddress = get(item, 'rewardTokens', [])
      const rewardTokens = await Promise.all(rewardTokenAddress.map(async (itemTokenAddress, index) => {
        const token = await window.wallet.fetchInfoCoin(chainActive, itemTokenAddress)
        const priceCoinEarn = await getPrice(token, chainActive)
        const rateReward = item.rewardMultipliers[index]
        return { ...token, price: priceCoinEarn, rateReward }
      }))

      const decimal = get(rewardTokens[0], 'decimal') || (get(rewardTokens[0], 'decimals', 18))
      const isDecimal = parseInt(decimal) === 18
      const isV2 = get(item, 'isV2')

      let contractAddress = CONTRACT_FARM_STAKE_V1[chainActive]

      if (isV2) {
        contractAddress = get(item, 'factoryAddress') || CONTRACT_FARM_STAKE_V2[chainActive]
      }
      const web3 = genWeb3(chainActive)
      const contractFinal = genContract(web3, abiFarmV2, contractAddress)

      let pendingReward = 0
      let balanceStaked = 0

      if (addressWallet) {
        const newPendingReward = await contractFinal.methods.pendingReward(item.pid, addressWallet).call()
        pendingReward = isV2 ? convertWeiToBalance(convertWeiToBalance(newPendingReward, 18), decimal) : (isDecimal ? convertWeiToBalance(newPendingReward, 12) : newPendingReward)
        const resInfoStaked = await contractFinal.methods.userInfo(item.pid, addressWallet).call()
        balanceStaked = convertWeiToBalance(resInfoStaked.amount)
      }

      // arr token reward
      const newRewardTokens = rewardTokens.map(token => {
        const amountTokenReward = isV2 ? pendingReward : pendingReward * token.rateReward
        const rewardUSD = amountTokenReward * token.price

        return {
          ...token,
          amountTokenReward,
          rewardUSD
        }
      })

      const arrRewardUSD = newRewardTokens.map(token => token.rewardUSD)
      const totalRewardUSD = arrRewardUSD.reduce((accumulator, item) => parseFloat(accumulator) + parseFloat(item))
      const timestampNow = Math.floor(new Date().getTime() / 1000)
      const isCompleted = timestampNow >= parseInt(item.rewardsExpiration)

      return {
        contractAddress,
        isCompleted,
        balanceStaked,
        totalRewardUSD: totalRewardUSD,
        balanceStakedUSD: balanceStaked * priceLp
      }
    }))

    return callBack ? callBack(newArrTotal || []) : newArrTotal
  }

  static formatPrice = (
    price,
    isLarge,
    mantissa
  ) => {
    const absPrice = Math.abs(price)

    // const currency = getItemStorage('CURRENCY')

    // const currencyRedux = useSelector(state => state.currencyRedux)
    const storeState = store.getState()
    const currencyRedux = get(storeState, 'currencyRedux')

    const currencyIcon = CURRENCY_SYMBOL[get(currencyRedux, 'label', 'USD')]
    const currencyRate = get(currencyRedux, 'value', 1)

    const isBTC = currencyIcon === 'BTC'
    const isVND = get(currencyRedux, 'label') === 'VND'

    let decimal = 2

    if (absPrice < 0.0001) {
      decimal = 8
    }

    if (absPrice < 0.01 && absPrice >= 0.0001) {
      decimal = 6
    }

    if (absPrice >= 0.01 && absPrice < 1) {
      decimal = 4
    }

    if (absPrice >= 1) {
      decimal = 2
    }

    if (isBTC) {
      decimal = 8
    }

    if (isLarge) {
      decimal = 2
    }

    if (price === 0) {
      decimal = 0
    }

    if (mantissa !== undefined) {
      decimal = mantissa
    }

    if (isVND) {
      decimal = 0
    }

    const threshholdPrice = isLarge ? 1000 : 10000000000

    const renderPrice = Math.abs(price * currencyRate) > threshholdPrice
      ? nFormatter(Math.abs(price * currencyRate), decimal)
      : formatNumberBro({
        number: Math.abs(price * currencyRate),
        mantissa: decimal,
        trimMantissa: true
      })

    return isVND
      ? renderPrice + currencyIcon
      : currencyIcon + renderPrice
  }
}
