import images from 'assets/images'
import { formatNumberBro, formatPrice } from 'common/functions'
import IconCustom from 'components/common/IconCustom'
import React from 'react'
import cn from 'classnames'
import './style.scss'
import ReduxServices from 'common/redux'

const PoolInfoItem = ({
  title = 'Total Tokens Locked',
  number = 0,
  numberChange,
  isTransaction,
  className,
  iconHead = 'web_repeat-circle'
}) => {
  const renderNumberChange = () => {
    if (!numberChange) return null
    return numberChange > 0
      ? (
        <div className="pool-info-item__grow pool-info-item__grow--up">
          {formatNumberBro({ number: numberChange, mantissa: 2 })}%{' '}
          <img src={images.iconGrowUp} alt="" />
        </div>
      )
      : (
        <div className="pool-info-item__grow pool-info-item__grow--down">
          {formatNumberBro({ number: numberChange, mantissa: 2 })}%{' '}
          <img src={images.iconGrowDown} alt="" />
        </div>
      )
  }
  return (
    <div className={cn('pool-info-item d-flex justify-content-between flex-column rounded-4', className)}>
      <IconCustom type={'default'} className='pool-item__icon' icon={iconHead}/>
      <div className='mt-4'>
        <span className="pool-item__heading lx-medium">{title}</span>
        <div className="pool-item__value lx-medium">{isTransaction ? number : ReduxServices.formatPrice(number, true)}</div>
        {renderNumberChange()}
      </div>
    </div>
  )
}

export default PoolInfoItem
