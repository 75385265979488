import images from 'assets/images'
import Button from 'components/common/Button'
import { get } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import './style.scss'

function ItemNft ({ item, handelOpenDetail }) {
  const { t } = useTranslation()

  return (
    <div className='item-nft' onClick={handelOpenDetail(item)}>
      <div className="item-nft__img-item">
        {/* <img src={item.img} alt="" className='item-image' /> */}
        <div className="bg-image" style={{ backgroundImage: `url(${get(item, 'img')})` }} />

        <Button
          className='btn-sale'
        >
          {t('saleOnDagora')}
        </Button>
      </div>

      <div className="item-nft__item-info">
        <div className="item-info-text">
          <div className="item-info-text__name">
            Dagora - Foxxy
          </div>

          {item.isProfile
            ? <div className="item-info-text__position-item">
              <img src={images.webCam} alt="" className="icon-status" />

              <div className="text-position">
                Profile
              </div>
            </div>
            : <div className="item-info-text__position-item">
              <img src={images.webWallet} alt="" className="icon-status" />

              <div className="text-position">
              Wallet
              </div>
            </div>
          }
        </div>

        <div className="item-info-text item-info-text--text-name">
          <div className="info-name">
            {item.name}
          </div>
        </div>

        <div className="item-info-text">
          <div className="info-price-title">
            Lowest Price
          </div>

          <div className="info-price-number">
            <img src={images.iconC98} alt="" className="icon-price" />

            <div className="price-number">
              {item.price}
            </div>
          </div>
        </div>
      </div>

      <div className="item-nft__action">
        <Button
          className='btn-sale-mobile'
        >
          {t('saleOnDagora')}
        </Button>
      </div>
    </div>
  )
}

export default ItemNft
