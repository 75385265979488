import { Select, Tooltip } from 'antd'
import images from 'assets/images'
import AboutToken from 'components/common/AboutToken'
import PageDropdown from 'components/common/PageDropdown'
import BaseAdapter from 'controller/api/BaseAdapter'
import i18n from 'i18n'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import style from './style.module.scss'
import cn from 'classnames'
import IconCustom from 'components/common/IconCustom'
import { upperCase } from 'lodash'
import useScreenWidth from 'common/customHooks/useScreenWidth'

const DropdownLang = () => {
  const { t } = useTranslation()

  const langRedux = useSelector(state => state.langRedux)
  const [isShowTooltip, setIsShowTooltip] = useState(false)
  const [selectedLang, setSelectedLang] = useState({ value: langRedux, label: upperCase(langRedux) })
  const [isChangingLang, setIsChangingLang] = useState()

  const { width } = useScreenWidth()
  const isLargeScreen = width > 1440

  const LIST_LANG = [
    {
      label: 'EN',
      value: 'en',
      render: (<div className='d-flex align-items-center'>
        <div>
          <AboutToken imgToken={`${window.location.origin}/Flag/en.svg`} className="token-img token-img--token-1 img-24 mr-3"/>
        </div>
        <div className='currency-info'>
          <div className='d-flex align-items-center'>
            <div className={cn('text-sm')}>EN</div>
            <div className='text-xs color-grey ml-3'>{t('en')}</div>
          </div>
          {langRedux === 'en' && <IconCustom icon="web_tick_circle_check" className="color-theme mr-3"/>}
        </div>
      </div>)

    },
    {
      label: 'VI',
      value: 'vi',
      render: (<div className='d-flex align-items-center'>
        <div>
          <AboutToken imgToken={`${window.location.origin}/Flag/vi.svg`} className="token-img token-img--token-1 img-24 mr-3"/>
        </div>
        <div className='currency-info'>
          <div className='d-flex align-items-center'>
            <div className={cn('text-sm')}>VI</div>
            <div className='text-xs color-grey ml-3'>{t('vi')}</div>
          </div>
          {langRedux === 'vi' && <IconCustom icon="web_tick_circle_check" className="color-theme mr-3"/>}
        </div>
      </div>)

    }

  ]

  const onDropdownVisibleChange = (val) => {
    setIsShowTooltip(val)
  }

  const onChangeLanguage = async (val) => {
    setSelectedLang(val)
    i18n.changeLanguage(val.value, () => {})
    setIsChangingLang(true)
    const res = await BaseAdapter.putData('user/chain/info', {
      locale: val.value
    })
    setIsChangingLang(false)
  }

  return (
    <div className={style['dropdown-warpper']}>
      <PageDropdown
        isAsyncChange
        isWaitingChange={isChangingLang}
        // tooltipText={isLargeScreen ? t('language') : null}
        toggleclassName="lang-toggle"
        menuClassName="menu-language"
        dropdownItemClassName="item-curency"
        options={LIST_LANG}
        defaultValue={selectedLang}
        onChange={onChangeLanguage}
      />
    </div>
  )
}

export default DropdownLang
