import Checkbox from 'components/Checkbox'
import Button from 'components/common/Button'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import './style.scss'

function ConfirmPopUp () {
  const { t } = useTranslation()
  const [isUnderstand, setIsUnderstand] = useState(false)

  const handleCheck = () => {
    setIsUnderstand(!isUnderstand)
  }

  const handleConfirm = () => {
    window.closeModal()
  }

  return (
    <div className='confirm-popup'>
      <div className="confirm-popup__title">
        {t('confirmTitle')}
      </div>

      <div className="confirm-popup__description">
        {t('confirmDes')}
      </div>

      <div className="confirm-popup__footer">
        {t('confirmFt')}
      </div>

      <div className="confirm-popup__accept" onClick={handleCheck}>
        <Checkbox
          text={t('understand')}
          itemChoose={isUnderstand}
        />
      </div>

      <div className="confirm-popup__action-confirm">
        <Button
          className='btn-action-confirm'
          isDisable={!isUnderstand}
          onClick={handleConfirm}
        >
          {t('confirm')}
        </Button>
      </div>
    </div>
  )
}

export default ConfirmPopUp
