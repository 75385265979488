/* eslint-disable no-var */
import React, { useEffect, useState } from 'react'
import 'antd/dist/antd.css'
import { Switch, Route, withRouter, useHistory } from 'react-router-dom'
import ReduxServices from 'common/redux'
import Header from 'components/Header'
import 'bootstrap/dist/css/bootstrap.min.css'
import get from 'lodash/get'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ModalServices from 'containers/app/ModalServices'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import PuffLoading from 'components/common/PuffLoading'
import InfoScreen from 'pages/InfoScreen'
import './styles/global.scss'
import './App.scss'
import '@coin98-com/wallet-adapter-react-ui/dist/index.css'

import images from 'assets/images'
import 'rc-slider/assets/index.css'
import NotfoundScreen from 'pages/NotfoundScreen'
import { useDispatch, useSelector } from 'react-redux'
import HomeScreen from 'pages/HomeScreen'
import Footer from 'pages/HomeScreen/components/Footer'
import TagManager from 'react-gtm-module'
import ProfileScreen from 'pages/ProfileScreen'
import FarmScreenMulti from 'pages/FarmScreenMulti'
import StakeV2 from 'pages/StakeV2'
import SnapshotScreen from 'pages/Snapshot/SnapshotScreen'
import SnapshotDetail from 'pages/Snapshot/SnapshotDetail'
import { CHAIN_DATA, DEFAULT_FAVORITE_TOKENS, TYPE_FAVORITE_POOL, TYPE_FAVORITE_TOKEN } from 'common/constants'
import LiquidityScreenContent from 'pages/LiquidityScreen/LiquidityScreenContent'
import BaseAdapter from 'controller/api/BaseAdapter'
import { getItemStorage, getLength, setItemStorage, showNotificationToast } from 'common/functions'
import StoreActions from 'controller/redux/actions/storeActions'
import SwapScreenContent from 'pages/SwapScreen/SwapScreenContent'
import { bsc, viction, bitkub, WalletModalC98, ancient8 } from '@coin98-com/wallet-adapter-react-ui'
import { useWallet } from '@coin98-com/wallet-adapter-react'
import useSocket from 'common/customHooks/useSocket'
import { onAddChain } from 'components/Header/DropdownChain'
import { useDebouncedCallback } from 'use-debounce'

const defaultChains = [bsc, viction, bitkub, ancient8]

function App () {
  const { address, connected, switchNetwork, wallet } = useWallet()
  const { active } = useSocket()
  const history = useHistory()
  const dispatch = useDispatch()
  const chainActive = useSelector((state) => state.chainActive)

  const search = new URL(window.location.href).search
  const query = new URLSearchParams(search)
  const chainUrl = query.get('chain')

  const [isLoading, setIsLoading] = useState(true)
  const isHomePage = history.location.pathname === '/' || get(history, 'location.pathname', '').includes('farm') || get(history, 'location.pathname', '').includes('stake') || get(history, 'location.pathname', '').includes('info') || get(history, 'location.pathname', '').includes('pools') || get(history, 'location.pathname', '').includes('tokens') || get(history, 'location.pathname', '').includes('profile') || get(history, 'location.pathname', '').includes('snapshot') || get(history, 'location.pathname', '').includes(['token']) || get(history, 'location.pathname', '').includes(['pool']) || get(history, 'location.pathname', '').includes(['wallet'])

  const isDarkMode = useSelector(state => state.themeRedux) === 'dark'

  const [scrollEvent, setScrollEvent] = useState({
    direction: 'down',
    key: Math.random()
  })

  const isLaunchPad = get(history, 'location.pathname', '').includes(
    'launchpad'
  )

  const isLaunchPadDetail = get(history, 'location.pathname', '').includes(
    'launchpad/'
  )

  const isLaunchPadAll = get(history, 'location.pathname', '').includes(
    'launchpad/all'
  )

  history.listen((location, action) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  })

  useEffect(() => {
    initFirebase()
    // initOneSignal()
    removeCache()
    active()

    ReduxServices.refreshAllData()
      .then((res) => {
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    onChangeChain(chainUrl)
  }, [connected])

  useEffect(() => {
    loadJwtToken(address)
  }, [address, chainActive])

  const onChangeChain = useDebouncedCallback(async (newChain) => {
    const chainId = get(CHAIN_DATA[newChain], 'chainId')
    if (!chainId) return false
    const res = connected ? await switchNetwork(chainId, onAddChain(newChain, wallet)) : { isError: false }

    if (get(res, 'isError')) {
      showNotificationToast('User rejected the request', 'error', 2000)
      return false
    }
    // setSelectObjChain(val)
    dispatch(StoreActions.setChainActive(newChain))
    return true
  }, 500)

  const loadJwtToken = useDebouncedCallback(async (address) => {
    const codeToken = await ReduxServices.createTokenWithWallet(address)

    getListFavoritePool(codeToken)
    getListFavoriteToken(codeToken)
  }, 500)

  const removeCache = async () => {
    const versionLocal = getItemStorage('VERSION_CLEAR_CACHE', '')
    const versionEnv = process.env.REACT_APP_VERSION_PERSIST

    if (!versionLocal || (versionLocal !== versionEnv)) {
      window.localStorage.clear()
      setItemStorage(versionEnv, 'VERSION_CLEAR_CACHE')
    }
  }

  useEffect(() => {
    if (isDarkMode) {
      document.querySelector('body').style = 'background-color: #0f0f0f'
      document
        .getElementsByTagName('HTML')[0]
        .setAttribute('theme', 'dark')
    } else {
      document.querySelector('body').style = 'background-color: #f5f5f5'
      document
        .getElementsByTagName('HTML')[0]
        .setAttribute('theme', 'light')
    }
  }, [isDarkMode])

  const initFirebase = () => {
    try {
      const firebaseConfig = {
        apiKey: 'AIzaSyBH9W_0DFFCLVbZ1CHKLXXMTCTGWCxRHi8',
        authDomain: 'coin98-web-baryon.firebaseapp.com',
        projectId: 'coin98-web-baryon',
        storageBucket: 'coin98-web-baryon.appspot.com',
        messagingSenderId: '27296037466',
        appId: '1:27296037466:web:62ed5b396a23680e80f663',
        measurementId: 'G-N83CQD3Z8D'
      }

      TagManager.initialize({ gtmId: 'GTM-TT2JPFB' })

      const app = initializeApp(firebaseConfig)
      getAnalytics(app)
    } catch (err) {
      console.log('firebase', err)
    }
  }

  const initOneSignal = () => {
    var OneSignal = window.OneSignal || []
    OneSignal.push(function () {
      OneSignal.init({
        appId: 'e8f3b1eb-7e9b-417a-ad56-2bc0b7bb560d',
        allowLocalhostAsSecureOrigin: true,
        restrictedOriginEnabled: false,
        autoRegister: true,
        notifyButton: {
          enable: false
        }
      })
    })
    ReduxServices.refreshNotification()
  }

  const getListFavoritePool = async (codeToken) => {
    const res = await BaseAdapter.getData('favorite', {
      type: TYPE_FAVORITE_POOL[chainActive],
      version: '2'
    }, null, codeToken)
    if (res && res.success) {
      const listFavPool = get(res, 'data[0].bonusValue', [])
      if (getLength(listFavPool) === 0) {
        dispatch(StoreActions.setListFavoritePoolRedux([]))
      } else dispatch(StoreActions.setListFavoritePoolRedux(listFavPool))
    } else dispatch(StoreActions.setListFavoritePoolRedux([]))
  }

  const getListFavoriteToken = async (codeToken) => {
    const res = await BaseAdapter.getData('favorite', {
      type: TYPE_FAVORITE_TOKEN[chainActive],
      version: '2'
    }, null, codeToken)

    if (res && res.success) {
      const listFavToken = get(res, 'data[0].bonusValue', [])
      if (getLength(listFavToken) === 0) {
        dispatch(StoreActions.setListFavoriteTokenRedux(DEFAULT_FAVORITE_TOKENS))
      } else dispatch(StoreActions.setListFavoriteTokenRedux(listFavToken))
    } else dispatch(StoreActions.setListFavoriteTokenRedux(DEFAULT_FAVORITE_TOKENS))
  }

  if (isLoading) {
    return <PuffLoading isFullScreen />
  }

  const getEventScroll = (direction) => {
    setScrollEvent({
      direction,
      key: Math.random()
    })
  }
  return (
    <div className={`App ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      {isLaunchPad && (
        <img src={images.bgBlueBlur} alt="" className="bg-blue" />
      )}
      <div className={`App ${!isHomePage && 'App__background'}`}>
        <div className="blur-green"></div>
        {isLaunchPad && (
          <div
            className={`background-launchpad ${
              isLaunchPadDetail &&
              !isLaunchPadAll &&
              'background-launchpad--detail'
            }`}
          ></div>
        )}
      </div>
      <Header scrollEvent={scrollEvent} />
      <Switch>
        <Route exact path="/">
          <HomeScreen getEventScroll={getEventScroll} />
        </Route>
        <Route exact path={['/swap']}>
          <SwapScreenContent />
        </Route>
        <Route exact path={['/liquidity', '/liquidity/:typeLiquidity', '/add', '/find', '/add/:addressMint0', '/add/:addressMint0/:addressMint1', '/remove/:addressMint0/:addressMint1']}>
          <LiquidityScreenContent />
        </Route>
        <Route exact path={['/info', '/pools', '/tokens', '/pool/:address', '/token/:address', '/wallet', '/wallet/:address']}>
          <InfoScreen />
        </Route>
        <Route exact path="/farm">
          <FarmScreenMulti />
        </Route>
        <Route exact path="/stake">
          <StakeV2 />
        </Route>
        <Route exact path="/profile">
          <ProfileScreen />
        </Route>
        <Route exact path={['/snapshot']}>
          <SnapshotScreen />
        </Route>
        <Route exact path={['/snapshot/:id']}>
          <SnapshotDetail />
        </Route>
        <Route exact path="/not-found">
          <NotfoundScreen />
        </Route>
        <Route path="*" exact={true} component={NotfoundScreen} />
      </Switch>
      <Footer isHomePage={isHomePage} />
      <WalletModalC98 enableChains={defaultChains} isC98Theme activeChainId={CHAIN_DATA[chainActive]?.chainId} />
      <ModalServices id={'ModalFirst'} />
      <ModalServices />
      <ToastContainer position="bottom-right" />
    </div>
  )
}

export default withRouter(App)
