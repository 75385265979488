import abiFactoryMdex from 'common/abi/abiFactoryMdex'
import { CONTRACT_FACTORY, MAIN_COIN_AMM, W_MAIN_COIN, W_MAIN_COIN_TOMO_OLD } from '../constants'

import ERC20 from 'common/abi/ERC20'
import { convertWeiToBalance, lowerCase, scientificToDecimal } from 'common/functions'
import abiLp from 'common/abi/abiLp'
import get from 'lodash/get'
import useStateCallback from 'common/customHooks/useStateCallback'

const { genWeb3, genContract, zeroAddress } = require('controller/Library/evm')
const { useState, useEffect } = require('react')

const usePairs = (chain, addressWallet, addressA, addressB, poolAddress, isToken) => {
  const [isLoading, setIsLoading] = useState(true)

  const [poolInfo, setPoolInfo] = useStateCallback({ poolAddress: '', balanceOfLp: 0, totalSupplyLp: 0 })

  const web3 = genWeb3(chain)

  const contractID = CONTRACT_FACTORY[chain]

  const contract = genContract(web3, abiFactoryMdex, contractID)
  const wrapCoin = W_MAIN_COIN[chain]

  useEffect(() => {
    if (!isToken) {
      loadInitial()
    } else setIsLoading(false)
  }, [chain, addressWallet, addressA, addressB, poolAddress, isToken])

  const loadInitial = async () => {
    const callBack = () => {
      setIsLoading(false)
    }

    setIsLoading(true)
    const newPoolInfo = await getLp(addressA, addressB, poolAddress)
    setPoolInfo(newPoolInfo, callBack)
  }

  const getPair = async (addressFrom, addressTo) => {
    if (!addressFrom || !addressTo) return ''
    const res = await contract.methods.getPair(addressFrom, addressTo).call()
    if (res === zeroAddress) return ''
    return res
  }

  const getLp = async (addressFrom, addressTo, poolAddress) => {
    try {
      const newPoolAddress = poolAddress || await getPair(addressFrom, addressTo)

      if (!newPoolAddress) return { poolAddress: newPoolAddress, balanceOfLp: 0, totalSupplyLp: 0 }

      const poolInfo = await getInfoPoolAddress(newPoolAddress)

      return poolInfo
    } catch (error) {
      return { poolAddress: '', balanceOfLp: 0, totalSupplyLp: 0 }
    }
  }

  const getInfoPoolAddress = async (poolAddress) => {
    const contractTokenLp = genContract(web3, abiLp, poolAddress)

    const decimals = await contractTokenLp.methods.decimals().call()
    const balanceOf = addressWallet ? await contractTokenLp.methods.balanceOf(addressWallet).call() : 0
    const totalSupply = await contractTokenLp.methods.totalSupply().call()

    const addressToken0 = await contractTokenLp.methods.token0().call()
    const addressToken1 = await contractTokenLp.methods.token1().call()
    const getReserves = await contractTokenLp.methods.getReserves().call()

    const balanceToken0User = (getReserves._reserve0 * balanceOf) / totalSupply
    const balanceToken1User = (getReserves._reserve1 * balanceOf) / totalSupply

    const shareOfPool = (parseFloat(convertWeiToBalance(balanceOf, decimals)).toFixed(18) / parseFloat(convertWeiToBalance(totalSupply, decimals)).toFixed(18)) * 100

    let token0Info, token1Info

    const mainCoin = MAIN_COIN_AMM[chain]

    if (lowerCase(addressToken0) === lowerCase(wrapCoin.address)) token0Info = mainCoin
    if (lowerCase(addressToken1) === lowerCase(wrapCoin.address)) token1Info = mainCoin

    if (lowerCase(addressToken0) === lowerCase(W_MAIN_COIN_TOMO_OLD.address)) token0Info = W_MAIN_COIN_TOMO_OLD
    if (lowerCase(addressToken1) === lowerCase(W_MAIN_COIN_TOMO_OLD.address)) token1Info = W_MAIN_COIN_TOMO_OLD

    if (!token0Info) {
      token0Info = await window.wallet.fetchInfoCoin(chain, addressToken0)
    }
    if (!token1Info) {
      token1Info = await window.wallet.fetchInfoCoin(chain, addressToken1)
    }

    if (!token0Info) {
      const contractToken0 = genContract(web3, ERC20, addressToken0)
      const decimals = await contractToken0.methods.decimals().call()
      const name = await contractToken0.methods.name().call()
      const symbol = await contractToken0.methods.symbol().call()

      token0Info = { address: addressToken0, decimals, name, symbol }
    }

    if (!token1Info) {
      const contractToken1 = genContract(web3, ERC20, addressToken1)
      const decimals = await contractToken1.methods.decimals().call()
      const name = await contractToken1.methods.name().call()
      const symbol = await contractToken1.methods.symbol().call()

      token1Info = { address: addressToken1, decimals, name, symbol }
    }

    return {
      poolAddress,
      balanceOfLp: parseFloat(convertWeiToBalance(balanceOf, decimals)),
      balanceOfLpWei: balanceOf,
      totalSupplyLp: parseFloat(convertWeiToBalance(totalSupply, decimals)),
      shareOfPool: shareOfPool > 100 ? 100 : shareOfPool,
      token0: {
        tokenInfo: token0Info,
        totalBalancePool: parseFloat(convertWeiToBalance(getReserves._reserve0, token0Info.decimals || token0Info.decimal)),
        balanceTokenUser: parseFloat(convertWeiToBalance(balanceToken0User, token0Info.decimals || token0Info.decimal))

      },
      token1: {
        tokenInfo: token1Info,
        totalBalancePool: parseFloat(convertWeiToBalance(getReserves._reserve1, token1Info.decimals || token1Info.decimal)),
        balanceTokenUser: parseFloat(convertWeiToBalance(balanceToken1User, token1Info.decimals || token1Info.decimal))
      }
    }
  }

  const getAmount = (token, amount) => {
    if (isNaN(amount)) return 0
    if (poolInfo.poolAddress) {
      const token0address = get(poolInfo, 'token0.tokenInfo.address') || wrapCoin?.address
      const tokenAddres = token?.address || wrapCoin?.address
      const isFrom = lowerCase(tokenAddres) === lowerCase(token0address)
      const rateFromTo = (get(poolInfo, 'token0.totalBalancePool') / get(poolInfo, 'token1.totalBalancePool'))

      let fAmount
      if (isFrom) fAmount = parseFloat(amount || 0) / rateFromTo
      else fAmount = parseFloat(amount || 0) * rateFromTo

      // const newAmount = scientificToDecimal(roundingNumber(fAmount, 6))

      return scientificToDecimal(fAmount.toString())
    }
    return 0
  }

  return {
    contract,
    isLoading,
    getPair,
    getLp,
    poolInfo,
    getAmount,
    getInfoPoolAddress,
    loadInitial
  }
}

export default usePairs
