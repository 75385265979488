import React from 'react'
import TablePool from 'pages/PoolScreen/components/TablePool'
import './style.scss'

const TableBoard = ({
  title,
  columns,
  dataSource,
  renderActionRight,
  renderSelectRight,
  isLoading,
  totalPage,
  handleSelectedPage,
  initialPage,
  isTransactions,
  currentTabTxs,
  isTokens,
  isPools
}) => {
  return (
    <div className="table-board">
      <div className="table-board__head">
        <div className="head-title">{title}</div>
        <div className='head-right'>
          {renderSelectRight && renderSelectRight()}
          {renderActionRight && (
            <div className="head-actions">
              {renderActionRight()}
            </div>
          )}
        </div>
      </div>

      <div className="table-board__table">
        <TablePool
          handleSelectedPage={handleSelectedPage}
          totalPage={totalPage}
          columns={columns}
          dataSource={dataSource}
          isLoading={isLoading}
          initialPage={initialPage}
          isTransactions={isTransactions}
          tabTxt={currentTabTxs}
          isTokens={isTokens}
          isPools={isPools}
        />
      </div>
    </div>
  )
}

export default TableBoard
