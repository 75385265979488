import React, { useEffect, useRef, useState } from 'react'
import Button from 'components/common/Button'
import get from 'lodash/get'
import './style.scss'
import { useDispatch, useSelector } from 'react-redux'
import { formatAddress, formatNumberBro, getItemStorage } from 'common/functions'
import { useTranslation } from 'react-i18next'
import { CHAIN_DATA, isDapp, CHAIN_ID_TO_CHAIN } from 'common/constants'
import { KEY_STORE } from 'common/constants/keystore'
import BoxLockMatrix from '../BoxLockMatrix'
import BoxLockPassword from '../BoxLockPassword'

import useOnClickOutside from 'common/customHooks/useClickOutSide'
import { useWallet, WALLETS_NAME } from '@coin98-com/wallet-adapter-react'
import { useWalletModal } from '@coin98-com/wallet-adapter-react-ui'
import StoreActions from 'controller/redux/actions/storeActions'
import { useMainBalanceContext } from 'context/MainBalanceContext'

const ButtonConnected = () => {
  const { t } = useTranslation()
  const { mainBalance } = useMainBalanceContext()
  const { address, selectedChainId, disconnect, connected, selectWallet } = useWallet()
  const dispatch = useDispatch()

  const chainActive = useSelector((state) => state.chainActive)
  const isSession = useSelector(state => state.isSession)

  const { openWalletModal } = useWalletModal()

  const [isShowMenu, setIsShowMenu] = useState(false)

  const pwTimer = useRef(null)
  const dropdownRef = useRef(null)
  const isNoWallet = !address

  useEffect(() => {
    // disconnect()
    if (!selectedChainId) return
    const chain = CHAIN_ID_TO_CHAIN[selectedChainId]

    if (chain) dispatch(StoreActions.setChainActive(chain))
    else disconnect()
  }, [selectedChainId])

  useOnClickOutside(dropdownRef, () => {
    setIsShowMenu(false)
  })

  useEffect(() => {
    let interval
    if (window.coin98?.provider) {
      interval = setInterval(() => {
        window.coin98?.provider.on('ping', () => {
          console.log('🚀 ~ file: index.js ~ line 104 ~ window.ethereum.on ~ ping')
        })
      }, 600000)
    }
  }, [])

  useEffect(() => {
    if (!isDapp) {
      clearTimeout(pwTimer.current)
      pwTimer.current = setTimeout(() => {
        if (!isSession && !isNoWallet) {
          window.requestAnimationFrame(() => {
            openModalConfirmPass()
          })
        } else {
          if (typeof get(window, 'closeModalFirst') === 'function') { window && window.closeModalFirst() }
        }
      }, 1000)
    }
  }, [isSession, isNoWallet])

  /// ////

  const onConnect = async () => {
    if (!isDapp) return openWalletModal()
    try {
      const chain = CHAIN_DATA[chainActive]?.chainId
      selectWallet(WALLETS_NAME.coin98Ether, chain)
    } catch (error) {
      console.log('🚀 ~ file: index.js:93 ~ onConnect ~ error:', error)
    }
  }

  const disconnectWallet = () => {
    window.openModal({
      okText: t('disconnect'),
      title: t('disconnectWallet'),
      type: 'confirm',
      onOk: async () => {
        await disconnect()
        window.closeModal()
      }
    })
  }

  const onToggleAddressMenu = () => {
    setIsShowMenu(true)
  }

  const openModalConfirmPass = () => {
    const typePassword = getItemStorage(KEY_STORE.TYPE_PASSWORD)
    const isMatrix = typePassword === 'matrix'
    if (window && typePassword) {
      window.openModalFirst({
        type: 'none',
        className: isMatrix ? 'popup-matrix' : '',
        content: isMatrix ? <BoxLockMatrix /> : <BoxLockPassword/>,
        preventClose: true,
        noIcon: true,
        isConfirmPassWord: true
      })
    }
  }

  return (
    <div className="button-connected-wr">
      {!connected
        ? (
          <Button onClick={onConnect} className="actions-connect">{t('connectWallet')}</Button>
        )
        : (
          <div className='button-connect-container' ref={dropdownRef}>
            <div className="button-connect" onClick={onToggleAddressMenu}>
              <div className="button-connect__circle"></div>
              <div className="button-connect__info">
                <span className='lx-regular'>{formatNumberBro({ number: mainBalance, mantissa: 4 })} {get(CHAIN_DATA[chainActive], 'symbol')}{' '}</span>
                <span>{formatAddress(address)}</span>
              </div>
              <div className="button-connect__space"></div>
              {isShowMenu && <div className="button-connect__dropdown">
                <Button
                  className="actions-disconnect"
                  onClick={disconnectWallet}
                  type="gradient"
                  isFullWidth
                >
                  {t('disconnect')}
                </Button>
              </div>
              }
            </div>
          </div>

        )
      }
    </div>
  )
}

export default ButtonConnected
