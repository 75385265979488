import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import style from './style.module.scss'
import { Col, Row } from 'antd'
import InfoWallet from './InfoWallet'
import BoardTransactions from '../BoardTransactions'
import OverviewBoard from './OverviewBoard'
import BaseAdapter from 'controller/api/BaseAdapter'
import { useParams } from 'react-router-dom'
import { get } from 'lodash'
import CountUpComponent from 'components/CountUpComponent'
import { PoolInfoContextProvider } from 'pages/PoolInfoScreen/context/PoolInfoContext'
import { useSelector } from 'react-redux'

const BoardWallet = ({ className }) => {
  const { t } = useTranslation()
  const { address } = useParams()
  const [walletStats, setWalletStats] = useState([])
  const chainActive = useSelector((state) => state.chainActive)

  useEffect(() => {
    loadingOverviewStats()
  }, [chainActive])

  const loadingOverviewStats = async () => {
    const res = await BaseAdapter.getData('baryon/wallet/address/statictical', { chain: chainActive })
    if (res) {
      const dataObj = get(res, 'data', {})
      const formatData = Object.keys(dataObj).map(item => ({
        total: dataObj[item],
        title: item
      }))

      setWalletStats(formatData)
    }
  }

  return (
    <PoolInfoContextProvider>
      <div className={cn(style['wallet-wrapper'], className)}>
        <div className="wallet-total">
          <Row gutter={[24, 24]}>
            {walletStats.map((item, index) => {
              return (
                <Col
                  xs={{
                    span: 12
                  }}
                  lg={{
                    span: 12
                  }}
                  xl={{
                    span: 6
                  }}
                  key={index}
                >
                  <div className="total-item lx-regular">
                    {/* <span className="total-item--main">{item.total}</span> */}
                    <CountUpComponent className="total-item--main" endNum={item.total} decimals={0} />
                    <span>{t(item.title)}</span>
                  </div>
                </Col>
              )
            })}
          </Row>
        </div>
        {address && <div className="wallet-chart mb-24">
          <InfoWallet/>
        </div>}

        <OverviewBoard address={address}/>

        { address &&
        <div className='wallet-transction mt-24'>
          <BoardTransactions typeBoard="wallet" address={address}/>
        </div>}
      </div>
    </PoolInfoContextProvider>
  )
}

export default BoardWallet
