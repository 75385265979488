import React, { useMemo } from 'react'
import cn from 'classnames'
import style from './style.module.scss'
import { CustomTable } from 'components/CustomTable'
import { useTranslation } from 'react-i18next'
import ReduxServices from 'common/redux'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { formatAddress } from 'common/functions'

const SnapshotSwap = ({ listSnapSwap, isLoading }) => {
  const { t } = useTranslation()
  const currencyRedux = useSelector(state => state.currencyRedux)

  const tableHead = [
    {
      title: t('rank'),
      dataIndex: 'rank',
      align: 'center',
      width: 5,
      render: (val) => {
        return val === 0 ? '-' : val
      }
    },
    {
      title: t('address'),
      dataIndex: 'address',
      width: 10,
      align: 'left',
      render: (val) => {
        return (
          <span>
            {formatAddress(val)}
          </span>
        )
      }
    },
    {
      title: t('tradingVolume'),
      dataIndex: 'tradingVolume',
      align: 'right',
      width: 20,
      render: (val) => {
        return ReduxServices.formatPrice(val)
      }
    },
    {
      title: t('joinDate'),
      dataIndex: 'joinDate',
      align: 'right',
      width: 15,
      render: (val) => {
        return (
          <span className='text-nowrap'>
            {
              moment(val).format('DD/MM HH:mm')
            }
          </span>
        )
      }
    },
    {
      title: t('reward'),
      dataIndex: 'reward',
      align: 'right',
      width: 15
    }
  ]
  const tableData = useMemo(() => {
    return listSnapSwap.map((item) => ({
      rank: item.rank,
      address: item.address,
      tradingVolume: item.tradingVolume,
      joinDate: item.joinDate,
      reward: item.reward
    }))
  }, [listSnapSwap, currencyRedux])

  return (
    <div className={cn(style.snapshotSwap)}>
      <CustomTable
        isLoading={isLoading}
        columns={tableHead}
        dataSource={tableData}
      />

    </div>
  )
}

export default SnapshotSwap
