import { formatPrice, showNotificationToast, upperCase } from 'common/functions'
import Button from 'components/common/Button'
import GasSlider from 'components/GasSlider'
import get from 'lodash/get'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import './style.scss'
import { encodeMessErr } from 'controller/Library/evm'
import PopupHash from 'components/PopupHash'
import { HARVEST } from 'common/farm/contants'
import useFarmV2 from 'common/farm/hooks/useFarmV2'
import BaseAPI from 'controller/api/BaseAPI'
import { historyType } from '../Constants'
import { useWallet } from '@coin98-com/wallet-adapter-react'
import AboutToken from 'components/common/AboutToken'

const HarvestPopup = ({ item, load, isUnStake }) => {
  const { t } = useTranslation()
  const chainActive = useSelector(state => state.chainActive)
  const { address } = useWallet()

  const [gasSelect, setGasSelect] = useState(0)
  const [gasLimitEst, setGasLimitEst] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const pid = get(item, 'pid')
  const stakedLpAddress = get(item, 'lpAddresses')
  const rewardTokens = get(item, 'rewardTokens', [])
  const totalRewardUSD = get(item, 'totalRewardUSD')
  const balanceStakedWei = get(item, 'balanceStakedWei')
  const balanceStaked = get(item, 'balanceStaked')
  const priceStake = get(item, 'priceStake', 0)
  const isV2 = get(item, 'isV2')
  const factoryAddress = get(item, 'factoryAddress')

  const totalRewardUSDFinal = isUnStake ? parseFloat(balanceStaked) * priceStake : totalRewardUSD

  const gasPrice = get(gasSelect, 'gas.value', 0)
  const isMobile = window.innerWidth < 576

  const { harvest, leaveStaking } = useFarmV2(stakedLpAddress, gasPrice, isV2, factoryAddress)

  useEffect(() => {
    loadGasLimitEst()
  }, [])

  const loadGasLimitEst = async () => {
    const gasLimit = isUnStake
      ? await leaveStaking({
        item,
        amount: balanceStakedWei,
        pid,
        callBackFail,
        isReturnEstimateGasLimit: true
      })
      : await harvest({
        pid,
        callBackFail,
        isReturnEstimateGasLimit: true
      })

    setGasLimitEst(gasLimit)
  }

  const callBackFail = (isEstimateGas) => (mess) => {
    const stringResult = encodeMessErr(mess)
    !isEstimateGas && showNotificationToast(t(stringResult), 'error', 2000)
    !isEstimateGas && window.closeModal()
  }

  const handleCancel = () => {
    window.closeModal()
  }

  const handleGas = (gasSelect) => {
    setGasSelect(gasSelect)
  }

  const orderComplete = (type) => async (hash) => {
    BaseAPI.postData('baryon/farm/history', {
      hash,
      createdAddress: address,
      chain: chainActive,
      type: isUnStake ? historyType.unStake : historyType.harvest,
      amount: isUnStake ? balanceStaked : 0,
      lpAddress: item?.stakedLpAddress,
      harvestData: rewardTokens.map(token => {
        return {
          amount: token?.amountTokenReward,
          token: token?.address
        }
      })
    })
    load && load()

    window.openModal({
      title: t('success'),
      containerClass: 'modal-success',
      content: <PopupHash type={type} hash={hash} />,
      type: 'success',
      okText: t('close')
    })
  }

  const handleSubmitActions = async () => {
    setIsLoading(true)
    if (isUnStake) {
      await leaveStaking({
        item,
        amount: balanceStakedWei,
        pid,
        callBack: orderComplete(HARVEST),
        callBackFail
      })
    } else {
      await harvest({
        pid,
        to: address,
        callBack: orderComplete(HARVEST),
        callBackFail
      })
    }
    setIsLoading(false)
  }

  const renderTokenUnstake = () => {
    const token0 = get(item, 'token0')
    const token1 = get(item, 'token1')
    const isStake = !token0
    const tokenInfoStaked = get(item, 'tokenInfoStaked')

    const renderSymbolLP = isStake
      ? upperCase(get(tokenInfoStaked, 'symbol', ''))
      : `${upperCase(get(token0, 'symbol', ''))} - ${upperCase(
        get(token1, 'symbol', '')
      )} LP`
    return (
      <div className="item-coin-harvest">
        <div className={`item-coin-harvest__coin ${isUnStake && !isStake && 'token-lp'}`}>

          <AboutToken imgToken={isStake ? tokenInfoStaked.image : token0.image} />
          {!isStake && <AboutToken className='coin-2' imgToken={token1.image} />}

          <div>{renderSymbolLP}</div>
        </div>
        <div className="item-coin-harvest__balance">
          {formatPrice(balanceStaked, false, true)}
        </div>
      </div>
    )
  }

  return (
    <div className='harvest-component'>

      <div className="harvest-content-popup">
        {/* <div className="harvest-content-popup__content">
          {t('harvesting')}:
          <span>
                &nbsp;
            {`${formatPrice(pendingReward, false, true) +
                  ' '} ~ ${formatPrice(totalRewardUSD)} `}
          </span>
        </div> */}

        {/* <div className="harvest-content-popup__icon">
          <img src={images.iconInfo} alt="" />
          <div className="tooltip-suggest">this is tooltip</div>
        </div> */}

        {rewardTokens.map((item, index) => {
          const amountTokenReward = get(item, 'amountTokenReward', [])

          return (
            <div key={index} className="item-coin-harvest">
              <div className="item-coin-harvest__coin">
                {/* <img src={item.image} alt="" /> */}
                <AboutToken imgToken={item.image} />
                <div>{upperCase(item.symbol)}</div>
              </div>
              <div className="item-coin-harvest__balance">
                {formatPrice(amountTokenReward, false, true)}
              </div>
            </div>
          )
        })}

        {isUnStake && renderTokenUnstake()}

        <div className='line' />

        <div className="total-receive">
          <div>{t('totalReceive')}</div>
          <div className='price'>{formatPrice(totalRewardUSDFinal)}</div>
        </div>

      </div>

      {/* {!isMobile && <GasSlider onChange={handleGas} chain={chainActive} gasLimitEst={gasLimitEst} gasSelected={gasSelect} /> } */}

      <div className="harvest-actions">
        <Button
          onClick={handleCancel}
          isFullWidth
          type='cancel'
          className='harvest-actions--cancel'
        >
          {t('cancel')}
        </Button>

        <Button
          isLoading={isLoading}
          onClick={handleSubmitActions}
          isFullWidth
        >
          {t('confirm')}
        </Button>
      </div>
    </div>
  )
}

export default HarvestPopup
