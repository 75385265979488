import React from 'react'
import { render } from 'react-dom'
import * as serviceWorker from './serviceWorker'
import './styles/global.scss'
import './i18n'
import WalletServices from 'common/WalletServices'
import 'normalize.css'
import { clearLoadingPool } from 'common/functions/utils'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from 'controller/redux/store/configureStore'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { WalletProvider, BLOCKCHAINS_DATA } from '@coin98-com/wallet-adapter-react'
import { Coin98WalletAdapter } from '@coin98-com/wallet-adapter-coin98'
import { Ancient8WalletAdapter } from '@coin98-com/wallet-adapter-ancient8'
import { MetaMaskWalletAdapter } from '@coin98-com/wallet-adapter-metamask'
import { WalletModalProvider } from '@coin98-com/wallet-adapter-react-ui'
import { MainBalanceContextProvider } from 'context/MainBalanceContext'
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'

const queryClient = new QueryClient()
window.wallet = new WalletServices()
const renderer = async () => {
  const enables = [BLOCKCHAINS_DATA.ethereum]
  const wallets = [
    Coin98WalletAdapter,
    MetaMaskWalletAdapter,
    Ancient8WalletAdapter
  ]

  try {
    await clearLoadingPool()
    await window.wallet.checkIsReady()
  } catch (e) {
    window.location.reload()
  }
  return render(

    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <WalletProvider wallets={wallets} enables={enables} autoConnect>
            <WalletModalProvider>
              <BrowserRouter>
                <MainBalanceContextProvider>
                  <QueryClientProvider client={queryClient}>
                    <App />
                  </QueryClientProvider>
                </MainBalanceContextProvider>

              </BrowserRouter>
            </WalletModalProvider>

          </WalletProvider>

        </PersistGate>

      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  )
}

renderer()

serviceWorker.unregister()
