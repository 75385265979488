import images from 'assets/images'
import { get } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ChangeImage from './ChangeImage'
import ConfirmPopUp from './ConfirmPopUp'
import ContentEdit from './ContentEdit'
import ProfileDetail from './ProfileDetail'

import './style.scss'
import WalletDetail from './WalletDetail'

function ProfilePopUp ({ isEditProfile, item }) {
  const { t } = useTranslation()
  const [changeImg, setChangeImg] = useState('')
  const [removeImgProfile, setRemoveImgProfile] = useState(false)

  const handleStatusEdit = (status) => () => {
    setChangeImg(status)
  }

  const renderContent = () => {
    if (isEditProfile) {
      return renderEditProfile()
    } else {
      return renderDetail()
    }
  }

  const renderTitle = () => {
    let title = ''
    if (isEditProfile) {
      // if (changeImg) {
      //   title = 'changePic'
      // } else {
      //   title = 'editProfile'
      // }
      switch (changeImg) {
      case 'edit':
        title = 'changePic'
        break
      case 'remove':
        title = 'removePic'
        break
      default:
        title = 'editProfile'
        break
      }
    } else {
      title = 'detail'
    }
    return title
  }

  const renderEditProfile = () => {
    // if (changeImg === 'edit') {
    //   return <ContentEdit handleStatusEdit={handleStatusEdit}/>
    // } else {
    //   return <ChangeImage handleStatusEdit={handleStatusEdit}/>
    // }

    switch (changeImg) {
    case 'edit':
      return <ChangeImage handleStatusEdit={handleStatusEdit}/>
    case 'remove':
      return <ConfirmPopUp />
    default:
      return <ContentEdit handleStatusEdit={handleStatusEdit} />
    }
  }

  const renderDetail = () => {
    if (get(item, 'isProfile')) {
      if (removeImgProfile) {
        return <ConfirmPopUp/>
      } else {
        return <ProfileDetail item={item} setRemoveImgProfile={setRemoveImgProfile}/>
      }
    } else {
      return <WalletDetail item={item}/>
    }
  }

  return (
    <div className={`edit-profile ${!changeImg && 'edit-profile--edit-img'}`}>
      <div className="edit-profile__title">
        {t(renderTitle())}
      </div>

      <div className="edit-profile__content">
        {renderContent()}
      </div>
    </div>
  )
}

export default ProfilePopUp
