import { chainType, CHAIN_DATA, COIN_IMAGE, IS_DEV } from 'common/constants'

export const selectDefaultChain = chainType.tomo

export const SLIPPAGE = 1

export const CONTRACT_KYC_BIT_KUB_V2 = '0x409CF41ee862Df7024f289E9F2Ea2F5d0D7f3eb4'
export const CONTRACT_TRC21_ISSUER = '0x8c0faeb5C6bEd2129b8674F262Fd45c4e9468bee'

export const ROUTER_SPONSOR_GAS = {
  [chainType.tomo]: IS_DEV ? '0xc87A7097528eCCc8e9c5aB46651512c8e37632cd' : '0x063b706C0E1e0aA83AE6CC237e9C0780fa784d83'
}

// export const ROUTER_SPONSOR_GAS_OLD = {
//   [chainType.tomo]: IS_DEV ? '0x8801Ac67b0a163192d60D5Dc0516E9D91dC2EC61' : '0xbDa6489496aeD3aB7Ca059e19B5e9E92d3f1535A'
// }

export const CONTRACT_ROUTER_OLD = {
  [chainType.tomo]: IS_DEV ? '0x761eE1A3cFa36aaF87b303acBf76Bea20DC21085' : '0x9a07e483cD8C6B7cD621B65Ac5466fF34D754957'
}

export const PERMIT_HELPER = IS_DEV ? '0x93F5C1BAF33F5676AF094AA5dbe17A14002128df' : '0x0731DD67080AFC5Cd50c2FD8C6DFFB5f9523fC19'

export const CONTRACT_ROUTER = {
  [chainType.heco]: IS_DEV ? '' : '0x5C0a4d02e2bd799A3C99F1fc53b802765Db36682',
  [chainType.binanceSmart]: IS_DEV ? '' : '0x3f8aadD297E60Ebe7c1bB8d3f6D413b9f39EFB8B',
  [chainType.tomo]: IS_DEV ? '0x3cB12DC332deB87dc5530a919E57a384e0132dA5' : '0x90Ff92592EE0beE0cAAA3cCcA5Dd718B32DBa96c',
  [chainType.bitkub]: IS_DEV ? '0xB7513EfE9Dd0b8e89B3288621ecC4166BFF69343' : '0x5C93F4B35d3dD97Ef481881aA33d00F76806FdAD',
  [chainType.ancient8]: IS_DEV ? '0xA47e686159cFAc811d268033c72485bB6F9576F1' : '0xA47e686159cFAc811d268033c72485bB6F9576F1',
  [chainType.ancient8Mainnet]: IS_DEV ? '0x67807b9f5B9757C0c79347F0b3f360C15c5E6aFF' : '0x67807b9f5B9757C0c79347F0b3f360C15c5E6aFF'
}

export const CONTRACT_FACTORY = {
  [chainType.heco]: IS_DEV ? '' : '0x89E7147b29D8842B49eF60b5ede07F9579e9dAef',
  [chainType.binanceSmart]: IS_DEV ? '' : '0x03879E2a3944FD601e7638DfCBC9253fb793b599',
  [chainType.tomo]: IS_DEV ? '0x6dba09b141ECd2b2DA503AAfB10A5b5c0dF85761' : '0xFe48A2E66EE2f90334d3565E56E0c9d0081447e8',
  [chainType.bitkub]: IS_DEV ? '0xf901635B144BA819AB71F22D3201a44F97e4aC1a' : '0xf7eEe3A8363731C611A24CdDfCBcaDE9C153Cfe8',
  [chainType.ancient8]: IS_DEV ? '0x134BC76d82E7aac0006b0D55DF8769d35DD1d290' : '0x134BC76d82E7aac0006b0D55DF8769d35DD1d290',
  [chainType.ancient8Mainnet]: IS_DEV ? '0xAE12C5930881c53715B369ceC7606B70d8EB229f' : '0xAE12C5930881c53715B369ceC7606B70d8EB229f'
}

export const CONTRACT_FARM_STAKE_V1 = {
  [chainType.binanceSmart]: IS_DEV ? '' : '0xC02Db0C82d143a1879173303AaA3E1b45e9139ff',
  [chainType.heco]: IS_DEV ? '' : '0x7f330666BC97D53775d9467Cbe1149C2243E7ABe',
  [chainType.tomo]: IS_DEV ? '0x3e2f799188200EF3527DD568C557772F3c2F649E' : '',
  [chainType.bitkub]: IS_DEV ? '' : '',
  [chainType.ancient8]: IS_DEV ? '' : '',
  [chainType.ancient8Mainnet]: IS_DEV ? '' : ''
}

export const CONTRACT_FARM_STAKE_V2 = {
  [chainType.binanceSmart]: IS_DEV ? '' : '0x4F62Ddc50290558c18731e48c0Ed3123FDD3A62c',
  [chainType.heco]: IS_DEV ? '' : '0x8c624b263aeffac872ec3f0dbae1f581faa66525',
  // [chainType.tomo]: IS_DEV ? '0x74B39e3EAc41ce5768A4a8552405E14681A82200' : '0xf7ECcb66a7C3C55B1eDFCe634AF8C20766F97553',
  [chainType.tomo]: IS_DEV ? '0xEC4B8681B41A769957674891Aa1dcd84831f9EB7' : '0xf7ECcb66a7C3C55B1eDFCe634AF8C20766F97553',
  [chainType.bitkub]: IS_DEV ? '0xff497144bc06a5A275E2125d1C654c669CB26656' : '0x963e1BcD1f82724bD8Fa16a3B6962D100fB287FC',
  [chainType.ancient8]: IS_DEV ? '0x6cb52a631CdE791E8e9F92c4a483fD4D7102700c' : '0x6cb52a631CdE791E8e9F92c4a483fD4D7102700c',
  [chainType.ancient8Mainnet]: IS_DEV ? '0xA277e01D612654417Cd0e7c4c47EEE4d2377dB8B' : '0x0eCE57A677D5e72D1ad45774239e23463CF1d743'
}

export const LIST_TOKEN_FAVORITE = {
  [chainType.binanceSmart]: ['0xaEC945e04baF28b135Fa7c640f624f8D90F1C3a6', '0xFa4BA88Cf97e282c505BEa095297786c16070129'],
  [chainType.tomo]: ['0x0Fd0288AAAE91eaF935e2eC14b23486f86516c8C', '0xb3008E7156Ae2312b49B5200C3E1C3e80E529feb'],
  [chainType.bitkub]: [],
  [chainType.ancient8]: [],
  [chainType.ancient8Mainnet]: []
}

export const COIN98_DEFAULT = {
  binanceSmart: {
    id: 'coin98',
    symbol: 'C98',
    decimals: 18,
    name: 'Coin98',
    image: 'https://coin98.s3.amazonaws.com/uwqLJuMfd8fsehgM',
    address: '0xaEC945e04baF28b135Fa7c640f624f8D90F1C3a6'
  },
  heco: {
    id: 'huobi-token',
    chainId: 128,
    name: 'HT Token',
    symbol: 'HT',
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2502.png',
    address: '0x5545153CCFcA01fbd7Dd11C0b23ba694D9509A6F'
  },
  tomo: {
    id: 'coin98',
    symbol: 'C98',
    decimals: 18,
    name: 'Coin98',
    image: 'https://coin98.s3.amazonaws.com/uwqLJuMfd8fsehgM',
    address: '0x0Fd0288AAAE91eaF935e2eC14b23486f86516c8C'
  },
  bitkub: {
    id: 'bitkub-coin', // change
    symbol: 'KKUB',
    decimals: 18,
    name: 'Bitkub',
    image: COIN_IMAGE.KUB,
    address: '0x67eBD850304c70d983B2d1b93ea79c7CD6c3F6b5'
  },
  ancient8: {
    id: 'ethereum',
    symbol: 'WETH',
    decimals: 18,
    name: 'Wrapped Ether',
    image: COIN_IMAGE.ETH,
    address: '0x4200000000000000000000000000000000000006'
  },
  ancient8Mainnet: {
    id: 'ethereum',
    symbol: 'WETH',
    decimals: 18,
    name: 'Wrapped Ether',
    image: COIN_IMAGE.ETH,
    address: '0x4200000000000000000000000000000000000006'
  }
}

export const W_MAIN_COIN_STAKE = {
  ancient8: {
    id: 'ethereum',
    chainId: 28122024,
    symbol: 'ETH',
    decimals: 18,
    name: 'Ethereum',
    image: COIN_IMAGE.ETH,
    logoURI: COIN_IMAGE.ETH,
    address: '0x4200000000000000000000000000000000000006'
  },
  ancient8Mainnet: {
    id: 'ethereum',
    chainId: 888888888,
    symbol: 'ETH',
    decimals: 18,
    name: 'Ethereum',
    image: COIN_IMAGE.ETH,
    logoURI: COIN_IMAGE.ETH,
    address: '0x4200000000000000000000000000000000000006'
  },
  bitkub: {
    id: 'bitkub-coin',
    chainId: 96,
    name: 'Bitkub',
    symbol: 'KUB',
    decimals: 18,
    logoURI: COIN_IMAGE.KUB,
    image: COIN_IMAGE.KUB,
    address: '0x67eBD850304c70d983B2d1b93ea79c7CD6c3F6b5'
  },
  tomo: {
    id: 'tomochain',
    chainId: 88,
    name: 'Viction',
    symbol: 'VIC',
    decimals: 18,
    logoURI: COIN_IMAGE.TOMO,
    image: COIN_IMAGE.TOMO,
    address: '0xC054751BdBD24Ae713BA3Dc9Bd9434aBe2abc1ce'
  },
  binanceSmart: {
    id: 'binancecoin',
    chainId: 56,
    name: 'BNB Token',
    symbol: 'BNB',
    decimals: 18,
    logoURI: COIN_IMAGE.BNB,
    image: COIN_IMAGE.BNB,
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
  }
}

export const MAIN_COIN_VIC_OLD = {
  id: 'tomochain',
  chainId: 88,
  name: 'Viction',
  symbol: 'VIC(old)',
  decimals: 18,
  logoURI: COIN_IMAGE.TOMO,
  image: COIN_IMAGE.TOMO,
  address: '0xB1f66997A5760428D3a87D68b90BfE0aE64121cC'
}

export const NAME_COIN_OLD = {
  '0xb1f66997a5760428d3a87d68b90bfe0ae64121cc': 'VIC(old)',
  '0xd84c4f5bdccd920068a69ec801cf19b9244ff22a': 'SVIC(old)'
}

export const TOKEN_HIDE = {
  tomo: {
    '0xd84c4f5bdccd920068a69ec801cf19b9244ff22a': 'SVIC(old)'
  }
}

export const W_MAIN_COIN_TOMO_OLD = {
  id: 'tomochain',
  chainId: 88,
  name: 'Wrapped TOMO',
  symbol: 'WTOMO',
  decimals: 18,
  logoURI: COIN_IMAGE.OLDTOMO,
  image: COIN_IMAGE.OLDTOMO,
  address: '0xB1f66997A5760428D3a87D68b90BfE0aE64121cC'
}

export const W_MAIN_COIN = {
  ancient8: {
    id: 'ethereum',
    chainId: 28122024,
    symbol: 'WETH',
    decimals: 18,
    name: 'Wrapped Ether',
    image: COIN_IMAGE.ETH,
    logoURI: COIN_IMAGE.ETH,
    address: '0x4200000000000000000000000000000000000006'
  },
  ancient8Mainnet: {
    id: 'ethereum',
    chainId: 888888888,
    symbol: 'WETH',
    decimals: 18,
    name: 'Wrapped Ether',
    image: COIN_IMAGE.ETH,
    logoURI: COIN_IMAGE.ETH,
    address: '0x4200000000000000000000000000000000000006'
  },
  tomo: {
    id: 'tomochain',
    chainId: 88,
    name: 'Wrapped VIC',
    symbol: 'WVIC',
    decimals: 18,
    logoURI: COIN_IMAGE.TOMO,
    image: COIN_IMAGE.TOMO,
    address: '0xC054751BdBD24Ae713BA3Dc9Bd9434aBe2abc1ce'
  },

  binanceSmart: {
    id: 'binancecoin',
    chainId: 56,
    name: 'Wrapped BNB',
    symbol: 'WBNB',
    decimals: 18,
    logoURI: COIN_IMAGE.BNB,
    image: COIN_IMAGE.BNB,
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
  },

  bitkub: {
    id: 'bitkub-coin',
    chainId: 96,
    name: 'Bitkub Coin',
    symbol: 'KKUB',
    decimals: 18,
    logoURI: COIN_IMAGE.KUB,
    image: COIN_IMAGE.KUB,
    address: '0x67eBD850304c70d983B2d1b93ea79c7CD6c3F6b5'
  }
}

export const MAIN_COIN_AMM = {
  ancient8: {
    id: 'ethereum',
    chainId: 28122024,
    symbol: 'ETH',
    decimals: 18,
    name: 'Ethereum',
    image: COIN_IMAGE.ETH,
    logoURI: COIN_IMAGE.ETH,
    address: ''
  },
  ancient8Mainnet: {
    id: 'ethereum',
    chainId: 888888888,
    symbol: 'ETH',
    decimals: 18,
    name: 'Ethereum',
    image: COIN_IMAGE.ETH,
    logoURI: COIN_IMAGE.ETH,
    address: ''
  },
  bitkub: {
    id: 'bitkub-coin',
    chainId: 96,
    name: 'Bitkub',
    symbol: 'KUB',
    decimals: 18,
    logoURI: COIN_IMAGE.KUB,
    image: COIN_IMAGE.KUB,
    address: ''
  },
  tomo: {
    id: 'tomochain',
    chainId: 88,
    name: 'Viction',
    symbol: 'VIC',
    decimals: 18,
    logoURI: COIN_IMAGE.TOMO,
    image: COIN_IMAGE.TOMO,
    address: ''
  },
  ether: {
    id: 'ethereum',
    chainId: 1,
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
    logoURI: COIN_IMAGE.ETH,
    image: COIN_IMAGE.ETH,
    address: ''
  },
  binanceSmart: {
    id: 'binancecoin',
    chainId: 56,
    name: 'BNB Token',
    symbol: 'BNB',
    decimals: 18,
    logoURI: COIN_IMAGE.BNB,
    image: COIN_IMAGE.BNB,
    address: ''
  },
  heco: {
    id: 'huobi-token',
    chainId: 128,
    name: 'HT Token',
    symbol: 'HT',
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2502.png',
    image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2502.png',
    address: ''
  },
  matic: {
    id: 'matic-network',
    chainId: 137,
    name: 'Matic',
    symbol: 'MATIC',
    decimals: 18,
    logoURI: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0/logo.png',
    image: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0/logo.png',
    address: ''
  },
  avax: {
    id: 'avalanche-2',
    chainId: 43114,
    name: 'Avax',
    symbol: 'AVAX',
    decimals: 18,
    logoURI: 'https://raw.githubusercontent.com/ava-labs/bridge-tokens/main/avalanche-tokens/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7/logo.png',
    image: 'https://raw.githubusercontent.com/ava-labs/bridge-tokens/main/avalanche-tokens/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7/logo.png',
    address: ''
  }
}

export const tradeType = {
  traderJoe: 'traderJoe',
  ubeSwap: 'ubeSwap',
  htMdexBSC: 'htMdexBSC',

  serumDEXV3: 'serumDEXV3',
  sushiSwap: 'sushiSwap',
  htMDex: 'htMDex',
  pancakeSwap: 'pancakeSwap',
  pancakeSwapV2: 'pancakeSwapV2',
  erc20splSwap: 'erc20splSwap',
  lunaDEX: 'lunaDEX',
  uniSwap: 'uniSwap',
  serumSwap: 'serumSwap',
  kinerc20spl: 'kinerc20spl',
  crossChainBridge: 'crossChainBridge',
  pangolinSwap: 'pangolinSwap',
  quickSwap: 'quickSwap',
  terraSwap: 'terraSwap',
  baryon: 'baryon',
  baryonHeco: 'baryonHeco',
  baryonTomo: 'baryonTomo',
  baryonTomoSponsorGas: 'baryonTomoSponsorGas',
  baryonBitkub: 'baryonBitkub',
  baryonAncient8: 'baryonAncient8',
  baryonAncient8Mainnet: 'baryonAncient8Mainnet'
}

export const TRADE_DEFAULT_CHAIN = {
  [chainType.ether]: tradeType.uniSwap,
  // [chainType.binanceSmart]: tradeType.pancakeSwapV2,
  [chainType.binanceSmart]: tradeType.baryon,
  [chainType.heco]: tradeType.baryonHeco,
  [chainType.tomo]: tradeType.baryonTomo,
  // [chainType.heco]: tradeType.htMDex,
  [chainType.matic]: tradeType.quickSwap,
  [chainType.solana]: tradeType.serumDEXV3,
  [chainType.avax]: tradeType.traderJoe,
  [chainType.celo]: tradeType.ubeSwap,
  [chainType.bitkub]: tradeType.baryonBitkub,
  [chainType.ancient8]: tradeType.baryonAncient8,
  [chainType.ancient8Mainnet]: tradeType.baryonAncient8Mainnet
}

export const CHAIN_ID = {
  [chainType.ether]: 1,
  [chainType.binanceSmart]: 56,
  [chainType.heco]: 128,
  [chainType.matic]: 137,
  [chainType.avax]: 43114,
  [chainType.celo]: 42220,
  [chainType.tomo]: 88,
  [chainType.bitkub]: 96,
  [chainType.ancient8]: 28122024,
  [chainType.ancient8Mainnet]: 888888888
}

export const STABLE_COIN = ['usdt', 'busd', 'tusd', 'dai', 'usdc', 'husd', 'fei', 'cusd', 'mcusd']

export const MAIN_COIN = {
  [chainType.ether]: 'ETH',
  [chainType.ancient8]: 'ETH',
  [chainType.ancient8Mainnet]: 'ETH',
  [chainType.binanceSmart]: 'BNB',
  [chainType.heco]: 'HT',
  [chainType.avax]: 'AVAX',
  [chainType.solana]: 'SOL',
  [chainType.matic]: 'MATIC',
  [chainType.celo]: 'CELO',
  [chainType.tomo]: 'TOMO',
  [chainType.bitkub]: 'KUB'
}

export const AMM_ADDRESS = {
  [tradeType.uniSwap]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  [tradeType.uniSwapV3]: '0xE592427A0AEce92De3Edee1F18E0157C05861564',
  [tradeType.sushiSwap]: '0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F',
  [tradeType.pancakeSwap]: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
  [tradeType.pancakeSwapV2]: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
  [tradeType.htMDex]: '0xED7d5F38C79115ca12fe6C0041abb22F0A06C300',
  [tradeType.htMdexBSC]: '0x7DAe51BD3E3376B8c7c4900E9107f12Be3AF1bA8',
  [tradeType.quickSwap]: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
  [tradeType.pangolinSwap]: '0xE54Ca86531e17Ef3616d22Ca28b0D458b6C89106',
  [tradeType.traderJoe]: '0x60aE616a2155Ee3d9A68541Ba4544862310933d4',
  [tradeType.ubeSwap]: '0xE3D8bd6Aed4F159bc8000a9cD47CffDb95F96121',
  [tradeType.ubeSwap + 'moola']: '0x56d0Ae52f33f7C2e38E92F6D20b8ccfD7Dc318Ce',
  [tradeType.baryonHeco]: CONTRACT_ROUTER[chainType.heco],
  [tradeType.baryon]: CONTRACT_ROUTER[chainType.binanceSmart],
  [tradeType.baryonTomo]: CONTRACT_ROUTER[chainType.tomo],
  [tradeType.baryonTomoSponsorGas]: ROUTER_SPONSOR_GAS[chainType.tomo],
  [tradeType.baryonBitkub]: CONTRACT_ROUTER[chainType.bitkub],
  [tradeType.baryonAncient8]: CONTRACT_ROUTER[chainType.ancient8],
  [tradeType.baryonAncient8Mainnet]: CONTRACT_ROUTER[chainType.ancient8Mainnet]
}

export const STABLE_COIN_CHAIN = {
  [chainType.binanceSmart]: {
    name: 'BUSD Token',
    symbol: 'BUSD',
    address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://coin98.s3.amazonaws.com/1M4PPVwJga2kEn6T',
    image: 'https://coin98.s3.amazonaws.com/1M4PPVwJga2kEn6T',
    id: 'binance-usd'
  },
  [chainType.ether]: {
    name: 'TetherUSD',
    symbol: 'USDT',
    address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    chainId: 1,
    decimals: 6
  },
  [chainType.heco]:
  {
    name: 'Heco-Peg HUSD Token',
    symbol: 'HUSD',
    address: '0x0298c2b32eaE4da002a15f36fdf7615BEa3DA047',
    chainId: 128,
    decimals: 8,
    image: 'https://coin98.s3.amazonaws.com/ZpCQe9gYijUfxbnu'

  },
  // {
  //   cgkId: '0',
  //   address: '0xa6fa2651ee0cf70626d01ebe0bb7f1ac17172e46',
  //   symbol: 'C98HTTEST',
  //   name: 'C98HT',
  //   decimals: 18,
  //   image: 'https://coin98.s3.amazonaws.com/9d7zDyZeu5JR7D61',
  //   balance: '701114489.8570572'
  // },
  [chainType.matic]: {
    name: 'TetherUSD',
    symbol: 'USDT',
    address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    chainId: 137,
    decimals: 6
  },
  [chainType.avax]: {
    name: 'TetherUSD',
    symbol: 'USDT',
    address: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    chainId: 43114,
    decimals: 6
  },
  [chainType.celo]: {
    name: 'Celo Dollar',
    symbol: 'cUSD',
    address: '0x765DE816845861e75A25fCA122bb6898B8B1282a',
    chainId: 42220,
    decimals: 18
  },
  [chainType.tomo]: {
    name: 'USDT',
    symbol: 'USDT',
    address: '0x381B31409e4D220919B2cFF012ED94d70135A59e',
    chainId: 88,
    decimals: 6,
    image: 'https://coin98.s3.amazonaws.com/8qtsR7ZdcbaQKIwN'
  },
  [chainType.bitkub]: {
    name: 'USDT Kub',
    symbol: 'KUSDT',
    address: '0x7d984c24d2499d840eb3b7016077164e15e5faa6',
    chainId: 96,
    decimals: 18,
    image: 'https://coin98.s3.amazonaws.com/qSxhJggIgRo1zKfb'
  },
  [chainType.ancient8]: {
    name: 'TetherUSD',
    symbol: 'USDT',
    address: '0x5DfB3D712629b4bBEf2AA485dFF65C1A7d81E5e7',
    chainId: 28122024,
    decimals: 18,
    image: 'https://coin98.s3.amazonaws.com/qSxhJggIgRo1zKfb'
  },

  [chainType.ancient8Mainnet]: {
    name: 'USDC',
    symbol: 'usdc',
    address: '0xB3fCcD379ad66CED0c91028520C64226611A48c9',
    chainId: 888888888,
    decimals: 6,
    image: 'https://file.coin98.com/images/bxuspxmvfpbs7ali-s34l9fupoy1sgvnz-3qzlkh6yydhmug10-xpmgadc1kg1hxcfx-O9fwrUzUvuTfzW4g.png'
  }
}

export const tradeTypeBary = {
  [chainType.heco]: tradeType.baryonHeco,
  [chainType.binanceSmart]: tradeType.baryon,
  [chainType.tomo]: tradeType.baryonTomo,
  [chainType.bitkub]: tradeType.baryonBitkub,
  [chainType.ancient8]: tradeType.baryonAncient8,
  [chainType.ancient8Mainnet]: tradeType.baryonAncient8Mainnet
}

export const TRADE_DATA = [

  {
    isTTL: true,
    chain: chainType.binanceSmart,
    key: tradeType.pancakeSwap,
    image: 'web_pancakes_swap',
    name: 'PancakeSwap Old',
    shortName: 'Pancake Old',
    isAMM: true,
    subName: CHAIN_DATA.binanceSmart.name,
    pairDefault: {
      default: { symbol: MAIN_COIN_AMM.binanceSmart.symbol, address: MAIN_COIN_AMM.binanceSmart.address },
      data: [
        { symbol: MAIN_COIN_AMM.binanceSmart.symbol, address: MAIN_COIN_AMM.binanceSmart.address },
        { address: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', symbol: 'DAI' },
        { address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', symbol: 'BUSD' },
        { address: '0x55d398326f99059fF775485246999027B3197955', symbol: 'USDT' }
      ]
    }
  },
  {
    isTTL: true,
    chain: chainType.binanceSmart,
    key: tradeType.pancakeSwapV2,
    image: 'web_pancakes_swap',
    name: 'PancakeSwap New',
    shortName: 'Pancake New',
    isAMM: true,
    subName: CHAIN_DATA.binanceSmart.name,
    pairDefault: {
      default: { symbol: MAIN_COIN_AMM.binanceSmart.symbol, address: MAIN_COIN_AMM.binanceSmart.address },
      data: [
        { symbol: MAIN_COIN_AMM.binanceSmart.symbol, address: MAIN_COIN_AMM.binanceSmart.address },
        { address: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', symbol: 'DAI' },
        { address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', symbol: 'BUSD' },
        { address: '0x55d398326f99059fF775485246999027B3197955', symbol: 'USDT' }
      ]
    }
  },

  {
    chain: chainType.heco,
    key: tradeType.htMDex,
    image: 'web_mdex',
    name: 'MDEX',
    isAMM: true,
    subName: CHAIN_DATA.heco.name,
    pairDefault: {
      default: { symbol: MAIN_COIN_AMM.heco.symbol, address: MAIN_COIN_AMM.heco.address },
      data: [
        { symbol: MAIN_COIN_AMM.heco.symbol, address: MAIN_COIN_AMM.heco.address },
        { address: '0x0298c2b32eaE4da002a15f36fdf7615BEa3DA047', symbol: 'HUSD' },
        { address: '0xa71EdC38d189767582C38A3145b5873052c3e47a', symbol: 'USDT' }
      ]
    }
  },
  {
    chain: chainType.binanceSmart,
    key: tradeType.baryon,
    image: 'baryon',
    name: 'BARYON',
    isAMM: true,
    subName: 'BARYON',
    pairDefault: {
      default: { symbol: MAIN_COIN_AMM.binanceSmart.symbol, address: MAIN_COIN_AMM.binanceSmart.address },
      data: [
        { symbol: MAIN_COIN_AMM.binanceSmart.symbol, address: MAIN_COIN_AMM.binanceSmart.address },
        { address: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', symbol: 'DAI' },
        { address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', symbol: 'BUSD' },
        { address: '0x55d398326f99059fF775485246999027B3197955', symbol: 'USDT' }
      ]
    }
  },

  {
    chain: chainType.heco,
    key: tradeType.baryonHeco,
    image: 'baryon',
    name: 'BARYON',
    isAMM: true,
    subName: 'BARYON',
    pairDefault: {
      default: { symbol: MAIN_COIN_AMM.heco.symbol, address: MAIN_COIN_AMM.heco.address },
      data: [
        { symbol: MAIN_COIN_AMM.heco.symbol, address: MAIN_COIN_AMM.heco.address },
        { address: STABLE_COIN_CHAIN.heco.address, symbol: STABLE_COIN_CHAIN.heco.symbol }
      ]
    }
  },

  {
    chain: chainType.tomo,
    key: tradeType.baryonTomo,
    image: 'baryon',
    name: 'BARYON',
    isAMM: true,
    subName: 'BARYON',
    pairDefault: {
      default: { symbol: MAIN_COIN_AMM.tomo.symbol, address: MAIN_COIN_AMM.tomo.address },
      data: [
        { symbol: MAIN_COIN_AMM.tomo.symbol, address: MAIN_COIN_AMM.tomo.address },
        { address: STABLE_COIN_CHAIN.tomo.address, symbol: STABLE_COIN_CHAIN.tomo.symbol }
      ]
    }
  },

  {
    chain: chainType.bitkub,
    key: tradeType.baryonBitkub,
    image: 'baryon',
    name: 'BARYON',
    isAMM: true,
    subName: 'BARYON',
    pairDefault: {
      default: { symbol: MAIN_COIN_AMM.bitkub.symbol, address: MAIN_COIN_AMM.bitkub.address },
      data: [
        { symbol: MAIN_COIN_AMM.bitkub.symbol, address: MAIN_COIN_AMM.bitkub.address },
        { address: STABLE_COIN_CHAIN.bitkub.address, symbol: STABLE_COIN_CHAIN.bitkub.symbol }
      ]
    }
  },
  {
    chain: chainType.ancient8,
    key: tradeType.baryonAncient8,
    image: 'baryon',
    name: 'BARYON',
    isAMM: true,
    subName: 'BARYON',
    pairDefault: {
      default: { symbol: MAIN_COIN_AMM.ancient8.symbol, address: MAIN_COIN_AMM.ancient8.address },
      data: [
        { symbol: MAIN_COIN_AMM.ancient8.symbol, address: MAIN_COIN_AMM.ancient8.address },
        { address: STABLE_COIN_CHAIN.ancient8.address, symbol: STABLE_COIN_CHAIN.ancient8.symbol }
      ]
    }
  },
  {
    chain: chainType.ancient8Mainnet,
    key: tradeType.baryonAncient8Mainnet,
    image: 'baryon',
    name: 'BARYON',
    isAMM: true,
    subName: 'BARYON',
    pairDefault: {
      default: { symbol: MAIN_COIN_AMM.ancient8Mainnet.symbol, address: MAIN_COIN_AMM.ancient8Mainnet.address },
      data: [
        { symbol: MAIN_COIN_AMM.ancient8Mainnet.symbol, address: MAIN_COIN_AMM.ancient8Mainnet.address },
        { address: STABLE_COIN_CHAIN.ancient8Mainnet.address, symbol: STABLE_COIN_CHAIN.ancient8Mainnet.symbol }
      ]
    }
  }

]

//

export const ADD_CHAIN = {

  [chainType.binanceSmart]: {
    chainId: CHAIN_DATA[chainType.binanceSmart].chainId,
    chainName: CHAIN_DATA[chainType.binanceSmart].name,

    nativeCurrency: {
      name: MAIN_COIN_AMM.binanceSmart.name,
      symbol: MAIN_COIN_AMM.binanceSmart.symbol,
      decimals: MAIN_COIN_AMM.binanceSmart.decimals
    },
    rpcUrls: [CHAIN_DATA[chainType.binanceSmart].rpcURL]
  },
  [chainType.tomo]: {
    chainId: CHAIN_DATA[chainType.tomo].chainId,
    chainName: CHAIN_DATA[chainType.tomo].name,

    nativeCurrency: {
      name: MAIN_COIN_AMM.tomo.name,
      symbol: MAIN_COIN_AMM.tomo.symbol,
      decimals: MAIN_COIN_AMM.tomo.decimals
    },
    rpcUrls: [CHAIN_DATA[chainType.tomo].rpcURL]
  },
  [chainType.bitkub]: {
    chainId: CHAIN_DATA[chainType.bitkub].chainId,
    chainName: CHAIN_DATA[chainType.bitkub].name,

    nativeCurrency: {
      name: MAIN_COIN_AMM.bitkub.name,
      symbol: MAIN_COIN_AMM.bitkub.symbol,
      decimals: MAIN_COIN_AMM.bitkub.decimals
    },
    rpcUrls: [CHAIN_DATA[chainType.bitkub].rpcURL]
  },
  [chainType.ancient8]: {
    chainId: CHAIN_DATA[chainType.ancient8].chainId,
    chainName: CHAIN_DATA[chainType.ancient8].name,

    nativeCurrency: {
      name: MAIN_COIN_AMM.ancient8.name,
      symbol: MAIN_COIN_AMM.ancient8.symbol,
      decimals: MAIN_COIN_AMM.ancient8.decimals
    },
    rpcUrls: [CHAIN_DATA[chainType.ancient8].rpcURL]
  },
  [chainType.ancient8Mainnet]: {
    chainId: CHAIN_DATA[chainType.ancient8Mainnet].chainId,
    chainName: CHAIN_DATA[chainType.ancient8Mainnet].name,

    nativeCurrency: {
      name: MAIN_COIN_AMM.ancient8Mainnet.name,
      symbol: MAIN_COIN_AMM.ancient8Mainnet.symbol,
      decimals: MAIN_COIN_AMM.ancient8Mainnet.decimals
    },
    rpcUrls: [CHAIN_DATA[chainType.ancient8Mainnet].rpcURL]
  }
}

export const NAME_VERSION_PERMIT = {
  Coin98: {
    name: 'Coin98VRC25',
    version: '1'
  },
  'Baryon LPs': {
    name: 'Baryon LPs',
    version: '1'
  },
  vrc25: {
    name: 'VRC25',
    version: '1'
  },
  'Wrapped Viction': {
    name: 'WVIC',
    version: '1'
  },
  Saros: {
    name: 'SarosVRC25',
    version: '1'
  }

}
