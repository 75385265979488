import React from 'react'
import Slider, { createSliderWithTooltip } from 'rc-slider'

import './style.scss'
import { useSelector } from 'react-redux'
const SliderWithTooltip = createSliderWithTooltip(Slider)

function SliderComponent ({ onChange, value, min = 0, max = 100, step = 1, marks, tipFormatter, className, onAfterChange }) {
  const isDarkMode = useSelector(state => state.themeRedux) === 'dark'

  return (
    <div className="slider-container" >
      <SliderWithTooltip
        step={step}
        className={className}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        onAfterChange={onAfterChange}
        marks={marks}
        tipFormatter={tipFormatter}
        railStyle={{
          height: 2,
          background: isDarkMode ? '#777777' : '#f0f0f0'
        }}
        handleStyle={{
          height: 10,
          width: 10,
          backgroundColor: '#f3ce54',
          border: 0,
          outline: 'none'
        }}
        trackStyle={{
          background: '#f3ce54',
          height: 2
        }}
      />
    </div>
  )
}

export default SliderComponent
