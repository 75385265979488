import initState from '../lib/initState'
import createReducer from '../lib/reducerConfig'
import { KEY_ACTION } from '../lib/constants'
import { selectDefaultChain } from 'common/poolEvm/constants'

export const langRedux = createReducer('en', {
  [KEY_ACTION.SET_LANG] (state, action) {
    return action.payload
  }
})

export const isSession = createReducer(initState.boolFalse, {
  [KEY_ACTION.SET_IS_SESSION] (state, action) {
    return action.payload
  }
})

export const settingRedux = createReducer(initState.objEmpty, {
  [KEY_ACTION.SET_CONFIG] (state, action) {
    return action.payload
  }
})

export const deviceId = createReducer(initState.stringEmpty, {
  [KEY_ACTION.SET_DEVICE_ID] (state, action) {
    return action.payload
  }
})

export const themeRedux = createReducer('dark', {
  [KEY_ACTION.SET_THEME_MODE] (state, action) {
    return action.payload
  }
})

export const loadingPool = createReducer(initState.objEmpty, {
  [KEY_ACTION.SET_LOADING_POOL] (state, action) {
    return action.payload
  }
})

export const chainActive = createReducer(selectDefaultChain, {
  [KEY_ACTION.SET_CHAIN_ACTIVE] (state, action) {
    return action.payload
  }
})

export const signature = createReducer(initState.stringEmpty, {
  [KEY_ACTION.SET_SIG] (state, action) {
    return action.payload
  }
})

export const currencyRedux = createReducer(initState.initCurrency, {
  [KEY_ACTION.SET_CURRENCY_REDUX] (state, action) {
    return action.payload
  }
})

export const listFavoriteTokenRedux = createReducer(initState.arrayEmpty, {
  [KEY_ACTION.SET_LIST_FAVORITE_TOKEN] (state, action) {
    return action.payload
  }
})

export const listFavoritePoolRedux = createReducer(initState.arrayEmpty, {
  [KEY_ACTION.SET_LIST_FAVORITE_POOL] (state, action) {
    return action.payload
  }
})
