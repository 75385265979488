import React, { useState, useEffect } from 'react'

import cn from 'classnames'
import ItemTable from './TableItem'
import { useTranslation } from 'react-i18next'
import EmptyData from 'components/common/EmptyData'
import './style.scss'
import { getLength } from 'common/functions'
import Loading from 'components/common/Loading'
import PuffLoading from 'components/common/PuffLoading'
import Item from 'antd/lib/list/Item'

const columnsDefault = [
  {
    dataIndex: 'name',
    width: 20,
    icon: '',
    className: 'left',
    title: 'name'
  },
  {
    dataIndex: 'age',
    title: 'age',
    width: 20,
    icon: '',
    className: 'center'
  },
  {
    dataIndex: 'address',
    title: 'address',
    width: 20,
    icon: '',
    className: 'right'
  },
  {
    dataIndex: 'test',
    title: 'test',
    width: 40,
    icon: '',
    className: 'right'
  }
]

const dataSourceDefault = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    test: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam voluptates quis vitae nobis! Quibusdam error deleniti odit nisi sit. Mollitia error vitae fuga natus, vero consequatur possimus sunt in iusto?'
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
    test: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam voluptates quis vitae nobis! Quibusdam error deleniti odit nisi sit. Mollitia error vitae fuga natus, vero consequatur possimus sunt in iusto?'
  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
    test: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam voluptates quis vitae nobis! Quibusdam error deleniti odit nisi sit. Mollitia error vitae fuga natus, vero consequatur possimus sunt in iusto?'
  },
  {
    key: '4',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
    test: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam voluptates quis vitae nobis! Quibusdam error deleniti odit nisi sit. Mollitia error vitae fuga natus, vero consequatur possimus sunt in iusto?'
  },
  {
    key: '4',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
    test: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam voluptates quis vitae nobis! Quibusdam error deleniti odit nisi sit. Mollitia error vitae fuga natus, vero consequatur possimus sunt in iusto?'
  },
  {
    key: '4',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
    test: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam voluptates quis vitae nobis! Quibusdam error deleniti odit nisi sit. Mollitia error vitae fuga natus, vero consequatur possimus sunt in iusto?'
  },
  {
    key: '4',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
    test: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam voluptates quis vitae nobis! Quibusdam error deleniti odit nisi sit. Mollitia error vitae fuga natus, vero consequatur possimus sunt in iusto?'
  },
  {
    key: '4',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
    test: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam voluptates quis vitae nobis! Quibusdam error deleniti odit nisi sit. Mollitia error vitae fuga natus, vero consequatur possimus sunt in iusto?'
  },
  {
    key: '4',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
    test: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam voluptates quis vitae nobis! Quibusdam error deleniti odit nisi sit. Mollitia error vitae fuga natus, vero consequatur possimus sunt in iusto?'
  },
  {
    key: '4',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
    test: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam voluptates quis vitae nobis! Quibusdam error deleniti odit nisi sit. Mollitia error vitae fuga natus, vero consequatur possimus sunt in iusto?'
  },
  {
    key: '4',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
    test: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam voluptates quis vitae nobis! Quibusdam error deleniti odit nisi sit. Mollitia error vitae fuga natus, vero consequatur possimus sunt in iusto?'
  },
  {
    key: '4',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
    test: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam voluptates quis vitae nobis! Quibusdam error deleniti odit nisi sit. Mollitia error vitae fuga natus, vero consequatur possimus sunt in iusto?'
  }
]

export const CustomTable = ({
  columns = columnsDefault,
  dataSource = dataSourceDefault,
  isLoading = false,
  onClickRow,
  isShowSkeleton,
  refTable,
  onScroll,
  isLoadMore,
  className,
  isLink
}) => {
  const [isSortedUp, setIsSortedUp] = useState(false)

  const [dataTable, setDataTable] = useState(dataSource)

  const { t } = useTranslation()

  useEffect(() => {
    setDataTable(dataSource)
  }, [dataSource])

  const onRowClicked = (row) => () => {
    if (typeof onClickRow === 'function') {
      onClickRow(row)
    }
  }
  const handleSwitchSort = (dataIndex) => () => {
    setIsSortedUp(!isSortedUp)
    const listData = [...dataTable]
    const sortedArr = !isSortedUp
      ? listData.sort((a, b) => (+a[dataIndex] - +b[dataIndex] >= 0 ? 1 : -1))
      : listData.sort((a, b) => (+a[dataIndex] - +b[dataIndex] <= 0 ? 1 : -1))
    setDataTable(sortedArr)
  }

  return (
    <div
      className={cn(
        'table-wrapper', className
      )}
      ref={refTable}
      onScroll={onScroll || (() => {})}
    >
      {isLoading
        ? (
          <div className={cn('table-loading')}>
            <PuffLoading />
          </div>
        )
        : getLength(dataTable) === 0
          ? (
            <EmptyData />
          )
          : (
            <>
              <table className={cn('table-container')}>
                <thead className={cn('table-container__header')}>
                  <tr >
                    {columns.map((col) => {
                      if (col.hide) return null
                      return (
                        <th
                          style={{ width: `${col.width}%` }}
                          key={col.dataIndex}
                        >
                          <div
                            className={cn(
                              `justify-${col.align}`
                            )}
                          >
                            {col.title}
                          </div>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody className={cn('table-container__body')}>
                  {dataTable.map((row) => (
                    <tr
                      key={row.key}
                      onClick={onRowClicked(row)}
                    >
                      <ItemTable
                        isLink={isLink}
                        isSortedUp={isSortedUp}
                        columns={columns}
                        data={row}
                      />
                    </tr>
                  ))}
                </tbody>
              </table>
              {isLoadMore && (
                <div className="table-loadmore">
                  <PuffLoading/>
                </div>
              )}
            </>
          )}
    </div>
  )
}
