import BaseAPI from 'controller/api/BaseAPI'
import { getLength } from './functions'

export default class MarketScan {
  static async getCoinGeckoDetail (id, symbol, isExact, signal) {
    const coinDetail = await BaseAPI.getData(
      'coin/cgk/detail',
      { id, symbol, isExact },
      { signal }
    )
    return coinDetail
  }

  static getDetailCgk = async ({ id, symbol }) => {
    const response = await BaseAPI.getData('coin/cgk/detail', {
      id,
      symbol
    })
    return response
  }

  static async getRateCryptoCurrency (id, currency = 'usd') {
    if (getLength(id) > 0) {
      const coinPrice = await BaseAPI.getData('coin/cgk/price', {
        id,
        currency
      })
      return coinPrice || 0
    }
    return null
  }

  static async getChartCoin (id, day = 1, interval = 3600, signal) {
    if (getLength(id) > 0) {
      try {
        const chartData = await BaseAPI.getData('coin/cgk/chart', { id, day, interval }, { signal })

        return chartData
      } catch (error) {
        return []
      }
    }
    return null
  }
}
