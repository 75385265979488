import images from 'assets/images'
import { CHAIN_DATA } from 'common/constants'
import { formatPrice } from 'common/functions'
import { CONTRACT_FARM_STAKE_V2, CONTRACT_FARM_STAKE_V1 } from 'common/poolEvm/constants'
import { get } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import './style.scss'

function InfoFarmBox ({ dataFarm, renderSymbolLP, stakeToken, quoteToken, isStakeFarm, token0, token1 }) {
  const { t } = useTranslation()
  const chainActive = useSelector((state) => state.chainActive)

  const isV2 = get(dataFarm, 'isV2')

  const addressContract = isV2 ? (get(dataFarm, 'factoryAddress') || CONTRACT_FARM_STAKE_V2[chainActive]) : CONTRACT_FARM_STAKE_V1[chainActive]

  const SCAN_URL = CHAIN_DATA[get(dataFarm, 'chain')].scan

  const totalUSD = get(dataFarm, 'totalStakedUSD') || get(dataFarm, 'totalLiquidity', 0)

  const isNotPrice = dataFarm?.isNotPrice

  const totalStaked = formatPrice(get(dataFarm, 'totalStaked', 0), false, true) + ' ' + renderSymbolLP

  const isStake = !token0

  const listInfoStake = [
    {
      name: t('viewContract'),
      icon: images.iconViewBlock,
      address: `${SCAN_URL}/address/${isStakeFarm ? addressContract : get(dataFarm, 'lpAddresses')}`
    },
    {
      name: t('seeTokenInfo'),
      icon: images.iconViewBlock,
      address: `token/${get(stakeToken, 'address')}`
    }
  ]

  const listInfoFarms = [
    {
      name: t('viewContract'),
      icon: images.iconViewBlock,
      address: `${SCAN_URL}/address/${isStakeFarm ? addressContract : get(dataFarm, 'lpAddresses')}`
    },
    {
      name: t('pairInfo'),
      icon: images.iconPairInfo,
      address: `pool/${isStakeFarm ? get(stakeToken, 'address') : get(dataFarm, 'lpAddresses')}`
    },
    {
      name: `Get ${renderSymbolLP}`,
      icon: images.iconC98BNB,
      address: `add/${get(isStakeFarm ? token0 : stakeToken, 'address')}/${get(isStakeFarm
        ? token1
        : quoteToken,
      'address'
      )}`
    }
  ]

  const renderItemInfo = () => {
    const data = isStake ? listInfoStake : listInfoFarms

    return data.map((item, i) => {
      return (
        <a href={item.address} className="liquidity-info-menu__item" key={i} target='_blank' rel="noreferrer">
          <img src={item.icon} className="icon-farm" alt="" />
          <div className="item-text-farm">{item.name}</div>
        </a>
      )
    })
  }
  return (
    <div className="info-show__liquidity">
      <div className="liquidity-info-balance">
        <div className="info-text">{t('liquidity')}</div>

        <div className="info-balance">
          {/* <CountUpComponent
            endNum={get(dataFarm, 'totalLiquidity', 0)}
            prefixValue="~$ "
          /> */}

          {isNotPrice ? totalStaked : '~' + formatPrice(totalUSD) }
        </div>
      </div>

      <div className="liquidity-info-menu">{renderItemInfo()}</div>
    </div>
  )
}

export default InfoFarmBox
