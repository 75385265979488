import images from 'assets/images'
import { convertDateMomentHistory, formatAddress, formatPrice, getLength } from 'common/functions'
import { get } from 'lodash'
import Pagination from 'pages/PoolScreen/components/Pagination'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MenuFilter from '../MenuFilter'

import './style.scss'

function TableList ({ arrItem }) {
  const { t } = useTranslation()
  const [filterChoose, setFilterChoose] = useState('')
  const [widthResize, setWidthResize] = useState(window.innerWidth)

  const [currentPage, setCurrentPage] = useState(1)
  const [postPage, setPostPage] = useState(10)

  const arrItemFinal = useMemo(() => {
    let dataArrFilter = [...arrItem]
    if (getLength(filterChoose) !== 0) {
      dataArrFilter = arrItem.filter(item => item.event === filterChoose)
    }
    return dataArrFilter
  }, [filterChoose, arrItem])

  const lastPost = currentPage * postPage
  const firstPost = lastPost - postPage
  const currentPost = arrItemFinal?.slice(firstPost, lastPost)
  const pageNum = []
  for (let i = 1; i <= Math.ceil(arrItemFinal?.length / postPage); i++) {
    pageNum.push(i)
  }

  const handleSelectedPage = page => {
    const { selected } = page
    // cancel
    const nextPage = selected + 1
    setCurrentPage(nextPage)
  }

  const handleFilter = (item) => () => {
    setFilterChoose(item)
  }

  const handleRefreshFilter = () => {
    setFilterChoose('')
  }

  const handleResize = () => {
    setWidthResize(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    setCurrentPage(1)
  }, [filterChoose])

  const renderContentTable = () => {
    return currentPost.map((item, i) => {
      return (
        <a className="body-content" key={i} href='/'>
          <div className="td td__info">
            <img src={get(item, 'img')} alt="" className='image-item' />

            <div className="info-text">
              <div className="info-text__title">Foxxy</div>
              <div className="info-text__name">
                {get(item, 'name')}
              </div>
            </div>
          </div>
          <div className={`td td__event ${get(item, 'event') === 'sold' && 'td__event--red-text'}`}>{t(get(item, 'event'))}</div>
          <div className="td td__price">
            <div className="price-box">
              <div className="price-number">
                <img src={images.iconC98} alt="" className='icon-coin' />
                {formatPrice(get(item, 'price'), null, true)}
              </div>

              <div className="price-balance">
                (~ {formatPrice(get(item, 'price') * 1.5)})
              </div>
            </div>
          </div>
          <div className="td td__from">
            <img src={get(item, 'img')} alt="" className='image-item' />

            <div className="from-text">
              <div className="from-text__address">
                {formatAddress(get(item, 'from'))}
              </div>
              <div className="from-text__name">
                {get(item, 'name')}
              </div>
            </div>
          </div>
          <div className="td td__to">
            <img src={get(item, 'img')} alt="" className='image-item' />

            <div className="from-text">
              <div className="from-text__address">
                {formatAddress(get(item, 'from'))}
              </div>
              <div className="from-text__name">
                {get(item, 'name')}
              </div>
            </div>
          </div>
          <div className="td td__date">
            <div className="date-time">
              {convertDateMomentHistory(get(item, 'date'), widthResize < 900 ? 'DD/MM' : 'DD/MM/YYYY')}, {convertDateMomentHistory(get(item, 'date'), widthResize < 900 ? 'HH/mm' : 'LT')}
            </div>

            {/* <img src={images.iconExplorer} alt="" className="icon-explorer" onClick={handleExplorer} /> */}
          </div>
        </a>
      )
    })
  }

  return (
    <div className='table-list'>
      <MenuFilter filterChoose={filterChoose} handleFilter={handleFilter} handleRefreshFilter={handleRefreshFilter} />

      <div className="table-container">
        <div className="table-container__header">
          <div className="th th__info">{t('item')}</div>
          <div className="th th__event">{t('event')}</div>
          <div className="th th__price">{t('price')}</div>
          <div className="th th__from">{t('from')}</div>
          <div className="th th__to">{t('to')}</div>
          <div className="th th__date">{t('date')}</div>
        </div>

        <div className="table-container__body">
          {renderContentTable()}
        </div>

        <div className="pagination-container">
          <Pagination
            totalPage={pageNum.length}
            handleSelectedPage={handleSelectedPage}
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(TableList)
