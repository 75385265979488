/* eslint-disable multiline-ternary */
import Button from 'components/common/Button'
import React, { useEffect, useMemo, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import images from 'assets/images'
import cn from 'classnames'

import './style.scss'
import { useSelector } from 'react-redux'
import { Portal } from 'containers/Portal'
const ModalContent = ({
  content,
  noButton,
  type,
  onCancelPopup,
  onOk,
  okText,
  isDisable,
  noIcon,
  isFullHeight
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const onOkClick = async () => {
    setIsLoading(true)
    await onOk()
    setIsLoading(false)
  }

  const renderButton = () => {
    if (noButton) return null
    switch (type) {
    case 'success':
      return (
        <Button
          isFullWidth
          className="button-item button-item--singe"
          onClick={onCancelPopup}
        >
          {okText || 'OK'}
        </Button>
      )
    case 'save-info':
      return (
        <Button
          isFullWidth
          className="button-item button-item--singe"
          onClick={onOk}
        >
          {okText || 'OK'}
        </Button>
      )
    case 'error':
      return (
        <Button
          className="button-item button-item--singe"
          type="red"
          onClick={onCancelPopup}
        >
          {okText || 'OK'}
        </Button>
      )

    case 'warning':
    case 'warningErr':
    case 'confirm':
    case 'connect':
      return (
        <div className="button-list">
          <Button
            type="border-popup"
            className="button-item button-item--cancel"
            disabled={isLoading}
            block
            onClick={onCancelPopup}
          >
              Cancel
          </Button>
          <Button
            className="button-item"
            block
            onClick={onOkClick}
            isLoading={isLoading}
            isDisable={isDisable || isLoading}
          >
            {okText || 'Confirm'}
          </Button>
        </div>
      )
    case 'no-button':
      return null
    }
  }

  return (
    <>
      <div
        className={`modal__content ${isFullHeight ? 'height-full' : ''} ${
          type === 'none' ? 'modal__content--mb0' : ''
        }`}
      >
        {content}
      </div>
      {!noIcon && !noButton && (
        <div className="modal__button">{renderButton()}</div>
      )}
    </>
  )
}

const Modal = ({
  children,
  content,
  className,
  title,
  renderDomTitle,
  isOpen,
  onClose,
  onOk,
  okText,
  icon,
  noIcon,
  type,
  isDisable,
  onCancel,
  isScroll,
  noButton,
  preventClose,
  noExit,
  noPadding,
  isNoPaddingModal,
  isFullHeight,
  backgroundTrans,
  isSwapAllScreen,
  containerClass,
  isCustomModal = false,
  isConfirmPassWord,
  classNameContent,
  id

}) => {
  const isDarkMode = useSelector(state => state.themeRedux) === 'dark'

  // -- close 15s
  const timer = useMemo(() => {
    if (type === 'error' && isOpen) {
      return setTimeout(() => {
        onCancelPopup()
      }, 10000)
    }
  }, [type, isOpen])

  const propsMotion = useMemo(() => {
    if (isSwapAllScreen) {
      return {
        initial: { right: '2.5rem', bottom: '2vh', translateX: '120%' },
        animate: { right: '2.5rem', bottom: '3vh', translateX: '0' },
        exit: { right: '2.5rem', bottom: 0, translateX: '120%' }
      }
    } else {
      return {
        initial: { y: '-100%' },
        animate: { y: 0 },
        exit: { y: '-200%' }
      }
    }
  })

  const handleClickInside = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const onCancelPopup = async () => {
    if (timer) {
      await clearTimeout(timer)
    }

    if (typeof onCancel === 'function') {
      onCancel()
    }
    onClose && onClose()
  }

  const renderIcon = () => {
    switch (type) {
    case 'notice':
      return <img src={images.iconNoti} className="modal-icon" />
    case 'success':
      return <img src={images.iconCheckWhite} className="modal-icon" />
    case 'error':
      // return <img src={images.iconError} className="modal-icon" />
      return <img src={images.iconCancelPass} className="modal-icon" />
    case 'none':
      return null
    case 'confirm':
      return <img src={images[isDarkMode ? 'ballModal' : 'ballModal']} className="modal-icon" />
    case 'warning':
      return <img src={images.iconBallWarningBaryon} className="modal-icon" />
    case 'warningErr':
      return <img src={images.iconWarningErr} className="modal-icon" />
    default:
      return <img src={images[isDarkMode ? 'ballModal' : 'ballModal']} className="modal-icon" />
    }
  }

  const modalClassNames = cn(
    'modal',
    isNoPaddingModal && 'modal-no-padding',
    containerClass
  )

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  return (
    <Portal id={id}>
      <AnimatePresence layout>
        {isOpen && (
          <div className={cn(isDarkMode ? 'dark-mode' : 'light-mode')}>
            <motion.div
              key={`my-modal-${Math.random()}`}
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={noExit ? null : { opacity: 0 }}
              className={modalClassNames}
              onClick={preventClose ? () => {} : onCancelPopup}
            >
              <motion.div
                {...propsMotion}
                className={`modal-content ${
                  noIcon ? 'modal-content--no-icon' : ''
                } ${isScroll ? 'scroll' : ''} ${
                  noPadding ? 'modal-content--no-padding' : ''
                }  ${backgroundTrans ? 'modal-content--trans' : ''} ${
                  type === 'none' ? 'modal-content--none' : ''
                }
             ${className}`}
                onClick={handleClickInside}
              >
                {isCustomModal ? (
                  content
                ) : (
                  <>
                    {noIcon ? null : (
                      <div className={`modal__icon ${type && `modal--${type}`}`}>
                        {icon || renderIcon()}
                        <h4 className="icon-title">{title || renderDomTitle}</h4>
                      </div>
                    )}
                    <div className={`modal-content__body ${classNameContent || ''} ${isConfirmPassWord && 'confirm-password'}`}>
                      <ModalContent
                        content={content || children}
                        noButton={noButton}
                        type={type}
                        onCancelPopup={onCancelPopup}
                        onOk={onOk}
                        okText={okText}
                        isDisable={isDisable}
                        noIcon={noIcon}
                        isFullHeight={isFullHeight}
                      />
                    </div>
                  </>
                )}
              </motion.div>
            </motion.div>
          </div>

        )}
      </AnimatePresence>
    </Portal>
  )
}

export default Modal
