import images from 'assets/images'
import { formatNumberBro, upperCase } from 'common/functions'
import { get } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './style.scss'
import { numPriceImpact } from 'pages/SwapScreen'

const PreviewOrder = ({
  baseCoin,
  pairCoin,
  txtNumFrom,
  txtNumTo,
  priceImpact,
  rate,
  isSponsorGas,
  isWrap
}) => {
  const { t } = useTranslation()

  const renderIcon = (coinSelected) => {
    const icon = get(coinSelected, 'logoURI', '') || get(coinSelected, 'image', '')
    if (icon) return icon
    return images.icoUnknown
  }

  return (
    <div className="preview-order">
      <div className="preview-order__item">
        <div className="img-token">
          <img src={renderIcon(baseCoin)} alt="" />
        </div>
        <div className="info-order">
          <div className="title">{t('youPay')}</div>
          <div className="price">
            {formatNumberBro({ number: txtNumFrom })}{' '}
            <span>{upperCase(baseCoin.symbol)}</span>
          </div>
        </div>
      </div>
      <div className={`preview-order__item ${!isWrap && 'preview-order__not-wrap'}`}>
        <div className="img-token">
          <img src={renderIcon(pairCoin)} alt="" />
        </div>
        <div className="info-order">
          <div className="title">
            {t('youReceive')} <span>({`${t('estimate')}`})</span>
          </div>
          <div className="price">
            {formatNumberBro({ number: txtNumTo })}{' '}
            <span>{upperCase(pairCoin.symbol)}</span>
          </div>
        </div>
      </div>

      {!isWrap &&
          <div className="preview-order__info">
            <div className="info-item">
              <p>{t('rate')}</p>
              <div className="info-item__price">{rate}</div>
            </div>
            <div className="info-item">
              <p>{t('priceImpact')}</p>
              <div
                className={`info-item__price ${
                  parseFloat(priceImpact) >= numPriceImpact ? 'info-item__price--error' : ''
                }`}
              >
                {parseFloat(priceImpact).toFixed(2) === '0.00'
                  ? '< 0.01'
                  : parseFloat(priceImpact).toFixed(2)}
                    %
              </div>
            </div>
            {isSponsorGas && <div className="info-item">
              <p>{t('networkFee')}</p>
              <div className="info-item__price">{t('free')}</div>
            </div>}

          </div>
      }

      {parseFloat(priceImpact) >= numPriceImpact && !isWrap && (
        <div className="preview-order__warning">
          {t('impactLarge', {
            number: formatNumberBro({ number: priceImpact, mantissa: 2 })
          })}
        </div>
      )}
    </div>
  )
}

export default PreviewOrder
