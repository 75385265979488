import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// Language Assets

import en from 'assets/lang/en'
import vi from 'assets/lang/vi'
import { store } from 'controller/redux/store/configureStore'
import StoreActions from 'controller/redux/actions/storeActions'

const resources = {
  en: {
    translation: en
  },
  vi: {
    translation: vi
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: window.localStorage.getItem('lang') || 'en',
  keySeparator: false,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
})

i18n.on('languageChanged', (lng) => {
  window.localStorage.setItem('lang', lng)
  store.dispatch(StoreActions.setLang(lng))
})

export default i18n
