import ModalServices from 'containers/app/ModalServices'
import { ListTokenContextProvider } from 'context/ListTokenContext'
import React from 'react'
import SwapScreen from '.'

const SwapScreenContent = () => {
  return (
    <ListTokenContextProvider>
      <SwapScreen />
      <ModalServices id={'ModalToken'} />
    </ListTokenContextProvider>
  )
}

export default SwapScreenContent
