import images from 'assets/images'
import Button from 'components/common/Button'
import { get } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import './style.scss'

function ProfileDetail ({ item, setRemoveImgProfile }) {
  const { t } = useTranslation()

  const handleStatus = () => {
    setRemoveImgProfile(true)
  }

  return (
    <div className='profile-detail'>
      <div className="profile-detail__info">
        <div className="info-left">
          <img src={get(item, 'img')} alt="" className="info-image" />

          <div className="info-left__name-item">
            {get(item, 'name')}
          </div>
        </div>

        <div className="info-right">
          {t('dagoraFoxxy')}
        </div>
      </div>

      <div className="profile-detail__info-token">
        <div className="info-token-id">
          {t('tokenID')}: {get(item, 'tokenID')}
        </div>

        <a href="/">
          <img src={images.iconExplorer} alt="" className="icon-scan" />
        </a>
      </div>

      <div className="profile-detail__content-text">
        <div className="content-text content-text--title">
          {t('useProfileAvatar')}
        </div>

        <div className="content-text content-text--description">
          {t('descriptionDetailPopUp')}
        </div>

        <div className="content-text content-text--footer">
          {t('textFooter')}
        </div>
      </div>

      <div className="profile-detail__action">
        <Button
          className='btn-detail-remove'
          type='cancel'
          onClick={handleStatus}
        >
          {t('removePic')}
        </Button>
      </div>
    </div>
  )
}

export default ProfileDetail
