import { upperCase } from 'common/functions'
// import FormInput from 'components/FormInput'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/common/Button'
import './style.scss'
import ReduxServices from 'common/redux'
import { useDispatch } from 'react-redux'
import StoreActions from 'controller/redux/actions/storeActions'
import get from 'lodash/get'

const BoxLockPassword = () => {
  const { t } = useTranslation()
  const [txtPass, setTxtPass] = useState('')
  const dispatch = useDispatch()

  const onChangePass = (e) => {
    const { value } = e.target
    setTxtPass(value)
  }

  const onKeyPress = (event) => {
    if (txtPass && event.key === 'Enter') {
      handleUnlock()
    }
  }

  const handleUnlock = async () => {
    const response = await window.coin98?.provider?.request({
      method: 'compare_passwords',
      params: { type: 'text', password: txtPass }
    })
    const session = get(response, 'session')
    const tryNum = get(response, 'tryNum')
    if (session) {
      dispatch(StoreActions.setSession(true))
      window.closeModalFirst()
    } else {
      if (tryNum < 10) {
        window.openModal({
          type: 'error',
          title: t('wrongPass'),
          content:
            tryNum === 9
              ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('onlyOneChanceReset')
                  }}
                />
              )
              : (
                t('tryLeft', { num: 10 - (tryNum + 1) })
              )
        })
        setTxtPass('')
      } else {
        ReduxServices.resetRedux()
        window.openModalFirst({
          type: 'error',
          title: t('walletHasBeenReset'),
          content: t('walletHasBeenReset')
        })
      }
    }
  }

  return (
    <div className="lock-password-container">
      <h4 className="lock-password-container__title">{t('unlockWallet')}</h4>
      {/* this is form to trick not autocomplete password google */}
      <div className="lock-password-container__form-hide">
        <input
          style={{
            opacity: 0,
            position: 'absolute',
            left: 0,
            top: 0,
            width: 0,
            height: 0
          }}
          type="email"
        />
        <input
          style={{
            opacity: 0,
            position: 'absolute',
            left: 0,
            top: 0,
            width: 0,
            height: 0
          }}
          autoComplete="off"
          type="password"
        />
      </div>
      <div className="lock-password-container__type-input">
        <div className="label">{upperCase(t('yourPassword'))}</div>
        <input
          onKeyPress={onKeyPress}
          onChange={onChangePass}
          value={txtPass}
          type="password"
          placeholder={t('password')}
        />
      </div>

      <Button
        isDisable={!txtPass}
        className="lock-password-container__button"
        onClick={handleUnlock}
      >
        {t('unlock')}
      </Button>
    </div>
  )
}

export default BoxLockPassword
