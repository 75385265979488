import React, { useEffect, useState } from 'react'
import './style.scss'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { isWindow } from 'common/constants'
import { Link, useHistory } from 'react-router-dom'
import { get } from 'lodash'

const InlineWrapperWithMargin = ({ children }) => {
  return <span>{children}</span>
}

const ItemTableAncient = ({
  data,
  columns,
  isLoading,
  isTransactions
}) => {
  const [widthScreen, setWidthScreen] = useState(isWindow && window.innerWidth)

  const onClickFullItem = get(data, 'onClickFullItem', () => {})

  const target = get(data, 'action.props.target', '')

  const handleResize = () => {
    setWidthScreen(window.innerWidth)
  }

  useEffect(() => {
    isWindow && window.addEventListener('resize', handleResize)
  }, [])

  if (data?.href) {
    return (
      <Link to={data?.href} className={`item-table-pool ${isTransactions && 'item-table-pool__transactions'}`} target={target}>
        {isLoading
          ? [1, 2, 3, 4].map((col, index) => (
            <Skeleton
              key={index}
              containerClassName="skeleton-table"
              count={1}
              wrapper={InlineWrapperWithMargin}
              inline
              height={'3rem'}
            ></Skeleton>
          ))
          : columns.map((col) => (
            <div
              style={{ width: `${col.width}%` }}
              key={col.dataIndex}
              className={`item-table-pool__item table-item__${col.dataIndex} item-table-pool__item--${col.align}`}
            >
              {data[`${col.dataIndex}`]}
            </div>
          ))}
      </Link>
    )
  } else {
    return (
      <div className={`item-table-pool ${isTransactions && 'item-table-pool__transactions'}`}>
        {isLoading
          ? [1, 2, 3, 4].map((col, index) => (
            <Skeleton
              key={index}
              containerClassName="skeleton-table"
              count={1}
              wrapper={InlineWrapperWithMargin}
              inline
              height={'3rem'}
            ></Skeleton>
          ))
          : columns.map((col) => (
            <div
              style={{ width: `${col.width}%` }}
              key={col.dataIndex}
              className={`item-table-pool__item item-table-pool__item--${col.align}`}
            >
              {data[`${col.dataIndex}`]}
            </div>
          ))}
      </div>
    )
  }
}

export default ItemTableAncient
