import images from 'assets/images'
import React from 'react'
import { useTranslation } from 'react-i18next'

import './styles.scss'

function MenuFilter ({ filterChoose, handleFilter, handleRefreshFilter }) {
  const { t } = useTranslation()

  const arrFilter = [
    {
      title: 'listed',
      value: 'listed'
    },
    {
      title: 'delisted',
      value: 'delisted'
    },
    {
      title: 'modified',
      value: 'modified'
    },
    {
      title: 'sold',
      value: 'sold'
    }
  ]

  const renderItemFilter = () => {
    return arrFilter.map((item, i) => {
      return (
        <div className={`item-filter ${filterChoose === item.value && 'item-filter--active'}`} key={i} onClick={handleFilter(item.value)}>
          {t(item.title)}
        </div>
      )
    })
  }

  return (
    <div className="table-list-filter">
      <div className="filter-left">
        <div className="filter-title">
          {t('filterBy')}
        </div>

        {renderItemFilter()}
      </div>

      <div className="filter-right">
        <img src={images.iconRefresh} alt="" className="icon-refresh" onClick={handleRefreshFilter} />
      </div>
    </div>
  )
}

export default MenuFilter
