
import abiRouterContract from 'common/abi/abiRouterContract'
import { convertDecimalToHex, lowerCase } from 'common/functions'
import { useSelector } from 'react-redux'
import { CONTRACT_ROUTER, CONTRACT_ROUTER_OLD, MAIN_COIN_AMM, ROUTER_SPONSOR_GAS } from '../constants'
import { useWallet } from '@coin98-com/wallet-adapter-react'

const { genWeb3, genContract, estimateGasTxs } = require('controller/Library/evm')
const { useState } = require('react')

const useRouterContract = (chain, isRouterWtomo) => {
  const [isLoading, setIsLoading] = useState()
  const chainActive = useSelector((state) => state.chainActive)
  const { address } = useWallet()

  const web3 = genWeb3(chain)

  const contractID = isRouterWtomo
    ? (CONTRACT_ROUTER_OLD[chain] || CONTRACT_ROUTER[chain])
    : (ROUTER_SPONSOR_GAS[chain] || CONTRACT_ROUTER[chain])
  // const contractID = CONTRACT_ROUTER[chain]

  const contract = genContract(web3, abiRouterContract, contractID)

  const addLiquidity = async ({
    gas,
    tokenA,
    tokenB,
    amountADesired,
    amountBDesired,
    amountAMin,
    amountBMin,
    account,
    deadline = 20 * 60,
    callBack
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const latestBlock = await web3.eth.getBlock('latest')
        const newDeadline = (parseFloat(latestBlock.timestamp) + deadline)

        const mainCoin = MAIN_COIN_AMM[chainActive]

        let data
        let valueNoConvert = 0

        if ((lowerCase(tokenA) === lowerCase(mainCoin?.address)) || (lowerCase(tokenB) === lowerCase(mainCoin?.address))) {
          const isMainCoinB = lowerCase(tokenB) === lowerCase(mainCoin?.address)

          data = contract.methods.addLiquidityETH(
            isMainCoinB ? tokenA : tokenB, // token
            isMainCoinB ? amountADesired : amountBDesired, // token desired
            isMainCoinB ? amountAMin : amountBMin, // token min
            isMainCoinB ? amountBMin : amountAMin, // eth min
            account,
            newDeadline).encodeABI()

          valueNoConvert = (isMainCoinB ? amountBDesired : amountADesired)
        } else {
          data = contract.methods.addLiquidity(
            tokenA,
            tokenB,
            amountADesired,
            amountBDesired,
            amountAMin,
            amountBMin,
            account,
            newDeadline).encodeABI()
        }

        const rawTxs = {
          from: address,
          to: contractID,
          data,
          valueDirect: convertDecimalToHex(valueNoConvert)
        }
        const gasLimitEst = await estimateGasTxs(rawTxs, web3, chain)

        resolve({ rawTxs, gasLimitEst })
      } catch (error) {
        reject(error)
      }
    })
  }

  const removeLiquidity = async ({
    gas,
    tokenA,
    tokenB,
    amountAMin,
    amountBMin,
    lpTokenAmount,
    account,
    deadline = 20 * 60,
    callBack
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const latestBlock = await web3.eth.getBlock('latest')
        const newDeadline = (parseFloat(latestBlock.timestamp) + deadline)

        const mainCoin = MAIN_COIN_AMM[chainActive]

        let data

        if ((tokenA === mainCoin?.address) || (tokenB === mainCoin?.address)) {
          const isMainCoinB = lowerCase(tokenB) === lowerCase(mainCoin?.address)

          data = contract.methods.removeLiquidityETHSupportingFeeOnTransferTokens(
            isMainCoinB ? tokenA : tokenB,
            lpTokenAmount,
            isMainCoinB ? amountAMin : amountBMin,
            isMainCoinB ? amountBMin : amountAMin,
            account,
            newDeadline).encodeABI()
        } else {
          data = contract.methods.removeLiquidity(
            tokenA,
            tokenB,
            lpTokenAmount,
            amountAMin,
            amountBMin,
            account,
            newDeadline).encodeABI()
        }

        const rawTxs = {
          from: address,
          to: contractID,
          data
        }

        const gasLimitEst = await estimateGasTxs(rawTxs, web3, chain)
        resolve({ rawTxs, gasLimitEst })
      } catch (error) {
        console.log('🚀 ~ file: useRouterContract.js:135 ~ returnnewPromise ~ error:', error)
        reject(error)
      }
    })
  }

  return {
    contract,
    isLoading,
    addLiquidity,
    removeLiquidity
  }
}

export default useRouterContract
