export default function createReducer (initialState, handlers) {
  return function reducer (state = initialState, action) {
    const hasActionType = Object.prototype.hasOwnProperty.call(handlers, action.type)
    if (hasActionType) {
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
}
