import size from 'lodash/size'
import io from 'socket.io-client'

let socketInfo
function useSocket () {
  const active = () => {
    activeSocketInfo()
  }

  const activeSocketInfo = () => {
    try {
      if (socketInfo) {
        socketInfo.removeAllListeners()
        socketInfo.disconnect()
      }
      socketInfo = io(process.env.REACT_APP_SOCKET_INFO_API, {
        transports: ['websocket']
      })
      socketInfo.on('connect', async () => {
        console.log('Socket info connected')
      })
      socketInfo.on('disconnect', activeSocketInfo)
    } catch (error) {
      console.log('Socket info error:', error)
      activeSocketInfo()
    }
  }

  const emitCoinMarket = (id) => {
    return new Promise(resolve => {
      if (size(id) === 0) {
        return resolve(true)
      }

      setTimeout(() => {
        resolve(true)
      }, 1000)

      socketInfo.emit('market_info', id, data => {
        return resolve(data)
      })
    })
  }

  return {
    active,
    emitCoinMarket
  }
}

export default useSocket
