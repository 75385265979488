import { Col, Row } from 'antd'
import images from 'assets/images'
import { isWindow } from 'common/constants'
import useFavoriteToken from 'common/customHooks/useFavoriteToken'
import {
  formatNumberBro,
  getItemStorage,
  getLength,
  upperCase
} from 'common/functions'
import { getPrice } from 'common/poolEvm/function'
import ReduxServices from 'common/redux'
import IconCustom from 'components/common/IconCustom'
import ProcessCircle from 'components/common/ProcessCircle'
import TableBoard from 'components/TableBoard'
import BaseAdapter from 'controller/api/BaseAdapter'
import BaseAPI from 'controller/api/BaseAPI'
import { get } from 'lodash'
import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ContainerShow from '../ContainerShow'
import FavoriteToken from '../FavoriteToken'
import './style.scss'
import cn from 'classnames'
import Tooltip from 'components/Tooltip'
import { useWallet } from '@coin98-com/wallet-adapter-react'
import useSocket from 'common/customHooks/useSocket'

const LIMIT = 10

const BoardTokens = ({ isHiddenMore }) => {
  const { t } = useTranslation()
  const { address } = useWallet()
  const { emitCoinMarket } = useSocket()
  const history = useHistory()
  const [isLike, setIsLike] = useState(false)
  const isDarkMode = useSelector((state) => state.themeRedux) === 'dark'
  const chainActive = useSelector((state) => state.chainActive)
  const { onChangeListTokenFavor } = useFavoriteToken()
  const listFavoriteTokenRedux =
    useSelector((state) => state.listFavoriteTokenRedux) || []

  const [listFavorToken, setListFavorToken] = useState([])
  const currencyRedux = useSelector((state) => state.currencyRedux)

  const [widthScreen, setWidthScreen] = useState(isWindow && window.innerWidth)
  const [tokenStats, setTokenStats] = useState({})
  const [totalPageTopToken, setTotalPageToken] = useState(1)
  const [dataSourceDefault, setDataSourceDefault] = useState([])
  const [currentPageToken, setCurrentPageToken] = useState(1)
  const [isLoadingListToken, setIsLoadingToken] = useState(false)
  const [isLoadingStats, setIsLoadingStats] = useState()

  const countTimeReload = null

  const handCheckLike = (address) => (e) => {
    e.preventDefault()
    setIsLike(!isLike)
    onChangeListTokenFavor(address)
  }

  useEffect(() => {
    fetchListTokenTrending()
  }, [chainActive])

  const fetchListTokenTrending = async () => {
    setIsLoadingStats(true)
    const response = await BaseAdapter.getData('baryon/token/statistical', { chain: chainActive })
    if (!get(response, 'success', false)) return

    const dataObj = get(response, 'data', {})
    setTokenStats(dataObj)
    setIsLoadingStats(false)
  }

  const checkScreen = widthScreen > 1024

  const columnsDefault = [
    {
      title: '',
      dataIndex: 'icon',
      width: checkScreen ? 3 : 10,
      icon: '',
      align: 'center'
    },
    {
      title: '',
      dataIndex: 'index',
      width: 3,
      icon: '',
      align: 'center'
    },
    {
      title: t('name'),
      dataIndex: 'name',
      width: checkScreen ? 27 : 20,
      icon: '',
      align: 'left'
    },
    {
      title: t('price'),
      dataIndex: 'price',
      width: checkScreen ? 17.5 : 70,
      icon: ''
    },
    {
      title: t('priceChange'),
      dataIndex: 'priceChange',
      width: 17.5,
      icon: ''
    },
    {
      title: t('volume24h'),
      dataIndex: 'volume24h',
      width: 17.5,
      icon: ''
    },
    {
      title: t('liquidity'),
      dataIndex: 'liquidity',
      width: 17.5,
      icon: ''
    }
  ]

  const renderColumn = () => {
    const cloneColumn = [...columnsDefault]
    const newCloneColumn = cloneColumn.filter((item) => {
      if (widthScreen <= 1024) {
        return (
          item.dataIndex !== 'index' &&
          item.dataIndex !== 'priceChange' &&
          item.dataIndex !== 'volume24h' &&
          item.dataIndex !== 'liquidity'
        )
      }
      return item
    })
    return newCloneColumn
  }

  useEffect(() => {
    getListTopToken({ page: 1 })
  }, [chainActive])

  const getListTopToken = async ({ page, isNotLoad = false }) => {
    !isNotLoad && setIsLoadingToken(true)
    const response = await BaseAPI.postData('baryon/token/top', {
      chain: chainActive,
      page,
      size: LIMIT
    })
    if (!response) return
    const arrTopToken = get(response, 'data', [])
    const totalPage = get(response, 'total', 1)

    const arrDataToken = await Promise.all(
      arrTopToken.map(async (item, index) => {
        const tokenInfo = get(item, 'info', {})
        const { id } = tokenInfo
        const detail = await emitCoinMarket(id)
        // const price = get(detail, 'current_price', 0)
        const price = await getPrice(tokenInfo, chainActive)

        const priceChange = get(detail, 'price_change_percentage_24h', 0)
        return {
          ...item,
          price: price,
          priceChange: priceChange
        }
      })
    )
    setTotalPageToken(totalPage)
    setIsLoadingToken(false)
    setDataSourceDefault(arrDataToken)
  }

  useEffect(() => {
    fetchListFavorInfo()
  }, [address, chainActive, JSON.stringify(listFavoriteTokenRedux)])

  const fetchListFavorInfo = async () => {
    const listCoinInfo = await Promise.all(
      listFavoriteTokenRedux.map(async (item) => {
        return await window.wallet.fetchInfoCoin(chainActive, item)
      })
    )
    setListFavorToken(listCoinInfo.filter(item => item))
  }

  const onReloadPie = () => {
    getListTopToken({ page: currentPageToken, isNotLoad: true })
  }

  const getDetailCgk = async ({ id, symbol }) => {
    const response = await BaseAPI.getData('coin/cgk/detail', {
      id,
      symbol
    })
    return response
  }

  const handleNavigateTokenInfo = (item) => () => {
    const address = get(item, 'address', '')
    history.push(`/token/${address}`)
  }

  const isFavorite = (mintAddress) => {
    if (!mintAddress) return false
    return listFavoriteTokenRedux.includes(mintAddress)
  }

  const renderDataToken = useMemo(() => {
    const currentPage = currentPageToken !== 0 ? currentPageToken - 1 : 0
    return dataSourceDefault.map((item, index) => {
      const tokenInfo = get(item, 'info', {})
      const { address } = tokenInfo
      const isPriceChangeUp = parseFloat(item.priceChange) > 0
      const volume24h = get(item, 'volume24h', 0)
      const liquidity = get(item, 'totalLiquidity', 0)
      const isFavor = isFavorite(get(item, 'address'))

      const data = {
        key: address,
        icon: (
          <div onClick={handCheckLike(get(item, 'address'))}>
            <IconCustom
              className={cn(isFavor && 'color-theme', 'icon-table')}
              icon={isFavor ? 'web_like_active' : 'web_like'}
            />
          </div>
        ),
        // onClickFullItem: handleNavigateTokenInfo(tokenInfo),
        href: `/token/${get(tokenInfo, 'address')}`,
        index: (
          <div className="token-index">{currentPage * LIMIT + index + 1}</div>
        ),
        name: (
          <div className="token-name">
            <div className="token-name__img">
              <img
                src={
                  get(tokenInfo, 'image') ||
                  get(
                    tokenInfo,
                    'logoURI',
                    images[isDarkMode ? 'icoUnknown' : 'icoUnknownLight']
                  )
                }
                alt=""
              />
            </div>
            <div className="token-name__info">
              <div className="info-name-token">
                {get(tokenInfo, 'name', '')}{' '}
              </div>
              <span className="info-name-symbol">
                ({upperCase(get(tokenInfo, 'symbol', ''))})
              </span>
            </div>
          </div>
        ),
        price: (
          <div className="token-price">
            {ReduxServices.formatPrice(item.price)}
          </div>
        ),
        priceChange: (
          <div
            className={`token-price-change ${
              isPriceChangeUp ? 'token-price-change--up' : ''
            }`}
          >
            {isPriceChangeUp
              ? (
                <img src={images.iconGrowUp} alt="" />
              )
              : (
                <img src={images.iconGrowDown} alt="" />
              )}
            {formatNumberBro({ number: item.priceChange, mantissa: 2 })}%
          </div>
        ),
        volume24h: (
          <div className="token-volume">
            {ReduxServices.formatPrice(volume24h)}
          </div>
        ),
        liquidity: (
          <div className="token-liquidity">
            {ReduxServices.formatPrice(liquidity)}
          </div>
        )
      }
      return data
    })
  }, [
    dataSourceDefault,
    currentPageToken,
    currencyRedux,
    listFavoriteTokenRedux
  ])

  const handleSelectedPageTopToken = (page) => {
    const { selected } = page
    const nextPage = selected + 1
    setCurrentPageToken(nextPage)
    getListTopToken({ page: nextPage, size: LIMIT })
  }

  const renderActionTabTokens = () => {
    return (
      <div className="head-actions-right">
        <div className="right-count-down-token-table">
          <Tooltip content={t('dataWillAuto')} className='pieloading-tooltip'>
            <ProcessCircle
              radius={14}
              stroke={2}
              onReload={onReloadPie}
              onResetCountDown={onReloadPie}
              countTimeReload={countTimeReload}
            />
          </Tooltip>

        </div>
      </div>
    )
  }

  const handleResize = () => {
    setWidthScreen(window.innerWidth)
  }

  useEffect(() => {
    isWindow && window.addEventListener('resize', handleResize)
  }, [])

  const handleClickTokenItem = (data) => {
    history.push(`/token/${get(data, 'address')}`)
  }

  return (
    <div className="board-tokens">
      {!isHiddenMore && (
        <>
          <div className="board-tokens__favorite">
            <FavoriteToken
              data={listFavorToken}
              onCLickItem={handleClickTokenItem}
            />
          </div>
          <div className="board-tokens__show">
            <Row gutter={[24, 24]} justify="center">
              {getLength(tokenStats.trending) > 0 && <Col
                xs={{
                  span: 24
                }}
                lg={{
                  span: 12
                }}
                xl={{
                  span: 8
                }}
              >
                <ContainerShow
                  isLoading={isLoadingStats}
                  title="trending"
                  data={tokenStats.trending}
                />
              </Col>}

              {getLength(tokenStats.gainer) > 0 && <Col
                xs={{
                  span: 24
                }}
                lg={{
                  span: 12
                }}
                xl={{
                  span: 8
                }}
              >
                <ContainerShow
                  isLoading={isLoadingStats}
                  isGainer
                  title="gainer"
                  data={tokenStats.gainer}
                />
              </Col>}

              {getLength(tokenStats.recentlyAdded) > 0 && <Col
                xs={{
                  span: 24
                }}
                lg={{
                  span: 12
                }}
                xl={{
                  span: 8
                }}
              >
                <ContainerShow
                  isLoading={isLoadingStats}
                  title="recentlyAdded"
                  data={tokenStats.recentlyAdded}
                />
              </Col>}

            </Row>
          </div>
        </>
      )}

      <div className="board-tokens__table">
        <TableBoard
          totalPage={totalPageTopToken}
          title={t('topTokens')}
          columns={renderColumn()}
          renderActionRight={renderActionTabTokens}
          dataSource={renderDataToken}
          handleSelectedPage={handleSelectedPageTopToken}
          initialPage={currentPageToken}
          isLoading={isLoadingListToken}
          isTokens
        />
      </div>
    </div>
  )
}

export default BoardTokens
