import React from 'react'
import './style.scss'
import ProcessCircle from 'components/common/ProcessCircle'
import Setting from 'pages/SwapScreen/component/Setting'
import { useTranslation } from 'react-i18next'
import IconCustom from 'components/common/IconCustom'
import Tooltip from 'components/Tooltip'

const countTimeReload = null

const ActionsRight = ({ onReloadBalance, settingRef, slippage, onChangeSlippage, handleSaveSetting, isHideSetting }) => {
  const { t } = useTranslation()
  const handleOpenSetting = () => {
    window.openModal({
      title: t('slippageTolerance'),
      type: 'save-info',
      okText: t('saveSetting'),
      containerClass: 'modal-setting',
      content: (
        <Setting
          ref={settingRef}
          slippage={slippage}
          onChangeSlippage={onChangeSlippage}
          isLiqui
        />
      ),
      onOk: async () => {
        handleSaveSetting()
      }
    })
  }

  return (
    <div className="actions-right">
      {!isHideSetting && <IconCustom className={'actions-right--setting'} onClick={handleOpenSetting} icon='web_setting'/>}
      {onReloadBalance && <div className="actions-right__pie-loading">
        <Tooltip content={t('dataWillAuto')} className='pieloading-tooltip'>
          <ProcessCircle
            radius={14}
            stroke={2}
            onReload={onReloadBalance}
            onResetCountDown={onReloadBalance}
            countTimeReload={countTimeReload}
          />
        </Tooltip>

      </div>
      }

    </div>
  )
}

export default ActionsRight
