import React, { useEffect, useState } from 'react'
import './style.scss'

const COUNTDOWN_TIME = 10

const ProcessCircle = ({ countTimeReload, radius, stroke, percent, color = '#fdbe1d', onReload = () => {}, onResetCountDown = () => {}, borderColor = 'transparent' }) => {
  const [countDownTime, setCountdownTime] = useState(0)
  const [isDisableReload, setIsDisableReload] = useState(false)
  const INTERVAL = 5000

  const progress = countDownTime * COUNTDOWN_TIME

  useEffect(() => {
    handleCountdownTime()
    return () => {
      clearInterval(countTimeReload)
    }
  }, [countDownTime >= COUNTDOWN_TIME, countDownTime === 0])

  const handleCountdownTime = () => {
    let timer = countDownTime
    countTimeReload = setInterval(function () {
      timer += 0.1
      setCountdownTime(timer)
    }, 100)
    if (countDownTime >= COUNTDOWN_TIME) {
      onReload(null, true)
    }
    if (timer >= COUNTDOWN_TIME) {
      setCountdownTime(0)
      clearInterval(countTimeReload)
    }
  }

  const handleReset = () => {
    if (!isDisableReload) {
      clearInterval(countTimeReload)
      setCountdownTime(0)
      onResetCountDown(null, true)
      setIsDisableReload(true)
      setTimeout(() => {
        setIsDisableReload(false)
      }, INTERVAL)
    }
  }

  const normalizedRadius = radius - stroke * 2
  const circumference = normalizedRadius * 2 * Math.PI
  const strokeDashoffset = circumference - (progress / 100) * circumference

  return (
    <div className={`process-chart ${isDisableReload ? 'disable-reload' : ''}`} onClick={handleReset}>
      <svg height={radius * 2} width={radius * 2}>
        <circle
          stroke={color}
          fill={borderColor}
          strokeWidth={stroke}
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
      {percent && (
        <div className="process-chart__percent">{`${percent}`}</div>
      )}
    </div>

  )
}

export default ProcessCircle
