import images from 'assets/images'
import { isWindow } from 'common/constants'
import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import './style.scss'

const Pagination = ({ totalPage = 10, handleSelectedPage, initialPage, forcePage = 0 }) => {
  const [widthScreen, setWidthScreen] = useState(isWindow && window.innerWidth)

  const handleResizeWidth = () => {
    setWidthScreen(window.innerWidth)
  }

  useEffect(() => {
    isWindow && window.addEventListener('resize', handleResizeWidth)
  }, [])

  return (
    <div className="pagination-wr">
      <ReactPaginate
        pageClassName="pagination-page"
        activeClassName="pagination-page--active"
        onPageChange={handleSelectedPage}
        previousLabel={
          <div className="icon-box icon-box--prev">
            <img src={images.iconPagiantionLeft} alt="" />
          </div>
        }
        nextLabel={
          <div className="icon-box icon-box--next">
            <img src={images.iconPagiantionLeft} alt="" />
          </div>
        }
        pageCount={totalPage}
        pageRangeDisplayed={1}
        marginPagesDisplayed={3}
        // initialPage={0}
        forcePage={forcePage}
      />
    </div>
  )
}

export default Pagination
