import { convertWeiToBalance } from 'common/functions'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useWallet } from '@coin98-com/wallet-adapter-react'
import { genWeb3 } from 'controller/Library/evm'

const MainBalanceContext = React.createContext({ })

export const MainBalanceContextProvider = ({ children }) => {
  const { address } = useWallet()
  const chainActive = useSelector((state) => state.chainActive)
  const [mainBalance, setMainBalance] = useState(0)

  useEffect(() => {
    onLoadInitial()
  }, [address, chainActive])

  const onLoadInitial = async () => {
    if (!chainActive || !address) return setMainBalance(0)
    const web3 = genWeb3(chainActive)
    if (web3) {
      const balance = await web3.eth.getBalance(address)
      setMainBalance(+(convertWeiToBalance(balance)))
    }
  }

  const value = {
    mainBalance,
    reloadMainBalance: onLoadInitial
  }

  return <MainBalanceContext.Provider value={value}>{children}</MainBalanceContext.Provider>
}

export const useMainBalanceContext = () => {
  return React.useContext(MainBalanceContext)
}
