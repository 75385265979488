export const STAKE = 'STAKE'
export const UN_STAKE = 'UN_STAKE'

export const BSC_BLOCK_TIME = 3
export const BLOCKS_PER_YEAR = (60 / BSC_BLOCK_TIME) * 60 * 24 * 365 // 10512000

export const CS98_PER_BLOCK = 40
export const CS98_PER_YEAR = CS98_PER_BLOCK * BLOCKS_PER_YEAR

export const secondPerYear = 31536000
export const secondPerDay = 86400

export const poolStakeConfig = [
  {
    pid: 0,
    stakingToken: {
      name: 'Coin98Swap Token',
      symbol: 'CS98',
      decimals: 18,
      address: '0xF8DB436ef550FFceD0C65008F73b4432679F0863',
      url: 'https://www.coin98.com/',
      images: 'https://coin98.s3.amazonaws.com/uwqLJuMfd8fsehgM'
    },
    earningToken: {
      name: 'Coin98Swap Token',
      symbol: 'CS98',
      decimals: 18,
      address: '0xF8DB436ef550FFceD0C65008F73b4432679F0863',
      url: 'https://www.coin98.com/',
      images: 'https://coin98.s3.amazonaws.com/uwqLJuMfd8fsehgM'
    },
    contractAddress: '0xd9efb22b54CB2d74eCEDd1124B8D83A407077ccb',
    tokenPerBlock: '40'
  }
]
