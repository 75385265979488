import images from 'assets/images'
import { isWindow, SCAN_URL } from 'common/constants'
import {
  convertDateMomentHistory,
  formatAddress,
  formatBilion,
  getLength,
  upperCase
} from 'common/functions'
import ReduxServices from 'common/redux'
import TableBoard from 'components/TableBoard'
import BaseAdapter from 'controller/api/BaseAdapter'
import BaseAPI from 'controller/api/BaseAPI'
import { get } from 'lodash'
import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import './style.scss'

const ALL = 'all'
const SWAP = 'swap'
const ADD = 'addLiquidity'
const REMOVE = 'removeLiquidity'
const CREATE = 'createPool'
const LIMIT = 10

// typeBoard:string = token, pool, overview
const BoardTransactions = ({ address, typeBoard }) => {
  const { t } = useTranslation()

  const chainActive = useSelector((state) => state.chainActive)

  const [widthScreen, setWidthScreen] = useState(isWindow && window.innerWidth)
  const [isSortUp, setIsSortUp] = useState(false)
  const [isSortDown, setIsSortDown] = useState(false)
  const currencyRedux = useSelector((state) => state.currencyRedux)

  const typeTxs = {
    [ALL]: t('all'),
    [SWAP]: t('swap'),
    [ADD]: t('add'),
    [REMOVE]: t('remove'),
    [CREATE]: t('create')
  }

  const checkScreen = widthScreen >= 768

  const columnsDefaultTransaction = [
    {
      title: t('action'),
      dataIndex: 'action',
      width: checkScreen ? 25 : 50,
      icon: '',
      align: 'left'
    },
    {
      title: t('totalValue'),
      dataIndex: 'totalValue',
      width: widthScreen >= 480 && widthScreen <= 768 ? 15 : 12,
      icon: ''
    },
    {
      title: `${t('tokenAmount')}`,
      dataIndex: 'tokenAmount0',
      width: checkScreen ? 15 : 0,
      icon: ''
    },
    {
      title: `${t('tokenAmount')}`,
      dataIndex: 'tokenAmount1',
      width: checkScreen ? 15 : 0,
      icon: ''
    },
    {
      title: t('account'),
      dataIndex: 'account',
      width: checkScreen ? 15 : 20,
      icon: ''
    },
    {
      title: t('time'),
      dataIndex: 'time',
      width: checkScreen ? 17 : 50,
      icon: ''
    }
  ]

  const listTabTxs = [
    {
      name: t('all'),
      type: ALL
    },
    {
      name: t('swap'),
      type: SWAP
    },
    {
      name: t('add'),
      type: ADD
    },
    {
      name: t('remove'),
      type: REMOVE
    }
  ]

  const [totalPageTopTxs, setTotalPageTxs] = useState(1)
  // const [dataSourceDefaultTransaction, setDataSourceDefaultTransaction] = useState([])
  const [dataTxs, setDataTxs] = useState([])
  const [currentPageTxs, setCurrentPageTxs] = useState(0)
  const [currentTabTxs, setCurrentTabTxs] = useState(ALL)

  const renderColumn = () => {
    const columnClone = [...columnsDefaultTransaction]
    const newColumnClone = columnClone.filter((item) => {
      if (widthScreen <= 768) {
        return (
          item.dataIndex !== 'account' &&
          item.dataIndex !== 'tokenAmount1' &&
          item.dataIndex !== 'tokenAmount0' &&
          item.dataIndex !== 'totalValue'
        )
      }
      if (widthScreen < 1204) {
        return (
          item.dataIndex !== 'tokenAmount1' && item.dataIndex !== 'tokenAmount0'
        )
      }
      return item
    })
    return newColumnClone
  }

  const renderTypeTxs = (type) => {
    return typeTxs[type]
  }

  useEffect(() => {
    getListTxs({ page: 1 })
  }, [typeBoard, address, chainActive])

  const getListTxs = async ({ page, type }) => {
    let url = null
    const params = {
      page,
      size: LIMIT,
      type,
      chain: chainActive
    }

    switch (typeBoard) {
    case 'token':
      url = 'baryon/token/transaction'
      params.tokenAddress = address
      break
    case 'pool':
      url = 'baryon/pool/transaction'
      params.poolAddress = address
      break
    case 'wallet':
      url = 'baryon/wallet/transaction'
      params.address = address
      break
    default:
      url = 'baryon/transaction'
      break
    }
    let response

    if (typeBoard === 'wallet') {
      const res = await BaseAdapter.getData(url, params)

      response = get(res, 'data')
    } else response = await BaseAPI.postData(url, params)

    const arrTemp = get(response, 'data', [])
    const totalPage = get(response, 'total', 1)
    setDataTxs(arrTemp)
    setTotalPageTxs(totalPage)
    // setDataSourceDefaultTransaction(txsList)
  }

  const renderTxsTableData = useMemo(() => {
    if (getLength(dataTxs) === 0) return []

    return dataTxs.map((item, index) => {
      const type = get(item, 'type', '')
      const token0 = get(item, 'token0', '')
      const token1 = get(item, 'token1', '')
      const amountUSD = get(item, 'amountUSD', 0)
      const amount0 = get(item, 'amount0', 0)
      const amount1 = get(item, 'amount1', 0)
      const address = get(item, 'from', '')
      const time = get(item, 'createdAt', '')
      const data = {
        key: index,
        action: (
          <a
            href={`${SCAN_URL[chainActive]}/tx/${item.hash}`}
            className="transaction-name"
            target="_blank"
            rel="noreferrer"
          >
            {`${renderTypeTxs(type)} ${upperCase(
              get(token0, 'symbol', '')
            )} for ${upperCase(get(token1, 'symbol', ''))}`}{' '}
          </a>
        ),
        totalValue: (
          <div className="transaction-total">
            {ReduxServices.formatPrice(amountUSD)}
          </div>
        ),
        tokenAmount0: (
          <div className="transaction-token-amount">
            <div className="transaction-token-amount__balance">
              {/* {formatNumberBro({ number: amount0, mantissa: 2 })} */}
              {formatBilion(amount0)}
            </div>
            <span>{upperCase(get(token0, 'symbol', ''))}</span>
          </div>
        ),
        tokenAmount1: (
          <div className="transaction-token-amount">
            <div className="transaction-token-amount__balance">
              {/* {formatNumberBro({ number: amount1, mantissa: 2 })} */}
              {formatBilion(amount1)}
            </div>
            <span>{upperCase(get(token1, 'symbol', ''))}</span>
          </div>
        ),
        account: (
          <a
            href={`${SCAN_URL[chainActive]}/address/${address}`}
            className="transaction-account"
            target="_blank"
            rel="noreferrer"
          >
            {formatAddress(address)}
          </a>
        ),
        time: (
          <div className="transaction-time">
            {convertDateMomentHistory(time, 'HH:mm DD/MM/YYYY')}
          </div>
        )
      }
      return data
    })
  }, [dataTxs, currencyRedux, currentPageTxs])

  //
  const handleViewTxs = (hash) => () => {
    const urlExplorer = SCAN_URL[chainActive]
    window.open(`${urlExplorer}/tx/${hash}`)
  }

  const handleViewAddress = (address) => () => {
    const urlExplorer = SCAN_URL[chainActive]
    window.open(`${urlExplorer}/address/${address}`)
  }

  const renderActionTransaction = () => {
    return (
      <div className="transaction-actions">
        {listTabTxs.map((tab, index) => (
          <div
            onClick={handleClickTab(tab.type)}
            key={index}
            className={`transaction-actions__item ${
              tab.type === currentTabTxs
                ? 'transaction-actions__item--active'
                : ''
            }`}
          >
            {tab.name}
          </div>
        ))}
      </div>
    )
  }

  const handleClickTab = (tab) => () => {
    const type = tab === 'all' ? '' : tab
    getListTxs({ page: 1, type })
    setCurrentPageTxs(0)
    setCurrentTabTxs(tab)
  }

  const handleSelectedPageTopTxs = (page) => {
    const { selected } = page
    const nextPage = selected + 1
    setCurrentPageTxs(nextPage)
    getListTxs({
      page: nextPage,
      type: currentTabTxs === 'all' ? '' : currentTabTxs
    })
  }

  const handleResize = () => {
    setWidthScreen(window.innerWidth)
  }

  useEffect(() => {
    isWindow && window.addEventListener('resize', handleResize)
  }, [])

  return (
    <div className="board-transactions">
      <div className="board-transactions__table">
        <TableBoard
          totalPage={totalPageTopTxs}
          columns={renderColumn()}
          dataSource={renderTxsTableData}
          renderActionRight={renderActionTransaction}
          //
          title={t('transactions')}
          handleSelectedPage={handleSelectedPageTopTxs}
          initialPage={currentPageTxs}
          currentTabTxs={currentTabTxs}
          isTransactions
        />
      </div>
    </div>
  )
}

export default BoardTransactions
