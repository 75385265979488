import images from 'assets/images'
import { getLength } from 'common/functions'
import React, { useEffect, useRef, useState } from 'react'

import './style.scss'

function InputSearch ({ placeHolder, handleChange, initValue, handleClear, setIsOpenSearchMobile, widthResize, isFarmStake, isOpenSearchMobile }) {
  const searchRef = useRef()

  const handleFocus = () => {
    if (widthResize < 768 && isFarmStake) {
      setIsOpenSearchMobile(false)
    } else {
      searchRef && searchRef.current.focus()
    }
  }

  const handleClickOut = (e) => {
    if (searchRef.current && !searchRef.current.contains(e.target)) {
      setIsOpenSearchMobile && setIsOpenSearchMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleClickOut)

    return () => window.removeEventListener('click', handleClickOut)
  }, [isOpenSearchMobile, searchRef])

  return (
    <div className="search-container">
      <input
        type="text"
        className='search-input'
        ref={searchRef}
        placeholder={placeHolder || ''}
        onChange={handleChange}
        value={initValue}
      />

      {getLength(initValue) !== 0
        ? <img
          src={images.iconClear}
          // className={`icon-clear-search ${getLength(initValue) !== 0 && 'icon-clear-search--show'}`}
          className='icon-clear-search'
          onClick={handleClear || null}
          alt="" />
        : <img
          src={images.iconSearchGrey}
          className='icon-search'
          alt=""
          onClick={handleFocus}
        />
      }
    </div>
  )
}

export default InputSearch
