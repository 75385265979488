import React, {
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback
} from 'react'
import images from '../../assets/images'
import './style.scss'
import { debounce, get } from 'lodash'
import { Link, useHistory } from 'react-router-dom'
import ButtonConnected from 'components/common/ButtonConnected'
import { useTranslation } from 'react-i18next'
import SidebarMenuMobile from 'components/common/SidebarMenuMobile'
import { useSelector, useDispatch } from 'react-redux'
import StoreActions from 'controller/redux/actions/storeActions'
import { isWindow } from 'common/constants'
import SearchMobile from 'components/common/SearchMobile'
import cn from 'classnames'
import { formatPrice, getLength, upperCase } from 'common/functions'
import useOnClickOutside from 'common/customHooks/useClickOutSide'
import BaseAPI from 'controller/api/BaseAPI'
import PuffLoading from 'components/common/PuffLoading'
import DropdownLang from './DropdownLang'
import DropdownCurrency from './DropdownCurrency'
import IconCustom from 'components/common/IconCustom'
import useScreenWidth from 'common/customHooks/useScreenWidth'
import NotiDropdown from './NotiDropdown'
import DropdownChain from './DropdownChain'
import { getTokeInfoPool } from 'common/poolEvm/function'

const Header = ({ scrollEvent }) => {
  const { t } = useTranslation()

  const refLogo = useRef(null)
  const theme = useSelector((state) => state.themeRedux)
  const chainActive = useSelector((state) => state.chainActive)
  const isDarkMode = theme === 'dark'
  const dispatch = useDispatch()
  const inputSearchRef = useRef()
  const { width } = useScreenWidth()

  const isIpad = width <= 1023

  const [widthScreen, setWidthScreen] = useState(isWindow && window.innerWidth)
  const [isOpenSearch, setIsOpenSearch] = useState(false)
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const [txtSearch, setTxtSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenSearchWeb, setIsOpenSearchWeb] = useState(false)
  const [chooseUrl, setChooseUrl] = useState('')

  const [tokens, setTokens] = useState([])
  const [pools, setPools] = useState([])

  const [isShowNoti, setIsShowNoti] = useState(false)

  const checkEmptyDataTokens = !!(
    !isLoading &&
    (getLength(tokens) !== 0 || getLength(pools) !== 0)
  )

  const history = useHistory()

  const isLaunchPad = get(history, 'location.pathname', '').includes(
    'launchpad'
  )

  const isLaunchPadDetail = get(history, 'location.pathname', '').includes(
    'launchpad/'
  )

  const isLaunchPadAll = get(history, 'location.pathname', '').includes(
    'launchpad/all'
  )

  const isSwap = get(history, 'location.pathname', '').includes('/swap')

  const loadInitSearch = async () => {
    setIsLoading(true)
    const data = await BaseAPI.getData('baryon/search', {
      keyword: '',
      chain: chainActive
    })
    setTokens(get(data, 'coin', []))
    setPools(get(data, 'pool', []))
    setIsLoading(false)
  }

  const menus = useMemo(() => {
    return [
      {
        name: 'swap',
        url: '/swap',
        isComing: false
      },
      {
        name: 'liquidity',
        url: '/liquidity',
        isComing: false
      },
      {
        name: t('pools'),
        url: '/info',
        isComing: false
      },
      {
        name: t('farm'),
        url: '/farm',
        isComing: false
      },
      {
        name: 'Stake',
        url: '/stake',
        isComing: false
      },
      {
        name: 'Snapshot',
        url: '/snapshot',
        isComing: false
      }
    ]
  }, [get(history, 'location.pathname', '')])
  const isShowButtonConnect = useMemo(() => {
    const path = get(history, 'location.pathname', '')
    const arrPath = ['/launchpad', '/swap', '/swap/raydium', '/launchpad/all']
    return arrPath.includes(path)
  }, [get(history, 'location.pathname', '')])

  useEffect(() => {
    if (isSwap) return
    if (isShowButtonConnect) {
      refLogo.current.style.animation = 'none'
      window.addEventListener('scroll', (event) => {
        const rotation = window.scrollY
        refLogo.current.style.transform = `rotate(${rotation}deg)`
      })
    } else {
      setTimeout(() => {
        if (refLogo && refLogo.current) {
          refLogo.current.style.animation = 'rotate 0.7s linear infinite'
          refLogo.current.style['animation-duration'] = '3s'
        }
      }, 1500)
    }
    return () => {
      window.removeEventListener('scroll', () => {})
    }
  }, [scrollEvent, isShowButtonConnect, isSwap])

  const handleGoTo = (url) => () => {
    if (!url) return
    history.push(`${url}`)
    setIsOpenSearch(false)
  }

  const handleMode = () => {
    if (isDarkMode) {
      dispatch(StoreActions.setThemeRedux('light'))
    } else {
      dispatch(StoreActions.setThemeRedux('dark'))
    }
  }

  const handleFocusInput = () => {
    if (widthScreen < 1024) {
      setIsOpenSearch(!isOpenSearch)
    } else {
      if (!isOpenSearchWeb) {
        inputSearchRef && inputSearchRef.current.focus()
      } else {
        inputSearchRef && inputSearchRef.current.blur()
      }
    }
  }

  const handleCloseSearch = () => {
    setIsOpenSearch(false)
    handleClear()
  }

  const handleResize = () => {
    setWidthScreen(window.innerWidth)
  }

  const handleChangeSearch = (e) => {
    const { value } = e.target
    setTxtSearch(value)
    debounceSearch(value)
  }

  const handleClear = () => {
    setTxtSearch('')
  }

  const onFocusSearch = () => {
    setIsOpenSearchWeb(true)
  }

  const onBlurSearch = () => {
    setTimeout(() => {
      setIsOpenSearchWeb(false)
      handleClear()
    }, 300)
  }

  const renderLogo = () => {
    return (
      <img
        key={window.location.pathname}
        className="logo-full"
        src={
          isDarkMode
            ? 'https://coin98.s3.ap-southeast-1.amazonaws.com/AmberBlock/baryon_dark.svg'
            : 'https://coin98.s3.ap-southeast-1.amazonaws.com/AmberBlock/baryon_light.svg'
        }
        // src={images[isDarkMode ? 'logoSarosDark' : 'logoSaros']}
        alt=""
      />
    )
  }

  const renderTokenItem = (arrToken) => {
    return arrToken.map((item, i) => {
      return (
        <div
          className="token-item-search"
          key={i}
          onClick={handleGoTo('/token/' + item.address)}
        >
          <div className="token-item-search__name">
            <img
              src={
                get(item, 'icon') ||
                get(item, 'image') ||
                get(item, 'logoURI', images.icoUnknown)
              }
              className="name-token"
              alt=""
            />
            <div className="name-text">{upperCase(item.symbol)}</div>
          </div>

          <div className="token-item-search__price">
            {formatPrice(item.price)}
          </div>
        </div>
      )
    })
  }

  const renderPoolItem = (arrPools) => {
    return arrPools.map((item, i) => {
      const token0 = getTokeInfoPool(item?.token0, chainActive)
      const token1 = getTokeInfoPool(item?.token1, chainActive)

      return (
        <div
          className="token-item-search"
          key={i}
          onClick={handleGoTo('/pool/' + item.id)}
        >
          <div className="token-item-search__name">
            <img
              src={
                get(token0, 'image') ||
                get(token0, 'logoURI', images.icoUnknown)
              }
              className="name-token"
              alt=""
            />
            <img
              src={
                get(token1, 'image') ||
                get(token1, 'logoURI', images.icoUnknown)
              }
              className="name-token"
              alt=""
            />
            <div className="name-text">
              {upperCase(get(token0, 'symbol'))}
            </div>
            <span>-</span>
            <div className="name-text">
              {upperCase(get(token1, 'symbol'))}
            </div>
          </div>

          <div className="token-item-search__price">
            {formatPrice(item.totalLiquidity)}
          </div>
        </div>
      )
    })
  }

  const renderContent = (data, titleLeft, titleRight, isToken) => {
    if (getLength(data) === 0) return <></>
    return (
      <div className="token-content">
        <div className="token-content__title">
          <div className="title-text">{titleLeft}</div>

          <div className="title-price">{titleRight}</div>
        </div>

        <div className="token-content__tokens">
          {isToken ? renderTokenItem(data) : renderPoolItem(data)}
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (txtSearch === '') {
      setTimeout(() => {
        isWindow && window.addEventListener('resize', handleResize)
        loadInitSearch()
      }, 300)
    }
  }, [txtSearch, chainActive])

  useEffect(() => {
    if (isOpenSearch) {
      loadInitSearch()
    }
  }, [isOpenSearch])

  useEffect(() => {
    if (isOpenSearch || isOpenSearchWeb || getLength(txtSearch) !== 0) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isOpenSearch, isOpenSearchWeb, txtSearch])

  const debounceSearch = useCallback(
    debounce(async (value) => {
      setIsLoading(true)
      // if (getLength(value) > 0) {
      const res = await BaseAPI.getData('baryon/search', {
        keyword: value || '',
        chain: chainActive
      })
      setTokens(get(res, 'coin', []))
      setPools(get(res, 'pool', []))
      setIsLoading(false)
      // }
    }, 1000),
    []
  )

  useEffect(() => {
    if (
      window.location.pathname === '/tokens' ||
      window.location.pathname === '/pools' ||
      window.location.pathname.includes('/pool') ||
      window.location.pathname.includes('/token') ||
      window.location.pathname.includes('/wallet')
    ) {
      setChooseUrl('/info')
    } else if (
      window.location.pathname.includes('/add') ||
      window.location.pathname.includes('/remove') ||
      window.location.pathname.includes('/find')
    ) {
      setChooseUrl('/liquidity')
    } else if (window.location.pathname.includes('/swap')) {
      setChooseUrl('/swap')
    } else if (window.location.pathname.includes('/snapshot')) {
      setChooseUrl('/snapshot')
    } else {
      setChooseUrl(window.location.pathname)
    }
  }, [window.location.pathname])

  useEffect(() => {
    widthScreen > 1440 && setIsOpenMenu(false)
  }, [widthScreen])

  const pRef = useRef()
  const settingRef = useRef()

  useOnClickOutside(pRef, () => {
    setIsShowNoti(false)
  })

  useOnClickOutside(settingRef, () => {
    setIsOpenMenu(false)
  })

  const handleOpenMenu = () => {
    setIsOpenMenu(!isOpenMenu)
  }

  return (
    <div className="header-wr">
      <div
        className={`header header--pool ${isLaunchPad && 'header--launchpad'} ${
          !isLaunchPadAll && isLaunchPadDetail && 'header--launchpad-all'
        }`}
      >
        <div className={cn('box-left-header', !isOpenSearch && 'box-left-header--active')}>
          <Link to="/" className="header__logo">
            {renderLogo()}
          </Link>
          <SidebarMenuMobile
            menus={menus}
            isOpen={isOpenMenu}
            isOpenSearch={isOpenSearch}
            chooseUrl={chooseUrl}
            setIsOpenMenu={setIsOpenMenu}
          />
        </div>
        <div
          className={`connect-container ${
            isOpenSearch && 'connect-container--mobile-search-active'
          }`}
        >
          <div
            className={`header-search ${
              isOpenSearch && 'header-search--open-search'
            }`}
          >
            <button
              className={`btn-cancel ${isOpenSearch && 'btn-cancel--show'}`}
              onClick={handleCloseSearch}
            >
              {t('cancel')}
            </button>

            <img
              src={images.iconClear}
              alt=""
              className={`icon-head-clear ${
                isOpenSearch &&
                getLength(txtSearch) > 0 &&
                'icon-head-clear--active'
              }`}
              onClick={handleClear}
            />

            <input
              type="text"
              placeholder={t('searchPoolOrToken')}
              className={`
                input-search
                ${isOpenSearch && 'input-search--active-mobile'} 
                ${
    (getLength(txtSearch) > 0 || isOpenSearchWeb) &&
                  'input-search--active-web'
    }`}
              onChange={handleChangeSearch}
              ref={inputSearchRef}
              value={txtSearch}
              onBlur={onBlurSearch}
              onFocus={onFocusSearch}
            />

            {widthScreen < 1024
              ? (
                <img
                  src={images.iconSearchGrey}
                  className={`icon-head-search ${
                    isOpenSearch && 'icon-head-search--open-search'
                  }`}
                  onClick={handleFocusInput}
                  alt=""
                />
              )
              : getLength(txtSearch) === 0
                ? (
                  <img
                    src={images.iconSearchGrey}
                    className="icon-head-search"
                    onClick={handleFocusInput}
                    alt=""
                  />
                )
                : (
                  <img
                    src={images.iconClear}
                    alt=""
                    className="icon-head-clear-web"
                    onClick={handleClear}
                  />
                )}

            <div
              className={`dropdown-search ${
                isOpenSearchWeb && 'dropdown-search--active'
              }`}
            >
              <div className="box-search-item">
                {!isLoading
                  ? (
                    checkEmptyDataTokens
                      ? (
                        <div className="box-content">
                          {renderContent(tokens, t('tokens'), t('price'), true)}
                          {renderContent(pools, t('pools'), t('liquidity'))}
                        </div>
                      )
                      : (
                        <div
                          className={`box-content ${
                            !checkEmptyDataTokens && 'box-content--empty'
                          }`}
                        >
                          <img
                            src={images.iconEmptyTable}
                            className="icon-empty-data"
                            alt=""
                          />
                          {t('emptyData')}
                        </div>
                      )
                  )
                  : (
                    <div
                      className={`box-content ${
                        isLoading && 'box-content--loading'
                      }`}
                    >
                      <PuffLoading />
                    </div>
                  )}
              </div>
            </div>
          </div>

          {!isIpad && <div className="header-option" ref={settingRef}>
            <div className={'option-setting'} onClick={handleOpenMenu}>
              <IconCustom
                type="default"
                icon={isOpenMenu ? 'web_close' : 'web_menu-expand'}
              />
            </div>
            <div
              className={cn(
                'header-setting',
                isOpenMenu && 'header-setting--open'
              )}
            >

              <div className="header-theme-baryon">
                <div className='header-theme-baryon--icon'>
                  <img src={images.logoBaryon} className="img-24 mr-1" alt="logo-baryon"/>
                  <span>{t('theme')}</span>
                </div>
                <div className='box-icon-them'>
                  <img
                    src={images[isDarkMode ? 'iconMoon' : 'iconSun']}
                    alt=""
                    className="icon-theme"
                    onClick={handleMode}
                  />
                </div>

              </div>

              <div className="header-convenient">
                <IconCustom type={'default'} icon="web_global" />
                <DropdownLang />
              </div>
              <div className="header-convenient header-language">
                <IconCustom type={'default'} icon="web_market_cap" />
                <DropdownCurrency />
              </div>

            </div>
          </div>}

          <div className="header-connect">
            <DropdownChain />
            <ButtonConnected />

          </div>

          <NotiDropdown/>

          {/* icon phone */}
          {!isOpenSearch && (
            <div className={'header-buger'} onClick={handleOpenMenu}>
              <IconCustom
                type="default"
                icon={isOpenMenu ? 'web_close' : 'web_menu-expand'}
              />
            </div>
          )}
        </div>

        <SearchMobile
          isOpenSearch={isOpenSearch}
          tokens={tokens}
          pools={pools}
          handleGoTo={handleGoTo}
          checkEmptyDataTokens={checkEmptyDataTokens}
          txtSearch={txtSearch}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export default Header
