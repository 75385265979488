import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/common/Button'
import TotalInfo from 'pages/PoolInfoScreen/components/TotalInfo'
import PoolInfoItem from 'pages/PoolInfoScreen/components/PoolInfoItem'
import ButtonPool from 'pages/PoolScreen/components/ButtonPool'
import RechartBar from 'components/RechartBar'
import usePairs from 'common/poolEvm/hooks/usePairs'
import StatusItem from 'components/common/StatusItem'
import cn from 'classnames'

import './style.scss'
import { get, isArray, lowerCase } from 'lodash'
import {
  formatNumberBro,
  formatPrice,
  getLength,
  showNotificationToast,
  upperCase
} from 'common/functions'
import PuffLoading from 'components/common/PuffLoading'
import { Link, useHistory, useParams } from 'react-router-dom'
import BaseAPI from 'controller/api/BaseAPI'
import RechartLine from 'components/RechartLine'
import { BounceLoader } from 'react-spinners'
import { useSelector } from 'react-redux'
import { getPrice } from 'common/poolEvm/function'
import IconCustom from 'components/common/IconCustom'
import AboutToken from 'components/common/AboutToken'
import { CHAIN_DATA } from 'common/constants'
import { getPriceLp } from 'controller/Library/evm'
import ReduxServices from 'common/redux'
import { useWallet } from '@coin98-com/wallet-adapter-react'
import { MAIN_COIN_AMM } from 'common/poolEvm/constants'

const LIQUIDITY = 'LIQUIDITY'
const VOLUME = 'VOLUME'

const PoolInfoScreen = () => {
  const { t } = useTranslation()
  const { address } = useParams()
  const history = useHistory()
  const chartContainerRef = useRef(null)
  const chainActive = useSelector(state => state.chainActive)
  const { address: addressActive } = useWallet()

  const listButton = [
    {
      name: t('volume'),
      type: VOLUME
    },
    {
      name: t('liquidity'),
      type: LIQUIDITY
    }
  ]
  const [fromToken, setFromToken] = useState(null)
  const [toToken, setToToken] = useState(null)
  const [infoToken, setInfoToken] = useState({})
  const [isLoadingScreen, setIsLoadingScreen] = useState(true)
  const [poolInfo, setPoolInfo] = useState({})
  const [dataFarm, setDataFarm] = useState([])
  const [typeChart, setTypeChart] = useState(VOLUME)
  const [dataChartVolume, setDataChartVolume] = useState([])
  const [dataChartLiquidity, setDataChartLiquidity] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [priceLp, setPriceLp] = useState(0)

  const isPriceChangeUp = parseFloat(get(infoToken, 'priceChange', 0)) > 0

  const mainCoin = MAIN_COIN_AMM[chainActive]

  const addressMint0 = get(fromToken, 'tokenInfo.address') || mainCoin?.symbol
  const addressMint1 = get(toToken, 'tokenInfo.address') || mainCoin?.symbol

  const addressInfoToken = get(infoToken, 'info.token.address')
  const addressPoolToken = get(poolInfo, 'poolAddress')

  const isPoolInfo = useMemo(() => {
    const { pathname } = history.location
    return pathname.includes('pool')
  }, [history.location.pathname])

  const { poolInfo: infoPool, getAmount, isLoading: isLoadingPoolInfo } =
  usePairs(chainActive, addressActive, null, null, address, !isPoolInfo)

  const poolAddress = isPoolInfo ? addressPoolToken : addressInfoToken
  const scanLp = `${CHAIN_DATA[chainActive].scan}/address/${poolAddress}`

  const info = isPoolInfo ? poolInfo : infoToken
  const volume24h = info?.volume24h
  const liquidity = isPoolInfo ? infoPool?.totalSupplyLp * priceLp : info?.liquidity
  const volume7d = info?.volume7d
  const transaction24h = info?.transaction24h
  const fee24h = info?.fee24h
  const volume24hChange = !isPoolInfo ? info?.volume24hChange : 0
  const liquidity24hChange = !isPoolInfo ? info?.liquidity24hChange : 0
  const balanceOfLpUSD = get(infoPool, 'balanceOfLp', 0) * priceLp
  const balanceToken0 = get(infoPool, 'token0.balanceTokenUser', 0)
  const balanceToken1 = get(infoPool, 'token1.balanceTokenUser', 0)

  const fromSymbol = useMemo(() => {
    return upperCase(get(fromToken, 'tokenInfo.symbol', ''))
  }, [fromToken])

  const toSymbol = useMemo(() => {
    return upperCase(get(toToken, 'tokenInfo.symbol', ''))
  }, [toToken])

  const dataInfoChart = [{
    label: 'yourPoolShare',
    content: <span>
      {formatPrice(get(infoPool, 'shareOfPool', 0), false, true) + '%' }
    </span>
  },
  {
    label: 'yourPoolToken',
    content: <span>
      {ReduxServices.formatPrice(balanceOfLpUSD)}
    </span>
  },
  {
    label: 'pooledSign',
    content: <span> {formatPrice(balanceToken0, false, true)} {fromSymbol}
    </span>
  },
  {
    label: 'pooledSign',
    content: <span>
      {formatPrice(balanceToken1, false, true)} {toSymbol}
    </span>
  }]

  useEffect(() => {
    setIsLoading(true)
    getTokenInfoChart()
    if (isPoolInfo) {
      getPoolInfo()
    } else {
      getTokenInfo()
    }
  }, [isPoolInfo, address])

  useEffect(() => {
    const token0 = get(infoPool, 'token0', {})
    const token1 = get(infoPool, 'token1', {})

    const loadPriceLp = async () => {
      const newPriceLp = await getPriceLp({
        token0: infoPool?.token0?.tokenInfo,
        token1: infoPool?.token1?.tokenInfo,
        chain: chainActive,
        totalLp: get(infoPool, 'totalSupplyLp', 0),
        balanceToken0Pool: get(infoPool, 'token0.totalBalancePool', 0),
        balanceToken1Pool: get(infoPool, 'token1.totalBalancePool', 0)
      })

      setPriceLp(newPriceLp)
    }

    loadPriceLp()

    setFromToken({
      ...token0
    })
    setToToken({
      ...token1
    })
  }, [infoPool])

  useEffect(() => {
    getDataFarm()
  }, [])

  const getDataFarm = async () => {
    const response = await BaseAPI.getData('baryon/farm', { chain: chainActive })
    const newData = get(response, 'data', [])
    setDataFarm(newData)
  }

  const getPoolInfo = async () => {
    setIsLoadingScreen(true)
    const response = await BaseAPI.postData('baryon/pool/info', {
      poolAddress: address,
      chain: chainActive
    })
    if (!response) return goToNotFoundScreen()
    setPoolInfo(response)
    setIsLoadingScreen(false)
  }

  const getDetailCgk = async ({ id, symbol }) => {
    const response = await BaseAPI.getData('coin/cgk/detail', { id })
    return response
  }

  const getTokenInfo = async () => {
    setIsLoadingScreen(true)
    const response = await BaseAPI.postData('baryon/token/info', {
      tokenAddress: address,
      chain: chainActive
    })
    if (!response) return goToNotFoundScreen()
    const tokenInfo = get(response, 'info.token', {})
    const { id } = tokenInfo
    const detail = await getDetailCgk({ id })
    const price = await getPrice(tokenInfo, chainActive)
    const priceChange = get(
      detail,
      'market_data.price_change_24h_in_currency.usd',
      0
    )
    setInfoToken({ ...response, price, priceChange })
    setIsLoadingScreen(false)
  }

  const getTokenInfoChart = async () => {
    const typeFetch = isPoolInfo ? 'pool' : 'token'
    const response = await BaseAPI.postData(`baryon/${typeFetch}/chart`, {
      [`${isPoolInfo ? 'poolAddress' : 'tokenAddress'}`]: address,
      chain: chainActive
    })
    if (!isArray(response)) return setIsLoading(false)
    const arrLiquidity = response.map((item) => ({
      number: item.totalLiquidity,
      date: item.date
    }))
    const arrVolume = response.map((item) => ({
      number: item.volume,
      date: item.date
    }))
    setDataChartLiquidity(arrLiquidity)
    setDataChartVolume(arrVolume)
    setIsLoading(false)
  }

  const handleSwitchChart = (type) => () => {
    setTypeChart(type)
  }

  const renderButtonChart = () => {
    return listButton.map((button, index) => (
      <div key={index} className="info-actions__item">
        <ButtonPool
          onClick={handleSwitchChart(button.type)}
          type={`${typeChart === button.type ? 'purple' : ''}`}
        >
          {button.name}
        </ButtonPool>
      </div>
    ))
  }

  const backTo = () => {
    history.goBack()
  }

  const goToNotFoundScreen = () => {
    history.push('/not-found')
  }

  const renderLinkLiquidity = useMemo(() => {
    if (addressMint0 && addressMint1) {
      return `/liquidity/add?chain=${chainActive}&token0=${addressMint0}&token1=${addressMint1}`
    } else {
      return `/add/${addressInfoToken}`
    }
  }, [addressMint0, addressMint1, addressInfoToken])

  const renderLinkFarm = () => {
    return `/farm?chain=${chainActive}&poolAddress=${addressPoolToken}`
  }

  const handleCopyAddress = (address) => () => {
    if (getLength(address) !== 0) {
      window.navigator.clipboard.writeText(address)
      showNotificationToast(t('copyAddress'), 'success', 3000, false)
    } else {
      showNotificationToast(t('copyAddressFail'), 'error', 3000, false)
    }
  }

  const renderNavigateSwap = () => {
    if (isPoolInfo) {
      const fromMint = get(fromToken, 'tokenInfo.address', '').toString() || mainCoin?.symbol
      const toMint = get(toToken, 'tokenInfo.address', '').toString() || mainCoin?.symbol

      return `/swap?chain=${chainActive}&base=${fromMint}&pair=${toMint}`
    }

    return `/swap?chain=${chainActive}&base=${address}`
  }

  if (isLoadingScreen) {
    return <PuffLoading isFullScreen />
  }

  const timestampNow = Math.floor(new Date().getTime() / 1000)

  const checkFarm = dataFarm.filter((item) => {
    const isSampleAddress = lowerCase(get(item, 'stakedLpAddress')) === lowerCase(addressPoolToken)
    const isCompleted = timestampNow >= parseInt(item.rewardsExpiration)
    return isSampleAddress && !isCompleted
  })
  const isFarmData = getLength(checkFarm) >= 1
  return (
    <div className="pool-info-wrapper">
      <div onClick={backTo} className="header-item-back d-flex align-items-center mb-4">
        <IconCustom icon='web_arrow_left'/>
        <span>{t('backTo')} {t(isPoolInfo ? 'allPools' : 'allTokens')} </span>
      </div>
      <div className="pool-info-screen">
        <div className={`pool-info-screen__dashboard ${isPoolInfo && 'pool-info-screen__dashboard--pool'}`}>
          <div className="row g-5 g-lg-4 g-xl-5">
            <div className="col-lg-8 order-2 order-lg-1">
              <div className="dashboard-item">
                {isPoolInfo && <div className='total-chart rounded-4'>
                  <div className="row">
                    {dataInfoChart.map((it, index) => {
                      return (
                        <div key={index} className="col-6 col-md-3">
                          <div className="text-center">
                            <div className="total-chart__content font-weight-bold ">
                              {it.content}
                            </div>
                            <span className='text-content text-12'>
                              {t(it.label)}
                            </span>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>}
                <div className="dashboard-item__info-chart">
                  <div className="info-actions">{renderButtonChart()}</div>
                </div>
                <div ref={chartContainerRef} className="dashboard-item__chart">
                  {typeChart === LIQUIDITY
                    ? (
                      <RechartLine dataChart={dataChartLiquidity} isHideTitle isLoading={isLoading}/>
                    )
                    : (
                  // <RechartLine dataChart={dataChartVolume} isHideTitle/>
                      <RechartBar dataChart={dataChartVolume} isHideTitle isLoading={isLoading}/>
                    )}
                </div>
              </div>
            </div>
            <div className="col-lg-4 order-1 order-lg-2">
              <div className="dashboard-item">
                {isPoolInfo
                  ? (
                    <div className="dashboard-item__inner">
                      <TotalInfo scanLp={scanLp} fromToken={fromToken} toToken={toToken} isLoading={isLoadingPoolInfo} />
                      <div className="item-exchange w-100">
                        <div className="row g-6">
                          <div className="col-6">
                            <span className='d-flex w-full gap-3'>
                              {/* 1 {fromSymbol} = {isLoadingPoolInfo ? <BounceLoader size={16} color="#bbbbbb" /> : formatPrice(getAmount(fromToken.tokenInfo, 1), null, true)} {toSymbol} */}
                          1 {fromSymbol} = {isLoadingPoolInfo ? <BounceLoader size={16} color="#bbbbbb" /> : formatNumberBro({ number: getAmount(fromToken.tokenInfo, 1), mantissa: 5 })} {toSymbol}
                            </span>
                          </div>
                          <div className="col-6">
                            <span className='d-flex w-full gap-3'>
                        1 {toSymbol} = { isLoadingPoolInfo ? <BounceLoader size={16} color="#bbbbbb" /> : formatPrice(getAmount(toToken.tokenInfo, 1), null, true)} {fromSymbol}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                  : (
                    <div className="dashboard-item__inner d-flex flex-column">
                      <div className="dashboard-item__name d-flex justify-content-between align-items-center mb-4">
                        <div className='name-title gap-3 d-flex align-items-center'>
                          <div>
                            <AboutToken imgToken={get(infoToken, 'info.token.image', '') || get(infoToken, 'info.token.logoURI', '')}/>
                          </div>
                          <span>
                            {get(infoToken, 'info.token.name', '')}
                          </span>
                          <span className='name-title__symbol text-uppercase'>
                            {get(infoToken, 'info.token.symbol', '')}
                          </span>
                        </div>
                        <a target="_blank" href={scanLp} rel="noreferrer">
                          <IconCustom icon='web_new-scan'/>
                        </a>
                      </div>
                      <div className='dashboard-item__price d-flex justify-content-between align-items-center'>
                        <span className='lx-medium'>
                          {formatPrice(get(infoToken, 'price', 0))}
                        </span>
                        <div className={`price-volume d-flex align-items-center ${isPriceChangeUp && 'price-volume--up'}`}>
                          <StatusItem className={cn(isPriceChangeUp ? 'onGoing' : 'completed')} status='%' number={formatNumberBro({
                            number: get(infoToken, 'priceChange', 0)
                          })}/>
                        </div>
                      </div>

                    </div>
                  )}
                <div className='row g-6 mb-5'>
                  <div className="col-6">
                    <PoolInfoItem
                      className={'pool-info-item--red'}
                      title={t('volume24h')}
                      number={volume24h}
                      numberChange={volume24hChange}
                    />
                  </div>
                  <div className="col-6">
                    <PoolInfoItem
                      className={'pool-info-item--blue'}
                      numberChange={liquidity24hChange}
                      number={liquidity}
                      title={t('liquidity')}
                      iconHead='web_direct-inbox'
                    />
                  </div>
                  <div className="col-6">
                    <PoolInfoItem
                      className={'pool-info-item--green'}
                      number={volume7d}
                      title={t('volume7d')}
                      iconHead="web_discount-circle"
                    />
                  </div>
                  <div className="col-6">
                    <PoolInfoItem
                      className={'pool-info-item--yellow'}
                      number={isPoolInfo ? fee24h : transaction24h}
                      title={t(isPoolInfo ? 'fee24h' : 'transaction24h')}
                      isTransaction = {!isPoolInfo}
                      iconHead='web_buy-crypto'
                    />
                  </div>
                </div>
                <div className='mt-auto button-action'>
                  {isPoolInfo
                    ? <div className='button-action__pool text-center'>
                      <div className='d-flex flex-column gap-3'>
                        <Link to={renderLinkLiquidity} className="actions-item" target="_blank">
                          <Button type="border-grey">
                            <IconCustom className={'text-content text-28'} type={'default'} icon='web_add_liquidity'/>
                          </Button>
                        </Link>
                        <span className='text-12 text-content'> {t('addLiquidity')}</span>
                      </div>
                      {isFarmData && <div className='d-flex flex-column gap-3'>
                        <Link to={renderLinkFarm} target="_blank">
                          <Button isLoading={isLoadingPoolInfo}>
                            <IconCustom className={'color-white text-28'} type={'default'} icon='web_farming'/>
                          </Button>
                        </Link>
                        <span className='text-12 text-content'> {t('farm')}</span>
                      </div>}
                      <div className='d-flex flex-column gap-3'>
                        <Link to={renderNavigateSwap} target="_blank">
                          <Button isLoading={isLoadingPoolInfo} >
                            <IconCustom className={'color-white text-28'} type={'default'} icon='web_swap'/>
                          </Button>
                        </Link>
                        <span className='text-12 text-content'> {t('swap')}</span>
                      </div>
                    </div>
                    : <Link to={renderNavigateSwap} target="_blank" className='w-100'>
                      <Button isLoading={isLoadingPoolInfo}>{t('swap')} </Button>
                    </Link>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PoolInfoScreen
