/* eslint-disable new-cap */
/* eslint-disable node/handle-callback-err */
import images from 'assets/images'
import React, { useEffect, useState, useRef, useMemo } from 'react'
import Setting from './component/Setting'
import './style.scss'
import Button from 'components/common/Button'
import { get, parseInt } from 'lodash'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ProcessCircle from 'components/common/ProcessCircle'
import PreviewOrder from './component/PreviewOrder'

import {
  convertWeiToBalance,
  countDots,
  formatNumberBro,
  getLength,
  lowerCase,
  roundingNumber,
  showNotificationToast,
  upperCase,
  validateNumber
} from 'common/functions'
import { toast } from 'react-toastify'
import BaseAPI from 'controller/api/BaseAPI'

import ChartSwap from './component/ChartSwap'
import SelectToken from 'pages/LiquidityScreen/components/SelectToken'
import {
  AMM_ADDRESS,
  COIN98_DEFAULT,
  CONTRACT_KYC_BIT_KUB_V2,
  MAIN_COIN,
  MAIN_COIN_AMM,
  SLIPPAGE,
  STABLE_COIN_CHAIN,
  TRADE_DEFAULT_CHAIN,
  W_MAIN_COIN,
  W_MAIN_COIN_TOMO_OLD,
  tradeType
} from 'common/poolEvm/constants'
import ReduxServices from 'common/redux'
import { exactTradeAmount } from 'controller/Library/AMM'
import {
  computeTradePriceBreakdown,
  exactCommonPair,
  formatExecutionPrice,
  formatPriceImpact,
  parseNumber
} from 'common/poolEvm/function'
import {
  BIPS_BASE,
  checkNumApproved,
  encodeMessErr,
  estimateGasSwapAMM,
  genContract,
  genWeb3,
  onWrapUnWrapToken,
  sendSwapAMM
} from 'controller/Library/evm'
import { JSBI, Percent } from '@uniswap/sdk'
import PopupHash from 'components/PopupHash'

import useGasData from 'common/customHooks/useGasData'
import ERC20 from 'common/abi/ERC20'
import { SWAP, UNWRAP, WRAP } from 'common/farm/contants'
import IconCustom from 'components/common/IconCustom'
import ShareModal from 'components/common/ShareModal'
import { useDebouncedCallback } from 'use-debounce'
import Tooltip from 'components/Tooltip'
import TableSwapChart from './component/TableSwapChart'
import { useWallet } from '@coin98-com/wallet-adapter-react'
import useServices from 'controller/Library/useServices'
import { chainType } from 'common/constants'
import { useMainBalanceContext } from 'context/MainBalanceContext'
import BaseAdapter from 'controller/api/BaseAdapter'
import abiKycV2Bitkub from 'common/abi/abiKycV2Bitkub'
import ModalConfirmImpact from './component/ModalConfirmImpact'

const countTimeReload = null

const renderBaseCoin = (base, pair, chainActive) => {
  const isStableCoin = lowerCase(pair) === lowerCase(STABLE_COIN_CHAIN[chainActive].address)
  const isShowMainCoin = (chainActive === chainType.tomo) || (chainActive === chainType.ancient8Mainnet)

  if (!base && isStableCoin) return MAIN_COIN_AMM[chainActive]
  else if (!base) return isShowMainCoin ? MAIN_COIN_AMM[chainActive] : STABLE_COIN_CHAIN[chainActive]
  return {}
}
export const numPriceImpact = 10

const SwapScreen = () => {
  const { t } = useTranslation()

  const search = new URL(window.location.href).search
  const query = new URLSearchParams(search)
  const baseUrl = query.get('base')
  const pairUrl = query.get('pair')

  const settingRef = useRef(null)
  const { address, connected, wallet } = useWallet()
  const { sendRawTxn } = useServices()
  const { mainBalance, reloadMainBalance } = useMainBalanceContext()

  const isDarkMode = useSelector((state) => state.themeRedux) === 'dark'
  const chainActive = useSelector((state) => state.chainActive)
  const isNoWallet = !address
  const isCoin98 = wallet?.adapter?.name === 'Coin98'

  const [fromAmount, setFromAmount] = useState('')
  const [toAmount, setToAmount] = useState('')
  const [balanceFromTo, setBalanceFromTo] = useState({ from: 0, to: 0 })
  const [coinNumApprove, setCoinNumApprove] = useState({
    symbol: '',
    number: 0
  })
  const [allowedPair, setAllowedPair] = useState([])
  const [tradeData, setTradeData] = useState(null)
  const [provider, setProviderFee] = useState({
    priceImpact: null,
    realizedLPFee: null
  })
  const [slippage, setSlippage] = useState(SLIPPAGE)
  const [gasLimitEst, setGasLimitEst] = useState(0)
  const [route, setRoute] = useState([])
  const [gasSelect, setGasSelect] = useState({ index: 0 })

  const [isLoadingFirst, setLoadingFirst] = useState(true)
  const [isOpenChart, setIsOpenChart] = useState(false)
  const [isLoadingTrade, setLoadingTrade] = useState(false)
  const [isSwitchTrade, setIsSwitchTrade] = useState(false)
  const [isExpandChart, setIsExpandChart] = useState(false)
  const [isFromInput, setStateInputFrom] = useState(false)
  const [isKycBitkub, setKycBitkub] = useState(false)

  const [listSponsorGas, setListSponsorGas] = useState([])

  const { gas, refreshGas } = useGasData(chainActive)
  const [fromToCoin, setFromToCoin] = useState(
    {
      from: renderBaseCoin(baseUrl, pairUrl, chainActive),
      to: !pairUrl ? COIN98_DEFAULT[chainActive] : {}
    }
  )

  const fromCoin = fromToCoin?.from || {}
  const toCoin = fromToCoin?.to || {}

  const arrWrap = [lowerCase(W_MAIN_COIN[chainActive]?.address), lowerCase(MAIN_COIN_AMM[chainActive]?.address), lowerCase(W_MAIN_COIN_TOMO_OLD.address)]
  const isWrapCoin = arrWrap.includes(lowerCase(fromCoin?.address)) && arrWrap.includes(lowerCase(toCoin?.address))
  const isWrap = !fromCoin.address
  const isBitkubChain = chainActive === chainType.bitkub

  const isShowKycBitkub = isWrapCoin && !isWrap && isBitkubChain && !isKycBitkub

  // const

  const selectedValueGas = get(gas, `gasFull.data[${gasSelect.index}].value`)
  const fRateFee = get(gas, 'fRateFee', 0)

  const mainCoin = MAIN_COIN_AMM[chainActive]
  const typeTrade = TRADE_DEFAULT_CHAIN[chainActive]
  const feeEst = convertWeiToBalance(gasLimitEst * selectedValueGas, 9)
  const isNeedApprove =
    parseFloat(coinNumApprove.number) < parseFloat(fromAmount || 0) &&
    fromCoin.symbol !== mainCoin.symbol

  const numImpact = formatNumberBro({
    number: formatPriceImpact(provider.priceImpact),
    mantissa: 2
  })

  const isLargePriceImpact =
    provider &&
    provider.priceImpact &&
    parseNumber(numImpact) >= numPriceImpact

  const isDisablePriceImpact = !parseNumber(numImpact)

  const isInput = fromAmount || toAmount
  const tokenSponsorGas = listSponsorGas.find(item => lowerCase(item.address) === lowerCase(fromCoin?.address))
  const isSponsorGas = tokenSponsorGas ? parseFloat(fromAmount || 0) >= parseFloat(convertWeiToBalance(tokenSponsorGas?.amount, fromCoin?.decimals || fromCoin.decimal)) : false
  const addressSwap = AMM_ADDRESS[isSponsorGas ? tradeType.baryonTomoSponsorGas : typeTrade]

  // useEffect

  useEffect(() => {
    document.title = 'Exchange | Baryon'
  })

  useEffect(() => {
    reloadApprove()
  }, [addressSwap])

  useEffect(() => {
    window.fromAmount = fromAmount
  }, [fromAmount])

  useEffect(() => {
    loadListSponsorGas()
  }, [chainActive])

  useEffect(() => {
    clearAmountField()
    if ((!baseUrl && !pairUrl)) {
      setFromToCoin({
        from: renderBaseCoin(baseUrl, pairUrl, chainActive),
        to: !pairUrl ? COIN98_DEFAULT[chainActive] : {}
      })
      window.history.pushState(
        {},
        'baryon',
        `/swap?chain=${chainActive}`
      )
    } else {
      loadToken()
    }
  }, [chainActive])

  useEffect(() => {
    onReloadGas()
  }, [JSON.stringify(fromCoin)])

  useEffect(() => {
    onReloadBalance()
    onReloadRateInitial()
  }, [JSON.stringify(fromToCoin), connected, address, typeTrade])

  useEffect(() => {
    loadRouter()
  }, [JSON.stringify(tradeData)])

  useEffect(() => {
    loadKycBitkub()
  }, [chainActive, isWrapCoin, address])

  const loadListSponsorGas = async () => {
    if (chainActive === chainType.tomo) {
      const res = await BaseAdapter.getData('baryon/tokenSponsor')
      setListSponsorGas(get(res, 'data', []))
    } else {
      setListSponsorGas([])
    }
  }

  const loadKycBitkub = async () => {
    if ((chainActive === chainType.bitkub) && isWrapCoin && address) {
      const web3 = genWeb3(chainActive)
      const contract = genContract(web3, abiKycV2Bitkub, CONTRACT_KYC_BIT_KUB_V2)
      const isAddressKyc = await contract.methods.isAddressKyc(address).call()
      setKycBitkub(isAddressKyc)
    } else {
      setKycBitkub(false)
    }
  }

  // reload

  const onReloadRateInitial = (isReloadAmount, callback) => {
    if (isWrapCoin) {
      setAllowedPair([])
      setTradeData(null)
      setProviderFee({
        priceImpact: null,
        realizedLPFee: null
      })
      setLoadingFirst(false)
      return
    }

    exactCommonPair(typeTrade, fromCoin, toCoin)
      .then(async (commonPair) => {
        // if (!isReloadAmount) {
        setAllowedPair(commonPair)
        callback && callback(commonPair)
        // }

        // Load trade default first
        const tradeResultMain = exactTradeAmount(
          chainActive,
          commonPair,
          fromCoin,
          toCoin
        )

        if (!isReloadAmount) {
          setTradeData(tradeResultMain)
          setProviderFee(
            computeTradePriceBreakdown(tradeResultMain, chainActive, typeTrade)
          )
        }

        setLoadingFirst(false)

        if (!isReloadAmount && tradeResultMain) {
          reloadApprove()
          reloadFeeETH(tradeResultMain)
        }
      })
      .catch((err) => {
        setLoadingFirst(false)
      })
  }

  const reloadFeeETH = async (tradeResult) => {
    if (fromCoin.symbol === MAIN_COIN_AMM[chainActive].symbol) {
      const feeGas = await estimateGasSwapAMM(
        addressSwap,
        tradeResult.route.path.map((item) => item.address),
        400,
        typeTrade,
        chainActive
      )
      setGasLimitEst(feeGas)
    } else {
      setGasLimitEst(150000)
    }
  }

  const loadRouter = async () => {
    const arrRoute = get(tradeData, 'route.path', [])
    const listCoinLocal = window.wallet.getCoinFetch(chainActive) || []

    const newRouter = arrRoute.map((item) => {
      if ((lowerCase(item.address) === lowerCase(MAIN_COIN_AMM[chainActive].address) || lowerCase(item.address) === lowerCase(W_MAIN_COIN[chainActive].address))
      ) {
        return MAIN_COIN_AMM[chainActive]
      }
      const itemRouter = listCoinLocal.find(
        (itemLocal) => lowerCase(item.address) === lowerCase(itemLocal.address)
      )
      return itemRouter || item
    })
    setRoute(newRouter)
  }

  const onReloadProcessCircle = () => {
    const callBackReloadRate = (newAllowedPair) => {
      window.setTimerAmount = setTimeout(() => {
        reloadTradeExact(true, fromAmount, false, true, true, newAllowedPair)
      }, 100)
    }
    onReloadBalance()
    onReloadRateInitial(true, callBackReloadRate)
    reloadMainBalance()
  }

  const onReloadBalance = async () => {
    try {
      if (!connected) {
        setBalanceFromTo({ from: 0, to: 0 })
        return 0
      }

      const fromAddress = get(fromCoin, 'address', '')
      const toAddress = get(toCoin, 'address', '')

      const isFromMain = fromAddress === mainCoin.address
      const isToMain = toAddress === mainCoin.address

      let balanceFrom = 0
      let balanceTo = 0
      if (fromCoin?.symbol) {
        balanceFrom = await ReduxServices.fetchBalanceByChain(
          chainActive,
          { address: address },
          isFromMain ? '' : fromCoin
        )
      }

      if (toCoin?.symbol) {
        balanceTo = await ReduxServices.fetchBalanceByChain(
          chainActive,
          { address: address },
          isToMain ? '' : toCoin
        )
      }

      if (balanceFrom) {
        const fromAmount = parseFloat(
          isFromMain
            ? parseFloat(balanceFrom) - parseFloat(feeEst)
            : balanceFrom
        )
        balanceFrom = fromAmount <= 0 ? 0 : fromAmount
      }

      setBalanceFromTo({ from: balanceFrom, to: balanceTo })
    } catch (error) {
      console.log(error)
    }
  }

  async function onReloadGas () {
    refreshGas()
  }

  function reloadApprove () {
    // const isMainCoinIn = baseCoin.symbol === MAIN_COIN[chain]
    const isMainCoinIn = fromCoin.symbol === MAIN_COIN[chainActive]

    if (!isMainCoinIn && address) {
      const web3 = genWeb3(chainActive)
      checkNumApproved(
        web3,
        fromCoin.address,
        address,
        addressSwap
      )
        .then((res) => {
          setCoinNumApprove({
            nativeNumber: parseFloat(res),
            number: convertWeiToBalance(
              res,
              fromCoin.decimals || fromCoin.decimal
            ),
            symbol: fromCoin.symbol
          })
        })
        .catch((err) => console.log(err))
    }
  }

  const reloadTradeExact = async (
    isFrom,
    newNumber,
    isResetChart,
    isCheckZero,
    isExactFrom = isFromInput,
    newAllowedPair
  ) => {
    try {
      if (isCheckZero) {
        setLoadingFirst(false)

        if (newNumber === '0') {
          if (isFrom) {
            setToAmount('0')
          } else {
            setFromAmount('0')
          }
        }
      }

      if (isResetChart) {
        reloadApprove()
      }

      const tradeResult = exactTradeAmount(
        chainActive,
        newAllowedPair || allowedPair,
        fromCoin,
        toCoin,
        newNumber || 1,
        isExactFrom
      )
      setLoadingFirst(false)

      if (tradeResult) {
        setTradeData(tradeResult)
        const newProvider = computeTradePriceBreakdown(
          tradeResult,
          chainActive,
          typeTrade
        )

        setProviderFee(newProvider)

        const slippageNum =
          (isNaN(parseFloat(slippage))
            ? '2'
            : parseFloat(slippage).toFixed(1)) * 100
        const percentSlip = new Percent(
          JSBI.BigInt(Math.floor(slippageNum)),
          BIPS_BASE
        )

        // Use when input amount from to
        if (newNumber) {
          if (isFrom) {
            const newAmount = tradeResult.outputAmount.toSignificant(8)

            if (window.fromAmount) {
              setToAmount(roundingNumber(newAmount, 4))
            }
          } else {
            const formatFee = parseFloat(
              newProvider.realizedLPFee.toSignificant(8)
            )
            let newAmount =
              parseFloat(
                tradeResult.maximumAmountIn(percentSlip).toSignificant(8)
              ) + formatFee
            newAmount = newAmount.toString()
            setFromAmount(newAmount)
          }
        }
      } else {
        setToAmount('')
      }
    } catch (error) {
      setLoadingFirst(false)
    }
  }

  const loadToken = async () => {
    if (!baseUrl && !pairUrl) return

    try {
      let token0, token1
      const listCoinLocal = window.wallet.getCoinFetch(chainActive)

      if (baseUrl === mainCoin.address || baseUrl === mainCoin.symbol) {
        token0 = mainCoin
      }
      if (pairUrl === mainCoin.address || pairUrl === mainCoin.symbol) {
        token1 = mainCoin
      }

      if (!token0) {
        token0 = listCoinLocal.find(
          (item) => lowerCase(baseUrl) === lowerCase(item.address)
        )
      }
      if (!token1) {
        token1 = listCoinLocal.find(
          (item) => lowerCase(pairUrl) === lowerCase(item.address)
        )
      }

      if (!token0) {
        if (!baseUrl) {
          token0 = renderBaseCoin(baseUrl, pairUrl, chainActive)
        } else {
          const isValidAddress = baseUrl
            ? window.wallet.validateBlockChainAddress(baseUrl, chainActive)
            : false
          if (isValidAddress) {
            const web3 = genWeb3(chainActive)

            const contractToken0 = genContract(web3, ERC20, baseUrl)
            const decimals = await contractToken0.methods.decimals().call()
            const name = await contractToken0.methods.name().call()
            const symbol = await contractToken0.methods.symbol().call()
            token0 = {
              address: baseUrl,
              decimals: parseInt(decimals),
              name,
              symbol,
              chain: chainActive,
              image: '',
              cgkId: '0'
            }
          } else {
            token0 = {}
          }
        }
      }

      if (!token1) {
        const isValidAddress = pairUrl
          ? window.wallet.validateBlockChainAddress(pairUrl, chainActive)
          : false

        if (isValidAddress) {
          const web3 = genWeb3(chainActive)
          const contractToken1 = genContract(web3, ERC20, pairUrl)
          const decimals = await contractToken1.methods.decimals().call()
          const name = await contractToken1.methods.name().call()
          const symbol = await contractToken1.methods.symbol().call()
          token1 = {
            address: pairUrl,
            decimals: parseInt(decimals),
            name,
            symbol,
            chain: chainActive,
            image: '',
            cgkId: '0'
          }
        } else {
          token1 = {}
        }
      }

      //  decimals

      if (token0) {
        if (token0.decimal) {
          token0.decimals = token0.decimal
        }
      }

      if (token1) {
        if (token1.decimal) {
          token1.decimals = token1.decimal
        }
      }

      // base && setFromCoin(token0 || {})
      // setToCoin(token1 || {})

      if (token0?.address || token1?.address) {
        setFromToCoin({
          from: token0 || {},
          to: token1 || {}
        })
      } else {
        setFromToCoin({
          from: renderBaseCoin(baseUrl, pairUrl, chainActive),
          to: !pairUrl ? COIN98_DEFAULT[chainActive] : {}
        })
        window.history.pushState(
          {},
          'baryon',
          `/swap?chain=${chainActive}`
        )
      }
    } catch (error) {
      setFromToCoin({
        from: renderBaseCoin(null, null, chainActive),
        to: COIN98_DEFAULT[chainActive]
      })
      if (!isLoadingFirst) {
        window.history.pushState(
          {},
          'baryon',
          `/swap?chain=${chainActive}`
        )
      }
    }
  }

  //

  const onChangeAmount =
    (newAmountSlider, isNotDisablePercent, isAfterChange) => (value) => {
      setStateInputFrom(true)
      try {
        let newAmount = newAmountSlider || value
        if (!newAmount) {
          setFromAmount('')
          setToAmount('')
          clearTimeout(window.setTimerAmount)
          return null
        }
        // Check format number only
        if (!validateNumber(newAmount)) {
          // Clear data and error when no input
          if (newAmount.length === 0) {
            clearAmountField(isNotDisablePercent)
          }
        } else {
          if (newAmount.includes('.')) {
            newAmount = newAmount.replaceAll(',', '')
          }

          if (newAmount.includes(',')) {
            newAmount = newAmount.replace(',', '.')
          }

          // Check clear first dots
          if (newAmount.toString() !== '.') {
            const dotsCount = countDots(newAmount, '\\.')
            // Check only accept 1 dots
            if (dotsCount <= 1) {
              let isError = false
              if (dotsCount === 1) {
                // Check if token decimal greater than 0
                const decimalCount =
                  newAmount.length - newAmount.indexOf('.') - 1
                if (
                  decimalCount > 10
                  // && percent !== '100'
                ) {
                  isError = true
                }
              }

              if (!isError) {
                // User can input if pass all previous condition

                setFromAmount(newAmount.toString())

                clearTimeout(window.setTimerAmount)
                window.setTimerAmount = setTimeout(() => {
                  callBackChangeAmount(
                    newAmount,
                    isNotDisablePercent,
                    isAfterChange,
                    newAmountSlider
                  )
                }, 300)
              }
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

  const onChangeFiat = (value) => {
    let newFiat = value
    setStateInputFrom(false)
    // setHandleSlider(true)

    // Check format number only

    if (!validateNumber(newFiat)) {
      // Clear data and error when no input
      if (newFiat.length === 0) {
        clearAmountField()
      }
    } else {
      // Check clear first dots
      if (newFiat.includes(',')) {
        newFiat = newFiat.replace(',', '.')
      }

      if (newFiat.toString() !== '.') {
        const dotsCount = countDots(newFiat, '\\.')
        // Check only accept 1 dots
        if (dotsCount <= 1) {
          const isError = false
          // const decimalCount = newFiat.length - newFiat.indexOf('.') - 1

          // if (dotsCount === 1 && decimalCount > 6) {
          //   // Check if money decimal greater than 2
          //   isError = true
          // }
          if (!isError) {
            // Convert to token amount format based on token Decimal
            setToAmount(newFiat.toString())
            clearTimeout(window.setTimerAmount)
            window.setTimerAmount = setTimeout(() => {
              callBackChangeFiat(newFiat)
            }, 300)
          }
        }
      }
    }
  }

  const callBackChangeAmount = (
    newAmount,
    isNotDisablePercent,
    isAfterChange,
    newAmountSlider
  ) => {
    // if (!isNotDisablePercent) {
    //   setSelectedPercent(null)
    // }

    if (isWrapCoin) return setToAmount(newAmount.toString())

    if (isAfterChange || !newAmountSlider) {
      reloadTradeExact(true, newAmount, false, true, true)
    }
  }
  const callBackChangeFiat = (newFiat) => {
    reloadTradeExact(false, newFiat, false, true, false)
  }

  const clearAmountField = (isNotDisablePercent) => {
    // !isNotDisablePercent && setSelectedPercent(null)
    setLoadingTrade(false)
    setFromAmount('')
    setToAmount('')
  }

  const onChangeInput = ({ isFrom, value, isLoading }) => {
    if (isFrom) {
      onChangeAmount()(value)
    } else {
      onChangeFiat(value)
    }
  }

  const onWrapUnWrap = async () => {
    setLoadingTrade(true)
    const isWrap = !fromCoin.address

    const callBack = (mess) => {
      clearAmountField()
      setLoadingTrade(false)
      window.openModal({
        title: t('success'),
        containerClass: 'modal-success',
        content: <PopupHash hash={mess} type={isWrap ? WRAP : UNWRAP} />,
        type: 'success',
        okText: t('close')
      })
    }

    window.openModal({
      title: t('reviewOrder'),
      type: 'confirm',
      content:
          <PreviewOrder
            baseCoin={fromCoin}
            pairCoin={toCoin}
            txtNumFrom={fromAmount}
            txtNumTo={toAmount}
            isWrap={true}
          />,
      afterClose: () => {
        setLoadingTrade(false)
      },
      onCancel: () => {
        setLoadingTrade(false)
      },
      onOk: async () => {
        await onWrapUnWrapToken({
          addressWToken: isWrap ? toCoin.address : fromCoin.address,
          addressWallet: address,
          chain: chainActive,
          callBack,
          callBackFail: onFailTrade,
          sendRawTxn,
          amount: fromAmount,
          isWrap
        })
      }
    })
  }

  function onBuy () {
    setLoadingTrade(true)

    const tradeResult = tradeData

    const realToAmount = tradeResult.outputAmount.toSignificant(8)

    const callBackFail = (mess) => {
      onFailTrade(mess)
    }

    const callBackBuySuccess = (mess) => {
      setLoadingTrade(false)
      clearAmountField()
      if (mess === 'txsFail') {
        setTimeout(() => {
          onFailTrade(mess)
        }, 2000)
      } else {
        setTimeout(() => {
          toast.dismiss()
        }, 200)

        setTimeout(() => {
          BaseAPI.postData('baryon/swap', { hash: mess, chain: chainActive })

          window.openModal({
            title: t('success'),
            containerClass: 'modal-success',
            content: <PopupHash hash={mess} type={SWAP} />,
            type: 'success',
            okText: t('close')
          })
        }, 400)

        setTimeout(() => {
          onReloadRateInitial()
        }, 3000)

        setTimeout(async () => {
          window.onReloadPage && window.onReloadPage()
          reloadApprove()
          onReloadBalance()
        }, 5000)
      }
    }

    const onRunTxs = async () => {
      setLoadingTrade(true)
      reloadApprove()

      const onEstimateGasConfirm = (fee, onPressTxs, title = 'reviewOrder') => {
        const isConfirmSwap = title === 'reviewOrder'
        setLoadingTrade(true)
        if (isConfirmSwap) {
          reloadApprove()
        }
        window.closeModal()
        window.openModal({
          title: t(title),
          type: 'confirm',
          // isDisable: objButton.isDisableApprove,
          content: isConfirmSwap
            ? (
              <PreviewOrder
                feeEst={fee}
                fRateFee={fRateFee}
                baseCoin={fromCoin}
                pairCoin={toCoin}
                txtNumFrom={fromAmount}
                txtNumTo={toAmount}
                priceImpact={formatPriceImpact(provider.priceImpact)}
                rate={formatExecutionPrice(tradeData, fromCoin, toCoin)}
                isSponsorGas={isSponsorGas}
              />
            )
            : <div className='confirm-approve-symbol'>{t('confirmApproveSymbol', { symbol: upperCase(fromCoin.symbol) })}</div>,
          afterClose: () => {
            setLoadingTrade(false)
          },
          onCancel: () => {
            setLoadingTrade(false)
          },
          onOk: async () => {
            window.closeModal()
            await onPressTxs()
          }
        })
      }

      const callSwap = async (swapAmount) => {
        const numSlipper = slippage
        const callBackDataFirst = () => {
          showNotificationToast(
            t('pleaseWaitOrder'),
            'success',
            15000,
            false
          )
          setLoadingTrade(false)
        }

        sendSwapAMM({
          addressSwap,
          address,
          trade: tradeResult,
          numDeadLine: 600,
          slippage: (isNaN(parseFloat(numSlipper))
            ? '2'
            : parseFloat(numSlipper).toFixed(1)) * 100,
          callBack: callBackBuySuccess,
          gasPrice: selectedValueGas,
          onEstimateGasConfirm,
          chain: chainActive,
          isUnlimitedApprove: true,
          typeTrade,
          receivedValue: realToAmount,
          isFromInput,
          callBackFail,
          sendRawTxn,
          isSponsorGas,
          isCoin98,
          callBackDataFirst
        }).catch((err) => {
          onFailTrade(err)
        })
      }

      const currentBalance = await ReduxServices.fetchBalanceByChain(
        chainActive,
        { address: address },
        fromCoin.symbol === MAIN_COIN_AMM[chainActive].symbol ? '' : fromCoin
      )

      const parseAmount =
        parseFloat(fromAmount) >= parseFloat(currentBalance)
          ? currentBalance
          : fromAmount
      callSwap(parseAmount)
    }

    const onRunBeforeTxs = () => {
      if (parseFloat(slippage) > 10) {
        window.openModal({
          title: t('swapConfirmation'),
          content: <div> {t('yourFrontrun2')}</div>,
          type: 'confirm',
          afterClose: () => {
            setLoadingTrade(false)
          },
          onCancel: () => {
            setLoadingTrade(false)
          },
          onOk: () => {
            onRunTxs()
            window.closeModal()
          }
        })
      } else {
        onRunTxs()
      }
    }

    if (isLargePriceImpact) {
      window.openModal({
        title: t('warning'),
        content: <ModalConfirmImpact numImpact={numImpact} onConfirm={onRunBeforeTxs} onLoading={setLoadingTrade} />,
        type: 'warningErr',
        noButton: true,
        preventClose: true
      })
    } else {
      onRunBeforeTxs()
    }
  }

  const onFailTrade = (error) => {
    window.closeModal()
    toast.dismiss()
    const errMess = encodeMessErr(error)
    showNotificationToast(t(errMess), 'error', 2000)
    setLoadingTrade(false)
    reloadApprove()
  }

  const onChangeSlippage = (slippage) => () => {
    setSlippage(slippage)
  }

  const onChangeGas = (gas) => {
    setGasSelect(gas)
  }

  const handleOpenSetting = () => {
    window.openModal({
      title: t('setting'),
      type: 'save-info',
      okText: t('saveSetting'),
      containerClass: 'modal-setting',
      content: (
        <Setting
          ref={settingRef}
          slippage={slippage}
          onChangeSlippage={onChangeSlippage}
          gasLimitEst={gasLimitEst}
          onChangeGas={onChangeGas}
          gasSelected={gasSelect}
          isShowGas
          isSwap
        />
      ),
      onOk: async () => {
        handleSaveSetting()
      }
    })
  }

  const handleExpand = () => {
    setIsExpandChart(!isExpandChart)
  }

  const handleToggleChart = () => {
    setIsOpenChart(!isOpenChart)
  }

  const handleSaveSetting = () => {
    if (settingRef && settingRef.current) {
      settingRef.current.handleSaveSetting()
      window.closeModal()
    }
  }

  const handleSelectToken = ({ isFrom, token }) => {
    const addressFrom = (lowerCase(fromCoin?.address) === lowerCase(mainCoin?.address)) ? mainCoin?.symbol : fromCoin?.address
    const addressTo = (lowerCase(toCoin?.address) === lowerCase(mainCoin?.address)) ? mainCoin?.symbol : toCoin?.address
    const addressToken = (lowerCase(token?.address) === lowerCase(mainCoin?.address)) ? mainCoin?.symbol : token?.address

    let newFromCoin, newToCoin

    if (isFrom) {
      const isCompare = addressToken === addressTo

      newFromCoin = token
      newToCoin = isCompare ? fromCoin : toCoin
    } else {
      const isCompare = addressToken === addressFrom

      newToCoin = token
      newFromCoin = isCompare ? toCoin : fromCoin
    }

    setFromToCoin({
      from: newFromCoin,
      to: newToCoin
    })
    clearAmountField()

    const address0 = (lowerCase(newFromCoin?.address) === lowerCase(mainCoin?.address)) ? mainCoin?.symbol : newFromCoin?.address
    const address1 = (lowerCase(newToCoin?.address) === lowerCase(mainCoin?.address)) ? mainCoin?.symbol : newToCoin?.address
    window.history.pushState(
      {},
      'baryon',
      `/swap?chain=${chainActive}&base=${address0}${address1 ? '&pair=' + address1 : ''}`
    )
  }

  const onSwitchTrade = useDebouncedCallback(() => {
    const oldFromCoin = { ...fromCoin }
    const oldToCoin = { ...toCoin }
    clearAmountField()
    setFromToCoin({
      from: oldToCoin,
      to: oldFromCoin
    })
    setIsSwitchTrade(!isSwitchTrade)
  }, 300)

  const handleShareSwap = () => {
    window.openModal({
      isCustomModal: true,
      containerClass: 'modal-stake',
      noIcon: true,
      backgroundTrans: true,
      className: 'modal-content--stake',
      content: <ShareModal
        type='swap'
        token0={fromCoin}
        token1={toCoin}
      />,
      onCancel: () => {
        console.log('cancel')
      }
    })
  }

  const renderRouter = (item, index) => {
    const isLastItem = index === getLength(get(tradeData, 'route.path', 1)) - 1

    const imgToken =
      get(item, 'logoURI') || get(item, 'image', images.icoUnknown)

    let realSymbol = item.symbol
    if (realSymbol === 'W' + MAIN_COIN_AMM[chainActive].symbol) {
      realSymbol = MAIN_COIN_AMM[chainActive].symbol
    }

    return (
      <React.Fragment key={get(item, 'address') + chainActive}>
        <div className="eth text-normal">
          <img src={imgToken} alt="" />
          {/* {routeShort && upperCase(realSymbol)} */}
        </div>
        {/* {!isLastItem && <img className='icon-route-next' src={isDarkMode ? images.iconNext : images.iconNextBlack} alt="" />} */}
        {!isLastItem && (
          <span className="icon-route-next icon-web_arrow_right"></span>
        )}
      </React.Fragment>
    )
  }

  const objButton = useMemo(() => {
    // isDisable
    const isSelectCoin = getLength(Object.keys(fromCoin)) > 0 && getLength(Object.keys(toCoin)) > 0

    let isAmountErr = balanceFromTo.from <= 0 || !toAmount
    if (!isAmountErr) {
      isAmountErr = balanceFromTo.from < parseFloat(fromAmount)
    }

    const isDisableBuy =
      getLength(fromAmount) === 0 || (!tradeData && !isWrapCoin) || parseFloat(fromAmount) <= 0

    const isFeeErr = !isLoadingFirst && !isSponsorGas && mainBalance - feeEst <= 0

    const isDisable = isAmountErr || isDisableBuy || isFeeErr || !connected || isNoWallet

    // mess
    let message = ''

    if (isAmountErr && isSelectCoin) message = 'amountErr'
    if (isFeeErr && isSelectCoin) message = 'feeErr'

    if (getLength(fromAmount) === 0 || getLength(toAmount) === 0) {
      message = 'inputAnAmount'
    }

    if (getLength(route) === 0 && isSelectCoin && !isWrapCoin) {
      message = 'poolNotFound'
    }

    if (!connected) {
      message = 'showLabelConnectWallet'
    }

    if (isNoWallet) {
      message = 'showLabelConnectWallet'
    }

    if (isWrapCoin && isWrap && !message) message = 'Wrap'
    if (isWrapCoin && !isWrap && !message) message = 'Unwrap'

    // if ((!isDisable || isNeedApprove) && !message) {
    //   message = 'approve'
    // }

    if (!message) message = 'swap'

    return {
      isDisable,
      message
    }
  })

  return (
    <div
      className={`swap-screen-raydium ${
        isOpenChart && 'swap-screen-raydium--open-chart'
      }`}
    >
      <div
        className={`swap-container ${
          isOpenChart && 'swap-container--show-chart'
        }`}
      >
        {isOpenChart && <div className='w-100'>
          <ChartSwap
            handleExpand={handleExpand}
            isExpandChart={isExpandChart}
            fromCoin={fromCoin}
            toCoin={toCoin}
          />
          <TableSwapChart fromCoin={fromCoin} toCoin={toCoin}/>
        </div>}
        <div className="swap-content">
          {/* <div className="swap-content__background">
            <img src={images.bigBall} alt="" />
          </div> */}
          <div
            className={`swap-content__box ${
              isNeedApprove && 'swap-content__box--need-approve'
            }`}
          >
            <div>
              <div className="box-head">
                <p>{t('swap')}</p>
                <div className="box-head__right">
                  <div onClick={handleToggleChart} className="right-setting">
                    <img
                      src={images[isOpenChart ? 'chartClose' : 'chart']}
                      alt=""
                      className="img-icon"
                    />
                    <img
                      src={
                        images[isOpenChart ? 'chartCloseYellow' : 'chartYellow']
                      }
                      alt=""
                      className="img-icon img-icon--hover"
                    />

                    <div className="tooltip-suggest">
                      {t(isOpenChart ? 'closeChart' : 'openChart')}
                    </div>
                  </div>

                  <div onClick={handleShareSwap} className="right-setting">
                    <IconCustom type={'default'} icon='app_share'/>
                    <div className="tooltip-suggest">{t('shareSwap')}</div>
                  </div>
                  <div onClick={handleOpenSetting} className="right-setting">
                    <img src={images.iconSetting} alt="" className="img-icon" />
                    <img
                      src={images.iconSettingYellow}
                      alt=""
                      className="img-icon img-icon--hover"
                    />

                    <div className="tooltip-suggest">{t('setting')}</div>
                  </div>

                  <div className="right-count-down">
                    <Tooltip content={t('dataWillAuto')} className='pieloading-tooltip'>
                      <ProcessCircle
                        radius={14}
                        stroke={2}
                        onReload={onReloadProcessCircle}
                        onResetCountDown={onReloadProcessCircle}
                        countTimeReload={countTimeReload}
                      />
                    </Tooltip>

                  </div>
                </div>
              </div>

              <div className="box-select">
                <div className="box-select__item">
                  <SelectToken
                    valueInput={fromAmount}
                    isFrom={true}
                    handleChangeInput={onChangeInput}
                    handleSelectToken={handleSelectToken}
                    coinSelected={fromCoin}
                    coinCompare={toCoin}
                    isDisable={false}
                    balance={balanceFromTo.from}
                    isSwapFrom
                  />
                </div>
                <div onClick={onSwitchTrade} className={'box-select__icon'}>
                  <img
                    src={images[isDarkMode ? 'iconSwapDark' : 'iconSwap']}
                    alt=""
                  />
                </div>
                <div className="box-select__item box-select__item--cursor">
                  <SelectToken
                    valueInput={toAmount}
                    handleChangeInput={onChangeInput}
                    handleSelectToken={handleSelectToken}
                    coinSelected={toCoin}
                    coinCompare={fromCoin}
                    isDisable={true}
                    balance={balanceFromTo.to}
                  />
                </div>
              </div>
              <div className="swap-info">
                {!isWrapCoin &&
                <>
                  <div className="swap-info__item">
                    <p>{t('rate')}</p>
                    <div className="info-item__price">
                      {tradeData
                        ? formatExecutionPrice(tradeData, fromCoin, toCoin)
                        : '-'}
                    </div>
                  </div>
                  <div className="swap-info__item">
                    <p>{t('slippageTolerance')}</p>
                    <p className={'info-detail'}>{slippage}%</p>
                  </div>
                  <div className="swap-info__item swap-info__item--price-impact">
                    <p>{t('priceImpact')}</p>
                    <div
                      className={`info-detail ${
                        isInput && isLargePriceImpact && 'price-impact-red'
                      }`}
                    >
                      {provider && isInput
                        ? numImpact + '%'
                        : '-'}
                    </div>
                  </div>

                  <div className="route swap-info__item">
                    <p>
                      {t('route')}
                    </p>
                    <div className="route__content">
                      {getLength(route) > 0 ? route.map(renderRouter) : '-'}
                    </div>
                  </div>
                </>}

                {isShowKycBitkub &&
                <div className='info-link-kyc'>
                  <p>Please KYC your address with this link before unwrap</p>
                  <a target="_blank" rel="noreferrer" href='https://kkub-otp.bitkubchain.com' >https://kkub-otp.bitkubchain.com</a>
                </div>}

              </div>
            </div>
            <div className="swap-action--button">
              {/* {!objButton.isDisableApprove && */}
              {/* <Button
                isDisable={objButton.isDisableApprove}
                onClick={onBuy}
                isLoading={isLoadingTrade}
                className={`swap-action ${
                  !isNeedApprove && 'swap-action--hidden'
                }`}
                isFullWidth
              >
                {t('approve') + ' ' + upperCase(fromCoin.symbol)}
              </Button> */}
              {/* } */}

              <Button
                isDisable={objButton.isDisable || isShowKycBitkub || (isDisablePriceImpact && !isWrapCoin)}
                onClick={isWrapCoin ? onWrapUnWrap : onBuy}
                isLoading={isLoadingTrade}
                className="swap-action"
                isFullWidth
              >
                {t(objButton.message)}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SwapScreen
