import {
  convertBalanceToWei,
  convertWeiToBalance,
  countDots,
  formatPrice,
  getLength,
  lowerCase,
  roundingNumber,
  showNotificationToast,
  upperCase,
  validateNumber
} from 'common/functions'
import { STAKE, UN_STAKE } from 'common/stake/constants'
import Button from 'components/common/Button'
import PopupHash from 'components/PopupHash'
import SliderComponent from 'components/Slider'
import get from 'lodash/get'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
import { encodeMessErr, zeroAddress } from 'controller/Library/evm'

import './style.scss'
import { useSelector } from 'react-redux'
import useFarmV2 from 'common/farm/hooks/useFarmV2'
import BaseAPI from 'controller/api/BaseAPI'
import { historyType } from '../Constants'
import { useWallet } from '@coin98-com/wallet-adapter-react'
import { CONTRACT_FARM_STAKE_V1, CONTRACT_FARM_STAKE_V2, W_MAIN_COIN_STAKE } from 'common/poolEvm/constants'
import useApprove from 'common/poolEvm/hooks/useApprove'
import Approve from 'components/Approve'

function StakePopUp ({ type, item, loadInitialTotal }) {
  const { t } = useTranslation()
  const chainActive = useSelector((state) => state.chainActive)
  const { address } = useWallet()

  const [valueSlide, setValueSlide] = useState(0)
  const [amount, setAmount] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [gasLimitEst, setGasLimitEst] = useState(0)
  const [gasSelect, setGasSelect] = useState({ index: 0 })
  const isStakeFarm = true

  const stakingToken = get(item, 'stakingToken')
  const isV2 = get(item, 'isV2')
  const isWvic = lowerCase(get(item, 'stakedLpAddress')) === lowerCase(W_MAIN_COIN_STAKE[chainActive].address)
  const stakedLpAddress = isWvic ? zeroAddress : get(item, 'stakedLpAddress')

  const rewardTokens = get(item, 'rewardTokens', [])
  const pid = get(item, 'pid')

  const token0 = get(item, 'token0')
  const token1 = get(item, 'token1')

  const tokenInfoStaked = get(item, 'tokenInfoStaked')
  const decimalStake = tokenInfoStaked.decimal ? tokenInfoStaked.decimal : tokenInfoStaked?.decimals
  const isStake = !token0

  const renderSymbolLP = isStake
    ? upperCase(get(tokenInfoStaked, 'symbol', ''))
    : `${upperCase(get(token0, 'symbol', ''))} - ${upperCase(
      get(token1, 'symbol', '')
    )} LP`

  const balanceStaked = get(item, 'balanceStaked', 0)
  const gasPrice = get(gasSelect, 'gas.value', 0)
  const factoryAddress = get(item, 'factoryAddress')

  const { balanceCoinStake, enterStaking, leaveStaking } = useFarmV2(stakedLpAddress, gasPrice, isV2, factoryAddress)
  const { payload: approval } = useApprove(chainActive, stakedLpAddress, address, isV2 ? (factoryAddress || CONTRACT_FARM_STAKE_V2[chainActive]) : CONTRACT_FARM_STAKE_V1[chainActive])

  const balance = type === STAKE ? balanceCoinStake : balanceStaked

  const isInsufficient = parseFloat(amount) > parseFloat(balance)
  const isNotApprove = parseFloat(amount) > parseFloat(convertWeiToBalance(approval, decimalStake))

  const isDisabled = !amount || convertBalanceToWei(amount) === '0' || (getLength(amount) === 0) || isInsufficient

  useEffect(() => {
    loadGasLimitEst()
  }, [])

  const loadGasLimitEst = async () => {
    const decimal = tokenInfoStaked.decimal ? tokenInfoStaked.decimal : tokenInfoStaked?.decimals
    const convertAmount = convertBalanceToWei(amount, decimal)

    const gasLimit = type === STAKE
      ? await enterStaking({
        amount: convertAmount,
        pid,
        callBackFail,
        isReturnEstimateGasLimit: true,
        value: isWvic ? convertAmount : 0
      })
      : await leaveStaking({
        amount: convertAmount,
        pid,
        callBackFail,
        isReturnEstimateGasLimit: true
      })
    setGasLimitEst(gasLimit)
  }

  const handleValueSlide = (value) => {
    let amount = (parseInt(value) / 100) * parseFloat(balance)
    if ((value === 100) || (value === 99) || (value === 98)) amount = balance

    setAmount(amount)
    setValueSlide(value)
  }

  const handleMax = () => {
    handleValueSlide(100)
  }

  const onChangeInput = (value) => {
    let newAmount = value

    if (!newAmount) {
      setAmount('')
      setValueSlide(0)
      return null
    }

    if (!validateNumber(newAmount)) {
      // Clear data and error when no input
      if (newAmount.length === 0) {
        setAmount('')
        setValueSlide(0)
      }
    } else {
      if (newAmount.includes(',')) {
        newAmount = newAmount.replace(',', '.')
      }
      // Check clear first dots
      if (newAmount.toString() !== '.') {
        const dotsCount = countDots(newAmount, '\\.')
        // Check only accept 1 dots
        if (dotsCount <= 1) {
          const slide = (newAmount / balance) * 100

          setValueSlide(slide < 100 ? Math.floor(slide) : 100)
          setAmount(newAmount)
        }
      }
    }
  }

  const handleCancel = () => {
    window.closeModal()
    window.closeModalFirst()
  }

  const orderComplete = (type) => (hash) => {
    BaseAPI.postData('baryon/farm/history', {
      hash,
      createdAddress: address,
      chain: chainActive,
      type: type === UN_STAKE ? historyType.unStake : historyType.stake,
      amount,
      lpAddress: item?.stakedLpAddress,
      harvestData: rewardTokens.map(token => {
        return {
          amount: token?.amountTokenReward,
          token: token?.address
        }
      })
    })

    loadInitialTotal && loadInitialTotal()

    window.closeModalFirst()
    window.closeModal()

    window.openModal({
      title: t('success'),
      containerClass: 'modal-success',
      content: <PopupHash type={type} hash={hash} />,
      type: 'success',
      okText: t('close')
    })
  }

  const callBackFail = (isEstimateGas) => (mess) => {
    const stringResult = encodeMessErr(mess)
    !isEstimateGas && showNotificationToast(t(stringResult), 'error', 2000)
    !isEstimateGas && window.closeModalFirst()
    !isEstimateGas && window.closeModal()
  }

  const handleSubmitActions = async () => {
    setIsLoading(true)
    const objAction = {
      [STAKE]: enterStaking,
      [UN_STAKE]: leaveStaking
    }
    const decimal = tokenInfoStaked.decimal ? tokenInfoStaked.decimal : tokenInfoStaked?.decimals
    const convertAmount = convertBalanceToWei(amount, decimal)
    await objAction[type]({
      value: isWvic ? convertAmount : 0,
      amount: convertAmount,
      pid,
      callBack: orderComplete(type),
      callBackFail
    })
    setIsLoading(false)
  }

  const handleApprove = async () => {
    window.openModal({
      title: t('confirmApprove'),
      type: 'confirm',
      noButton: true,
      content: <Approve renderSymbol={upperCase(renderSymbolLP)} callBackSuccess={handleSubmitActions} contractAddress={stakedLpAddress} spender={isV2 ? (factoryAddress || CONTRACT_FARM_STAKE_V2[chainActive]) : CONTRACT_FARM_STAKE_V1[chainActive]} load={loadInitialTotal} />
    })
  }

  const renderText = (isTitle) => {
    if (isInsufficient) return 'insufficientFunds'

    switch (type) {
    case STAKE:
      if (!isTitle && isNotApprove) return 'approve'
      return isStake ? 'stakeInPool' : 'stakeLP'
    case UN_STAKE:
      return isStake ? 'unStake' : 'unStakeLP'
    default:
      return ''
    }
  }

  return (
    <div className="stake-form-pop-up">
      <div className="stake-form-pop-up__title">{t(renderText(true))}</div>

      <div className="select-stake">
        <div className="select-stake__form">
          <div className="form-input">
            <div className="form-input-top">
              {/* <input
                onChange={(e) => onChangeInput(e.target.value)}
                value={roundingNumber(amount, 6, true)}
                type="text"
                placeholder="0.0"
              /> */}
              <NumberFormat
                placeholder="0.0"
                onValueChange={(event) => onChangeInput(event.value)}
                thousandsGroupStyle="thousand"
                value={roundingNumber(amount, 7, true)}
                prefix=""
                decimalSeparator="."
                displayType="input"
                type="text"
                thousandSeparator={true}
              />

              <div className="form-input-top__info-token">
                {!isStakeFarm && <img
                  src={get(stakingToken, 'image', '')}
                  alt=""
                  className="icon-token"
                />}

                {!isStakeFarm ? upperCase(get(stakingToken, 'symbol', '')) : renderSymbolLP}
              </div>
            </div>

            <div className="form-input-bottom">
              <div className="form-input-bottom--left">{t('amount')}</div>

              <div className="form-input-bottom--right" onClick={handleMax}>
                {t('balance')}:{' '}
                <span>{formatPrice(balance, false, true)}</span>
              </div>
            </div>

            <div className="form-input-slide">
              <SliderComponent
                min={0}
                max={100}
                tipFormatter={(value) => <div>{`${value}%`}</div>}
                marks={{ 0: '0%', 25: '25%', 50: '50%', 75: '75%', 100: '100%' }}
                value={valueSlide}
                onChange={handleValueSlide}
              />
            </div>
          </div>

        </div>

      </div>

      {/* {!isMobile && <GasSlider className={'gwei-stake'} onChange={handleGas} chain={chainActive} gasLimitEst={gasLimitEst} gasSelected={gasSelect} />} */}

      {/* <div className="current-rate">
        <div className="current-rate__text">Annual ROI at current rates</div>
        <div className="current-rate__balance">{formatPrice(142.12312321)}</div>
      </div> */}

      <div className="stake-actions">
        <Button
          type='cancel'
          isDisable={isLoading}
          onClick={handleCancel}
          isFullWidth

          className="stake-action__actions stake-action__cancel"
        >
          {t('cancel')}
        </Button>

        <Button
          isLoading={isLoading}
          onClick={ ((type === STAKE) && isNotApprove) ? handleApprove : handleSubmitActions}
          isFullWidth
          className="stake-action__actions"
          isDisable={isDisabled}
        >
          {t(renderText())}
        </Button>
      </div>

      {/* {type === 'stake' && (
        <div className="stake-link-change">
          <img src={images.iconC98BNB} alt="" className="link-icon" />

          {isFarm ? t('getC98BNB') : 'Get C98'}
        </div>
      )} */}
    </div>
  )
}

export default StakePopUp
