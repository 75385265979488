/* eslint-disable no-throw-literal */
/* eslint-disable prefer-regex-literals */

import React from 'react'
import images from 'assets/images'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import bigdecimal from 'bigdecimal'
import numeral from 'numbro'
import { upperFirst } from 'lodash'
import moment from 'moment'
import converter from 'hex2dec'
import { chainType, SCAN_URL } from 'common/constants'

export const inputRegex = RegExp('^\\d*(?:\\\\[.])?\\d*$')

export function escapeRegExp (string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export const showNotificationToast = (
  description = null,
  type = 'success',
  duration = 5000,
  hideBar = true,
  title
) => {
  const objIcon = {
    success: 'successIcon',
    error: 'iconError',
    update: 'iconSync',
    send: 'iconSend',
    confirm: 'iconAlertConfirm'
  }

  return toast(
    <div className="toast-container">
      {/* <div className={`toast-status toast-status--${type}`}></div> */}
      <div onClick={() => toast.dismiss()} className="toast-container__close">
        <img src={images.iconCloseLight} alt="" />
      </div>
      <img className="toast-container__img" src={images[objIcon[type]]} />
      <div className="toast-container__text">
        <h4>{type === 'success' ? 'Successful' : upperFirst(type)}</h4>
        {description && (
          <h6
            className={!title && 'description-title'}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    </div>,
    {
      positition: 'bottom-right',
      autoClose: duration,
      hideProgressBar: hideBar,
      className: `luna-toast luna-toast--${type}`
    }
  )
}

export const getItemStorage = (key) => {
  try {
    return JSON.parse(window.localStorage.getItem(key))
  } catch (error) {
  }
}

export const setItemStorage = (item, key) => {
  window.localStorage.setItem(key, JSON.stringify(item))
}

export const removeItemStorage = (key) => {
  window.localStorage.removeItem(key)
}

export const getLength = (value) => (value ? value.length : 0)

export const lowerCase = (value) => {
  return value && value.toLowerCase ? value.toLowerCase() : value
}
export const upperCase = (value) => {
  return value && value.toUpperCase ? value.toUpperCase() : value
}

export const formatAddress = (addr, num = 5) => {
  if (!addr || !addr.split) {
    return '...'
  }
  return (
    addr.slice(0, num) +
    '...' +
    addr.split('').reverse().slice(0, num).reverse().join('')
  )
}

export const convertWeiToBalance = (strValue, iDecimal = 18) => {
  try {
    if (parseFloat(strValue) === 0) return 0
    const multiplyNum = new bigdecimal.BigDecimal(Math.pow(10, iDecimal))
    const convertValue = new bigdecimal.BigDecimal(String(strValue))
    return convertValue.divide(multiplyNum).toString()
  } catch (err) {
    return 0
  }
}

export const countDots = (strString, strLetter) => {
  const string = strString.toString()

  if (!string.includes('.')) {
    return 0
  }
  return (string.match(RegExp(strLetter, 'g')) || []).length
}

export const formatNumberBro = ({
  number,
  mantissa = 4,
  isReturnNaN = false,
  textNa = '',
  trimMantissa = true,
  isMoreThan = false
}) => {
  if (
    number !== false &&
    number !== 'null' &&
    !(number === null) &&
    !isNaN(number) &&
    !(number === undefined) &&
    number !== 'NaN' &&
    number !== Infinity
  ) {
    if (number.toString().length > 0 && !isMoreThan) {
      // eslint-disable-next-line no-useless-escape
      return numeral(number.toString().replace(/\,/g, '')).format({
        trimMantissa,
        thousandSeparated: true,
        mantissa
      })
    }

    // isMoreThan ...
    if (number.toString().length > 0 && isMoreThan) {
      // eslint-disable-next-line no-useless-escape
      const newNumber = numeral(number.toString().replace(/\,/g, '')).format({
        trimMantissa,
        thousandSeparated: true,
        mantissa
      })
      const indexE = number.toString().indexOf('e')
      const dotsCount = countDots(number.toString(), '\\.')
      let decimalCount = 0
      if (dotsCount === 1) {
        decimalCount =
          number.toString().length - number.toString().indexOf('.') - 1
      }

      if (
        (newNumber.toString() === '0' && indexE !== -1) ||
        (newNumber.toString() === '0' && mantissa < decimalCount)
      ) {
        return parseInt(newNumber).toFixed(mantissa) + '...'
      }
      return newNumber
    }
  }
  return isReturnNaN ? textNa || 'N/A' : 0
}

export const formatPrice = (
  price,
  isLarge = false,
  isNoDollar = false,
  symbol,
  isMoreThan = false
) => {
  const absPrice = Math.abs(price)

  const isBTC = symbol === 'BTC'

  let decimal = 2

  if (absPrice < 0.0001) {
    decimal = 8
  }

  if (absPrice < 0.01 && absPrice >= 0.0001) {
    decimal = 6
  }

  if (absPrice >= 0.01 && absPrice < 1) {
    decimal = 4
  }

  if (absPrice >= 1) {
    decimal = 2
  }

  if (isBTC) {
    decimal = 8
  }

  if (isLarge) {
    decimal = 2
  }

  if (price === 0) {
    decimal = 0
  }
  return (
    `${!isNoDollar ? '$' : ''}${formatNumberBro({
      number: Math.abs(price),
      mantissa: decimal,
      trimMantissa: true,
      isMoreThan
    })}`
  )
}

export const roundingNumber = (number, rounding = 6, isReturnAll) => {
  if (isNaN(number)) {
    return ''
  }
  if (isReturnAll) {
    if (number === '') {
      return ''
    }
    const dotsCount = countDots(number, '\\.')
    if (dotsCount === 1) {
      const decimalCount = number.toString().length - number.toString().indexOf('.') - 1
      if (decimalCount <= rounding) return number
    }

    if (getLength(Math.floor(number).toString()) > 16) {
      return number
    }
  }

  const powNumber = Math.pow(10, parseInt(rounding))

  if (number > 9999999999) return number
  return Math.floor(number * powNumber) / powNumber
}

export const scientificToDecimal = (num) => {
  if (!isFinite(num)) return 0
  const sign = Math.sign(num)
  // if the number is in scientific notation remove it
  // eslint-disable-next-line no-useless-escape
  if (/\d+\.?\d*e[\+\-]*\d+/i.test(num)) {
    const zero = '0'
    const parts = String(num).toLowerCase().split('e') // split into coeff and exponent
    const e = parts.pop() // store the exponential part
    let l = Math.abs(e) // get the number of zeros
    const direction = e / l // use to determine the zeroes on the left or right
    const coeffArray = parts[0].split('.')

    if (direction === -1) {
      coeffArray[0] = Math.abs(coeffArray[0])
      num = zero + '.' + new Array(l).join(zero) + coeffArray.join('')
    } else {
      const dec = coeffArray[1]
      if (dec) l = l - dec.length
      num = coeffArray.join('') + new Array(l + 1).join(zero)
    }
  }

  if (sign < 0) {
    num = -num
  }
  return num
}

export const validateNumber = (strNumber, onlyNumber = false) => {
  try {
    let reg = /^([0-9_,.]+)$/
    if (onlyNumber) reg = /^([0-9_]+)$/

    return reg.test(strNumber)
  } catch (error) {
    return false
  }
}

export const formatBilion = (labelValue, mantissa = 2) => {
  // Nine Zeroes for Billions
  const numFormat =
    Math.abs(Number(labelValue)) >= 1.0e9
      ? formatNumberBro({ number: (Math.abs(Number(labelValue)) / 1.0e9).toFixed(mantissa) }) + 'B'
      : Math.abs(Number(labelValue)) >= 1.0e6
        ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(1) + 'M'
        : formatNumberBro({ number: labelValue, mantissa })
  return numFormat === 'N/A' ? '' : numFormat
}

export const generatePasswordMatrix = (size = 5) => {
  const matrix = [...Array(size * 5).fill(Array(size * 5).fill(0))]

  return matrix.map((row) => row.map((col) => random()))
}

export const random = (to = 9) => {
  return Math.floor(Math.random() * (to + 1))
}

export const extract2D = function extract2d (array, x1, y1, x2, y2) {
  const result = []
  for (let y = y1; y <= y2; y++) {
    result.push(array[y].slice(x1, x2))
  }
  return result
}

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const convertBalanceToWei = (strValue, iDecimal = 18) => {
  try {
    const multiplyNum = new bigdecimal.BigDecimal(Math.pow(10, iDecimal))
    const convertValue = new bigdecimal.BigDecimal(String(strValue))
    return multiplyNum.multiply(convertValue).toString().split('.')[0]
  } catch (err) {
    return 0
  }
}

export const nFormatter = (num, mantissa = 1) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(mantissa).replace(/\.0$/, '') + 'B'
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(mantissa).replace(/\.0$/, '') + 'M'
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(mantissa).replace(/\.0$/, '') + 'K'
  }
  return formatNumberBro({ number: num, mantissa: 2 })
}

export const convertDateMomentHistory = (
  date = new Date(),
  type = 'HH:mm DD/MM/YYYY',
  isTimeStamp
) => {
  const dateFormat = isTimeStamp ? new Date(date * 1000) : new Date(date)
  const strTime = moment(dateFormat).format(type)
  return strTime
}

export const convertDateMoment = (date, type) => {
  if (!date) return 'N/A'
  const dateFormat = new Date(date)
  const strTime = moment(dateFormat).format(type)
  return strTime
}

export const relativeTime = (date) => {
  const dateFormat = new Date(date)
  const strTime = moment(dateFormat).fromNow()
  return strTime
}

export const convertTimestamp = (number, type = 'DD/MM/YYYY') => {
  let strTime

  if (number) strTime = moment.unix(number).format(type)
  else strTime = moment().format(type)
}

export const renderAmountSlippage = (amount, slippage) => {
  return parseFloat(roundingNumber((parseFloat(amount) * parseFloat(slippage) / 100), 6))
}

export const convertDecimalToHex = (number) => {
  return converter.decToHex(number.toString())
}

export const convertHexToDecimal = (hexNum) => {
  return converter.hexToDec(hexNum)
}

export const upperCaseFirstLetter = (lower) => {
  if (!lower) return lower
  const upper = lower.replace(/^\w/, (chr) => chr.toUpperCase())
  return upper
}

export const onViewOnScan = ({ address, typeView = 'tx', chain = chainType.binanceSmart }) => {
  const type = typeView

  const urlExplorer = SCAN_URL[chain]
  window.open(`${urlExplorer}/${type}/${address}`)
}

export const numberFormat = (value) => {
  const newValue = value.toString()
  const dotsCount = countDots(newValue, '\\.')
  const isDot = dotsCount === 1

  if (isDot) {
    const decimalCount = newValue.length - newValue.indexOf('.') - 1

    if (parseFloat(newValue) === Math.floor(newValue)) {
      return newValue
    }

    if (parseFloat(newValue) === 0) {
      return newValue
    }

    if (decimalCount === 0) {
      return Intl.NumberFormat('ja-JP').format(newValue) + '.'
    }
  }
  return value ? Intl.NumberFormat('ja-JP', { minimumFractionDigits: 0, maximumFractionDigits: 4 }).format(newValue) : newValue
}
