import { mapValues, pickBy, transform } from 'lodash'
import get from 'lodash/get'

export const IS_DEV = process.env.REACT_APP_IS_DEV === 'true'

export const isDapp = get(window, 'coin98.isMobile')

export const isWindow = typeof window !== 'undefined'

const C98_MINT = '0xaEC945e04baF28b135Fa7c640f624f8D90F1C3a6'
const BUSD_MINT = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
const CUSD_MINT = '0xFa4BA88Cf97e282c505BEa095297786c16070129'
const BNB_MINT = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'

export const DEFAULT_FAVORITE_TOKENS = [BNB_MINT, C98_MINT, BUSD_MINT, CUSD_MINT]

export const COIN_IMAGE = {
  ETH: 'https://coin98.s3.ap-southeast-1.amazonaws.com/Coin/ETH.png',
  BNB: 'https://coin98.s3.ap-southeast-1.amazonaws.com/Coin/BNBVer2.png',
  HT: 'https://coin98.s3.ap-southeast-1.amazonaws.com/Coin/heco.png',
  SOL: 'https://coin98.s3.ap-southeast-1.amazonaws.com/Coin/solana.jpg',
  TOMO: 'https://coin98.s3.ap-southeast-1.amazonaws.com/Chains/tomo.png',
  OLDTOMO: 'https://coin98.s3.amazonaws.com/VQ83gXzrhcL5wuIe',
  KUB: 'https://coin98.s3.ap-southeast-1.amazonaws.com/Chains/bitkub.png'
}

// add new chain --> add chain Data && isSupportedBaryon

export const CHAIN_DATA = {
  binanceSmart: {
    isSupportedBaryon: true,
    numChainId: 56,
    chainId: '0x38',
    numLoad: 1,
    isToken: true,
    isSupportedNFT: true,
    nftToken: 'BEP721',
    trcToken: 'BEP20',
    symbolSpecial: 'BSC',
    balances: '0xA6762c710852681c4593C10c4304C5211FB2122c',
    multisend: '0x2E1D30460265bFEBedacf5bb6f9A80F0E74B7498',

    subName: 'BSC',

    isWeb3: true,
    isFee: true,
    image: 'web_binance',

    id: 'binancecoin',
    name: 'Binance Smart Chain',
    shortName: 'BSC',
    imageLink: COIN_IMAGE.BNB,
    symbol: 'BNB',
    chain: 'binanceSmart',
    trcName: 'BNB BEP20',
    rpcURL: 'https://bsc-dataseed1.defibit.io/',
    scan: 'https://bscscan.com'
  },
  tomo: {
    isSupportedBaryon: true,
    numPath: 889,
    numChainId: 88,
    chainId: '0x58',
    numLoad: 2,
    isBridge: true,
    isToken: true,
    isSupportedNFT: true,
    trcToken: 'VRC25',
    nftToken: 'TRC721',
    isWeb3: true,
    isFee: true,
    image: 'web_vic',
    balances: '0xf7eEe3A8363731C611A24CdDfCBcaDE9C153Cfe8',
    nftMint: '0xAE12C5930881c53715B369ceC7606B70d8EB229f',

    id: 'tomochain',
    name: 'Viction',
    shortName: 'Viction',
    imageLink: COIN_IMAGE.TOMO,
    symbol: 'VIC',
    chain: 'tomo',
    trcName: 'VIC VRC25',
    rpcURL: 'https://rpc.tomochain.com',
    scan: 'https://www.vicscan.xyz'
  },
  solana: {
    isToken: true,
    trcToken: 'SPL',
    image: 'web_solana',
    isMnemonic: true,
    id: 'solana',
    name: 'Solana',
    shortName: 'Solana',
    symbol: 'SOL',
    chain: 'solana',
    trcName: 'SOL SPL'
  },
  heco: {
    numChainId: 128,
    // chainId: '0x80',
    numLoad: 1,
    isToken: true,
    isSupportedNFT: true,
    trcToken: 'HRC20',
    nftToken: 'HRC721',
    isWeb3: true,
    isFee: true,
    image: 'web_heco',
    balances: '0x963e1BcD1f82724bD8Fa16a3B6962D100fB287FC',
    multisend: '0x5C93F4B35d3dD97Ef481881aA33d00F76806FdAD',
    isSupportedEIP1559: true,

    id: 'huobi-token',
    name: 'HECO Chain',
    shortName: 'Huobi',
    imageLink: COIN_IMAGE.HT,
    symbol: 'HT',
    chain: 'heco',
    trcName: 'HT HRC20',
    rpcURL: 'https://http-mainnet.hecochain.com',
    scan: 'https://hecoinfo.com'
  },
  bitkub: {
    isSupportedBaryon: true,
    numChainId: 96,
    chainId: '0x60',
    isToken: true,
    isSupportedNFT: true,
    trcToken: 'KAP-20',
    nftToken: 'KAP721',
    isWeb3: true,
    image: 'web_bitkub',
    balances: '0x4d461b38d1753386D4d6797F79441Ed0adC2f6F8',

    id: 'bitkub-coin',
    name: 'Bitkub Chain',
    shortName: 'Bitkub',
    imageLink: COIN_IMAGE.KUB,
    symbol: 'KUB',
    chain: 'bitkub',
    rpcURL: 'https://rpc.bitkubchain.io',
    scan: 'https://www.bkcscan.com'
  },
  ancient8: {
    balances: '0x6509A9977569413CE8baCa7C50BeA0F2F983E53B',
    isSupportedBaryon: false,
    replacementSymbol: 'ANCIENT8',
    isSupportedNFT: true,
    isCrawlNFTServices: true,
    numChainId: 28122024,
    chainId: '0x1ad1ba8',
    tokenStandard: 'ERC20',
    nftStandard: 'ERC721',
    isWeb3: true,
    isFee: true,
    image: 'web_ancient8',
    environment: 'devnet',
    isL2: true,
    name: 'Ancient8 Testnet V2',
    shortName: 'ETH',
    logo: COIN_IMAGE.ETH,
    symbol: 'ETH',
    chain: 'ancient8',
    standard: 'ERC20 ERC721',
    rpcURL: 'https://rpcv2-testnet.ancient8.gg',
    scan: 'https://scanv2-testnet.ancient8.gg'
  },
  ancient8Mainnet: {
    balances: '0xf7eEe3A8363731C611A24CdDfCBcaDE9C153Cfe8',
    isSupportedBaryon: true,
    replacementSymbol: 'ANCIENT8',
    isSupportedNFT: true,
    isCrawlNFTServices: true,
    numChainId: 888888888,
    chainId: '0x34fb5e38',
    tokenStandard: 'ERC20',
    nftStandard: 'ERC721',
    isWeb3: true,
    isFee: true,
    image: 'web_ancient8',
    environment: 'devnet',
    isL2: true,
    name: 'Ancient8',
    shortName: 'ETH',
    logo: COIN_IMAGE.ETH,
    symbol: 'ETH',
    chain: 'ancient8Mainnet',
    standard: 'ERC20 ERC721',
    rpcURL: 'https://rpc.ancient8.gg',
    scan: 'https://scan.ancient8.gg'
  }

}

export const chainType = pickBy(mapValues(CHAIN_DATA, 'chain'))
export const chainSupportBaryon = Object.values(CHAIN_DATA).filter(item => item.isSupportedBaryon)

export const CHAIN_TO_CHAIN_ID = pickBy(mapValues(CHAIN_DATA, (value, key) => {
  if (value.isSupportedBaryon) {
    return value.chainId
  }
}))

export const CHAIN_ID_TO_CHAIN = transform(
  CHAIN_TO_CHAIN_ID,
  (result, value, key) => {
    result[value] = key
  },
  {}
)

export const CHAIN_TO_NUM_CHAIN_ID = pickBy(mapValues(CHAIN_DATA, (value, key) => {
  if (value.isSupportedBaryon) {
    return value.numChainId
  }
}))

export const NUM_CHAIN_ID_TO_CHAIN = transform(
  CHAIN_TO_NUM_CHAIN_ID,
  (result, value, key) => {
    result[value] = key
  },
  {}
)

export const LIST_CHAIN_SUPPORT = Object.keys(CHAIN_TO_CHAIN_ID)

export const SCAN_URL = mapValues(CHAIN_DATA, 'scan')

export const SCAN_TX = mapValues(CHAIN_DATA, (value, key) => {
  return value.scan + '/tx/'
})

// add new chain --> ADD TYPE_FAVORITE

export const TYPE_FAVORITE_POOL = {
  [chainType.binanceSmart]: 'baryonPool',
  [chainType.tomo]: 'baryonPoolTomo',
  [chainType.bitkub]: 'baryonPoolBitkub',
  [chainType.ancient8]: 'baryonPoolAncient8',
  [chainType.ancient8Mainnet]: 'baryonPoolAncient8Mainnet'

}

export const TYPE_FAVORITE_TOKEN = {
  [chainType.binanceSmart]: 'baryonToken',
  [chainType.tomo]: 'baryonTokenTomo',
  [chainType.bitkub]: 'baryonTokenBitkub',
  [chainType.ancient8]: 'baryonTokenAncient8',
  [chainType.ancient8Mainnet]: 'baryonTokenAncient8Mainnet'
}

//

export const CONTRACT_MASTER_CHEF = {
  [chainType.heco]: '0x3446e3F204AF61478B2fA46Af0d0507c718bb9a1',
  [chainType.binanceSmart]: '0xabd8DDB9C60F9ad25387c0F70Fff1Ed63B381899'
}

export const MAIN_API = {
  BASE_API: IS_DEV ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PRO,
  BASE_ADAPTER_API: IS_DEV ? process.env.REACT_APP_ADAPTER_API_DEV : process.env.REACT_APP_ADAPTER_API_PRO,
  SUPPORT_API: IS_DEV ? process.env.REACT_APP_SUPPORT_API_DEV : process.env.REACT_APP_SUPPORT_API_PRO
}

export const REQUEST_TYPE = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch'
}

export const CURRENCY_DATA = [
  'USD',
  'VND',
  'CNY',
  'JPY',
  'THB',
  'AUD',
  'BRL',
  'CAD',
  'CHF',
  'CLP',
  'CZK',
  'DKK',
  'EUR',
  'GBP',
  'HKD',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'ISK',
  'KRW',
  'MXN',
  'MYR',
  'NOK',
  'NZD',
  'PHP',
  'PKR',
  'PLN',
  'RUB',
  'SEK',
  'SGD',
  'TRY',
  'TWD',
  'ZAR'
]

export const CURRENCY_SYMBOL = {
  USD: '$',
  JPY: '¥',
  AUD: '$',
  BRL: 'R$',
  CAD: '$',
  CHF: 'FS',
  CLP: '$',
  CNY: '¥',
  CZK: 'Kč',
  DKK: 'kr',
  EUR: '€',
  GBP: '£',
  HKD: '$',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  INR: 'Rs.',
  ISK: 'kr',
  KRW: '₩',
  MXN: '$',
  MYR: 'RM',
  NOK: 'kr',
  NZD: '$',
  PHP: '₱',
  PKR: 'Rs.',
  PLN: 'zł',
  RUB: '₽',
  SEK: 'kr',
  SGD: '$',
  THB: '฿',
  TRY: '₺',
  TWD: 'NT$',
  ZAR: 'R',
  VND: 'đ'
}
