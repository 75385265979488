import React, { useContext, useMemo, useState } from 'react'
import cn from 'classnames'
import style from './style.module.scss'
import { useTranslation } from 'react-i18next'
import SwitchButton from 'components/SwitchButton/SwitchButton'
import EmptyData from 'components/common/EmptyData'
import AboutToken from 'components/common/AboutToken'
import { formatPrice, getLength, lowerCase } from 'common/functions'
import PuffLoading from 'components/common/PuffLoading'
import StatusItem from 'components/common/StatusItem'
import { get } from 'lodash'
import { LiquidityContext } from 'context/liquidity/liquidityContext'
import { useWallet } from '@coin98-com/wallet-adapter-react'
import { useSelector } from 'react-redux'
import { getTokeInfoPool } from 'common/poolEvm/function'

const YourLiquidity = ({ isLoading, className, status }) => {
  const { t } = useTranslation()
  const { connected } = useWallet()
  const chainActive = useSelector((state) => state.chainActive)

  const liquidityContext = useContext(LiquidityContext)
  const {
    handlePushStateUrl, listLiquidityPool, isLoadingListPool, listPoolFarm, onSelectFromToCoin
  } = liquidityContext

  const [isShowFarming, setIsShowFarming] = useState(false)

  const onSelectPoolItem = (item) => () => {
    const token0Info = get(item, 'token0.tokenInfo', {})
    const token1Info = get(item, 'token1.tokenInfo', {})
    handlePushStateUrl({
      token0Mint: token0Info.address,
      token1Mint: token1Info.address,
      typeLiquidity: status
    })
    onSelectFromToCoin(token0Info, token1Info)
  }

  const onToggleIsFarming = () => {
    setIsShowFarming(!isShowFarming)
  }

  const listPoolRender = useMemo(() => {
    if (isShowFarming) {
      return listLiquidityPool.filter(item => listPoolFarm.findIndex(it => lowerCase(it) === lowerCase(item.poolAddress)) >= 0)
    }
    return listLiquidityPool
  }, [isShowFarming, listLiquidityPool, listPoolFarm])

  const renderListLiquidity = () => {
    if (isLoadingListPool) {
      return <PuffLoading isCenter className={'pull-loading'}/>
    }
    if (getLength(listPoolRender) === 0 && !isLoading) {
      return <EmptyData />
    }
    if (!connected) {
      return <EmptyData />
    }
    return listPoolRender.map((item, index) => {
      const token0Info = getTokeInfoPool(get(item, 'token0.tokenInfo', {}), chainActive)
      const token1Info = getTokeInfoPool(get(item, 'token1.tokenInfo', {}), chainActive)

      const balanceLp = formatPrice(item.balanceOfLp, false, true)
      return (
        <li key={index} onClick={onSelectPoolItem(item)}>
          <div className="liquidity-content__item">
            <div className="liquidity-content__left">
              <div>
                <AboutToken imgToken={token0Info.image} type={'default'}/>
              </div>
              <div>
                <AboutToken imgToken={token1Info.image} type={'default'}/>
              </div>
              <span className='text-uppercase text-nowrap'>
                {token0Info.symbol} - {token1Info.symbol}
              </span>
            </div>
            <div className="liquidity-content__right">
              {balanceLp === '0' ? '~ 0' : balanceLp}
            </div>
            {
              listPoolFarm.findIndex(it => lowerCase(it) === lowerCase(item.poolAddress)) >= 0 ? <StatusItem status={'farming'}/> : ''
            }
          </div>
        </li>
      )
    }
    )
  }
  return (
    <div className={cn(style['your-liquidity'], className)}>
      <div className="liquidity-header">
        <div>
          <h4 className="heading-4">{t('yourLiquidity')}</h4>
          <span className='text-second'>
            {!connected ? '0' : getLength(listPoolRender)}
          </span>
        </div>
        <div className="switch-btn">
          <SwitchButton
            isToggle={isShowFarming}
            onToggle={onToggleIsFarming}
          />
          <span>{t('farming')}</span>
        </div>
      </div>
      <div className="liquidity-content">
        <ul style={{ height: '356px' }}>
          {renderListLiquidity()}
        </ul>
      </div>
    </div>
  )
}

export default YourLiquidity
