import React from 'react'
import ProfileHead from './ProfileHead'
import ProfileItems from './ProfileItems'

import './style.scss'

function ProfileScreen () {
  return (
    <div className='profile-screen'>
      <div className="profile-screen__head">
        <ProfileHead/>
      </div>

      <div className="profile-screen__list_items">
        <ProfileItems/>
      </div>
    </div>
  )
}

export default ProfileScreen
