import Button from 'components/common/Button'
import React from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import AboutToken from 'components/common/AboutToken'
import StatusItem from 'components/common/StatusItem'
import { get } from 'lodash'
import SnapSocial from '../SnapSocial'

import style from './style.module.scss'
import {
  convertDateMoment,
  formatBilion,
  formatPrice,
  getLength,
  showNotificationToast
} from 'common/functions'
import ModalSnapJoin from '../ModalSnapJoin'
import BaseAPI from 'controller/api/BaseAPI'
import { useWallet, WALLETS_NAME } from '@coin98-com/wallet-adapter-react'
import IconCustom from 'components/common/IconCustom'
import { CHAIN_DATA, isDapp } from 'common/constants'
import { useSelector } from 'react-redux'
import { useWalletModal } from '@coin98-com/wallet-adapter-react-ui'

const SnapCard = ({ item }) => {
  const { t } = useTranslation()
  const { address, connected, selectWallet } = useWallet()
  const { openWalletModal } = useWalletModal()

  const chainActive = useSelector((state) => state.chainActive)

  const history = useHistory()
  // day
  const startDay = convertDateMoment(item.start, 'DD')
  const startMonth = convertDateMoment(item.start, 'MMM')
  const endDay = convertDateMoment(item.end, 'DD')
  const endMonth = convertDateMoment(item.end, 'MMM')

  // status
  const toDay = new Date().getTime()
  const isGoing = item.start < toDay && item.end > toDay
  const isEnd = item.end <= toDay

  const status = isGoing ? 'onGoing' : isEnd ? 'completed' : 'upComing'

  const reww = get(item, 'reward', '').toString()
  const chain = get(item, 'chain')

  const textBtnActive = status === 'completed'
    ? 'campEnd'
    : item?.isJoined === true
      ? 'joined'
      : 'joinNow'

  const textBtn = (connected || isEnd) ? textBtnActive : 'connectWallet'

  // reward
  const reward =
    getLength(reww) > 6
      ? formatBilion(item?.reward)
      : formatPrice(item?.reward, false, true)

  const onCloseModal = () => {
    window.closeModal()
  }

  const onJoin = async () => {
    try {
      const res = await BaseAPI.postData('snapshot/participant', {
        id: item.id,
        address: address
      })

      if (res?.errMess) {
        showNotificationToast(t(res?.errMess), 'error', 2000)
        window.closeModal()
      } else {
        showNotificationToast(t('joinSuccess'), 'success', 2000)
        setTimeout(() => {
          history.push(`snapshot/${item?.id}`)
        }, 100)
      }
      window.closeModal()
    } catch (error) {
      showNotificationToast(t(error), 'error', 2000)
      window.closeModal()
    }
  }

  const onConnect = async () => {
    if (!isDapp) return openWalletModal()
    try {
      const chain = CHAIN_DATA[chainActive]?.chainId
      selectWallet(WALLETS_NAME.coin98Ether, chain)
    } catch (error) {
      console.log('🚀 ~ file: index.js:93 ~ onConnect ~ error:', error)
    }
  }

  const handleConfirm = async (e) => {
    e.preventDefault()

    if (!connected) return onConnect()

    window.openModal({
      isCustomModal: true,
      content: (
        <ModalSnapJoin
          onCancle={onCloseModal}
          onConfirm={onJoin}
          title={item?.name}
        />
      )
    })
  }
  // button

  // social
  const social = get(item, 'social', [])
  const telegram = social.find((item) => item.title === 'telegram')
  const twitter = social.find((item) => item.title === 'twitter')
  const link = social.find((item) => item.title === 'link')

  const rewardToken = get(item, 'tokenInfo', {})

  const arrSocial = [
    {
      icon: 'web_telegram-fill',
      tooltip: 'telegram',
      link: get(telegram, 'value', '#')
    },
    {
      icon: 'web_twitter-fill',
      tooltip: 'twitter',
      link: get(twitter, 'value', '#')
    },
    {
      icon: 'web_global',
      tooltip: 'link',
      link: get(link, 'value', '#')
    }
  ]

  return (
    <div className={cn(style['snap-card'])}>
      <Link to={`/snapshot/${item?.id}`} className="card-thumnail">
        <figure>
          <img src={item?.image} className="img-fluid" alt="" />
          <div className="card-thumnail--content">
            <SnapSocial dataSocial={arrSocial} className="content-social" />
            <p
              dangerouslySetInnerHTML={{
                __html: item?.description?.replace('\\n' || '\n', '<br/>')
              }}
            />
            <Button
              isDisable={item?.isJoined || status === 'completed'}
              onClick={handleConfirm}
            >
              {t(textBtn)}
            </Button>
          </div>
          <div className="card-thumnail--status">
            <StatusItem status={status} />
          </div>
        </figure>
      </Link>

      <div className="card-content">
        <div className='card-content--top'>
          <Link to={`/snapshot/${item?.id}`}>
            <div className="card-content-top-item">
              <div>
                <AboutToken className={'card-logo'} imgToken={item?.logo} />
              </div>
              <div className="top-heading">
                <h3 className="lx-regular">{item?.name}</h3>
                <span>{item?.owner}</span>
              </div>
            </div>
          </Link>
          <IconCustom type={'default'} className="icon-chain color-theme" icon={`${CHAIN_DATA[chain]?.image}`}/>

        </div>

        <div className="card-content--bottom lx-regular">
          <div className="total-reward">
            <span className="heading-sub">{t('totalReward')}</span>
            <div className="reward-main">
              <span> {t('upTo')}</span>
              <span>{reward}</span>
              {rewardToken?.symbol && <span className='reward-main--uppercase'>{rewardToken?.symbol}</span>}
            </div>
          </div>
          <div className="bottom-duration">
            <span className="heading-sub">{t('duration')}</span>
            <div className="bottom-duration--time">
              <div className="time-group">
                <span className="time-date">{startDay}</span>
                <span className="time-month">{startMonth}</span>
              </div>
              <span>-</span>
              <div className="time-group">
                <span className="time-date">{endDay}</span>
                <span className="time-month">{endMonth}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SnapCard
