import React from 'react'
import './style.scss'

const ButtonPool = ({ children, type = 'default' || 'purple', ...props }) => {
  return (
    <button className={`button-pool button-pool--${type}`} type="default" {...props}>
      {children}
    </button>
  )
}

export default ButtonPool
