import { chainType, CHAIN_DATA } from 'common/constants'
import ReduxService from 'common/redux'
import BaseAPI from 'controller/api/BaseAPI'
import { getGasPrice } from 'controller/Library/evm'
import get from 'lodash/get'
import { KEY_STORE } from './constants/keystore'
import { convertBalanceToWei, convertWeiToBalance, getItemStorage, getLength, setItemStorage } from './functions'

export default class GasServices {
  // Get and cached new ether gas
  static async getGasEther (isGetNew) {
    const getCache = isGetNew ? false : await getItemStorage(KEY_STORE.FEE_TXS)

    if (getCache) {
      const gasFull = await BaseAPI.getData('cryptoData/ethGas', { chain: chainType.ether })
      if (gasFull) {
        setItemStorage(gasFull, KEY_STORE.FEE_TXS)
      }
      return gasFull
    } else {
      const gasFull = await BaseAPI.getData('cryptoData/ethGas', { chain: chainType.ether })
      if (gasFull) {
        setItemStorage(gasFull, KEY_STORE.FEE_TXS)
      }
      return gasFull
    }
  }

  static async get (chain, isGetNew, isGetWithText) {
    // Ether will get from etherscan or ethgas station
    // Bitcoin will get from library
    // Other chain get from setting database

    const isEtherChain = chain === chainType.ether
    const isKlaytn = chain === chainType.klaytn

    let gasSelect
    const chainSelected = CHAIN_DATA[chain]

    if (chain === chainType.avax || !chainSelected || get(chainSelected, 'isL2')) {
      gasSelect = convertWeiToBalance(await getGasPrice(undefined, chain), 9)
    } else {
      const standardGas =
        isEtherChain
          ? { ether: await GasServices.getGasEther(isGetNew) }
          : (isGetNew ? await BaseAPI.getConfigByType('standardGwei') : ReduxService.genGasStandard())

      gasSelect = (standardGas && standardGas[chain]) ? standardGas[chain] : parseFloat(convertWeiToBalance(await getGasPrice(undefined, chain), 9))
    }

    if (isKlaytn) {
      return {
        standard: gasSelect,
        fast: gasSelect,
        fastest: gasSelect,
        gaswar: gasSelect,
        starwar: gasSelect
      }
    }

    const isLowerDecimal = gasSelect < 1
    const numFixed = isLowerDecimal ? 4 : 2

    return isEtherChain
      ? gasSelect
      : {
        standard: parseFloat((gasSelect * 1).toFixed(numFixed)),
        fast: parseFloat((gasSelect * 1.1).toFixed(numFixed)),
        fastest: parseFloat((gasSelect * 1.3).toFixed(numFixed)),
        gaswar: parseFloat((gasSelect * 1.7).toFixed(numFixed)),
        starwar: parseFloat((gasSelect * 3).toFixed(numFixed))
      }
  }

  static async getFee (selectedToken, gasLimit, gasPrice) {
    const chain = selectedToken.chain
    const chainSelected = CHAIN_DATA[selectedToken.chainCustom ? chainType.custom : selectedToken.chain]

    const isSendToken = getLength(selectedToken.address) > 0

    if (chainSelected.chain === chainType.custom || chainSelected.isWeb3) {
      return convertWeiToBalance(gasLimit * gasPrice, 9)
    }

    if (chain === chainType.bitcoin) {
      return gasPrice
    }

    if (chain === chainType.tron) {
      // Estimate enegry for sending tron data
      // Send TRX fixed at 0.1
      if (isSendToken) {
        // Is TRC10
        const isTRC10 = !isNaN(selectedToken.address)

        if (isTRC10) {
          return 0
        } else {
          const fee = await window.wallet.tronClient.trx.getChainParameters()
          const estFee = convertBalanceToWei(1 / fee.find(item => item.key === 'getEnergyFee').value, 6)
          return 29631 / estFee
        }
      }
      return 0
    }

    if (chainSelected.feeDefault) {
      return chainSelected.feeDefault
    }

    if (chain === chainType.avaxX) {
      // Avax-X fixed at 0.001 on every transaction
      return 0.001
    }

    if (chain === chainType.secretNetwork) {
      // Need estimated fee in here
      return 0.02
    }

    if (chain === chainType.solana) {
      // Solana fixed at 0.000005 on every transaction
      return 0.000005
    }

    if (chain === chainType.kusama) {
      // Kusama fixed at 0.0026 on every transaction
      return 0.0026
    }
    if (chain === chainType.polkadot) {
      // Polkadot fixed at 0.016 on every transaction
      return 0.016
    }

    if (chain === chainType.near) {
      return 0.0001
    }

    if (chainSelected.isCosmos) {
      // Need estimated fee in here
      return 0.0025
    }

    return 0
  }

  // App only using for get language for gas
  static async getGasWithText (chain, isGetNew) {
    const fullGas = await GasServices.get(chain, isGetNew, true)

    let subName = ' Gwei '
    switch (chain) {
    case chainType.bitcoin:
      subName = ' '
      break
    }

    const gasFullData = [
      { key: 'lowest', value: fullGas.lowest, label: 'slowest', subLabel: subName + fullGas.lowest },
      { key: 'low', value: fullGas.low, label: 'slow', subLabel: subName + fullGas.low },
      { key: 'standard', value: fullGas.standard, label: 'standard', subLabel: subName + fullGas.standard },
      { key: 'fast', value: fullGas.fast, label: 'fast', subLabel: subName + fullGas.fast },
      { key: 'fastest', value: fullGas.fastest, label: 'fastest', subLabel: subName + fullGas.fastest },
      { key: 'gaswar', value: fullGas.gaswar, label: 'gasWar', subLabel: subName + fullGas.gaswar },
      { key: 'starwar', value: fullGas.starwar, label: 'starWar', subLabel: subName + fullGas.starwar }
    ]

    const data = gasFullData.filter(item => fullGas[item.key])
    return { data, index: data.findIndex(itm => itm.key === 'standard') }
  }

  static async getGasByType (chain, isGetNew, type = 'standard') {
    const fullGas = await GasServices.get(chain, isGetNew)
    return fullGas[type] || 25
  }
}
