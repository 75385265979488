import FarmScreenMulti from 'pages/FarmScreenMulti'
import React from 'react'

const StakeV2 = () => {
  return (
    <FarmScreenMulti isStake/>
  )
}

export default StakeV2
