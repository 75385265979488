import { formatPrice, formatBilion } from 'common/functions'
import CountUpComponent from 'components/CountUpComponent'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import images from 'assets/images'

import './style.scss'
import ReduxServices from 'common/redux'
import CountComponent from 'components/CountComponent'

function MyReward ({ totalMyRewards }) {
  const { t } = useTranslation()
  const isDarkMode = useSelector(state => state.themeRedux) === 'dark'

  return (
    <div className='my-reward-farm-v2'>
      <div className="my-reward-farm-v2__title">
        {t('myReward')}
      </div>

      <div className="my-reward-farm-v2__pulse" />
      <img src={images.baryonRight} alt="" />
      <div className="my-reward-farm-v2__value">
        {/* <CountUpComponent
          endNum={totalMyRewards}
          prefixValue='$'
          decimals={2}
        /> */}
        <CountComponent number={ReduxServices.formatPrice(totalMyRewards)}/>
      </div>
    </div>
  )
}

export default MyReward
