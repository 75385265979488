import React, { useMemo } from 'react'
import style from './style.module.scss'
import { Col, Row, Select } from 'antd'
import Pagination from 'pages/PoolScreen/components/Pagination'
import { useTranslation } from 'react-i18next'
import InputSearch from 'components/InputSearch'
import { Option } from 'antd/lib/mentions'
import { upperCaseFirstLetter } from 'common/functions/utils'
import {
  convertDateMoment,
  formatBilion,
  formatPrice,
  getLength,
  nFormatter
} from 'common/functions'
import PuffLoading from 'components/common/PuffLoading'
import EmptyData from 'components/common/EmptyData'
import { get } from 'lodash'
import ReduxServices from 'common/redux'

const TableSnap = ({
  tableData,
  tableRender,
  total,
  handleSelectedPage,
  isShowPagination,
  onChangeSearch,
  handleChangeSelect,
  arrSort,
  isLoadingList,
  rewardToken,
  txtSearch,
  handleClear
}) => {
  const { t } = useTranslation()
  const onFixAntd = (target) => target.parentNode

  // data

  // const arrSort = [
  //   {
  //     name: 'earliest',
  //     value: 'desc'
  //   },
  //   {
  //     name: 'latest',
  //     value: 'asc'
  //   }
  // ]

  // const tableData = [...Array(20)].fill(1).map((item, index) => {
  //   return {
  //     address: '0xb253d0c3 ... a49839b640',
  //     joinDate: '2022-06-21T04:32:33.947Z',
  //     noTitle: 1,
  //     reward: 639197372,
  //     volume: 639197372
  //   }
  // })

  // const tableRender = [
  //   { filed: 'noTitle', type: 'number' },
  //   { filed: 'address', type: 'address' },
  //   { filed: 'volume', type: 'number' },
  //   { filed: 'joinDate', type: 'date' },
  //   { filed: 'reward', type: 'number' }
  // ]

  const gridTemplateColumns = useMemo(() => {
    let template = ''
    tableRender.forEach((item) => {
      switch (item.filed) {
      case 'address':
        template = template + ' ' + '1fr'
        break
      case 'reward':
        template = template + ' ' + '0.75fr'
        break
      case 'rank':
        template = template + ' ' + '0.25fr'
        break
      default:
        template = template + ' ' + '1fr'
        break
      }
    })
    return template
  })

  const renderTitleTable = () => {
    return (
      <>
        {tableRender.map((item, index) => {
          return (
            <div
              key={index}
              className={`head-title ${
                index === 0 || item?.type === 'boolean'
                  ? 'head-title--center'
                  : ''
              } ${item?.filed === 'address' ? 'head-title--left' : ''}`}
            >
              <span>{upperCaseFirstLetter(t(item?.filed))}</span>
            </div>
          )
        })}
      </>
    )
  }

  const renderContentItem = (dataContent) => {
    return (
      <>
        {tableRender.map((item, index) => {
          // txtContent
          let txtContent = dataContent[item?.filed]
          if (
            item?.type === 'number' &&
            !['noTitle', 'rank', 'reward'].includes(item?.filed)
          ) {
            txtContent = ReduxServices.formatPrice(txtContent)
          }
          if (item?.type === 'date') {
            txtContent = convertDateMoment(txtContent, 'DD/MM HH:mm')
          }
          if (item?.filed === 'reward') {
            txtContent = getLength(txtContent.toString()) > 6
              ? formatBilion(txtContent)
              : formatPrice(txtContent, false, true)
          }
          // div Content
          let divContent = (
            <span className={item?.filed === 'address' && 'address-text'}>
              {txtContent}
            </span>
          )
          if (item?.type === 'image') {
            divContent = (
              <figure>
                <img src={txtContent} className="img-fluid" alt="logo token" />
              </figure>
            )
          }
          if (item?.filed === 'reward') {
            divContent = (
              <span className='reward-join'>
                {txtContent} {rewardToken?.symbol}
              </span>
            )
          }
          if (item?.filed === 'rank') {
            divContent = (
              <span>
                {txtContent === 0 ? '-' : txtContent}
              </span>
            )
          }
          if (item.type === 'boolean') {
            divContent = (
              <div className="status-join">
                <div className={txtContent ? 'joined' : 'disable'}>
                  <span className="icon-web_ic-verify-un"></span>
                </div>
              </div>
            )
          }

          // return
          return (
            <div
              key={index}
              className={`content-column ${
                index === 0 || item?.type === 'boolean'
                  ? 'content-column--center'
                  : ''
              } ${item.filed === 'address' ? 'content-column--left' : ''}`}
            >
              {divContent}
            </div>
          )
        })}

      </>
    )
  }

  return (
    <div className={style['tableSnap-container']}>
      <div className="tablesnap-feature">
        <div className="feature-left">
          <InputSearch
            placeHolder={t('searchByAddress')}
            handleChange={onChangeSearch}
            initValue={txtSearch}
            handleClear={handleClear}
          />
        </div>
        <div className="feature-right">
          <Select
            suffixIcon={<span className="icon-web_arrow_down"></span>}
            getPopupContainer={onFixAntd}
            className="drop-custom"
            defaultValue={t('desc')}
            onChange={handleChangeSelect}
          >
            {arrSort.map((item, index) => {
              return (
                <Option key={index} value={item?.value}>
                  {t(item?.name)}
                </Option>
              )
            })}
          </Select>
        </div>
      </div>
      <div className="tablesnap-content">
        {isLoadingList
          ? (
            <PuffLoading isCenter className="puff-loading" />
          )
          : getLength(tableData) === 0
            ? (
              <EmptyData classNames={'custom-empty'} />
            )
            : (
              <>
                <div className="tablesnap-header" style={{ gridTemplateColumns }}>
                  {renderTitleTable()}
                </div>
                <div className="tablesnap-main">
                  {tableData.map((it, index) => {
                    return (
                      <div
                        key={it.id}
                        className="tablesnap-main__item"
                        style={{ gridTemplateColumns }}
                      >
                        {renderContentItem(it)}
                      </div>
                    )
                  })}
                </div>
              </>
            )}
      </div>
      {isShowPagination && (
        <div className="tabsnap-pagination">
          <Pagination
            totalPage={total}
            handleSelectedPage={handleSelectedPage}
          />
        </div>
      )}
    </div>
  )
}

export default TableSnap
