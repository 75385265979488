import images from 'assets/images'
import { nFormatter } from 'common/functions'
import ReduxServices from 'common/redux'
import PuffLoading from 'components/common/PuffLoading'
import moment from 'moment'
import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import './style.scss'

const RechartBar = ({ dataChart, isHideTitle, isLoading }) => {
  const { t } = useTranslation()
  const isDarkMode = useSelector(state => state.themeRedux) === 'dark'

  const currencyRedux = useSelector(state => state.currencyRedux)
  const [dataHead, setDataHead] = useState({
    date: new Date(),
    number: 0
  })

  const tickFormatter = (data) => {
    return new Date(data).getDate()
  }
  const tickFormatterYAxis = (data) => {
    return ReduxServices.formatPrice(data, true)
  }

  useEffect(() => {
    if (!dataChart.length) return
    setDataHead({
      ...dataChart[dataChart.length - 1]
    })
  }, [dataChart, currencyRedux])
  const onMouseOver = (data) => {
    const { payload } = data
    setDataHead({
      ...payload
    })
  }

  const onMouseOut = (data) => {}

  const formatDate = (date) => {
    return moment(date).format('LL')
  }
  const renderChart = useMemo(() => {
    if (isLoading) {
      return (
        <div className="loading-chart">
          <PuffLoading/>
        </div>
      )
    } else {
      return (dataChart.length && !isLoading)
        ? (
          <ResponsiveContainer width="99%" height='100%'>
            <BarChart
              width={500}
              height={300}
              data={dataChart}
              margin={{
                top: 20,
                right: -45,
                left: -15,
                bottom: 55
              }}
            >
              {/* <defs>
                <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={'#8ef8c6'} stopOpacity={0.5} />
                  <stop offset="129%" stopColor={'#8064ea'} stopOpacity={0.5} />
                </linearGradient>
              </defs> */}
              <XAxis tickFormatter={tickFormatter} dataKey="date" axisLine={false} tickLine={false} />
              <YAxis tickFormatter={tickFormatterYAxis} orientation="right" axisLine={false} tickLine={false} scale="linear"/>
              <Tooltip wrapperStyle={{ display: 'none' }} cursor={{ fill: 'transparent' }} />
              <Bar
                onMouseLeave={onMouseOut}
                onMouseMove={onMouseOver}
                dataKey="number"
                fill="#fdbe1d"
                // fill="url(#gradient)"
              />
            </BarChart>
          </ResponsiveContainer>
        )
        : (
          <div className="empty-chart">
            <img src={images.iconEmptyTable} alt="" />
            {t('emptyData')}
          </div>
        )
    }
  }, [isLoading, dataChart, currencyRedux])

  return (
    <React.Fragment>
      <div className="info-value">
        {!isHideTitle && <div className="info-value__title">{t('volume24h')}</div> }
        <div className="info-value__usd">{ReduxServices.formatPrice(dataHead.number)}</div>
        <div className="info-value__date">{formatDate(dataHead.date)}</div>
      </div>
      {/* {!isLoading
        ? dataChart.length
          ? (
            <ResponsiveContainer width="99%" height='100%'>
              <BarChart
                width={500}
                height={300}
                data={dataChart}
                margin={{
                  top: 20,
                  right: -45,
                  left: -15,
                  bottom: 55
                }}
              >
                <defs>
                  <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor={'#8ef8c6'} stopOpacity={0.5} />
                    <stop offset="129%" stopColor={'#8064ea'} stopOpacity={0.5} />
                  </linearGradient>
                </defs>
                <XAxis tickFormatter={tickFormatter} dataKey="date" axisLine={false} tickLine={false} />
                <YAxis tickFormatter={tickFormatterYAxis} orientation="right" axisLine={false} tickLine={false} scale="linear"/>
                <Tooltip wrapperStyle={{ display: 'none' }} />
                <Bar
                  onMouseLeave={onMouseOut}
                  onMouseMove={onMouseOver}
                  dataKey="number"
                  // fill="#8871e1"
                  fill="url(#gradient)"
                />
              </BarChart>
            </ResponsiveContainer>
          )
          : (
            <div className="empty-chart">{t('emptyData')}</div>
          )
        : <div className='loading-container'>
          <PuffLoading/>
        </div>
      } */}
      {renderChart}
    </React.Fragment>
  )
}

export default RechartBar
