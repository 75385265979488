import { Col, Row } from 'antd'
import { formatBilion, lowerCase } from 'common/functions'
import MarketScan from 'common/MarketScan'
import { STABLE_COIN } from 'common/poolEvm/constants'
import RechartLine from 'components/RechartLine'
import BaseAPI from 'controller/api/BaseAPI'
import { uniqBy, get } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReduxServices from 'common/redux'

import './style.scss'
import { useSelector } from 'react-redux'

const ChartSwap = ({ isExpandChart, handleExpand, toCoin, fromCoin }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const [dataChart, setDataChart] = useState([])
  const [detailCgk, setDetailCgk] = useState({})
  const [timeChoose, setTimeChoose] = useState('1D')

  const currencyRedux = useSelector(state => state.currencyRedux)

  const newPairCoin = useMemo(() => {
    if (STABLE_COIN.includes(lowerCase(get(toCoin, 'symbol')))) {
      return fromCoin
    }

    return toCoin
  }, [toCoin, fromCoin])

  const timeFrom = useMemo(() => {
    let from
    switch (timeChoose) {
    case '1D':
      // from = moment().subtract(1, 'years').unix()
      from = 1
      break
    case '1W':
      from = 7
      break
    case '1M':
      from = 30
      break
    default:
      // from = moment().subtract(4, 'years').unix()
      from = 365
      break
    }
    return from
  }, [timeChoose])

  useEffect(() => {
    loadData({
      address: get(newPairCoin, 'address'),
      type: timeChoose,
      timeFrom: timeFrom,
      timeTo: moment().unix()
    })

    getDetailCgk(get(newPairCoin, 'cgkId') || get(newPairCoin, 'id', ''))
  }, [newPairCoin, timeFrom, currencyRedux])

  const loadData = async ({ address, type, timeFrom, timeTo }) => {
    // const res = await axios.get(`https://information.coin98.services/api/sarosChart?address=${address}&type=${type}&time_from=${timeFrom}&time_to=${timeTo}`)
    // const res = await SupportAPI.getData('sarosChart', { address, type, timeFrom, timeTo })

    const id = get(newPairCoin, 'cgkId', '') || get(newPairCoin, 'id', '')

    const resCgk = await MarketScan.getChartCoin(id, timeFrom)

    let newData = get(resCgk, 'prices', [])
      .map((it) => {
        return {
          number: it[1],
          date: Number(it[2]),
          parseDate: it[0]
        }
      })
      .sort((a, b) => a.time - b.time)

    if (timeFrom === 1) {
      newData = uniqBy(newData, (it) => it.parseDate)
    }

    setDataChart(newData)

    // const data = get(res, 'data.data.items')
    // if (data) {
    //   const newData = data.map(item => {
    //     return { number: get(item, 'o', 0), date: get(item, 'humanTime') }
    //   })
    //   setDataChart(newData)
    // }
  }

  const getDetailCgk = async (id) => {
    const response = await BaseAPI.getData('coin/cgk/detail', { id })

    setDetailCgk(response)
  }
  const dateFake = [{
    title: 'marketCap',
    value: ReduxServices.formatPrice(get(detailCgk, 'market_data.market_cap.usd'), true)
  },
  {
    title: 'totalSupply',
    value: ReduxServices.formatPrice(get(detailCgk, 'market_data.total_supply'), true)
  },
  {
    title: 'allTimeLow',
    value: ReduxServices.formatPrice(get(detailCgk, 'market_data.atl.usd'))
  },
  {
    title: 'allTimeHigh',
    value: ReduxServices.formatPrice(get(detailCgk, 'market_data.ath.usd'))
  }
  ]

  const handleChooseTime = (time) => () => {
    setTimeChoose(time)
  }

  return (
    <div
      className={`swap-chart ${isExpandChart && 'swap-chart--expand-chart'}`}
    >
      <div className='swap-chart__main'>
        <RechartLine
          dataChart={dataChart}
          isLoading={isLoading}
          isHideTitle
          isSwapChart
          handleExpand={handleExpand}
          isExpandChart={isExpandChart}
          toCoin={newPairCoin}
          fromCoin={fromCoin}
          detailCgk={detailCgk}
          handleChooseTime={handleChooseTime}
          timeChoose={timeChoose}
        />
      </div>
      <div className="swap-chart__total">
        <Row gutter={[{ xs: 16, sm: 16, md: 16, lg: 24 }, { xs: 16, sm: 16, md: 16, lg: 24 }]}>
          {dateFake.map((item, index) => {
            return <Col key={index} xs={12} sm={12} md={12} lg={6} >
              <div className="total-inner">
                <span>
                  {item.value}
                </span>
                <span className='text-content'>
                  {t(item.title)}
                </span>
              </div>
            </Col>
          })}
        </Row>
      </div>
    </div>
  )
}

export default ChartSwap
