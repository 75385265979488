import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { usePortal } from './usePortal'

export const Portal = ({ children, id }) => {
  const [mounted, setMounted] = useState(false)

  const target = usePortal(id, mounted)

  useEffect(() => {
    setMounted(true)

    return () => setMounted(false)
  }, [])

  return mounted
    ? createPortal(
      children,
      target
    )
    : null
}
