import { chainType, CHAIN_DATA } from 'common/constants'
import get from 'lodash/get'
import React, { useEffect, useState } from 'react'
import SliderComponent from 'components/Slide'
import useGasData from 'common/customHooks/useGasData'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { convertWeiToBalance, formatPrice } from 'common/functions'
import './style.scss'

const GasSlider = ({ chain, onChange, gasLimitEst = 150000, gasSelected, className }) => {
  const { t } = useTranslation()
  const isETH = chain === chainType.ether
  const meansValue = 100 / (isETH ? 6 : 4)
  const [valueRangeSlider, setValueRangeSlider] = useState(
    get(gasSelected, 'index', 0)
  )

  const { gas } = useGasData(chain)
  const selectedGas = get(gas, `gasFull.data[${valueRangeSlider}]`)

  const feeTxs = convertWeiToBalance(
    gasLimitEst * get(selectedGas, 'value', 1),
    9
  )

  const onScrollSliderGas = (value) => {
    const idxSlide = value / meansValue
    setValueRangeSlider(idxSlide)
  }

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange({ gas: selectedGas, index: valueRangeSlider })
    }
  }, [valueRangeSlider, gas])

  return (
    <div className={cn('gas-slider-component', className)}>
      <div className={'box-gwei'}>
        <div className={'box-gwei__title'}>Gwei</div>
        <div className={'box-gwei__content'}>
          {selectedGas
            ? t(selectedGas?.label) + ': ' + selectedGas?.value
            : '-'}
        </div>
      </div>

      <SliderComponent
        min={0}
        max={100}
        step={meansValue}
        value={valueRangeSlider * meansValue}
        tipFormatter={(value) => {
          const gasValue = get(
            gas,
            `gasFull.data[${Math.ceil(value / meansValue)}].value`,
            ''
          )
          return <div>{gasValue}</div>
        }}
        onChange={onScrollSliderGas}
        marks={
          !isETH
            ? {
              [meansValue * 0]: 0,
              [meansValue * 1]: 1,
              [meansValue * 2]: 2,
              [meansValue * 3]: 3,
              [meansValue * 4]: 4
            }
            : {
              [meansValue * 0]: 0,
              [meansValue * 1]: 1,
              [meansValue * 2]: 2,
              [meansValue * 3]: 3,
              [meansValue * 4]: 4,
              [meansValue * 5]: 5,
              [meansValue * 6]: 6
            }
        }
      />

      <div className={'box-gwei'}>
        <div className={'box-gwei__title'}>{t('estimateFee')}: </div> &nbsp; &nbsp;
        <div className={'box-gwei__content'}>
          {t('txtFee', {
            amount: `${formatPrice(feeTxs, false, true)} ${
              CHAIN_DATA[chain].symbol
            }`,
            amountUSD: `$ ${formatPrice(
              feeTxs * get(gas, 'fRateFee', 1),
              false,
              true
            )}`
          })}
        </div>
      </div>
    </div>
  )
}

export default GasSlider
