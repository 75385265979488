import { Select } from 'antd'
import images from 'assets/images'
import { CURRENCY_DATA, CURRENCY_SYMBOL } from 'common/constants'
import IconCustom from 'components/common/IconCustom'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './style.module.scss'
import cn from 'classnames'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import StoreActions from 'controller/redux/actions/storeActions'
import BaseAdapter from 'controller/api/BaseAdapter'
import PageDropdown from 'components/common/PageDropdown'
import AboutToken from 'components/common/AboutToken'
import Tooltip from 'components/Tooltip'
import useScreenWidth from 'common/customHooks/useScreenWidth'

const DropdownCurrency = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currencyRedux = useSelector(state => state.currencyRedux)
  const [isChangingCurrency, setIsChangingCurrency] = useState()

  const { width } = useScreenWidth()
  const isLargeScreen = width > 1368

  const LIST_CURRENCY = CURRENCY_DATA.map(item => {
    const imageLink = `${window.location.origin}/Currency/${item}.png`
    const isActive = item === currencyRedux.label
    return {
      value: item,
      label: item,
      render: (
        <div className='d-flex align-items-center'>
          <AboutToken imgToken={imageLink} className="img-24 mr-3"/>
          <div className='currency-info'>
            <div>
              <div className={cn(isActive && 'color-theme', 'text-sm')}>{item} ({CURRENCY_SYMBOL[item]})</div>
              <div className='text-xs color-grey'>{t(item)}</div>
            </div>
            {isActive && <IconCustom icon="web_tick_circle_check" className="color-theme mr-3"/>}
          </div>
        </div>
      )
    }
  })

  const onChangeCurrency = async (val) => {
    setIsChangingCurrency(true)
    const res = await BaseAdapter.getData(`program/currency/${val.value}`)
    setIsChangingCurrency(false)
    if (res && res.success) {
      const rateObj = {
        value: get(res, 'data', 1),
        label: val.value
      }
      dispatch(StoreActions.setCurrency(rateObj))
    }
  }

  return (
    <div className={style['dropdown-warpper']}>
      <PageDropdown
        isAsyncChange
        isWaitingChange={isChangingCurrency}
        // tooltipText={isLargeScreen ? t('currency') : null}
        toggleclassName="cur-toggle"
        menuClassName="menu-language"
        dropdownItemClassName="item-curency"
        options={LIST_CURRENCY}
        defaultValue={currencyRedux}
        onChange={onChangeCurrency}
      />
    </div>
  )
}

export default DropdownCurrency
