import PageDropdown from 'components/common/PageDropdown'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './style.module.scss'
import IconCustom from 'components/common/IconCustom'
import useScreenWidth from 'common/customHooks/useScreenWidth'
import { CHAIN_DATA, LIST_CHAIN_SUPPORT } from 'common/constants'

const DropdownChainFilterChain = ({ onSelectChain }) => {
  const { t } = useTranslation()

  const [selectObjChain, setSelectObjChain] = useState({ value: '', label: <span>All Chain</span> })
  const { width } = useScreenWidth()

  const isActiveAllChain = selectObjChain?.value === ''

  const isLargeScreen = width > 1440
  const LIST_CHAIN = LIST_CHAIN_SUPPORT.map(item => {
    const dataChain = CHAIN_DATA[item]
    const isActive = selectObjChain?.value === dataChain?.chain

    return {
      labelRender: <span>{dataChain?.name}</span>,
      value: dataChain?.chain,
      render: (<div className={`d-flex align-items-center item-filter-chain ${isActive && 'active'}`}>
        <div>
          <IconCustom icon={dataChain?.image} className={`token-img token-img--token-1 img-24 mr-3 ${isActive && 'color-theme'}`} />
        </div>
        <div className='currency-info'>
          <div className='d-flex align-items-center'>
            <div className='text-sm color-grey'>{dataChain?.name}</div>
          </div>
        </div>
      </div>)
    }
  })

  LIST_CHAIN.unshift({
    labelRender: <span>All Chain</span>,
    value: '',
    render: (<div className={`d-flex align-items-center item-filter-chain ${isActiveAllChain && 'active'}`}>

      <div className='currency-info'>
        <div className='d-flex align-items-center'>
          <div className='text-sm color-grey'>All Chain</div>
        </div>
      </div>
    </div>)
  })

  const onChangeChain = async (val) => {
    setSelectObjChain(val)
    onSelectChain && onSelectChain(val?.value)
  }

  return (
    <div className={style['dropdown-warpper-filter-chain']}>
      <PageDropdown
        isAsyncChange
        isWaitingChange={true}
        tooltipText={isLargeScreen ? t('chain') : null}
        toggleclassName="chain-toggle"
        menuClassName="menu-chain"
        dropdownItemClassName="item-curency"
        options={LIST_CHAIN}
        defaultValue={selectObjChain}
        onChange={onChangeChain}
        isAwaitChange={true}
      />
    </div>
  )
}

export default DropdownChainFilterChain
