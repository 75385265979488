import images from 'assets/images'
import { convertDateMoment, formatNumberBro, formatPrice, getLength, nFormatter, upperCase } from 'common/functions'
import ReduxServices from 'common/redux'
import PuffLoading from 'components/common/PuffLoading'
import { get, parseInt } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { isWindow } from 'common/constants'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import './style.scss'

const RechartLine = ({
  interval = 70,
  dataChart,
  width = '100%',
  height = '100%',
  isHideTitle,
  title,
  isLoading,
  isSwapChart,
  handleExpand,
  isExpandChart,
  fromCoin,
  toCoin,
  detailCgk,
  handleChooseTime,
  timeChoose,
  hideTick,
  percentChart
}) => {
  const { t } = useTranslation()
  const [dataHead, setDataHead] = useState({
    date: new Date(),
    number: 0
  })
  const [viewChoose, setViewChoose] = useState('default')
  const [widthScreen, setWidthScreen] = useState(isWindow && window.innerWidth)

  const currencyRedux = useSelector(state => state.currencyRedux) // needed to rerender onChange Currency

  const isPriceChangeUp = parseFloat(get(detailCgk, 'market_data.price_change_24h_in_currency.usd', 0)) > 0

  const imgToken = get(toCoin, 'logoURI') || get(toCoin, 'image', images.icoUnknown)
  const checkScreenIpad = widthScreen < 1024

  const handleResize = () => {
    setWidthScreen(window.innerWidth)
  }

  useEffect(() => {
    isWindow && window.addEventListener('resize', handleResize)
  }, [])

  const arrTimes = [
    {
      name: '24h',
      value: '1D'
    },
    {
      name: '1W',
      value: '1W'
    },
    {
      name: '1M',
      value: '1M'
    },
    {
      name: '1Y',
      value: '1Y'
    }
  ]

  useEffect(() => {
    if (!dataChart.length) return

    setDataHead({
      ...dataChart[dataChart.length - 1]
    })
  }, [dataChart, currencyRedux])

  useEffect(() => {
    if (isSwapChart) {
      setDataHead({
        date: new Date(),
        number: get(detailCgk, 'market_data.current_price.usd', 0)
      })
    }
  }, [detailCgk])

  const tickFormatter = (data) => {
    if (timeChoose === '1D') {
      return new Date(data).getHours()
    }
    // return new Date(data).getDate()
    return convertDateMoment(data, 'MMM DD')
  }

  const tickFormatterYAxis = (data) => {
    // return isSwapChart ? formatPrice(data, null, true) : nFormatter(data)
    // return ReduxServices.formatPrice(data, true, data < 1 ? 2 : 1)
    return ReduxServices.formatPrice(data, true)
  }

  const onMouseOver = (data) => {
    setDataHead({
      ...data
    })
  }

  const formatDate = (date) => {
    return moment(date).format('LL')
  }

  const renderPrice = (date) => {
    if (isSwapChart) {
      return ReduxServices.formatPrice(get(dataHead, 'number', 0), true)
    }
    return ReduxServices.formatPrice(get(dataHead, 'number', 0), true)
  }

  const onMouseOut = () => {
    const lengthDataChart = getLength(dataChart)

    if (lengthDataChart > 0) {
      const data = dataChart[lengthDataChart - 1]
      if (isSwapChart) {
        data.number = get(detailCgk, 'market_data.current_price.usd', 0)
      }

      setDataHead({
        ...data
      })
    }
  }

  const handleChooseView = (value) => () => {
    setViewChoose(value)
  }

  const renderChart = useMemo(() => {
    if (isLoading) {
      return (
        <div className="loading-chart">
          <PuffLoading/>
        </div>
      )
    } else {
      return (dataChart.length)
        ? (
          <ResponsiveContainer width="99%" height="100%" >
            <AreaChart
              width={500}
              height={300}
              data={dataChart}
              baseValue={0}
              margin={{
                top: 20,
                right: -40,
                left: 0,
                bottom: 60
              }}
              onMouseLeave={() => {
                onMouseOut()
              }}
            >
              <defs>
                <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={'#fdbe1d'} stopOpacity={0.7} />
                  <stop offset="20%" stopColor={'#fdbe1d'} stopOpacity={0.3} />
                  <stop offset="100%" stopColor={'rgba(215, 204, 255, 0)'} stopOpacity={0} />
                </linearGradient>
              </defs>
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <XAxis
                tickFormatter={tickFormatter}
                dataKey="date"
                axisLine={false}
                tickLine={false}
                tick={!hideTick}
                interval={interval}
                tickMargin={10}
                dx={checkScreenIpad ? 15 : 40}
              />
              <YAxis
                tickFormatter={tickFormatterYAxis}
                orientation="right"
                axisLine={false}
                domain={['auto', 'auto']}
                tickLine={false}
                scale="linear"
                allowDataOverflow={true}
                tick={!hideTick}

              />
              <Tooltip
                wrapperStyle={{ display: 'none' }}
                formatter={(tooltipValue, name, props) => {
                  onMouseOver(get(props, 'payload', {}))
                }}
                cursor={!hideTick}
              />
              <Area
                // onMouseMove={onMouseOver}
                onMouseLeave={onMouseOut}
                type="monotone"
                dataKey="number"
                stroke={!hideTick && '#fdbe1d'}
                fill={'url(#gradient)'}
                activeDot={!hideTick}
              />
            </AreaChart>
          </ResponsiveContainer>
        )
        : (
          <div className="empty-chart">
            <img src={images.iconEmptyChart} alt="" className='empty-chart__icon' />
            {t('emptyData')}
          </div>
        )
    }
  }
  , [isLoading, dataChart, detailCgk, currencyRedux])

  const renderTimeSwap = () => {
    return arrTimes.map((item, i) => {
      return (
        <div className={`item-time ${timeChoose === get(item, 'value') && 'item-time--active'}`} key={i} onClick={handleChooseTime(get(item, 'value'))}>
          {get(item, 'name')}
        </div>
      )
    })
  }

  return (
    <React.Fragment>
      <div className={`info-value ${hideTick && 'info-value--hide'}`}>
        {!isHideTitle && <div className="info-value__title">{title}</div>}

        {isSwapChart && <div className="info-value__title-swap">
          <div className="title-swap-left">
            <div className="icon-tokens">
              {/* <img src={get(fromCoin, 'icon')} className='icon-token-swap-chart' alt="" /> */}
              <img src={imgToken} className='icon-token-swap-chart' alt="" />
            </div>
            {/* {upperCase(get(fromCoin, 'symbol'))} / {upperCase(get(toCoin, 'symbol'))} */}
            {get(toCoin, 'name')} ({upperCase(get(toCoin, 'symbol'))})

            {/* <img src={images.iconChange} className='icon-change-swap-chart' alt="" /> */}

            {/* <div className={`choose-view ${viewChoose === 'default' && 'choose-view--active'}`} onClick={handleChooseView('default')}>
              {t('default')}
            </div>

            <div className={`choose-view ${viewChoose === 'trading' && 'choose-view--active'}`} onClick={handleChooseView('trading')}>
              {t('tradingView')}
            </div> */}
          </div>

          {/* <div className="title-swap-right">
            <img src={images[!isExpandChart ? 'iconExpand' : 'iconCollapse']} alt="" onClick={handleExpand} />
          </div> */}
        </div>}

        <div className={`info-value__usd ${isSwapChart && 'info-value__usd--swap'}`}>
          <div className="usd-swap--left">
            {renderPrice()}

            {isSwapChart &&
            <>
              <div className="token-symbol">
                {/* {upperCase(get(fromCoin, 'symbol'))} / {upperCase(get(toCoin, 'symbol'))} */}
                {upperCase(get(toCoin, 'symbol'))}
              </div>

              <div className={`percent-symbol ${isPriceChangeUp && 'percent-symbol--up'}`}>
                {isPriceChangeUp ? '+' : ''}
                {`${formatNumberBro({ number: get(detailCgk, 'market_data.price_change_24h_in_currency.usd') })}
                (${formatNumberBro({ number: get(detailCgk, 'market_data.price_change_percentage_24h_in_currency.usd'), mantissa: 2 })}%)`}
              </div>
            </>
            }
          </div>

          {isSwapChart && <div className="usd-swap--right">
            {renderTimeSwap()}
          </div>
          }
        </div>

        <div className="info-value__date">
          {formatDate(get(dataHead, 'date', new Date()))}
        </div>
      </div>

      {renderChart}

    </React.Fragment>
  )
}

export default RechartLine
