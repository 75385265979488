import { chainType, CHAIN_DATA } from 'common/constants'
import GasServices from 'common/GasServices'
import MarketScan from 'common/MarketScan'
import ReduxServices from 'common/redux'
import { useEffect, useState } from 'react'

const useGasData = (chain) => {
  const isETH = chain === chainType.ether
  const [gas, setGas] = useState(null)

  const fetchGas = async () => {
    try {
      const fRateFee = await MarketScan.getRateCryptoCurrency(CHAIN_DATA[chain].id)

      const onSetGas = async (gasFull) => {
        try {
          // const newGasFull = renderGasFull(t, isETH, gasFull)
          setGas({
            fRateFee,
            gasFull: gasFull
          })
        } catch (error) {

        }
      }

      if (isETH) {
        ReduxServices.getGasTxsEther(onSetGas)
      } else {
        GasServices.getGasWithText(chain, true).then(gasFull => {
          onSetGas(gasFull)
        })
      }
    } catch (e) {
      setTimeout(() => {
        fetchGas()
      }, 300)
    }
  }

  useEffect(() => {
    fetchGas()
  }, [chain])

  return {
    gas,
    refreshGas: fetchGas
  }
}

export default useGasData
