import images from 'assets/images'
import ReduxServices from 'common/redux'
import VideoBox from 'components/common/VideoBox'
import CountUpComponent from 'components/CountUpComponent'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactNumberTicker from 'react-animate-number-ticker'
import 'react-animate-number-ticker/dist/index.css'

import './style.scss'
import CountComponent from 'components/CountComponent'

function InfoTotalValue ({ isFarm, totalValueStake }) {
  const { t } = useTranslation()

  // effect data
  useEffect(() => {
    const el = document.getElementById('prevent-bg')
    if (el) {
      el.addEventListener('contextmenu', (e) => {
        e.preventDefault()
      })
    }
  }, [])

  return (
    <div className="info-total-value">
      <div className="info-left">
        <div className="info-total-value__top">
          <div className="top-title">{isFarm ? 'Farm' : 'Stake'}</div>
          <div className="top-description">
            {t(isFarm ? 'farmingDescription' : 'stakeDescription')}
          </div>
        </div>
        <div className="info-total-value__bottom">
          <div className="bottom-title">{t('stakeTotal')}</div>
          <div className="bottom-value">
            {/* <CountUpComponent
              endNum={totalValueStake}
              isCurrency
            /> */}
            <CountComponent number={ReduxServices.formatPrice(totalValueStake)}/>
          </div>
        </div>
      </div>
      <div className="info-right">
        <div className="info-right--video">
          <VideoBox src={images[isFarm ? 'bannerFarm' : 'bannerStake']} />
        </div>
        <figure className="info-right--gif">
          <img
            src={images[isFarm ? 'bannerFarmGif' : 'bannerStakeGif']}
            alt=""
          />
        </figure>
      </div>
      <figure id="prevent-bg" className="background-cover">
        <img src={images.baryonMain} className="img-fluid" alt="" />
      </figure>
    </div>
  )
}

export default InfoTotalValue
