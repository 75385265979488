import BaseAPI from 'controller/api/BaseAPI'
import { get } from 'lodash'
import React, { useEffect, useState, useRef } from 'react'
import { selectDefaultChain } from 'common/poolEvm/constants'
import { useDebouncedCallback } from 'use-debounce'
import { getLength } from 'common/functions'
import { useSelector } from 'react-redux'

export const sizeSnapShot = 10

const arrSort = [
  {
    name: 'decreasing',
    value: 'desc'
  },
  {
    name: 'ascending',
    value: 'asc'
  }
]
const useListJoin = ({ id, time, isParticipated }) => {
  const [dataJoin, setDataJoin] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [dataRenderTitle, setDataRenderTitle] = useState([])
  const [txtSearch, setTxtSearch] = useState('')
  const [sort, setSort] = useState('desc')
  const tableRep = useRef([])

  const handleSelectedPage = (value) => {
    const { selected } = value
    setCurrentPage(selected + 1)
  }
  const onChangeSearch = (e) => {
    const { value } = e.target
    setTxtSearch(value)
  }
  const handleClear = () => {
    setTxtSearch('')
  }
  const currencyRedux = useSelector(state => state.currencyRedux) // needed to rerender onChange Currency

  useEffect(() => {
    fetchData()
  }, [currentPage])

  useEffect(() => {
    const isValidAddress = window.wallet.validateBlockChainAddress(
      txtSearch,
      selectDefaultChain
    )
    if (isValidAddress || txtSearch === '') {
      fetchData()
    } else if (getLength(txtSearch) === 0) {
      setDataJoin(tableRep.current)
    } else {
      setDataJoin([])
    }
  }, [txtSearch, sort])

  const fetchData = useDebouncedCallback(async (size = 10) => {
    setIsLoading(true)
    try {
      const body = {
        page: currentPage,
        size,
        id,
        sort: sort,
        time,
        keyword: txtSearch,
        isParticipated
      }
      const res = await BaseAPI.getData('snapshot/participant', body)

      if (res) {
        if (getLength(txtSearch) === 0) {
          tableRep.current = res.data
        }
        setDataJoin(res.data)
        setDataRenderTitle(res.render)
        const totalApi = get(res, 'total', 0)
        setTotal(totalApi / size)
      }
    } catch (e) {}
    setIsLoading(false)
  }, 200)

  const handleChangeSelect = (value) => {
    setSort(value)
  }

  return {
    total,
    dataJoin,
    dataRenderTitle,
    isLoading,
    refreshData: fetchData,
    handleSelectedPage,
    onChangeSearch,
    arrSort,
    handleChangeSelect,
    txtSearch,
    handleClear
  }
}

export default useListJoin
