import React, { useMemo } from 'react'
import cn from 'classnames'
import style from './style.module.scss'
import { formatAddress, formatBilion, formatNumberBro, getLength } from 'common/functions'
import { useTranslation } from 'react-i18next'
import { get, random, sample } from 'lodash'
import AboutToken from 'components/common/AboutToken'
import { CustomTable } from 'components/CustomTable'
import StatusItem from 'components/common/StatusItem'
import { useSelector } from 'react-redux'
import ReduxServices from 'common/redux'
import Pagination from 'pages/PoolScreen/components/Pagination'

const LIMIT = 10

const TableFarmStake = ({ address, tableData, isLoading, totalPage, onChangePage, isStake, currPage, onClickRow }) => {
  const { t } = useTranslation()
  const isMobile = window.innerWidth < 768
  const currencyRedux = useSelector(state => state.currencyRedux)
  const handleChangePage = (val) => {
    const { selected } = val
    onChangePage && onChangePage(selected + 1)
  }

  const tableRowsData = useMemo(() => {
    if (getLength(tableData) === 0) return []
    return tableData.map((item, index) => {
      return {
        index: LIMIT * (currPage - 1) + index + 1,
        account: item.address,
        tokenIn: item.tokenIn,
        status: get(item, 'status'),
        tokenOut: item.tokenOut,
        apr: item.apr,
        value: item.amountUSD
      }
    })
  }, [tableData, currencyRedux])

  const columnHeaderFarmStake = [
    {
      title: '',
      dataIndex: 'index',
      width: 2,
      render: (val) => {
        return <div>{val}</div>
      }
    },
    {
      title: t('account'),
      dataIndex: 'account',
      width: 20,
      render: (val) => {
        return <div className='color-theme'>{formatAddress(val, isMobile ? 6 : 10) }</div>
      }
    },
    {
      title: t(isStake ? 'Token' : 'Pair'),
      dataIndex: 'tokenIn',
      width: 20,
      render: (val) => {
        return (
          <div className='d-flex align-items-center  text-uppercase'>
            {
              val?.map(item => (
                <AboutToken key={item.id} imgToken={get(item, 'image')} alt="" className="token-img token-img--token-1 img-24 mr-2" />
              ))
            }
            {
              val?.map((item, index) => (
                <div key={item.id} className='token-symbol-container'>
                  <span >{get(item, 'symbol')}</span>
                  { (index === 0 && !isStake) && <span >/</span>}
                </div>

              ))
            }

          </div>
        )
      }
    },
    {
      title: t('apr'),
      dataIndex: 'apr',
      width: 20,
      align: 'right',
      render: (val) => {
        const valFormat = val * 100
        return <div>{formatNumberBro({ number: valFormat, mantissa: 2 })}%</div>
      }
    },
    {
      title: t('status'),
      dataIndex: 'status',
      width: 15,
      align: 'right',
      render: (val) => {
        return <div className={cn(val === 'active' ? 'color-success' : 'color-theme')}>{t(val)}</div>
      }
    },
    {
      title: t(isStake ? 'tokenValue' : 'lpValue'),
      dataIndex: 'value',
      width: 15,
      align: 'right',
      render: (val) => {
        return <div>{ReduxServices.formatPrice(val)}</div>
      }
    },
    {
      title: t('earn'),
      dataIndex: 'tokenOut',
      align: 'right',
      width: 20,
      render: (val) => {
        return (
          <div className='text-uppercase union-icon-list'>
            {
              val?.map(item => (
                <AboutToken key={item.id} imgToken={get(item, 'image')} alt="" className="token-img union-icon-list--icon token-img--token-1 img-24 mr-2" />
              ))
            }

          </div>
        )
      }
    }
  ]

  const handleClickRow = (row) => {
    onClickRow && onClickRow(row)
  }

  return (
    <div className={cn(style['table-farm'])}>
      <CustomTable
        isLoading={isLoading}
        columns={columnHeaderFarmStake}
        dataSource={tableRowsData }
        onClickRow={handleClickRow}
      />
      <div className='d-flex justify-content-center'>
        {
          getLength(tableData) > 0 &&
          <Pagination
            forcePage={currPage - 1}
            totalPage={totalPage}
            handleSelectedPage={handleChangePage}
          />
        }
      </div>
    </div>
  )
}

export default TableFarmStake
