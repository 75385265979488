import React, { useEffect, useMemo, useState } from 'react'
import './style.scss'
import BaseAPI from 'controller/api/BaseAPI'
import BoardTransactions from 'pages/PoolInfoScreen/components/BoardTransactions'
import RechartLine from 'components/RechartLine'
import BoardPool from 'pages/PoolInfoScreen/components/BoardPool'
import BoardTokens from 'pages/PoolInfoScreen/components/BoardTokens'
import { useTranslation } from 'react-i18next'
import PoolInfoScreen from 'pages/PoolInfoScreen'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import BoardWallet from 'pages/PoolInfoScreen/components/BoardWallet'
import { chainType } from 'common/constants'
import useScreenWidth from 'common/customHooks/useScreenWidth'

const MENU_TYPE = {
  overview: 'info',
  pools: 'pools',
  tokens: 'tokens',
  wallet: 'wallet'
}

const InfoScreen = () => {
  const { t } = useTranslation()
  const chainActive = useSelector((state) => state.chainActive)
  const currencyRedux = useSelector(state => state.currencyRedux)

  const [dataChartVolume, setDataChartVolume] = useState([])
  const [dataChartLiquidity, setDataChartLiquidity] = useState([])
  const [typeMenu, setTypeMenu] = useState(MENU_TYPE.overview)
  const [isLoading, setIsLoading] = useState(false)
  const { width } = useScreenWidth()

  const { address } = useParams()

  const history = useHistory()

  const isMobile = width < 576

  const numDate = isMobile ? 4 : 6

  const timestampNow = Math.floor(new Date().getTime())
  const timeChart = {
    [chainType.binanceSmart]: 1654041600000,
    [chainType.tomo]: 1704067200000,
    [chainType.bitkub]: 1699660800000,
    [chainType.ancient8]: 1703030400000,
    [chainType.ancient8Mainnet]: 1712448000000
  }

  const intervalChartChain = {
    [chainType.binanceSmart]: Math.floor((((timestampNow - timeChart[chainType.binanceSmart]) / 86400000)) / numDate),
    [chainType.tomo]: Math.floor((((timestampNow - timeChart[chainType.tomo]) / 86400000)) / numDate),
    [chainType.bitkub]: Math.floor((((timestampNow - timeChart[chainType.bitkub]) / 86400000)) / numDate),
    [chainType.ancient8]: Math.floor((((timestampNow - timeChart[chainType.ancient8]) / 86400000)) / numDate),
    [chainType.ancient8Mainnet]: Math.floor((((timestampNow - timeChart[chainType.ancient8Mainnet]) / 86400000)) / numDate)
  }

  const isItemInfo = useMemo(() => {
    const { pathname } = history.location
    const isPoolOrToken = pathname.includes('pool') || pathname.includes('token')
    return isPoolOrToken && address
  }, [history.location.pathname, address])

  useEffect(() => {
    document.title = 'Info | Baryon'
  })

  useEffect(() => {
    // TRIGGER RERENDER CHART
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 300)
  }, [currencyRedux])

  useEffect(() => {
    initData()
  }, [chainActive])

  useEffect(() => {
    const pathname = history.location.pathname
    if (pathname.includes('info')) return setTypeMenu(MENU_TYPE.overview)
    if (pathname.includes('pool')) return setTypeMenu(MENU_TYPE.pools)
    if (pathname.includes('token')) return setTypeMenu(MENU_TYPE.tokens)
    if (pathname.includes('wallet')) return setTypeMenu(MENU_TYPE.wallet)
  }, [history.location.pathname])

  const initData = () => {
    setIsLoading(true)
    getTokenInfoChart()
  }

  // const handleMenu = (type) => () => {
  //   history.push('/' + type)
  // }

  const getTokenInfoChart = async () => {
    const response = await BaseAPI.postData('baryon/pool/total', {
      from: timeChart[chainActive],
      chain: chainActive
    })
    if (!response) return
    const arrLiquidity = response.map((item) => ({
      number: item.totalLiquidity,
      date: item.from
    }))
    const arrVolume = response.map((item) => ({
      number: item.cumulatedVolume,
      date: item.from
    }))
    setDataChartLiquidity(arrLiquidity)
    setDataChartVolume(arrVolume)
    setIsLoading(false)
  }

  const renderChartOverview = () => {
    return (
      <div className="box-chart-overview">
        <div className="box-chart-overview__chart">
          <RechartLine
            height="40rem"
            dataChart={dataChartLiquidity}
            // dataChart={dataTest}
            title={t('liquidity')}
            isLoading={isLoading}
            interval={intervalChartChain[chainActive]}
          />
        </div>
        <div className="box-chart-overview__chart">
          {/* <RechartBar height="40rem"
            dataChart={dataChartVolume}
            // dataChart={dataTest}
          /> */}

          <RechartLine
            height="40rem"
            dataChart={dataChartVolume}
            title={t('volume')}
            isLoading={isLoading}
            interval={intervalChartChain[chainActive]}
          />
        </div>
      </div>
    )
  }

  const renderDetailOverview = () => {
    return (
      <>
        <div className="section-board section-board--token">
          <BoardTokens isHiddenMore />
        </div>
        <div className="section-board section-board--pool">
          <BoardPool isHiddenMore type="pool" />
        </div>
        <div className="section-board section-board--txs">
          <BoardTransactions />
        </div>
      </>
    )
  }

  const renderInfoTopScreen = () => {
    if (isItemInfo) {
      return <PoolInfoScreen />
    } else {
      switch (typeMenu) {
      case MENU_TYPE.pools:
        return <BoardPool type="pool" />

      case MENU_TYPE.tokens:
        // return <TopTokenMenu />
        return <BoardTokens />
      case MENU_TYPE.wallet:
        return <BoardWallet />
      default:
        return renderChartOverview()
      }
    }
  }

  const renderDetailInfoScreen = () => {
    if (isItemInfo) {
      switch (typeMenu) {
      case MENU_TYPE.pools:
        return <BoardTransactions typeBoard="pool" address={address} />

      case MENU_TYPE.tokens:
        return (
          <>
            <BoardPool isHiddenMore type="token" address={address} />
            <BoardTransactions typeBoard="token" address={address} />
          </>
        )

      default:
        return <BoardTokens />
      }
    } else {
      switch (typeMenu) {
      case MENU_TYPE.overview:
        return renderDetailOverview()
      case MENU_TYPE.tokens:
        return <></>
      default:
        return <></>
      }
    }
  }

  return (
    <div className="info-screen">
      <div className="info-screen__wrapper">
        <div className="info-screen__menu">
          <div className="menu-info-container">
            {Object.keys(MENU_TYPE).map((item, index) => {
              const isActive = typeMenu === MENU_TYPE[item]
              return (
                <Link key={index} to={`/${MENU_TYPE[item]}`} className={`menu-info-container__item ${isActive && 'menu-info-container__item--active'}`}>
                  {t(item)}
                </Link>
              )
            })}
          </div>

          <div className="info-top-screen">
            {renderInfoTopScreen()}
          </div>

        </div>

        <div className="info-screen__section">
          {renderDetailInfoScreen()}
        </div>
      </div>
    </div>
  )
}

export default InfoScreen
