import images from 'assets/images'
import Button from 'components/common/Button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import MenuItemBaryon from './MenuItemBaryon'

import './style.scss'

function BaryonMenu () {
  const { t } = useTranslation()
  const isDarkMode = useSelector((state) => state.themeRedux) === 'dark'

  const arrMenuBaryon = [
    {
      button: 'swapNow',
      icon: images.imgSwap,
      name: 'swap',
      des: 'swapHidden',
      link: '/swap'
    },
    {
      button: 'farmNow',
      icon: images.imgFarm,
      name: 'farm',
      des: 'earnExtra',
      link: '/farm'
    },
    {
      button: 'stakeNow',
      icon: images.imgStake,
      name: 'stake',
      des: 'stakeToEarn',
      link: '/stake'
    }
  ]

  // const renderMenuBaryon = () => {
  //   return arrMenuBaryon.splice(0, 1).map((item, i) => {
  //     return <MenuItemBaryon key={i} item={item} />
  //   })
  // }

  // const renderMenuBaryonBottom = () => {
  //   return arrMenuBaryon.slice(0).map((item, i) => {
  //     return <MenuItemBaryon key={i} item={item} isBottom />
  //   })
  // }

  const renderMenuBaryon = () => {
    return arrMenuBaryon.map((item, i) => {
      return <MenuItemBaryon key={i} item={item} />
    })
  }

  return (
    <div className="baryon-menu-container">
      {/* <img src={images[isDarkMode ? 'bgHalfDark' : 'bgHalfLight']} alt="" className="bg-half-circle" /> */}

      {/* <h2 className="baryon-menu-container__title">
        {t('exploreBaryon')}
        <img src={images[isDarkMode ? 'lightYellow' : 'lightYellowLight']} alt="" className="bg-light-yellow" />
      </h2> */}
      {/* <div className="bg-light-yellow"></div> */}

      {/* <img src={images.bgStar} alt="" className="star-menu" /> */}

      {/* <div className="baryon-menu-container__list-menu-top">
        {renderMenuBaryon()}
      </div> */}

      {/* <div className="baryon-menu-container__list-menu-bottom">
        {renderMenuBaryonBottom()}
      </div> */}

      <h2 className="baryon-menu-container__title heading-2 ">
        {t('exploreBaryon')}
      </h2>
      <div className="explore-content">
        {renderMenuBaryon()}
      </div>
    </div>
  )
}

export default BaryonMenu
