import images from 'assets/images'
import { getLength } from 'common/functions'
import React from 'react'

import './style.scss'

function Checkbox ({ text, itemChoose = false }) {
  return (
    <div className="checkbox-container">
      <div className="checkbox">
        <img src={images.tickBaryon} alt="" className={`tick-choose ${itemChoose && 'tick-choose--active'}`} />
      </div>

      <div className="name-text">
        {getLength(text) !== 0 && text}
      </div>
    </div>
  )
}

export default Checkbox
