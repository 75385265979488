import { isWindow } from 'common/constants'
import { formatNumberBro, upperCase } from 'common/functions'
import TableBoard from 'components/TableBoard'
import get from 'lodash/get'
import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ProcessCircle from 'components/common/ProcessCircle'
import './style.scss'
import cn from 'classnames'
import images from 'assets/images'
import { useSelector } from 'react-redux'
import { Col, Row } from 'antd'
import ContainerShow from '../ContainerShow'
import FavoriteToken from '../FavoriteToken'
import IconCustom from 'components/common/IconCustom'
import ReduxServices from 'common/redux'
import useFavoritePool from 'common/customHooks/useFavoritePool'
import BaseAdapter from 'controller/api/BaseAdapter'
import PageDropdown from 'components/common/PageDropdown'
import Tooltip from 'components/Tooltip'
import { getTokeInfoPool } from 'common/poolEvm/function'

const LIMIT = 10
const SAROS_POOL = 'SAROS_POOL'
const ALL_POOL = 'ALL_POOL'

const BoardPool = ({ type, address, isHiddenMore }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const isDarkMode = useSelector((state) => state.themeRedux) === 'dark'
  const [totalPageTopPool, setTotalPagePool] = useState(1)
  const [dataSourceDefaultPool, setDataSourceDefaultPool] = useState([])
  const [currentPagePool, setCurrentPagePool] = useState(1)
  const [isLoadingStats, setIsLoadingStats] = useState()
  const [isLoadingListPool, setIsLoadingPool] = useState(false)
  const [widthScreen, setWidthScreen] = useState(isWindow && window.innerWidth)
  const [currentTabPool, setCurrentTabPool] = useState(SAROS_POOL)
  const currencyRedux = useSelector((state) => state.currencyRedux)
  const chainActive = useSelector((state) => state.chainActive)

  const listFavoritePoolRedux = useSelector(
    (state) => state.listFavoritePoolRedux
  )

  const arrSort = [
    {
      label: 'liquidity',
      value: 'totalLiquidity'
    },
    {
      label: 'apr',
      value: 'apr'
    },
    {
      label: 'volume24h',
      value: 'volume24h'
    },
    {
      label: 'volume7d',
      value: 'volume7d'
    },
    {
      label: 'fees',
      value: 'volume24h'
    }
  ]

  const { onChangeListPoolFavor } = useFavoritePool()

  const [poolStats, setPoolStats] = useState({})

  const [sort, setSort] = useState(arrSort[0].value)

  const handCheckLike = (info) => async (e) => {
    e.preventDefault()
    onChangeListPoolFavor(info)
  }

  useEffect(() => {
    fetchListPoolTrending()
  }, [chainActive])

  const fetchListPoolTrending = async () => {
    setIsLoadingStats(true)
    const response = await BaseAdapter.getData('baryon/pool/statistical', { chain: chainActive })
    if (!get(response, 'success', false)) return
    const dataObj = get(response, 'data', {})
    setPoolStats(dataObj)
    setIsLoadingStats(false)
  }
  const checkScreenIpad = widthScreen > 1024

  const columnsDefaultPool = [
    {
      title: '',
      dataIndex: 'icon',
      width: checkScreenIpad ? 3 : 10,
      icon: '',
      align: 'center'
    },
    {
      title: '',
      dataIndex: 'index',
      width: 5,
      icon: '',
      align: 'center'
    },
    {
      title: t('pool'),
      dataIndex: 'pool',
      width: checkScreenIpad ? 24 : 20,
      icon: '',
      align: 'left'
    },
    {
      title: t('liquidity'),
      dataIndex: 'liquidity',
      width: 14,
      icon: ''
    },
    {
      title: t('volume24h'),
      dataIndex: 'volume24h',
      width: checkScreenIpad ? 15 : 70,
      icon: ''
    },
    {
      title: t('volume7d'),
      dataIndex: 'volume7d',
      width: 15,
      icon: ''
    },
    {
      title: t('fees'),
      dataIndex: 'lpRewardFee',
      width: 15,
      icon: ''
    },
    {
      title: t('apr'),
      dataIndex: 'lpRewardApr',
      width: 14,
      icon: ''
    }
  ]

  const countTimeReload = null

  const renderColumn = () => {
    const cloneColumn = [...columnsDefaultPool]
    const newCloneColumn = cloneColumn.filter((item) => {
      if (widthScreen <= 1024) {
        return (
          item.dataIndex !== 'index' &&
          item.dataIndex !== 'liquidity' &&
          item.dataIndex !== 'volume7d' &&
          item.dataIndex !== 'lpRewardFee' &&
          item.dataIndex !== 'lpRewardApr'
        )
      }
      return item
    })
    return newCloneColumn
  }

  const onReloadPie = () => {
    getListTopPool({ page: currentPagePool, isNotLoad: true })
  }

  // useEffect(() => {
  //   getListTopPool({ page: 1 })
  //   console.log('????')
  // }, [address, currentTabPool, sort, currentPagePool])

  useEffect(() => {
    getListTopPool({ page: currentPagePool })
  }, [currentTabPool, sort, currentPagePool, chainActive])

  const getListTopPool = ({ page = currentPagePool, isNotLoad }) => {
    clearTimeout(window.timing)
    window.timing = setTimeout(async () => {
      !isNotLoad && setIsLoadingPool(true)
      const params = {
        page: page,
        size: LIMIT,
        sort: sort,
        chain: chainActive
      }
      const urlFetch = {
        token: {
          [SAROS_POOL]: 'baryon/pool/filter',
          [ALL_POOL]: 'baryon/token/pool/all'
        },
        pool: {
          [SAROS_POOL]: 'baryon/pool/filter',
          [ALL_POOL]: 'baryon/pool/top/all'
        }
      }
      switch (type) {
      case 'token':
        params.tokenAddress = address
        break
      case 'pool':
        params.type = 'top'
        break
      default:
        break
      }
      // const response = await BaseAPI.postData(
      //   urlFetch[type][currentTabPool],
      //   params
      // )
      const response = await BaseAdapter.getData(
        urlFetch[type][currentTabPool],
        params
      )
      const totalPage = get(response, 'data.total', 1)

      if (!response) return
      const arrTopPool = get(response, 'data.data', [])

      setTotalPagePool(totalPage)
      setDataSourceDefaultPool(arrTopPool)

      setIsLoadingPool(false)
    }, 0)
  }

  const handleChangeSelect = (value) => {
    setSort(value.value)
  }

  const handleNavigateTokenInfo = (item) => () => {
    const poolAddress = get(item, 'poolAddress', '')
    history.push(`/pool/${poolAddress}`)
  }

  const renderDataSourceDefaultPool = useMemo(() => {
    const currentPage = currentPagePool !== 0 ? currentPagePool - 1 : 0
    const dataPool = dataSourceDefaultPool.map((item, index) => {
      const token0 = getTokeInfoPool(get(item, 'info.token0', {}), chainActive)
      const token1 = getTokeInfoPool(get(item, 'info.token1', {}), chainActive)
      const volume24h = get(item, 'volume24h', 0)
      const volume7d = get(item, 'volume7d', 0)
      const liquidity = get(item, 'liquidity', 0)
      const lpRewardFee24 = get(item, 'fee24h', 0)
      const lpRewardApr = get(item, 'feeAPR', 0)
      const isFavor = listFavoritePoolRedux.some(
        (pool) => pool.poolAddress === item.poolAddress
      )
      const data = {
        key: index,
        icon: (
          <div onClick={handCheckLike(item)}>
            <IconCustom
              className={cn(isFavor && 'color-theme', 'icon-table')}
              icon={isFavor ? 'web_like_active' : 'web_like'}
            />
          </div>
        ),
        index: (
          <div className="pool-index">{currentPage * LIMIT + index + 1}</div>
        ),
        href: `/pool/${get(item, 'poolAddress', '')}`,
        pool: (
          <div className="pool-name">
            <div className="pool-name__img">
              <img
                src={
                  get(token0, 'image') ||
                  get(
                    token0,
                    'logoURI',
                    images[isDarkMode ? 'icoUnknown' : 'icoUnknownLight']
                  )
                }
                alt=""
              />
              <img
                src={
                  get(token1, 'image') ||
                  get(
                    token1,
                    'logoURI',
                    images[isDarkMode ? 'icoUnknown' : 'icoUnknownLight']
                  )
                }
                alt=""
              />
            </div>
            <div className="pool-name__info">
              {upperCase(get(token0, 'symbol', ''))} /{' '}
              {upperCase(get(token1, 'symbol', ''))}
            </div>
          </div>
        ),
        volume24h: (
          <div className="pool-volume">
            {ReduxServices.formatPrice(volume24h)}
          </div>
        ),
        volume7d: (
          <div className="pool-volume">
            {ReduxServices.formatPrice(volume7d)}
          </div>
        ),
        lpRewardFee: (
          <div className="pool-reward">
            {ReduxServices.formatPrice(lpRewardFee24)}
          </div>
        ),
        lpRewardApr: (
          <div className="pool-reward">
            {formatNumberBro({ number: lpRewardApr, mantissa: 2 })}%
          </div>
        ),
        liquidity: (
          <div className="pool-liquidity">
            {ReduxServices.formatPrice(liquidity)}
          </div>
        )
      }
      return data
    })
    return dataPool
  }, [
    dataSourceDefaultPool,
    currentPagePool,
    currencyRedux,
    listFavoritePoolRedux
  ])

  const handleSelectedPageTopPool = (page) => {
    const { selected } = page
    // cancel
    const nextPage = selected + 1
    setCurrentPagePool(nextPage)
    getListTopPool({ page: nextPage, size: LIMIT })
  }

  const handleResize = () => {
    setWidthScreen(window.innerWidth)
  }

  useEffect(() => {
    isWindow && window.addEventListener('resize', handleResize)
  }, [])

  const handleClickPoolItem = (data) => {
    history.push(`/pool/${get(data, 'poolAddress')}`)
  }

  const renderActionTabPool = () => {
    return (
      <div className="head-actions-right">
        <div className="right-count-down-pool-table">
          <Tooltip content={t('dataWillAuto')} className='pieloading-tooltip'>
            <ProcessCircle
              radius={14}
              stroke={2}
              onReload={onReloadPie}
              onResetCountDown={onReloadPie}
              countTimeReload={countTimeReload}
            />
          </Tooltip>
        </div>
      </div>
    )
  }

  const renderSelectRight = () => {
    return (
      <div>
        <PageDropdown
          menuClassName="dropdown-menu__pool"
          options={arrSort}
          onChange={handleChangeSelect}
          defaultValue={sort.value}
          translate
        />
        {/* <Select
          suffixIcon={<span className="icon-web_arrow_down"></span>}
          getPopupContainer={onFixAntd}
          className="drop-custom"
          defaultValue={sort}
          onChange={handleChangeSelect}
        >
          {arrSort.map((item, index) => {
            return (
              <Option key={index} value={item?.value}>
                {t(item?.label)}
              </Option>
            )
          })}
        </Select> */}
      </div>
    )
  }

  return (
    <div className="board-pool">
      {!isHiddenMore && (
        <>
          <div className="board-pool__favorite">
            <FavoriteToken
              isPool
              data={listFavoritePoolRedux}
              onCLickItem={handleClickPoolItem}
            />
          </div>
          <div className="board-pool__show">
            <Row gutter={[24, 24]} justify="center">
              <Col
                xs={{
                  span: 24
                }}
                lg={{
                  span: 12
                }}
                xl={{
                  span: 8
                }}
              >
                <ContainerShow
                  isLoading={isLoadingStats}
                  isPool
                  data={poolStats.popular}
                  title="popular"
                />
              </Col>
              <Col
                xs={{
                  span: 24
                }}
                lg={{
                  span: 12
                }}
                xl={{
                  span: 8
                }}
              >
                <ContainerShow
                  isLoading={isLoadingStats}
                  data={poolStats.topVolume24h}
                  isPool
                  title="topVolume24h"
                />
              </Col>
              <Col
                xs={{
                  span: 24
                }}
                lg={{
                  span: 12
                }}
                xl={{
                  span: 8
                }}
              >
                <ContainerShow
                  isLoading={isLoadingStats}
                  isPool
                  data={poolStats.topLiquidity}
                  title="topLiquidity"
                />
              </Col>
            </Row>
          </div>
        </>
      )}

      <div className="board-pool__table">
        <TableBoard
          totalPage={totalPageTopPool}
          columns={renderColumn()}
          dataSource={renderDataSourceDefaultPool}
          renderSelectRight={renderSelectRight}
          renderActionRight={renderActionTabPool}
          title={t('topPools')}
          handleSelectedPage={handleSelectedPageTopPool}
          initialPage={currentPagePool}
          isLoading={isLoadingListPool}
          currentTabTxs={currentTabPool}
          isPools
        />
      </div>
    </div>
  )
}

export default React.memo(BoardPool)
