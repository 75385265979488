import { formatAddress, onViewOnScan } from 'common/functions'
import IconCustom from 'components/common/IconCustom'
import { get } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styles from './style.module.scss'
import { useSelector } from 'react-redux'

const InfoTokenEarn = ({ listTokenInfo = [] }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const chainActive = useSelector((state) => state.chainActive)

  const handleNavigateToInfo = (token) => (event) => {
    event.stopPropagation()
    const type = get(token, 'type', 'token')

    let url = `/${type}/${get(token, 'address')}`
    switch (type) {
    case 'farm':
      url = `/farm?poolAddress=${get(token, 'lpAddress')}&completed=${get(token, 'isCompleted')}`
      break
    default:
      break
    }
    history.push(url)
  }

  const handleViewTokenOnScan = (token) => (event) => {
    event.stopPropagation()
    const type = 'address'
    const address = get(token, 'address')

    onViewOnScan({ address, typeView: type, chain: chainActive })
  }
  return (
    <div className={styles['info-token-earn']}>
      <div className="tooltip-title text-xl color-theme">{t('address')}</div>
      <div className="info-list text-sm">
        {listTokenInfo.map((item) => (
          <div key={get(item, 'poolRewardAddress')} className="info-token">
            <div>
              <div className="info-token__symbol text-uppercase text-sm">
                {get(item, 'symbol', 'lp')}
              </div>
            </div>
            <div className='info-token--right'>
              <div className="info-token__address text-sm">
                {formatAddress(get(item, 'address'))}
              </div>
              <div className='d-flex align-items-center'>
                <IconCustom
                  onClick={handleNavigateToInfo(item)}
                  icon="web_document-upload"
                  className="info-token__icon text-l"
                />
                <IconCustom
                  className="info-token__icon text-l"
                  onClick={handleViewTokenOnScan(item)}
                  icon="web_explorer"
                />

              </div>
            </div>

          </div>
        ))}
      </div>
    </div>
  )
}

export default InfoTokenEarn
