import images from 'assets/images'
import StoreActions from 'controller/redux/actions/storeActions'
import React from 'react'
import ButtonConnected from 'components/common/ButtonConnected'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import './style.scss'
import IconCustom from 'components/common/IconCustom'
import DropdownChain from 'components/Header/DropdownChain'
function Footer ({ isHomePage, isNoFooter }) {
  const theme = useSelector((state) => state.themeRedux)
  const dispatch = useDispatch()
  const isDarkMode = theme === 'dark'
  const handleMode = () => {
    if (isDarkMode) {
      dispatch(StoreActions.setThemeRedux('light'))
    } else {
      dispatch(StoreActions.setThemeRedux('dark'))
    }
  }
  const { t } = useTranslation()
  const listFooter = [
    {
      name: 'Gecko Terminal',
      isComing: false,
      link: 'https://www.geckoterminal.com/tomochain/baryon-network-viction/pools',
      iconCustom: 'web_gecko-terminal',
      icon: images.docsFooter
    },
    {
      name: 'Docs',
      isComing: false,
      link: 'https://docs.baryon.network',
      iconCustom: 'web_doc',
      icon: images.docsFooter,
      iconLight: images.docsFooterBlack,
      iconActive: images.docsFooterActive
    },
    {
      name: 'Blog',
      isComing: false,
      iconCustom: 'web_blog',
      link: 'https://blog.baryon.network/',
      icon: images.iconBlogFt,
      iconLight: images.iconBlogFtBlack,
      iconActive: images.iconBlogFtActive
    },
    {
      name: 'Twitter',
      isComing: false,
      iconCustom: 'web_twitter-fill',
      link: 'https://baryon.link/twitter',
      icon: images.twitterFooter,
      iconLight: images.twitterFooterBlack,
      iconActive: images.twitterFooterActive
    },
    {
      name: 'Telegram',
      isComing: false,
      iconCustom: 'web_tele-full',
      link: 'https://baryon.link/telegram',
      icon: images.teleFooter,
      iconLight: images.teleFooterBlack,
      iconActive: images.teleFooterActive
    },
    {
      name: 'Discord',
      isComing: false,
      iconCustom: 'web_discord-full',
      link: 'https://baryon.link/discord',
      icon: images.discordFooter,
      iconLight: images.discordFooterBlack,
      iconActive: images.discordFooterActive
    }
    // {
    //   name: 'Brand Assets',
    //   isComing: false,
    //   link: 'https://baryon.link/branding'
    // }
  ]
  return (
    <div className={`footer ${!isHomePage && 'footer--page-detail'}`}>
      <div
        className={`footer__list ${!isHomePage && 'footer__list--page-detail'}`}
      >
        <div className="footer-left">
          <figure>
            <img
              className="logo-full"
              src={ isDarkMode ? 'https://coin98.s3.ap-southeast-1.amazonaws.com/AmberBlock/baryon_dark.svg' : 'https://coin98.s3.ap-southeast-1.amazonaws.com/AmberBlock/baryon_light.svg' }
              alt=""
            />
          </figure>
          <span>{t('copyRight')}</span>
        </div>
        <div className="footer-right">
          {listFooter.map((item, index) => (
            <a
              href={item.link}
              key={index}
              className={`item ${!item.link && 'item--disabled'}`}
              target="_blank"
              rel="noreferrer"
            >
              <div className="tooltip-suggest-footer">{item.name}</div>
              <IconCustom className={'icon-footer'} type={'default'} icon={item.iconCustom}/>
              <span className='footer-name'>
                {item.name}
              </span>

              {item.isComing && (
                <div className="tooltip-coming">
                  <div className="tooltip-coming__content">
                    <div className="trigle-up"></div>
                    Coming Soon
                  </div>
                </div>
              )}
            </a>
          ))}
        </div>
        <div className="footer-phone">
          <img
            src={images[isDarkMode ? 'iconMoon' : 'iconSun']}
            alt=""
            className="icon-theme"
            onClick={handleMode}
          />
          <div className='footer-phone--button'>
            <DropdownChain />
            <ButtonConnected/>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Footer
