import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import style from './style.module.scss'
import { useTranslation } from 'react-i18next'
import images from 'assets/images'
import SnapCard from './SnapCard'
import { Col, Row, Select } from 'antd'
import useDataSnapShot from '../hooks/useDataSnapShot'
import { getLength } from 'common/functions'
import EmptyData from 'components/common/EmptyData'
import PuffLoading from 'components/common/PuffLoading'
import VideoBox from 'components/common/VideoBox'
import BaseAPI from 'controller/api/BaseAPI'
import { useWallet } from '@coin98-com/wallet-adapter-react'
import DropdownChainFilterChain from 'components/common/DropdownFilterChain'
const { Option } = Select

const SnapshotScreen = () => {
  const onFixAntd = (target) => target.parentNode
  const { t } = useTranslation()
  const { address } = useWallet()

  const [totalSnapshot, setTotalSnapshot] = useState({})

  useEffect(() => {
    document.title = 'Snapshot | Baryon'
  })

  useEffect(() => {
    loadTotalSnapshot()
  }, [address])

  // data
  const {
    data,
    isLoading,
    handleSetStatus,
    handleChangeSelect,
    setFilterChain,
    status,
    arrStatus,
    arrSort

  } = useDataSnapShot()

  const isEmpty = getLength(data) === 0
  const renderContent = () => {
    if (isLoading) {
      return <PuffLoading isCenter className='puff-loading'/>
    }
    if (isEmpty) {
      return <EmptyData classNames={'custom-empty'} />
    }
    return (
      <>
        {data.map((item) => {
          return (
            <>
              <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 8 }}>
                <SnapCard key={item.id} item={item} address={address} />
              </Col>
            </>
          )
        })}
      </>
    )
  }
  useEffect(() => {
    const el = document.getElementById('prevent-img')
    if (el) {
      el.addEventListener('contextmenu', (e) => {
        e.preventDefault()
      })
    }
  }, [])

  // handle
  const renderStatus = () => {
    return arrStatus.map((item, index) => {
      const isActive = item.value === status
      const total = totalSnapshot[item.name === 'all' ? 'total' : item.name] || 0
      return (
        <li
          key={index}
          className={isActive ? 'active' : ''}
          onClick={handleSetStatus(item.value)}
        >
          <div className="nav-item">
            <span>{t(item.name)}</span>
            <span className='nav-item__total'>
              {'(' + total + ')'}
            </span>
          </div>
        </li>
      )
    })
  }

  const loadTotalSnapshot = async () => {
    const res = await BaseAPI.getData('snapshot/campaign/statistical', { address: address, from: process.env.REACT_APP_SOURCE_API })
    if (res) {
      setTotalSnapshot(res)
    } else {
      setTotalSnapshot({})
    }
  }

  return (
    <div className={cn(style['snap-wrapper'])}>
      {/* <CustomTable/> */}
      <div className="snap-container">
        <div className="snap-banner">
          <div className="snap-banner--left">
            <h2 className="lx-regular">{t('activityCampaigns')}</h2>
            <p>{t('participateIn')}</p>
          </div>
          <div className="snap-banner--right" >
            <VideoBox className={'right-video'} src={images.bannerSnapWebm}/>
            <figure>
              <img src={images.bannerSnapGit} className="img-fluid" alt="" />
            </figure>
          </div>
          <figure className="background-cover" id='prevent-img'>
            <img src={images.baryonMain} className="img-fluid" alt="" />
          </figure>
        </div>
        <div className="snap-content">
          <div className="content-nav">
            <div className="nav-left">
              <ul>{renderStatus()}</ul>
              {/* <div className="nav-left--switch">
                <SwitchButton onChange={handleSetStatus}/>
                {t('participated')}
              </div> */}
            </div>
            <div className="nav-right">
              <DropdownChainFilterChain onSelectChain={setFilterChain}/>
              <Select
                suffixIcon={<span className="icon-web_arrow_down"></span>}
                getPopupContainer={onFixAntd}
                className="drop-custom"
                defaultValue={t('earliest')}
                onChange={handleChangeSelect}
              >
                {arrSort.map((item, index) => {
                  return (
                    <Option key={index} value={item.value}>
                      {t(item.name)}
                    </Option>
                  )
                })}
              </Select>
            </div>
          </div>
          <div className="content-main">
            <Row gutter={[24, 24]}>{renderContent()}</Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SnapshotScreen
