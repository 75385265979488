import images from 'assets/images'
import { upperCaseFirstLetter } from 'common/functions'
import AboutToken from 'components/common/AboutToken'
import get from 'lodash/get'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'

const NotiItem = ({ data = {} }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.notiItemContainer}>
      <div className='mr-2'>
        <AboutToken imgToken={images.logoSingal} className="img-24 rounded-circle mr-2 mt-1"/>
      </div>
      <div className='noti-info'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='color-theme noti-info__title'>{t(data.type)}</div>
          <div className='text-xs color-normal'>{moment(data.createdAt).fromNow()}</div>
        </div>
        <div className='text-xs color-normal'>
          {upperCaseFirstLetter(get(data, 'message', ''))}
        </div>
      </div>
    </div>
  )
}

export default NotiItem
