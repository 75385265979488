import React from 'react'
import './style.scss'
import { BounceLoader } from 'react-spinners'

const Button = ({
  children,
  isDisable,
  isLoading,
  isFullWidth,
  className,
  type = 'default' ||
    'grey' ||
    'gradient' ||
    'red' ||
    'border' ||
    'border-grey' ||
    'cancel',
  ...props
}) => {
  return (
    <button
      disabled={isDisable || isLoading}
      className={`button-wr button-wr--${type} ${
        isDisable || isLoading ? 'button-wr--disabled' : ''
      } ${isFullWidth ? 'button-wr--full-width' : ''} ${className}`}
      {...props}
    >
      {isLoading ? <BounceLoader size={16} color="#bbbbbb" /> : children}
    </button>
  )
}

export default Button
