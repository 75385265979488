import React, { useEffect, useRef } from 'react'
import cn from 'classnames'
import style from './style.module.scss'
import AboutToken from 'components/common/AboutToken'
import { getLength } from 'common/functions'
import IconCustom from 'components/common/IconCustom'
import useFavoriteToken from 'common/customHooks/useFavoriteToken'
import { get } from 'lodash'
import useFavoritePool from 'common/customHooks/useFavoritePool'

const dataFavorite = [...Array(17).fill(1).map((item, index) => {
  return {
    icon: <AboutToken/>,
    symbol: 'C98',
    name: 'Coin98',
    icon2: <AboutToken/>,
    symbol2: 'C98'
    // href: '#'
  }
})]

const FavoriteToken = ({ isPool, data = dataFavorite, className, onCLickItem, isModal }) => {
  const ref = useRef()

  const lastPosition = useRef(0)
  const { onChangeListTokenFavor } = useFavoriteToken()
  const { onChangeListPoolFavor } = useFavoritePool()

  const onRemoveFavorite = (item) => (e) => {
    e.stopPropagation()
    if (isPool) {
      return onChangeListPoolFavor(item)
    }
    return onChangeListTokenFavor(get(item, 'address'))
  }

  const Div = ({ children, ...props }) => {
    return <div {...props}>{children}</div>
  }

  useEffect(() => {
    const evenListener = () => {
      const current = ref.current.scrollTop
      const lenghtData = getLength(data)
      if (current > lastPosition.current && lenghtData > 8) {
        ref.current.classList.add('sticky-header')
      } else {
        ref.current.classList.remove('sticky-header')
      }
    }
    ref.current.addEventListener('scroll', evenListener)
    return () => {
      ref.current.removeEventListener('scroll', evenListener)
    }
  }, [ref, JSON.stringify(data)])

  const handleClickToken = (data) => (e) => {
    // history.push(isPool ? `/pool/${get(data, 'poolAddress')}` : `/token/${get(data, 'address')}`)
    onCLickItem && onCLickItem(data)
  }
  return (
    <div className={cn(style['favorite-token'], className)} ref={ref}>
      {data.map((item, index) => {
        // const Render = item?.href ? Link : Div
        return (
          <div onClick={handleClickToken(item)} className='favorite-token__item' key={index}>
            {isPool
              ? <>
                <div className='item-bold'>
                  <AboutToken imgToken={get(item, 'info.token0.image') || get(item, 'info.token0.logoURI')} type={'default'}/>
                  <AboutToken imgToken={get(item, 'info.token1.image')} type={'default'}/>
                </div>
                <span className='text-uppercase'>
                  {get(item, 'info.token0.symbol')}/{get(item, 'info.token1.symbol')}
                </span>
              </>
              : <>
                <div className={cn('d-flex align-items-center')}>
                  <div>
                    <AboutToken imgToken={get(item, 'image')} type={'default'}/>
                  </div>
                  {!isModal && <span className='ml-2 text-nowrap'>
                    {get(item, 'name')}
                  </span>}
                </div>
                <span className={cn('text-uppercase text-content', isModal && 'modal-symbol')}>
                  {get(item, 'symbol')}
                </span>

              </>}
            <IconCustom onClick={onRemoveFavorite(item)} className='close-custom' icon='web_close_circle_bold'/>
          </div>

        )
      })}
    </div>
  )
}

export default React.memo(FavoriteToken)
