import React, { useState, useRef, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import cn from 'classnames'
import style from './style.module.scss'
const BannerImage = ({ className, src }) => {
  const [isLoad, setIsLoad] = useState(false)

  const imgRef = useRef()

  const onFinishLoad = () => {
    setIsLoad(true)
  }

  return (
    <figure className={cn(style['img-share'], className)}>
      {
        !isLoad &&
        <Skeleton
        />
      }
      <img ref={imgRef} className={!isLoad ? 'showImage' : 'hideImage'} src={src} alt="" onLoad={onFinishLoad} />
    </figure>
  )
}

export default BannerImage
