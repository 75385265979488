
import React from 'react'
import { useDispatch } from 'react-redux'
import ConfirmMatrix from './ConfirmMatrix'
import './style.scss'
import { useTranslation } from 'react-i18next'
import StoreActions from 'controller/redux/actions/storeActions'
import ReduxServices from 'common/redux'
import get from 'lodash/get'

const BoxLockMatrix = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onConfirm = async (arrPassword) => {
    const response = await window.coin98?.provider?.request({
      method: 'compare_passwords',
      params: { type: 'matrix', password: arrPassword }
    })

    const session = get(response, 'session')
    const tryNum = get(response, 'tryNum')

    if (session) {
      dispatch(StoreActions.setSession(true))
      window.closeModalFirst()
    } else {
      if (tryNum < 3) {
        window.openModal({
          type: 'error',
          title: t('wrongPass'),
          content:
            tryNum === 2
              ? <div
                dangerouslySetInnerHTML={{
                  __html: t('onlyOneChanceReset')
                }}
              />
              : t('tryLeft', { num: 3 - (tryNum + 1) })
        })
      } else {
        window.openModalFirst({
          type: 'error',
          title: t('walletHasBeenReset'),
          content: t('walletHasBeenReset')
        })
        ReduxServices.resetRedux()
      }
    }
  }

  return (
    <div className='box-lock-matrix'>
      <ConfirmMatrix onConfirm={onConfirm} isValidate/>
    </div>
  )
}

export default BoxLockMatrix
