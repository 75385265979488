import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import style from './style.module.scss'
import IconCustom from 'components/common/IconCustom'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { Col, Row } from 'antd'
import AboutToken from 'components/common/AboutToken'
import { formatAddress, nFormatter } from 'common/functions'
import RechartLine from 'components/RechartLine'
import BaseAdapter from 'controller/api/BaseAdapter'
import { get } from 'lodash'
import moment from 'moment'
import ReduxServices from 'common/redux'
import PageDropdown from 'components/common/PageDropdown'
import useScreenWidth from 'common/customHooks/useScreenWidth'
import { usePoolInfo } from 'pages/PoolInfoScreen/context/PoolInfoContext'

const defaultLPInfo = {
  value: null, label: 'All Liquidity Share'
}
const InfoWallet = () => {
  const { address } = useParams()
  if (!address) return null
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()

  const [dayRequest, setDayRequest] = useState(1)
  const [dataChart, setDataChart] = useState([])
  const [walletInfo, setWalletInfo] = useState({})
  const [listLPInfo, setListLPInfo] = useState([])
  const { lpInfo, setLPInfo, setTemp } = usePoolInfo()
  const { width } = useScreenWidth()
  const isMobile = width < 576

  useEffect(() => {
    loadDataChart()
  }, [address, dayRequest, lpInfo.value])

  useEffect(() => {
    setTemp(listLPInfo)
  }, [JSON.stringify(listLPInfo)])

  useEffect(() => {
    loadListLPInfo()
  }, [address])

  useEffect(() => {
    loadWalletInfo()
  }, [])

  const loadDataChart = async () => {
    setIsLoading(true)
    const queryBody = {
      address: address
    }
    if (lpInfo.value) {
      queryBody.poolAddress = lpInfo.value
    }
    const response = await BaseAdapter.getData('baryon/wallet/tvl/chart', queryBody)
    if (response && response.success) {
      const data = get(response, 'data')
      const newdataChart = data.map(item => ({
        date: moment(item.time).unix(),
        number: item.value
      }))
      setDataChart(newdataChart)
    }

    setIsLoading(false)
  }

  const loadWalletInfo = async () => {
    const response = await BaseAdapter.getData('baryon/wallet/address/info', {
      address: address
    })

    if (response && response.success) {
      setWalletInfo(response.data)
    }
  }

  const loadListLPInfo = async () => {
    const response = await BaseAdapter.getData('baryon/wallet/address/lp', {
      address: address
    })

    if (response && response.success) {
      const listPoolData = get(response, 'data', [])
      const listPoolDropdown = listPoolData.map(item => {
        const token0 = get(item, 'token0', {})
        const token1 = get(item, 'token1', {})
        return {
          value: get(item, 'poolAddress'),
          label: (
            <div className='d-flex align-items-center text-uppercase'>
              <AboutToken imgToken={get(token0, 'image') || token0?.logoURI} alt="" className="token-img token-img--token-1 img-24 mr-1" />
              <AboutToken imgToken={get(token1, 'image') || token1?.logoURI} alt="" className="token-img token-img--token-2 img-24 mr-2" />
              <span>{get(token0, 'symbol')} / { get(token1, 'symbol')}</span>
            </div>
          )
        }
      })
      const updateList = [defaultLPInfo].concat(listPoolDropdown)
      setListLPInfo(updateList)
    }
  }

  const backTo = () => {
    history.goBack()
  }

  const onChangeLPAddress = (data) => {
    setLPInfo(data)
  }

  return (
    <div className={cn(style['info-wallet'])}>
      <div className="back-to" onClick={backTo}>
        <IconCustom icon="web_arrow_left" type={'default'} />
        <span>{t('backToWallet')}</span>
      </div>
      <div className="wallet-head">
        <span>{isMobile ? formatAddress(address, 10) : address}</span>
        <PageDropdown
          className="right-toggle-down"
          toggleclassName="wallet-toggle"
          menuClassName="right-menu-down"
          options={listLPInfo}
          defaultValue={lpInfo}
          onChange={onChangeLPAddress}
        />
      </div>
      <div className="wallet-chart">
        <Row gutter={[24, 24]}>
          <Col xs={{ span: 24 }} md={{ span: 6 }}>
            <div className="chart-info chart-item">
              <div className="chart-info__item">
                <div className="item-total">
                  {ReduxServices.formatPrice(walletInfo.tvl, true)}
                </div>
                <span>{t('totalValueLocked')}</span>
              </div>
              <div className="chart-info__item">
                <div className="item-total">{nFormatter(walletInfo.poolFarming)}</div>
                <span>{t('poolFarming')}</span>
              </div>
              <div className="chart-info__item">
                <div className="item-total">{nFormatter(walletInfo.poolStaked)}</div>
                <span>{t('poolStaked')}</span>
              </div>
            </div>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 18 }}>
            <div className="chart-main chart-item">
              <RechartLine height="40rem" dataChart={dataChart} ideTick={true} title="TVL" />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default InfoWallet
