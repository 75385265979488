import get from 'lodash/get'
import { convertWeiToBalance, getItemStorage, getLength, lowerCase, setItemStorage } from './functions'
import SupportAPI from 'controller/api/SupportAPI'
import BaseAPI from 'controller/api/BaseAPI'
import { chainType, CHAIN_DATA, COIN_IMAGE, chainSupportBaryon } from './constants'
import { genContract, genWeb3, getTokenInfo } from 'controller/Library/evm'
import C98Balances from './abi/C98Balances'
import { KEY_STORE } from './constants/keystore'
import ERC20 from './abi/ERC20'
import { MAIN_COIN_AMM, W_MAIN_COIN_TOMO_OLD } from './poolEvm/constants'

const CHAIN_DATA_VALUES = Object.values(CHAIN_DATA)
export const SETTING_LOCAL = CHAIN_DATA_VALUES.filter(itm => itm.rpcURL).reduce((a, v) => ({ ...a, [v.chain]: v.rpcURL }), {})
export const WEB3_CHAIN = CHAIN_DATA_VALUES.filter(itm => itm.isWeb3).map(itm => itm.chain)
export const COSMOS_CHAIN = CHAIN_DATA_VALUES.filter(itm => itm.isCosmos).map(itm => itm.chain)

export default class WalletServices {
  constructor (props) {
    this.setting = SETTING_LOCAL
    this.coinLocal = {}

    this.checkIsReady = this.checkIsReady.bind(this)
    this.fetchSetting = this.fetchSetting.bind(this)
    this.fetchInfoCoin = this.fetchInfoCoin.bind(this)
  }

  async checkIsReady () {
    try {
      await this.fetchSetting()

      WEB3_CHAIN.map(item => {
        const web3Only = genWeb3(item)
        this['web3' + item] = web3Only

        if (CHAIN_DATA[item]?.balances) {
          this['contractBalance' + item] = new web3Only.eth.Contract(C98Balances, CHAIN_DATA[item].balances)
        }
      })

      await this.refreshCoinData()
      return true
    } catch (error) {
      return false
    }
  }

  async fetchSetting () {
    // return new Promise(async (resolve) => {
    //   try {
    //     setTimeout(() => {
    //       resolve()
    //     }, 1500)
    //     const response = await SupportAPI.getSetting()
    //     if (response) {
    //       this.setting = response
    //     }
    //     resolve()
    //   } catch (error) {
    //     resolve()
    //   }
    // })
  }

  findSetting (chain) {
    return this.setting[chain]
  }

  findCoinLocal (chain, address) {
    const listCoin = this.coinLocal[chain] || []
    return listCoin.find(item => lowerCase(item.address) === lowerCase(address))
  }

  fetchInfoCoin = async (chain, address) => {
    if ((lowerCase(address) === lowerCase(MAIN_COIN_AMM[chain]?.symbol)) || (lowerCase(address) === lowerCase(MAIN_COIN_AMM[chain]?.address))) {
      return MAIN_COIN_AMM[chain]
    }

    let info = this.findCoinLocal(chain, address)

    if (!info) {
      info = await getTokenInfo(address, chain)
    }

    return info
  }

  getCoinFetch (chain) {
    return (chain ? this.coinLocal[chain] : this.coinLocal) || []
  }

  async getListLp (chain, address, listAddressLp) {
    if (this['contractBalance' + chain]) {
      const result = await this.getBalancesLpContract(chain, address, listAddressLp)
      return result
    } else {
      return []
    }
  }

  async fetchAssetServices (chainId, address) {
    const response = await this.call(`https://balances.coin98.com/assets/${chainId}/${address}`)
    return response
  }

  async call (url) {
    const response = await fetch(url)
    return await response.json()
  }

  async getBalancesLpContract (chain, address, listAddressLp) {
    return new Promise(async (resolve, reject) => {
      try {
        const fetchSplit = async () => {
          return this['contractBalance' + chain].methods.balances([address], listAddressLp.slice()).call().then(resultData => {
            const mapData = resultData.map((item, index) => {
              const formatBalance = parseFloat(item)
              if (formatBalance > 0.00000099) {
                return {
                  balance: convertWeiToBalance(formatBalance),
                  address: listAddressLp[index]
                }
              } else {
                return null
              }
            })

            return mapData.filter(item => item)
          }).catch((er) => {
            return []
          })
        }

        const data = await fetchSplit()
        resolve(data)
      } catch (error) {
        resolve([])
      }
    })
  }

  async getDataTokens (chain, address, listToken) {
    if (chain === chainType.ether) {
      const result = await BaseAPI.getData(`fetchEther/${address}`)
      return result ? result.tokens : []
    } else {
      if (this['contractBalance' + chain]) {
        const result = await this.getWeb3BalancesContract(chain, address, listToken)
        return result
      } else {
        return []
      }
    }
  }

  async getWeb3BalancesContract (chain, address, listToken) {
    if (!address) return []
    return new Promise(async (resolve, reject) => {
      try {
        const tokenData = listToken || (this.coinLocal[chain] ? this.coinLocal[chain].filter((item) => getLength(item.address) > 0) : [])
        const addressChain = tokenData.map((item) => item.address.trim())

        const fetchSplit = async (start) => {
          const sliceTokenData = tokenData.slice(start, start + 500)
          return this['contractBalance' + chain].methods.balances([address], addressChain.slice(start, start + 500)).call().then(resultData => {
            const mapData = resultData.map((item, index) => {
              const formatBalance = parseFloat(item)
              if (formatBalance > 0) {
                const tokenCheck = sliceTokenData[index]

                const decimals = tokenCheck.decimal || tokenCheck.decimals

                return {
                  balance: convertWeiToBalance(formatBalance, decimals),
                  tokenInfo: {
                    cgkId: tokenCheck.cgkId || tokenCheck.id,
                    address: tokenCheck.address,
                    symbol: tokenCheck.symbol,
                    name: tokenCheck.name,
                    decimals,
                    image: tokenCheck.image
                  }
                }
              } else {
                return null
              }
            })

            return mapData.filter(item => item)
          }).catch((er) => {
            return []
          })
        }
        const fullResponse = []

        for (let i = 0; i < addressChain.length; i += 500) {
          const data = await fetchSplit(i)
          Array.prototype.push.apply(fullResponse, data)
        }
        resolve(fullResponse)
      } catch (error) {
        console.log('🚀 ~ file: WalletServices.js ~ line 222 ~ WalletServices ~ returnnewPromise ~ error', error)
        resolve([])
      }
    })
  }

  async refreshCoinData (isReloadNew) {
    return new Promise(async (resolve, reject) => {
      const oldDataLocal = await getItemStorage(KEY_STORE.LIST_TOKEN_CUSTOM)

      const mergeLocal = (tokenList) => {
        oldDataLocal.map(item => {
          tokenList[item.chain].push(item)
          return item
        })
      }

      if (!isReloadNew) {
        const oldData = await getItemStorage('coinDataLocal')
        if (oldData) {
          if (getLength(oldDataLocal) > 0) {
            mergeLocal(oldData)
          }
          this.coinLocal = oldData
          resolve()
        }
      }

      const response = await SupportAPI.getCoinLocal('coinLocal')

      if (response) {
        const newResponse = Object.fromEntries(
          chainSupportBaryon.map(item => [item.chain, response[item.chain]])
        )

        setItemStorage(newResponse, 'coinDataLocal')
        if (getLength(oldDataLocal) > 0) {
          mergeLocal(newResponse)
        }
        this.coinLocal = newResponse

        resolve()
      }
    })
  }

  async fetchTokenBalance (contractAddress, address, decimalToken, chain, solTokenAddress, chainCustom) {
    return new Promise(async (resolve, reject) => {
      const chainSelected = CHAIN_DATA[chain]

      if (chain === chainType.tron) {
        if (this.tronClient.isAddress(contractAddress)) {
          this.tronClient.contract().at(contractAddress).then(contract => {
            contract.balanceOf(address).call().then(balance => {
              const tokenBalance = convertWeiToBalance(balance, decimalToken)
              resolve(tokenBalance)
            }).catch(() => resolve(0))
          }).catch(() => resolve(0))
        } else {
          const response = await fetch(`https://api.trongrid.io/v1/accounts/${address}`)
          const fullData = await response.json()
          if (fullData && getLength(fullData.data) > 0) {
            const trc10Tokens = fullData.data[0].assetV2
            const trc10Balance = trc10Tokens.find(it => it.key === contractAddress)

            if (trc10Balance) {
              resolve(convertWeiToBalance(trc10Balance.value, decimalToken))
            } else {
              resolve(0)
            }
          }
        }
        //  else if (chain === chainType.near) {
      //   const tokenBalance = await getNearTokenBalance(contractAddress, address)
      //   resolve(convertWeiToBalance(tokenBalance, decimalToken))
      // }
      } else if (chain === chainType.solana) {
        resolve(0)
      } else if (chain === chainType.kardia) {
        const tokenBalance = await this.kardiaClient.getTokenBalance({ contractAddress, address, decimalToken })
        resolve(tokenBalance)
      } else if (get(chainSelected, 'isLibrary')) {
        const tokenBalance = await this[chainSelected.chain + 'Client'].getBalanceToken(address, contractAddress, decimalToken)
        resolve(tokenBalance)
      } else {
        try {
          const chainGenerate = chainCustom || chain
          const web3 = this['web3' + chainGenerate]

          if (!web3) {
            this['web3' + chainGenerate] = genWeb3(chain, false, chainCustom)
          }

          const contract = genContract(web3, ERC20, contractAddress)

          contract.methods.balanceOf(address).call().then(balance => {
            if (decimalToken) {
              const tokenBalance = convertWeiToBalance(balance, decimalToken)
              resolve(tokenBalance)
            } else {
              contract.methods.decimals().call().then(decimal => {
                const tokenBalance = convertWeiToBalance(balance, decimal)
                resolve(tokenBalance)
              }).catch(() => {
                const tokenBalance = convertWeiToBalance(balance, 18)
                resolve(tokenBalance)
              })
            }
          }).catch(() => {
            resolve(0)
          })
        } catch (err) {
          resolve(0)
        }
      }
    })
  }

  async fetchMainBalance (address, chain = chainType.tomo, bnbId, denom, isReloadHome, wallet) {
    return new Promise(async (resolve, reject) => {
      try {
        if (wallet.chainCustom) {
          return this['web3' + wallet.chainCustom].eth.getBalance(address).then(async (response) => {
            resolve(convertWeiToBalance(response))
          }).catch(() => {
            resolve(0)
          })
        } else if (chain === chainType.kardia) {
          try {
            const balance = await this.kardiaClient.getBalance(address)
            resolve(balance)
          } catch (e) {
            resolve(0)
          }
        } else if (WEB3_CHAIN.includes(chain)) {
          this['web3' + chain].eth.getBalance(address).then(async (response) => {
            resolve(convertWeiToBalance(response))
          }).catch((errr) => {
            resolve(0)
          })
        } else if (chain === chainType.polkadot || chain === chainType.kusama) {
          // const balancePol = await MarketScan.getPolkadotChainBalance(chain, address)
          // resolve(balancePol || 0)
          resolve(0)
        } else if (chain === chainType.tron) {
          this.tronClient.trx.getBalance(address)
            .then(result => resolve(convertWeiToBalance(result, 6)))
            .catch(() => resolve(0))
        } else if (chain === chainType.solana) {
          resolve(0)
        } else if (COSMOS_CHAIN.includes(chain)) {
          // const balance = await this['cosmos' + chain].getBalance({ address, asset: denom, bnbId })
          resolve(0)
        } else if (chain === chainType.near) {
          // resolve(await getNearBalance(address))
        } else if (chain === chainType.avaxX) {
          // const findWallet = wallet || ReduxServices.findWalletByAddress(address, chainType.avaxX)
          // if (findWallet) {
          //   await this.avaxClient.updateSeed(findWallet.mnemonic)
          // }
          // const balance = await this.avaxClient.getBalance()
          resolve(0)
        }
      } catch (error) {
        // clog('Get balance err ', error, chain)
        resolve(0)
      }
    })
  }

  validateBlockChainAddress = (address, chain = chainType.ether) => {
    const chainSelected = CHAIN_DATA[chain]
    if (chain === chainType.tron) {
      const reg = /^T[1-9A-HJ-NP-Za-km-z]{33}$/
      return reg.test(address)
    } else if (get(chainSelected, 'isCosmos')) {
      return address.startsWith(chainSelected.prefix) && (parseInt(getLength(address)) - parseInt(getLength(chainSelected.prefix))) === 39
    } else if (chain === chainType.near || chain === chainType.avaxX) {
      return true
    } else if (get(chainSelected, 'isPolkadot')) {
      // return validateAddressPolkadot(address)
      return true
    } else {
      const reg = /^(0x)[0-9A-Fa-f]{40}$/
      return reg.test(address)
    }
  }
}
