import React from 'react'
import { lowerCase } from 'common/functions'
import BaseAdapter from 'controller/api/BaseAdapter'
import StoreActions from 'controller/redux/actions/storeActions'
import cloneDeep from 'lodash/cloneDeep'
import { useSelector, useDispatch } from 'react-redux'
import { TYPE_FAVORITE_TOKEN } from 'common/constants'

const useFavoriteToken = () => {
  const dispatch = useDispatch()
  const listFavoriteTokenRedux = useSelector((state) => state.listFavoriteTokenRedux) || []
  const chainActive = useSelector((state) => state.chainActive)

  const onChangeListTokenFavor = async (mintAddress) => {
    let listFavoriteClone = cloneDeep(listFavoriteTokenRedux)
    const findTokenAddress = listFavoriteClone.find(
      (item) => lowerCase(item) === lowerCase(mintAddress)
    )
    if (findTokenAddress || (findTokenAddress === '')) {
      listFavoriteClone = listFavoriteClone.filter(
        (item) => lowerCase(item) !== lowerCase(findTokenAddress)
      )
    } else {
      listFavoriteClone.push(mintAddress)
    }

    const res = await BaseAdapter.postData('favorite/take', {
      type: TYPE_FAVORITE_TOKEN[chainActive],
      bonusValue: listFavoriteClone
    })
    if (res && res.success) {
      dispatch(StoreActions.setListFavoriteTokenRedux(listFavoriteClone))
    }
  }
  return {
    onChangeListTokenFavor
  }
}

export default useFavoriteToken
