
import ERC20 from 'common/abi/ERC20'
import { useEffect, useState, useMemo } from 'react'
import { MAIN_COIN_AMM, PERMIT_HELPER } from '../constants'
import { useWallet } from '@coin98-com/wallet-adapter-react'
import useServices from 'controller/Library/useServices'
import KAP20 from 'common/abi/KAP20'
import { chainType } from 'common/constants'
import { convertDecimalToHex, convertHexToDecimal } from 'common/functions'

const { genWeb3, genContract, generateApprovedData, estimateGasTxs, generatePermitData, createPermit, unlimitedHex, encodeMessErr, zeroAddress } = require('controller/Library/evm')
// eslint-disable-next-line no-loss-of-precision
const unlimit = 115792089237316195423570985008687907853

const useApprove = (chain, contractAddress, owner, spender, isMainCoin) => {
  const [payload, setPayload] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const { sendRawTxn } = useServices()
  const { address, wallet } = useWallet()

  const isCoin98 = wallet?.adapter?.name === 'Coin98'

  const web3 = useMemo(() => {
    return genWeb3(chain)
  }, [chain])

  useEffect(() => {
    load()
  })

  const load = async () => {
    if ((!contractAddress && !isMainCoin) || !chain || !owner || !spender) return setPayload(null)

    if ((contractAddress === MAIN_COIN_AMM[chain].address) || (contractAddress === zeroAddress)) return setPayload(unlimit)
    const contract = genContract(web3, ERC20, contractAddress)
    try {
      const res = await contract.methods.allowance(owner, spender).call()
      setPayload(res)
    } catch (error) {
      const contract = genContract(web3, KAP20, contractAddress)
      const res = await contract.methods.allowances(owner, spender).call()
      setPayload(res)
    }
  }

  const onApprove = async (callBack, numApprove, callBackFail) => {
    // numApprove === undefined ---> Unlimited
    setLoading(true)
    // const dataTxs = generateApprovedData(web3, contractAddress, spender, numApprove)
    let dataTxs, isPermit, newErr

    if (chain === chainType.tomo) {
      const number = numApprove ? ((numApprove.startsWith && numApprove.startsWith('0x')) ? numApprove : convertDecimalToHex(numApprove)) : convertHexToDecimal(unlimitedHex)
      await createPermit({ chain, tokenAddress: contractAddress, ownerAddress: owner, spenderAddress: spender, amount: number, isCoin98 })
        .then(result => {
          dataTxs = generatePermitData(web3, contractAddress, spender, number, result, owner)
          isPermit = true
        })
        .catch(err => {
          if (encodeMessErr(err) === 'User rejected signing') {
            newErr = err
            return
          }
          dataTxs = generateApprovedData(web3, contractAddress, spender, number)
        })
    } else {
      dataTxs = generateApprovedData(web3, contractAddress, spender, numApprove)
    }

    if (!dataTxs) {
      setLoading(false)
      callBackFail && callBackFail(newErr)
      return
    }

    const generateTxs = {
      to: isPermit ? PERMIT_HELPER : contractAddress,
      data: dataTxs,
      callBack,
      callBackFail,
      isWaitDone: true
    }
    await sendRawTxn(generateTxs)
    setLoading(false)
  }

  const onEstimateGasApprove = async (callBackFail) => {
    const dataTxs = generateApprovedData(web3, contractAddress, spender)
    const generateTxs = {
      from: address,
      to: contractAddress,
      data: dataTxs
    }

    const gasLimitEst = await estimateGasTxs(generateTxs, web3, chain, null, null, callBackFail)
    return gasLimitEst
  }

  return {
    isLoading,
    payload,
    onApprove,
    onEstimateGasApprove
  }
}

export default useApprove
