import images from 'assets/images'
import { convertWeiToBalance, formatNumberBro, nFormatter, upperCase } from 'common/functions'
import AboutToken from 'components/common/AboutToken'
import IconCustom from 'components/common/IconCustom'
import { get } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { BounceLoader } from 'react-spinners'
import './style.scss'

const TotalInfo = ({ fromToken, toToken, isLoading, scanLp }) => {
  const { t } = useTranslation()
  const isDarkMode = useSelector(state => state.isDarkMode) === 'dark'
  const fromIcon = useMemo(() => {
    return get(fromToken, 'tokenInfo.image', '') || get(fromToken, 'tokenInfo.logoURI', images[isDarkMode ? 'icoUnknown' : 'icoUnknownLight'])
  }, [fromToken])

  const toIcon = useMemo(() => {
    return get(toToken, 'tokenInfo.image', '') || get(toToken, 'tokenInfo.logoURI', images[isDarkMode ? 'icoUnknown' : 'icoUnknownLight'])
  }, [toToken])

  const fromSymbol = useMemo(() => {
    return upperCase(get(fromToken, 'tokenInfo.symbol', ''))
  }, [fromToken])

  const toSymbol = useMemo(() => {
    return upperCase(get(toToken, 'tokenInfo.symbol', ''))
  }, [toToken])

  const totalAmountToken0 = get(fromToken, 'totalBalancePool', 0)
  const totalAmountToken1 = get(toToken, 'totalBalancePool', 0)

  return (
    <div className="total-info">
      <div className="total-info__title text-content">
        <span>
          {t('totalTokenLocked')}
        </span>
        <a href={scanLp} target="_blank" rel="noreferrer">
          <IconCustom type={'none'} icon='web_new-scan'/>
        </a>
      </div>
      <div className="total-info__item">
        <div className="item-icon">
          <div className='item-icon__left d-flex justify-center align-items-center gap-3'>
            <AboutToken imgToken={fromIcon} />
            <span>
              {fromSymbol}
            </span>
          </div>
          <span>{isLoading ? <div className='bounce-load'> <BounceLoader size={16} color="#bbbbbb" /> </div> : nFormatter(totalAmountToken0)}</span>
        </div>
        <div className="item-icon">
          <div className='item-icon__left d-flex justify-center align-items-center gap-3'>
            <AboutToken imgToken={toIcon} />
            <span>
              {toSymbol}
            </span>
          </div>
          <span>{ isLoading ? <div className='bounce-load'> <BounceLoader size={16} color="#bbbbbb" /> </div> : nFormatter(totalAmountToken1)}</span>
        </div>
      </div>
    </div>
  )
}

export default TotalInfo
