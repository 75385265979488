import React from 'react'

import cn from 'classnames'
// import { Tooltip } from 'antd'
import style from './style.module.scss'
import { useTranslation } from 'react-i18next'
import Tooltip from 'components/Tooltip'
import IconCustom from 'components/common/IconCustom'

const SnapSocial = ({ dataSocial, className }) => {
  const { t } = useTranslation()

  const handleClick = (item) => (e) => {
    e.preventDefault()
    window.open(item?.link)
  }
  return (
    <div className={cn(style['snap-social'], `${className}`)}>
      <ul>
        { dataSocial.map((item, i) => {
          return (
            <li key={i}>
              {/* <Tooltip title={t(item?.tooltip)} placement="bottom"> */}
              <a onClick={handleClick(item)} href={item?.link} target='_blank' rel="noreferrer">
                <Tooltip content={t(item?.tooltip)} className='tooltip-share-component'>
                  <IconCustom type={'default'} icon={item?.icon}/>
                </Tooltip>
              </a>
              {/* </Tooltip> */}
            </li>
          )
        })}
      </ul>
    </div>
  )
}
export default SnapSocial
