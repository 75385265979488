import React from 'react'
import style from './style.module.scss'
import AboutToken from 'components/common/AboutToken'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import StatusItem from 'components/common/StatusItem'
import Button from 'components/common/Button'
import images from 'assets/images'
import TableSnap from './TableSnap'
import SnapSocial from '../SnapshotScreen/SnapSocial'
import { useParams } from 'react-router-dom'
import useDataDetailSnapShot from '../hooks/useDataDetailSnapShot'
import {
  convertDateMoment,
  formatBilion,
  formatPrice,
  getLength,
  showNotificationToast
} from 'common/functions'
import get from 'lodash/get'
import useListJoin from '../hooks/useListJoin'
import ModalSnapJoin from '../SnapshotScreen/ModalSnapJoin'
import BaseAPI from 'controller/api/BaseAPI'
import { sizeSnapShot } from '../hooks/useDataSnapShot'
import { useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import IconCustom from 'components/common/IconCustom'
import ShareModal from 'components/common/ShareModal'
import Tooltip from 'components/Tooltip'
import { useWallet, WALLETS_NAME } from '@coin98-com/wallet-adapter-react'
import { CHAIN_DATA, isDapp } from 'common/constants'
import { useWalletModal } from '@coin98-com/wallet-adapter-react-ui'

const SnapshotDetail = () => {
  const { t } = useTranslation()
  const isDarkMode = useSelector((state) => state.themeRedux) === 'dark'
  const { id } = useParams()
  const { item, isJoined, loadDataJoin, isLoading } = useDataDetailSnapShot(id)
  const {
    isLoading: isLoadingList,
    dataJoin,
    dataRenderTitle,
    currentPage,
    total,
    arrSort,
    handleSelectedPage,
    onChangeSearch,
    handleChangeSelect,
    refreshData,
    txtSearch,
    handleClear

  } = useListJoin({ id })
  const { address, connected, selectWallet } = useWallet()
  const { openWalletModal } = useWalletModal()
  const chainActive = useSelector((state) => state.chainActive)

  // social
  const social = get(item, 'social', [])

  const telegram = social.find((item) => item.title === 'telegram')
  const twitter = social.find((item) => item.title === 'twitter')
  const link = social.find((item) => item.title === 'link')

  // status
  const toDay = new Date().getTime()
  const isGoing = item?.start < toDay && item?.end > toDay
  const isEnd = item?.end <= toDay
  const status = isGoing ? 'onGoing' : isEnd ? 'completed' : 'upComing'
  const reww = get(item, 'reward', '').toString()

  const textBtnActive = status === 'completed'
    ? 'campEnd'
    : isJoined === true
      ? 'joined'
      : 'joinNow'

  const textBtn = (connected || isEnd) ? textBtnActive : 'connectWallet'

  // token infor
  const rewardToken = get(item, 'tokenInfo', {})

  // reward
  const reward =
    getLength(reww) > 6
      ? formatBilion(item?.reward)
      : formatPrice(item?.reward, false, true)

  const arrSocial = [
    {
      icon: 'web_telegram-fill',
      tooltip: 'telegram',
      link: get(telegram, 'value', '#')
    },
    {
      icon: 'web_twitter-fill',
      tooltip: 'twitter',
      link: get(twitter, 'value', '#')
    },
    {
      icon: 'web_global',
      tooltip: 'link',
      link: get(link, 'value', '#')
    }
  ]

  const onCloseModal = () => {
    window.closeModal()
  }

  const onJoin = async () => {
    try {
      const res = await BaseAPI.postData('snapshot/participant', {
        id: item?.id,
        address: address
      })

      if (res?.errMess) {
        showNotificationToast(t(res?.errMess), 'error', 2000)
        window.closeModal()
      } else {
        showNotificationToast(t('joinSuccess'), 'success', 2000)
        setTimeout(() => {
          refreshData()
          loadDataJoin()
        }, 100)
      }
      window.closeModal()
    } catch (error) {
      showNotificationToast(t(error), 'error', 2000)
      window.closeModal()
    }
  }

  const onShareSnapshot = () => {
    window.openModal({
      isCustomModal: true,
      containerClass: 'modal-stake',
      noIcon: true,
      backgroundTrans: true,
      className: 'modal-content--stake',
      content: <ShareModal
        type="snapshot"
        campDetail={item}
      />,
      onCancel: () => {
        console.log('cancel')
      }
    })
  }

  // handleConfirm
  const handleImage = () => {
    window.openModal({
      className: 'modal-snapimg',
      isCustomModal: true,
      content: (
        <figure className="background-main--img">
          <img src={item?.background} className="img-fluid" alt="" />
        </figure>
      )
    })
  }

  const onConnect = async () => {
    if (!isDapp) return openWalletModal()
    try {
      const chain = CHAIN_DATA[chainActive]?.chainId
      selectWallet(WALLETS_NAME.coin98Ether, chain)
    } catch (error) {
      console.log('🚀 ~ file: index.js:93 ~ onConnect ~ error:', error)
    }
  }

  // handleConfirm
  const handleConfirm = async (e) => {
    e.preventDefault()

    if (!connected) return onConnect()

    window.openModal({
      isCustomModal: true,
      content: (
        <ModalSnapJoin
          onCancle={onCloseModal}
          onConfirm={onJoin}
          title={item?.name}
        />
      )
    })
  }
  const isEmpty = getLength(dataJoin) === 0 || getLength(item) === 0
  const isShowPagination = !isEmpty && total * 10 >= sizeSnapShot

  return (
    <div className={style['snapdetail-wrapper']}>

      <div className="snapdetail-container">
        <div className="detail-banner">
          <Row gutter={[32, 24]} align="middle">
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <div className="detail-banner__left">
                <div className="left-heading">
                  <div>
                    <AboutToken imgToken={item?.logo} isLoading={isLoading} />
                  </div>
                  <div className="left-heading__main">
                    {isLoading
                      ? (
                        <>
                          <Skeleton
                            containerClassName="skeleton-empty"
                            baseColor={isDarkMode ? '#242424' : '#bbb'}
                            width={'100%'}
                          />
                          <Skeleton
                            containerClassName="skeleton-empty"
                            baseColor={isDarkMode ? '#242424' : '#bbb'}
                            width={'100%'}
                          />
                        </>
                      )
                      : (
                        <>
                          <span className="main-owner">{item?.owner}</span>
                          <h3 className="heading-3">{item?.name}</h3>
                        </>
                      )}
                  </div>
                </div>
                <div className="left-feature">
                  <Button
                    isLoading={isLoading}
                    isDisable={isJoined || status === 'completed'}
                    onClick={handleConfirm}
                  >
                    {t(textBtn)}
                  </Button>
                  {isLoading
                    ? (
                      <Skeleton
                        containerClassName="skeleton-empty"
                        baseColor={isDarkMode ? '#242424' : '#bbb'}
                        width={'100%'}
                      />
                    )
                    : (
                      <StatusItem status={status} />
                    )}
                </div>
                <div className="left-detail">
                  {isLoading
                    ? (
                      <>
                        <Skeleton
                          containerClassName="skeleton-empty"
                          baseColor={isDarkMode ? '#242424' : '#bbb'}
                          width={'100%'}
                        />
                        <Skeleton
                          containerClassName="skeleton-empty"
                          baseColor={isDarkMode ? '#242424' : '#bbb'}
                          width={'100%'}
                        />
                      </>
                    )
                    : (
                      <>
                        <div className="left-detail__item">
                          <span className="lx-regular item-reward">
                            {reward} {rewardToken?.symbol}
                          </span>
                          <label>{t('totalReward')}</label>
                        </div>
                        <div className="left-detail__item">
                          <span className="lx-regular">
                            {convertDateMoment(item?.start, 'DD MMM')} -{' '}
                            {convertDateMoment(item?.end, 'DD MMM YYYY')}
                          </span>
                          <label>{t('duration')}</label>
                        </div>
                        <div className="left-detail__item">
                          <span className="lx-regular">{item?.type}</span>
                          <label>{t('participated')}</label>
                        </div>
                      </>
                    )}
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: item?.description?.replace('\\n\n', '<br/>').replace('\n\\n', '<br/>').replace('\n', '<br/>').replace('\\n', '<br/>')
                  }}
                />
                <div className="left-social">
                  <SnapSocial
                    dataSocial={arrSocial}
                    className="left-social--inner"
                  />
                  <Tooltip content={t('share')} className='tooltip-share'>
                    <IconCustom type={'default'} onClick={onShareSnapshot} className="icon-share" icon='app_share'/>
                  </Tooltip>
                </div>
              </div>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <div className="detail-banner__right">
                <div className="background-main">
                  <figure
                    onClick={handleImage}
                    className="background-main--img"
                  >
                    {isLoading
                      ? (
                        <Skeleton
                          containerClassName="skeleton-empty"
                          baseColor={isDarkMode ? '#242424' : '#bbb'}
                          width={'100%'}
                        />
                      )
                      : (
                        <img
                          src={item?.background}
                          className="img-fluid"
                          alt=""
                        />
                      )}
                  </figure>
                </div>
              </div>
            </Col>
          </Row>
          <div className="background-cover">
            <figure>
              <img src={images.snapGlobalHd} className="img-fluid" alt="" />
            </figure>
            <figure>
              <img src={images.snapGlobal} className="img-fluid" alt="" />
            </figure>
            <figure>
              <img src={images.snapCoverBottom} className="img-fluid" alt="" />
            </figure>
          </div>
        </div>
        <div className="detail-table">
          <TableSnap
            rewardToken={rewardToken}
            tableData={dataJoin}
            tableRender={dataRenderTitle}
            currentPage={currentPage}
            total={total}
            arrSort={arrSort}
            handleSelectedPage={handleSelectedPage}
            isShowPagination={isShowPagination}
            onChangeSearch={onChangeSearch}
            handleChangeSelect={handleChangeSelect}
            isLoadingList={isLoadingList}
            txtSearch={txtSearch}
            handleClear={handleClear}
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(SnapshotDetail)
