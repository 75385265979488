import React, { useMemo, useRef } from 'react'
import './style.scss'
import get from 'lodash/get'
import { BounceLoader } from 'react-spinners'
import { countDots, formatPrice, numberFormat, roundingNumber, upperCase, validateNumber } from 'common/functions'
import images from 'assets/images'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ListTokenBaryon from '../ListTokenBaryon'
import AboutToken from 'components/common/AboutToken'

const SelectToken = ({
  coinSelected,
  valueInput,
  className,
  isLoadingBalance,
  balance = 0,
  handleChangeInput,
  isFrom = false,
  handleSelectToken,
  isDisable,
  isLoadListToken,
  isImport,
  isSwapFrom,
  maxLength
}) => {
  const { t } = useTranslation()
  const refList = useRef(null)
  const isDarkMode = useSelector(state => state.themeRedux) === 'dark'

  const symbol = get(coinSelected, 'symbol', '')

  const isSelectedCoin = !!symbol

  const renderIcon = useMemo(() => {
    const icon = get(coinSelected, 'logoURI', '') || get(coinSelected, 'image', '')
    if (icon) return icon
    return images[isDarkMode ? 'icoUnknown' : 'icoUnknownLight']
  }, [coinSelected, isDarkMode])

  const handleChangeValue = (e) => {
    if (!isDisable) {
      const newAmount = e.target.value.replaceAll(',', '')
      if (newAmount === '') {
        handleChangeInput({ isFrom, value: newAmount })
        return
      }

      if (!validateNumber(newAmount)) return
      const dotsCount = countDots(newAmount, '\\.')
      const isDot = dotsCount === 1
      const decimalCount = isDot ? newAmount.length - newAmount.indexOf('.') - 1 : 0
      const isDotFirst = newAmount.indexOf('.') === 0
      if (isDotFirst) return
      if (dotsCount > 1) return
      if (decimalCount > 4) return

      handleChangeInput({ isFrom, value: newAmount })
    }
  }

  const handleMaxBalance = () => {
    handleChangeInput({ isFrom, value: roundingNumber(balance, 6).toString() })
  }

  const handleToggleList = () => {
    if (!isLoadListToken) {
      window.openModalToken({
        className: 'listtoken-custom',
        content: <div ref={refList} className="select-token__list">
          <ListTokenBaryon
            selectToken={selectToken}
          />
        </div>,
        noIcon: true

      })
    }
  }

  const selectToken = (token) => {
    handleSelectToken({ isFrom, token })
    window.closeModalToken()
  }

  const handleHalt = () => {
    handleChangeInput({ isFrom, value: roundingNumber(balance / 2, 6).toString() })
  }

  if (isImport) {
    return (
      <div className="select-token-wr">
        <div className={`select-token ${className}`}>
          <div onClick={handleToggleList} className={`select-token__item select-token__item--top ${isImport && 'import'}`}>
            <div className={`token-select ${isLoadListToken && 'token-select--disabled'}`}>
              <img className="token-select__image" src={renderIcon} alt="" />
              <div className="token-select__symbol">
                {upperCase(get(coinSelected, 'symbol', ''))}
              </div>

            </div>
            <img
              className="token-select__icon"
              src={images[isDarkMode ? 'iconDropdownDark' : 'iconDropdown']}
              alt=""
            />

          </div>

        </div>
      </div>
    )
  }

  return (
    <div className="select-token-wr">
      <div className={`select-token ${className}`}>
        <div className="select-token__item select-token__item--top">
          <div onClick={handleToggleList} className={`token-select ${isLoadListToken && 'token-select--disabled'}`}>
            <AboutToken className="token-select__image" imgToken={renderIcon} alt="" />
            <div className="token-select__symbol">
              {upperCase(get(coinSelected, 'symbol', ''))}
            </div>

            <img
              className="token-select__icon"
              src={images[isDarkMode ? 'iconDropdownDark' : 'iconDropdown']}
              alt=""
            />
          </div>
          <div className={'amount'}>
            {/* <NumberFormat
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              value={valueInput}
              maxLength={maxLength}
              disabled={isDisable || !isSelectedCoin}
              onValueChange={handleChangeValue}
              placeholder='0.0'
              className={`amount__input ${(isDisable || !isSelectedCoin) && 'amount__input--disabled'}`}
            /> */}

            <input
              placeholder='0.0'
              className={`amount__input ${(isDisable || !isSelectedCoin) && 'amount__input--disabled'}`}
              type="text"
              value={numberFormat(valueInput)}
              onChange={handleChangeValue}
              maxLength="25"
              disabled={isDisable || !isSelectedCoin}
            />

          </div>
        </div>
        <div className="select-token__item select-token__item--bottom">
          <div className="balance" onClick={handleMaxBalance}>
            {t('balance')}:{' '}
            <span>
              {isLoadingBalance
                ? (
                  <div className="loading-balance">
                    <BounceLoader size={16} color="#bbbbbb" />
                  </div>
                )
                : (
                  formatPrice(balance, false, true)
                )}{' '}
            </span>
          </div>

          {isSwapFrom &&
          <>
            <div className="amount">|</div>
            <div className="amount half" onClick={handleHalt}>{t('half')}</div>
          </>
          }

        </div>
      </div>
    </div>
  )
}

export default React.memo(SelectToken)
